import { useState } from 'react';
import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const TFoot = ({ isView, moduleData }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
        <th align="left" colSpan={6}>
          **NOTE: Sample lot of 10 parts is taken and inspected. If any NG is
          found, 100% dimension check is done. (FRM-CMT-07)
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={6}>
          Remarks:
          <TableInput
            isView={isView}
            name="remarks"
            type="text"
            value={moduleData?.remarks}
            onChange={(e) => {
              moduleData.remarks = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Name :
          <TableInput
            isView={isView}
            name="inspected_by"
            type="text"
            value={moduleData?.inspected_by}
            onChange={(e) => {
              moduleData.inspected_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={3} align="left">
          Signature:
          <TableInput
            isView={isView}
            name="approved_by"
            type="text"
            value={moduleData?.approved_by}
            onChange={(e) => {
              moduleData.approved_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
