import { TableInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';

const THead = ({
  isView,
  item,
  index,
  removeRow,
  handleChange,
}: {
  isView: any;
  item: any;
  removeRow: any;
  index: number;
  handleChange: any;
}) => {
  return (
    <thead>
      <tr>
        <td>{index + 1}</td>
        <td style={{ minWidth: '250px' }}>
          <TableInput
            isView={isView}
            name="check_point"
            type="text"
            value={item.check_point}
            onChange={(e) => {
              handleChange(e, index);
            }}
          />
        </td>
        <td style={{ minWidth: '250px' }}>
          <TableInput
            isView={isView}
            name="dimension"
            type="text"
            value={item.dimension}
            onChange={(e) => {
              handleChange(e, index);
            }}
          />
        </td>
        <td style={{ minWidth: '250px' }}>
          <TableInput
            isView={isView}
            name="method"
            type="text"
            value={item.method}
            onChange={(e) => {
              handleChange(e, index);
            }}
          />
        </td>
        <td style={{ minWidth: '200px' }}>
          <DeleteIconButton
            tooltipTitle="delete"
            onClick={() => removeRow(index)}
          />
        </td>
      </tr>
    </thead>
  );
};

export default THead;
