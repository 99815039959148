const TFoot = () => {
  return (
    <tfoot>
      <tr>
        <th colSpan={22} align="left">
          Legend : Q = Critical
        </th>
      </tr>
      <tr>
        <th colSpan={3}></th>
        <th colSpan={4}></th>
        <th colSpan={9}></th>
        <th colSpan={2}>FRM-ENG-06</th>
        <th colSpan={2}> </th>
        <th colSpan={2}> </th>
      </tr>
      <tr>
        <th colSpan={3}>Rev. No.</th>
        <th colSpan={4}>Revision Date</th>
        <th colSpan={9}>Description</th>
        <th colSpan={2}>Document No.</th>
        <th colSpan={2}> Prepared By:</th>
        <th colSpan={2}> Approved By:</th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
