export const listConstant = {
  childHeader: 'Gauge Maintenance list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Gauge Maintenance list',
  theadHeader: ' Gauge Maintenance',
  extracolSpan: 3,
};

export const apiConstant = {
  // list api url
  gettableListDataurl: 'gaugeMaintenancePlanRoute/monthlylist',
  deleteListurl: 'gaugeMaintenancePlanRoute',
  getListPlanByid: 'gaugeMaintenancePlanRoute',
  createListPlanByid: 'gaugeMaintenancePlanRoute',

  // -----plan--
  sync: 'gaugeMaintenancePlanRoute/handleSync',
  getAllPlanById: 'gaugeMaintenancePlanRoute/allplansbyid',
  upadateCreateAllPlan: 'gaugeMaintenancePlanRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'gaugeMaintenancePlanRoute/maintenancePlanDeleteBulkById',
};

export const tilesConstant = {
  childHeaderText: 'Gauge Maintenance',
};

export const navigationConstant = {
  planRouter: '/common/gaugeMaintenancePlanCreate',
};
