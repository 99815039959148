import { MenuItem } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import CustomSelect from '../../../../components/common/SelectField';

interface IProps {
  process: any;
  isView: boolean;
  partId: string;
  setRender:any;
}
const OperationSelect = ({ process, partId, isView ,setRender}: IProps) => {
  const [operation, setOperation] = useState([]);
  useEffect(() => {
    if (partId) {
      axios
        .get(`/api/controlPlan/controlPlan/processes/${partId}`)
        .then((res) => {
          if (res.data) {
            setOperation(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [partId]);
  return (
    <>
      {isView ? (
        process.operation_name
      ) : (
        <CustomSelect
          name="operation_name"
          value={process.operation_name || ''}
          onChange={(e) => {
            process.operation_name = e.target.value;
            setRender((prev:any) => !prev);
          }}
        >
          <MenuItem value="">None</MenuItem>
          {operation.map((name) => (
            <MenuItem value={name}>{name}</MenuItem>
          ))}
        </CustomSelect>
      )}
    </>
  );
};

export default OperationSelect;
