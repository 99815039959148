import axios from 'axios';
import { useState, useEffect } from 'react';
import useConfirm from '../../../../../components/common/useConfirm';

const useGetData = (id: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({ list: [] });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tniTopic, setTniTopic] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [Sync, confirmSync] = useConfirm(
    'Row',
    'Are you sure you want to add row?'
  );

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const addRow = async () => {
    const ans = await confirmSync();
    if (!ans) return;
    let values = { ...moduleData };
    values.list.push({});
    setModuleData(values);
  };
  useEffect(() => {
    setIsLoading(true);
    if (id) {
      axios
        .get(`/api/skillMatrixRoute/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (moduleData.category_id) {
      axios
        .get(
          `/api/skillEvaluationTopicRoute/getTopicByCategory/${moduleData.category_id}`
        )
        .then((res) => {
          if (res.data) {
            setTniTopic(res.data.topics);
          }
        })
        .catch((err) => {
          setError(err);
        });
    }
  }, [moduleData]);

  const handleDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.list.splice(rowIdx, 1);
    setModuleData(data);
  };

  useEffect(() => {
    axios
      .get('/api/employee/employeeList/customerForAutoComplete')
      .then((res) => {
        setEmployeeData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return {
    DialogDeleteRow,
    handleDeleteRows,
    moduleData,
    tniTopic,
    isLoading,
    error,
    addRow,
    Sync,
    employeeData,
  };
};

export default useGetData;
