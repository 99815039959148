import { Link } from 'react-router-dom';

import { HomeContainer } from '../../assets/styles/layout/home.styled';
import { useAuth } from '../../services/auth/AuthProvider';

interface Iprop {
  tilesObj: {
    id: number;
    link: string;
    isLink: boolean;
    isIcon: boolean;
    clauseNo?: string | number;
    target?: string;
    p: string;
    span: string;
    icon?: any;
    red?: string;
    style?: {};
  }[];
  height?: string;
  justify?: string;
  columns?: string;
}

const AdminHomeRenderTiles = ({
  tilesObj,
  height,
  justify,
  columns,
}: Iprop): JSX.Element => {
  const { state } = useAuth();
  return (
    <HomeContainer height={height} justify={justify} columns={columns}>
      {tilesObj?.map((tiles, index: number) => {
        return !state.user?.clauseIds ? (
          <div key={index + tiles?.id}>
            <Link to={tiles?.link} target={tiles?.target} className="panel">
              {tiles.isIcon ? (
                tiles.icon
              ) : (
                <span
                  className="numberingIcon"
                  style={{ color: tiles.red ? '#a12222' : '#003566' }}
                >
                  {tiles?.clauseNo}
                </span>
              )}
            </Link>
            <p className="card-btn-para">
              {tiles?.p}
              <span className="card-btn-span">{tiles?.span}</span>
            </p>
          </div>
        ) : (
          state.user?.clauseIds.includes(`${tiles.id}`) && (
            <div key={index + tiles?.id}>
              <Link to={tiles?.link} target={tiles?.target} className="panel">
                {tiles.isIcon ? (
                  tiles.icon
                ) : (
                  <span
                    className="numberingIcon"
                    style={{ color: tiles.red ? '#a12222' : '#003566' }}
                  >
                    {tiles?.clauseNo}
                  </span>
                )}
              </Link>
              <p className="card-btn-para">
                {tiles?.p}
                <span className="card-btn-span">{tiles?.span}</span>
              </p>
            </div>
          )
        );
      })}
    </HomeContainer>
  );
};
export default AdminHomeRenderTiles;
