import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import MenuItem from '@mui/material/MenuItem';
import { IData } from './Interface';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import SelectGridMui from '../../components/common/selectscustom/SelectGridMui';
import useConfirm from '../../components/common/useConfirm';
import moment from 'moment';
import DateGridMui from '../../components/common/dateCustom/DateGridMui';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [supplier, setSupplier] = useState<IData>({
    supplier_name: null,
    supplier_code: null,
    address: null,
    material: null,
    rm_grade: null,
    category: null,
    contact_person: null,
    contact_number: null,
    email_id: null,
    gst_no: null,
    remark: null,
    supplier_date: moment(),
    iso_certified_trader: null,
  });
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Supplier' : 'Create Supplier'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/supplierRoutes/${id}`)
        .then((res) => setSupplier(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleSupplier(e: React.ChangeEvent<HTMLInputElement>) {
    setSupplier((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/supplierRoutes/${id}`, supplier)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/supplierRoutes', supplier)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'supplier_name'}
        name={'supplier_name'}
        html_for={'supplier_name'}
        label_name={'Party Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={supplier?.supplier_name || ''}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'supplier_code'}
        name={'supplier_code'}
        html_for={'supplier_code'}
        label_name={'Supplier Code:'}
        input_type={'text'}
        focusType={'onblur'}
        value={supplier?.supplier_code || ''}
        onChange={handleSupplier}
      />
      {/* <GridInputs
        id={'material'}
        name={'material'}
        html_for={'material'}
        label_name={'Material:'}
        input_type={'text'}
        focusType={'onblur'}
        value={supplier?.material || ''}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'rm_grade'}
        name={'rm_grade'}
        html_for={'rm_grade'}
        label_name={'Grade:'}
        input_type={'text'}
        focusType={'onblur'}
        value={supplier?.rm_grade || ''}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'address'}
        name={'address'}
        html_for={'address'}
        label_name={'Address:'}
        input_type={'text'}
        focusType={'onblur'}
        value={supplier?.address || ''}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'contact_person'}
        name={'contact_person'}
        html_for={'contact_person'}
        label_name={'Contact Person:'}
        input_type={'text'}
        focusType={'onblur'}
        value={supplier?.contact_person || ''}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'contact_number'}
        name={'contact_number'}
        html_for={'contact_number'}
        label_name={'Contact Number:'}
        input_type={'number'}
        focusType={'onblur'}
        value={supplier?.contact_number || ''}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'email_id'}
        name={'email_id'}
        html_for={'email_id'}
        label_name={'Email Id:'}
        input_type={'text'}
        focusType={'onblur'}
        value={supplier?.email_id || ''}
        onChange={handleSupplier}
        last_child={2}
      />
      <GridInputs
        id={'category'}
        name={'category'}
        html_for={'category'}
        label_name={'Category:'}
        input_type={'text'}
        focusType={'onblur'}
        value={supplier?.category || ''}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'gst_no'}
        name={'gst_no'}
        html_for={'gst_no'}
        label_name={'GSTIN No.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={supplier?.gst_no || ''}
        onChange={handleSupplier}
      />
      <SelectGridMui
        name="iso_certified_trader"
        value={supplier?.iso_certified_trader || ''}
        lable={'ISO Certified/Trader:'}
        onChange={(e: any) => handleSupplier(e)}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </SelectGridMui>
      <DateGridMui
        lable="Party Date:"
        onChange={(date: any) => {
          supplier.supplier_date = date;
          setRender((prev) => !prev);
        }}
        value={moment(supplier.supplier_date) || null}
      /> */}
      <GridInputs
        id={'remark'}
        name={'remark'}
        html_for={'remark'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={supplier?.remark || ''}
        onChange={handleSupplier}
        last_child={2}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
