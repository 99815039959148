import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={11}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No : ', 'Eff. Date:']}
        values={['RM-QAS-A-010', '00', '01-04-2023']}
        heading="Process & Product Audit Summary Sheet"
      />
      <tr>
        <th colSpan={3} align="left">
          Month :
          <TableInput
            name="month"
            type="text"
            value={moduleData.month}
            onChange={(e: any) => {
              moduleData.month = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={8}></th>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'S.No',
          'Audit Date',
          'Part Name',
          'Part No.',
          'Conforming / Not Conforming(NC)',
          'Total No. of Ncs.',
          'NC Status',
          'NC Closing Date',
          'Audit Status',
        ].map((head, index) => (
          <th
            key={head}
            colSpan={index === 6 ? 2 : 1}
            rowSpan={index === 6 ? 1 : 2}
            style={
              index === 2
                ? { minWidth: '300px', textTransform: 'uppercase' }
                : { minWidth: '', textTransform: 'uppercase' }
            }
          >
            {head}
          </th>
        ))}
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {['Open', 'Close'].map((head, index) => (
          <th key={head + index} style={{ textTransform: 'uppercase' }}>
            {head}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
