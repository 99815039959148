import { Box } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';
// import { useEffect, useState } from 'react';
// import axios from 'axios';

interface IProps {
  moduleData: IData;
  isView: boolean;
  handleSumbit: any;
  // customerData: any;
  // handelDeleteRows: any;
  // isOpenPopup: any;
  // setIsOpenPopup: any;
  // setPartIdx: any;
}

const TController = ({
  moduleData,
  // customerData,
  isView,
}: // handelDeleteRows,
// setIsOpenPopup,
// setPartIdx,
IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            // handelDeleteRows,
            // customerData,
            // isOpenPopup,
            // setIsOpenPopup,
            // setPartIdx,
          }}
        />
        <TFoot
          preparedBy={moduleData.report_prepared_by}
          approvedBy={moduleData.report_approved_by}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
