import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { TableCustom } from '../../../../../components/common';
import {
  BlockIconButton,
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import { useAuth } from '../../../../../services/auth/AuthProvider';
import CustomUploadButton from '../../../../../components/common/button/CustomUploadButton';
import { UpdateButton } from '../../../../../components/common/button/CustomButtons';

interface IProps {
  deleteDocument: (id: string) => void;
  tableData: {
    [key: string]: string;
  }[];
  uploadImage: (id: string) => void;
  setImages: React.Dispatch<React.SetStateAction<null>>;
}

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ITEM NUMBER',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ITEM NAME/DESCRIPTION',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'WELD SHOP INSPECTION DATE',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'NAME',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'STATUS',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'VIEW',
      colspan: 1,
      rowspan: 1,
    },
  ],
];
const SupervisorTable = ({
  setImages,
  uploadImage,
  deleteDocument,
  tableData,
}: IProps) => {
  const navigate = useNavigate();
  const { state } = useAuth();
  const { user } = state;
  return (
    <React.Fragment>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {tableData.map(
            (document: { [key: string]: any }, documentIndex: number) => (
              <TableRow>
                <TableCell align="center">{documentIndex + 1}</TableCell>
                <TableCell align="center">
                  {document.part_id?.part_number}
                </TableCell>
                <TableCell align="center">
                  {document.part_id?.part_name}
                </TableCell>
                <TableCell align="center">
                  {formatDate(document.pir_date)}
                </TableCell>
                <TableCell align="center">
                  {document.report_prepared_by}
                </TableCell>

                {/*<TableCell align="center">
                  <CustomUploadButton
                    lable={'UPLOAD DRAWING'}
                    accept="image/png,image/jpeg,image/jpg"
                    onChange={(e) => setImages(e.target.files)}
                  />
                </TableCell>*/}
                <TableCell align="center">
                  {document.isSubmitted ? (
                    <div
                      style={{
                        fontSize: '1.4rem',
                        outline: 'none',
                        padding: '5px',
                        textAlign: 'center',
                        width: '200px',
                        margin: 'auto',
                        textTransform: 'uppercase',
                        ...(document.is_admin_request === 'pending' && {
                          backgroundColor: '#FDD835',
                        }),
                        ...(document.is_admin_request === 'accepted' && {
                          backgroundColor: '#2b8a3e',
                          color: '#fff',
                        }),
                        ...(document.is_admin_request === 'rejected' && {
                          backgroundColor: '#f03e3e',
                          color: '#fff',
                        }),
                      }}
                    >
                      {document.is_admin_request === 'pending' &&
                        'pending for approval'}
                      {document.is_admin_request === 'accepted' && 'accepted'}
                      {document.is_admin_request === 'rejected' && 'rejected'}
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: '#FDD835',
                        fontSize: '1.4rem',
                        outline: 'none',
                        padding: '5px',
                        textAlign: 'center',
                        width: '200px',
                        margin: 'auto',
                      }}
                    >
                      {document.isSubmitted ? 'SUBMITTED' : 'DRAFT'}
                    </div>
                  )}
                </TableCell>
                <TableCell align="center">
                  <ViewIconButton
                    tooltipTitle="view"
                    onClick={() =>
                      navigate(
                        `/${
                          user?.userType === 2 ? 'common' : 'common'
                        }/bopcreate/${document.part_id?._id}/${
                          document._id
                        }?isView=true&partName=${document.part_id
                          ?.part_name}&partNumber=${document.part_id
                          ?.part_number}`,
                      )
                    }
                  />

                  {!document.isSubmitted &&
                    document.is_admin_request !== 'rejected' && (
                      <>
                        <EditIconButton
                          tooltipTitle="edit"
                          onClick={() =>
                            navigate(
                              `/common/bopcreate/${document.part_id?._id}/${document._id}?isView=false&partName=${document.part_id?.part_name}&partNumber=${document.part_id?.part_number}`,
                            )
                          }
                        />
                        <DeleteIconButton
                          tooltipTitle="delete"
                          onClick={() => deleteDocument(document._id)}
                        />
                      </>
                    )}
                  {document.is_admin_request === 'rejected' && (
                    <>
                      <EditIconButton
                        tooltipTitle="edit"
                        onClick={() =>
                          navigate(
                            `/common/bopcreate/${document.part_id?._id}/${document._id}?isView=false`,
                          )
                        }
                      />
                      <DeleteIconButton
                        tooltipTitle="delete"
                        onClick={() => deleteDocument(document._id)}
                      />
                    </>
                  )}
                  {/*<UpdateButton
                    label="update img"
                    onClick={() => uploadImage(document._id)}
                  />*/}
                </TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </TableCustom>
    </React.Fragment>
  );
};

export default SupervisorTable;
