import React, { useEffect, useState } from 'react';
import TableCustom from '../../components/common/customTable/TableCustom';
import {
  Stack,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { SearchBar } from '../../components/common/SearchBar';
import FilterBy from '../../components/common/FilterBy';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import ChildHeader from '../../components/ui/ChildHeader';
import axios from 'axios';
import AddEditPart, { IPart } from '../addparts/AddEditPart';
import useDebounce from '../../hooks/useDebounce';
import { useAuth } from '../../services/auth/AuthProvider';
import formatDate from '../../components/common/formatDate';
import {
  EditIconButton,
  ViewIconButton,
  DeleteIconButton,
  CustomIconButton,
} from '../../components/common/button/CustomIconButton';
import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import { useNavigate } from 'react-router-dom';
import { CustomPagination } from '../../components/common';
import ConfirmDialogueForInput from '../../components/common/ConfirmDialogueForInput';
import TableCellStatus from './components/TabcellStatus';
import {
  AIButton,
  CustomButton,
} from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import Aimodal from '../addparts/Aimodal';
import { MdLock } from 'react-icons/md';
import { CiLock, CiUnlock } from 'react-icons/ci';

const filterCategoryOptions = [
  { value: '', name: 'NONE' },
  { value: 'BOP', name: 'BOP' },
  { value: 'MIG WELDING', name: 'MIG WELDING' },
  { value: 'PLATING & POWDER COATING', name: 'PLATING & POWDER COATING' },
  { value: 'PRESS SHOP', name: 'PRESS SHOP' },
  { value: 'WELD SHOP', name: 'WELD SHOP' },
];
const ViewAdminPpapDoc = () => {
  const { state } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState<IPart[]>([]);
  const [isNewPartModal, setIsNewPartModal] = useState(false);
  const [render, setRender] = useState(false);
  const [dropDownRender, setDropDownRender] = useState(false);
  const [duplicateOpenModal, setDuplicateOpenModal] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [partId, setPartId] = useState<string | null>('');
  const [itemPartNumber, setItemPartNumber] = useState<string | null>(null);
  const [confirmOpenForDelete, setConfirmOpenForDelete] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState<string | null>(null);

  const [filterCustomer, setFilterCustomer] = useState<any[]>([]);
  const [DialogConfirmAi, confirmSubmitAi] = useConfirm(
    'AI ✨',
    'Do you want to confirm using AI for analysis?'
  );
  const [aiModal, setAiModal] = useState(false);

  const search = useQuery('search');
  const category = useQuery('category');
  const customer = useQuery('customer');
  const page = useQuery('page') || 1;

  const tableHeaderObj: any = [
    [
      { text: '#', rowspan: 1, colspan: 1 },
      { text: 'Item Number', rowspan: 1, colspan: 1 },
      { text: 'Item Description', rowspan: 1, colspan: 1 },
      { text: 'Customer', rowspan: 1, colspan: 1 },
      { text: 'Category', rowspan: 1, colspan: 1 },
      { text: 'Wig No.', rowspan: 1, colspan: 1 },
      { text: 'Created At', rowspan: 1, colspan: 1 },
      { text: 'ControlPlan Status', rowspan: 1, colspan: 1 },
      { text: 'AI Analysis', rowspan: 1, colspan: 1 },
      state.user?.role === 'superuser' && {
        text: 'Lock',
        rowspan: 1,
        colspan: 1,
      },
      { text: 'Action', rowspan: 1, colspan: 1 },
    ].filter(Boolean),
  ];

  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        if (res.data) {
          const formattedData = res.data.map((customer: any) => ({
            name: customer.customer_name,
            value: customer.customer_name,
          }));
          setFilterCustomer([{ name: 'None', value: '' }, ...formattedData]);
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const fetchData = async () => {
    console.log(state);
    const res: any = await axios
      .get(
        `/api/addPartRoute/viewPpapPart/publish?page=${page}&customer=${customer}&category=${category}&search=${search}&perPage=${10}&role=${
          state?.user?.role
        }&userId=${state.user?._id}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    render,
    customer,
    category,
    duplicateOpenModal,
    isNewPartModal,
  ]);
  const deletePart = async (id: string) => {
    await axios.delete(`/api/addPartRoute/${id}`);
    setRender((prev) => !prev);
  };

  const handleDialogWithInput = () => {
    if (!partId) return;
    if (itemPartNumber === textFieldValue) {
      deletePart(partId);
      setPartId(null);
      setTextFieldValue(null);
      setConfirmOpenForDelete(false);
    }
  };

  const handleLock = async (isLocked: boolean, id: any) => {
    axios
      .patch(`/api/addPartRoute/partList/lock/${id}`, {
        isLocked,
      })
      .then((res) => {
        alert('Successfully Updated');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      <ChildHeader text="PART LIST"></ChildHeader>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        sx={{
          justifyItems: 'center',
          alignItems: 'center',
          margin: '15px',
          width: '100%',
          gap: 2,
        }}
      >
        <SearchBar
          label="Search Part"
          sx={{ width: { xs: '100%', sm: '67%' } }}
        />
        <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ gap: 2 }}>
          <FilterBy
            sx={{ minWidth: '190px', maxWidth: '190px' }}
            name="customer"
            label="Filter By Customer"
            options={filterCustomer}
            variant={'outlined'}
          />
          <FilterBy
            sx={{ minWidth: '180px', maxWidth: '180px' }}
            name="category"
            label="Filter By Category"
            options={filterCategoryOptions}
            variant={'outlined'}
          />
        </Stack>
      </Stack>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IPart, index: number) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell align="center" width={10}>
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.part_number}</TableCell>
                  <TableCell align="center">{item?.part_name}</TableCell>
                  <TableCell align="center">
                    {item?.customer_id &&
                      typeof item?.customer_id !== 'string' &&
                      item.customer_id.customer_name}
                  </TableCell>
                  <TableCell align="center">{item?.category} </TableCell>
                  <TableCell align="center">{item?.wig_no}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.createdAt || '')}
                  </TableCell>
                  <TableCellStatus partId={item._id} />
                  <TableCell align="center">
                    <AIButton
                      label="AI"
                      onClick={async () => {
                        const ans = await confirmSubmitAi();
                        if (!ans) return;
                        setPartId(item?._id!);
                        setAiModal(true);
                      }}
                    />
                  </TableCell>
                  {state.user?.role === 'superuser' && (
                    <TableCell align="center">
                      <CustomIconButton
                        sx={{ p: 0 }}
                        tooltipTitle={'Lock'}
                        onClick={async () => {
                          await handleLock(!item.isLocked, item?._id);
                          setRender((prev) => !prev);
                        }}
                      >
                        {item.isLocked ? (
                          <CiLock
                            style={{
                              color: '#51cf66',
                              fontSize: '26px',
                            }}
                          />
                        ) : (
                          <CiUnlock
                            style={{ color: '#c92a2a', fontSize: '26px' }}
                          />
                        )}
                      </CustomIconButton>
                    </TableCell>
                  )}
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setPartId(item?._id!);
                          setIsNewPartModal(true);
                        }}
                      />
                      <ViewIconButton
                        tooltipTitle="VIEW"
                        onClick={() => {
                          navigate(
                            `/admin/ppapTiles/${item?._id}?part_name=${item.part_name}&part_number=${item.part_number}&customer_name=${item.customer_id?.customer_name}`
                          );
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          // deletePart(item?._id!);
                          setItemPartNumber(item?.part_number);
                          setPartId(item?._id!);
                          setConfirmOpenForDelete(true);
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ConfirmDialogueForInput
        title="DELETE PART"
        open={confirmOpenForDelete}
        setOpen={setConfirmOpenForDelete}
        onConfirm={handleDialogWithInput}
        isSubmitContent={false}
      >
        <p style={{ margin: 0, fontSize: '1.6rem' }}>
          Are you sure you want to delete this part?
        </p>
        <p style={{ margin: 0, fontSize: '1.6rem' }}>
          Enter the <b>Part Number :</b> "{`${itemPartNumber}`}" to confirm part
          deletion:
        </p>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          autoComplete="false"
          label="Item Number"
          type="text"
          fullWidth
          variant="standard"
          onChange={(e) => setTextFieldValue(e.target.value)}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
        />
      </ConfirmDialogueForInput>
      <ModalCustom
        openModal={isNewPartModal}
        closeModal={() => {
          setPartId(null);
          setIsNewPartModal(false);
        }}
        title="UPDATE PART"
      >
        <AddEditPart
          setIsNewPartModal={setIsNewPartModal}
          id={partId}
          setPartId={setPartId}
        />
      </ModalCustom>
      <ModalCustom
        title={'AI ANALYSIS'}
        dialogTitleCustomBackground={'#f8f9fa'}
        dialogTitleCustomColor={'#343a40'}
        isRequireCloseButton={false}
        openModal={aiModal}
        closeModal={() => {
          setAiModal(false);
        }}
      >
        <Aimodal partId={partId} setAiModal={setAiModal} />
      </ModalCustom>
      <DialogConfirmAi />
    </div>
  );
};

export default ViewAdminPpapDoc;
