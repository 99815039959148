import React from 'react';
import { FlexBox } from '../../../../../../components/common';
import {
  AddProcessButton,
} from '../../../../../../components/common/button/CustomButtons';

interface IProps {
  moduleData: any;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  setModuleData: React.Dispatch<React.SetStateAction<any>>;
  setExpandState: React.Dispatch<React.SetStateAction<any>>;
}
const Heading = ({
  moduleData,
  setExpandState,
}: IProps) => {
  return (
    <FlexBox
      justify="space-between"
      style={{
        borderBottom: '1px solid #c80202',
        width: '99%',
        margin: '0 auto',
      }}
    >
      <p style={{ color: '#c80202', fontSize: '2rem' }}>
        • ADD <span style={{ fontWeight: 500, color: 'black' }}>QUESTION</span>
      </p>
      <FlexBox>
        <AddProcessButton
          label="add Question"
          onClick={() => {
            moduleData &&
              moduleData?.topics.push({
                s_no: moduleData?.topics.length + 1,
                name: '',
                type: '',
              });
            setExpandState((prev: any) => {
              prev[moduleData?.topics.length - 1] = true;
              return [...prev];
            });
          }}
        />
      </FlexBox>
    </FlexBox>
  );
};

export default Heading;
