import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import { IData } from './helpers/Interface';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';

const Create = ({
  id,
  setIsModal,
}: {
  id?: null | string;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleDate, setModuleData] = useState<IData>({
    spare_part_name: '',
    spare_part_desc: '',
    opening_stock_UOM: '',
    min_stock_qty: null,
    max_stock_qty: null,
    reorder_level: null,
    min_qty_remarks: '',
    max_qty_remarks: '',
    remarks: '',
  });
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update ' : 'Create '}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/criticalSparePartsRoute/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleModuleData(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/criticalSparePartsRoute/${id}`, moduleDate)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/criticalSparePartsRoute', moduleDate)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'spare_part_name'}
        name={'spare_part_name'}
        html_for={'spare_part_name'}
        label_name={'Spare Part Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleDate?.spare_part_name || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'spare_part_desc'}
        name={'spare_part_desc'}
        html_for={'spare_part_desc'}
        label_name={'Spare Part Description:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleDate?.spare_part_desc || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'opening_stock_UOM'}
        name={'opening_stock_UOM'}
        html_for={'opening_stock_UOM'}
        label_name={'UOM:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleDate?.opening_stock_UOM || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'min_stock_qty'}
        name={'min_stock_qty'}
        html_for={'min_stock_qty'}
        label_name={'Minimum Qty:'}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleDate?.min_stock_qty || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'min_qty_remarks'}
        name={'min_qty_remarks'}
        html_for={'min_qty_remarks'}
        label_name={'Minimum Qty Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleDate?.min_qty_remarks || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'max_stock_qty'}
        name={'max_stock_qty'}
        html_for={'max_stock_qty'}
        label_name={'Maximum Qty:'}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleDate?.max_stock_qty || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'max_qty_remarks'}
        name={'max_qty_remarks'}
        html_for={'max_qty_remarks'}
        label_name={'Maximum Qty Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleDate?.max_qty_remarks || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'reorder_level'}
        name={'reorder_level'}
        html_for={'reorder_level'}
        label_name={'Reorder level:'}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleDate?.reorder_level || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleDate?.remarks || ''}
        onChange={handleModuleData}
        last_child={2}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
