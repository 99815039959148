import TBodyRow from './TBodyRow';

interface IProps {
  processes: any;
  isView: any;
  handleChangeTbody: (e: any, processIndex: number, fmeaIndex?: number) => void;
  removeFmeas: (processIndex: number, data: any) => void;
  addFmeas: (processIndex: number, data: any) => void;
}
const TBody = ({
  removeFmeas,
  handleChangeTbody,
  processes,
  isView,
  addFmeas,
}: IProps) => {
  return (
    <tbody>
      {processes.map((process: any, processIndex: number) => {
        return (
          <>
            {process.fmeas.map((fmea: any, fmeaIndex: number) => (
              <TBodyRow
                key={`${processIndex}-${fmeaIndex}`}
                rowSpan={process.fmeas.length}
                {...{
                  process,
                  fmea,
                  handleChangeTbody,
                  isView,
                  processIndex,
                  fmeaIndex,
                  removeFmeas,
                  addFmeas,
                }}
              />
            ))}
            <tr key={`empty-row-${processIndex}`} style={{ height: '50px' }}>
              <td
                colSpan={29}
                style={{
                  backgroundColor: 'rgb(79, 129, 189)',
                }}
              ></td>
            </tr>
          </>
        );
      })}
    </tbody>
  );
};

export default TBody;
