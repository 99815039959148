import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController
);

const options: any = {
  plugins: {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: '',
    },
  },
  responsive: true,
  scales: {
    x: {
      title: {
        display: true,
        text: '',
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        //stepSize: 5,
      },
      title: {
        display: true,
        text: 'MTBF',
      },
    },
  },
};

interface IProps {
  moduleData: any;
}
const BarLineChart = ({ moduleData }: IProps) => {
  const targetData = [
    moduleData.target_april,
    moduleData.target_may,
    moduleData.target_june,
    moduleData.target_july,
    moduleData.target_august,
    moduleData.target_september,
    moduleData.target_october,
    moduleData.target_november,
    moduleData.target_december,
    moduleData.target_january,
    moduleData.target_february,
    moduleData.target_march,
  ];
  const mtbfData = [
    moduleData?.list?.[moduleData?.list?.length - 1].april,
    moduleData?.list?.[moduleData?.list?.length - 1].may,
    moduleData?.list?.[moduleData?.list?.length - 1].june,
    moduleData?.list?.[moduleData?.list?.length - 1].july,
    moduleData?.list?.[moduleData?.list?.length - 1].august,
    moduleData?.list?.[moduleData?.list?.length - 1].september,
    moduleData?.list?.[moduleData?.list?.length - 1].october,
    moduleData?.list?.[moduleData?.list?.length - 1].november,
    moduleData?.list?.[moduleData?.list?.length - 1].december,
    moduleData?.list?.[moduleData?.list?.length - 1].january,
    moduleData?.list?.[moduleData?.list?.length - 1].february,
    moduleData?.list?.[moduleData?.list?.length - 1].march,
  ];

  console.log('targetData', mtbfData);
  const data: any = {
    labels: [
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
      'Jan',
      'Feb',
      'Mar',
    ],
    datasets: [
      {
        type: 'bar',
        label: 'Actual MTBF per month',
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        data: mtbfData,
        barThickness: 50,
      },
      {
        type: 'line',
        label: 'Target MTBF per month',
        fill: false,
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 2,
        data: targetData,
      },
    ],
  };
  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarLineChart;
