import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TController from './components/view/TController';
const View = () => {
  const { categoryId } = useParams();
  const [moduleData, setModuleData] = useState<any>();
  useEffect(() => {
    if (categoryId) {
      axios
        .get(`/api/skillEvaluationTopicRoute/getOneTopicPaper/${categoryId}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [categoryId]);

  return (
    <Box>
      <TController {...{ moduleData }} />
    </Box>
  );
};

export default View;
