import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { Box, Checkbox, Divider, Stack } from '@mui/material';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import { TimePicker } from '@mui/x-date-pickers';
import { TableInput } from '../../../../components/common';
import { convToTime } from '../../../../utils/helpers';

interface IProps {
  moduleData: any;
  isView: boolean;
  machineData: any;
  toolsData: any;
  departmentData: any;
  partData: any;
  setRender: any;
}

const theadData = [
  'Breakdown Details',
  'Action Taken',
  'Spare Parts Used',
  'Remarks',
];

const THead = ({
  moduleData,
  isView,
  machineData,
  toolsData,
  departmentData,
  partData,
  setRender,
}: IProps) => {
  return (
    <thead>
      <TableCompHead
        colSpan={5}
        rowSpan={3}
        labels={['Doc No.:', 'Rev No.:', 'Eff. Date:']}
        values={['FRM-MNT-0-004', '00', '01-04-2023']}
        heading="Break Down Slip"
      />
      <tr>
        <th colSpan={5} align="left">
          Type of Breakdown
        </th>
      </tr>

      <tr>
        {['Mechanical', 'Electrical', 'Electronic', 'Fixture'].map(
          (option, index) => (
            <th key={option + index} colSpan={index === 3 ? 2 : 1} align="left">
              {option}
              {/* {isView && (moduleData.type_of_breakdown === option ? '⬤' : '◯')} */}

              <Checkbox
                checked={moduleData.type_of_breakdown === option}
                onChange={(e) => {
                  moduleData.type_of_breakdown = option;

                  setRender((prev: any) => !prev);
                }}
                disabled={isView}
              />
            </th>
          ),
        )}
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Machine Name/Part No./Tool Name :
          {moduleData?.machine_id?.machine_name ||
            moduleData?.machine_id_autocomplete?.machine_name}
          {moduleData?.machine_id && moduleData?.part_id && ' / '}
          {moduleData?.part_id?.part_number}
          {moduleData?.part_id && moduleData?.tool_id && ' / '}
          {moduleData?.tool_id?.tool_name ||
            moduleData?.tool_id_autocomplete?.tool_name}
        </th>
      </tr>
      <tr>
        <th align="left">
          Reported By:
          <TableInput
            isView={isView}
            name="report_prepared_by"
            type="text"
            value={moduleData.report_prepared_by}
            onChange={(e) => {
              moduleData.report_prepared_by = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
        <th align="left">
          Completed By:
          <TableInput
            isView={isView}
            name="report_approved_by"
            type="text"
            value={moduleData.report_approved_by}
            onChange={(e) => {
              moduleData.report_approved_by = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
        <th align="left">
          Reported By Date:
          {isView ? (
            formatDate(moduleData.report_prepared_by_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.report_prepared_by_date || null)}
              onChange={(date) => {
                moduleData.report_prepared_by_date = date;
                setRender((prev: any) => !prev);
              }}
            />
          )}
        </th>
        <th align="left" colSpan={2}>
          Completed By Date:
          {isView ? (
            formatDate(moduleData.report_approved_by_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.report_approved_by_date || null)}
              onChange={(date) => {
                moduleData.report_approved_by_date = date;
                setRender((prev: any) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Machine No./Part No./Tool Name :
          {isView &&
            (moduleData?.machine_id?.machine_no ||
              moduleData?.machine_id_autocomplete?.machine_no)}
          {isView && '/'}
          {isView && moduleData?.part_id?.part_number}
          {isView && '/'}
          {isView &&
            (moduleData?.tool_id?.tool_name ||
              moduleData?.tool_id_autocomplete?.tool_name)}
          {!isView && (
            <Stack direction="row" spacing={2}>
              <AutocompleteMuiCustom
                id="Machine No."
                label="Machine"
                option_name="machine_no"
                key={'machine'}
                arrayofObj={machineData}
                value={
                  moduleData?.machine_id_autocomplete ||
                  moduleData?.machine_id ||
                  ''
                }
                onChange={(e, value) => {
                  moduleData.machine_id = value?._id;
                  moduleData.machine_id_autocomplete = value;
                  setRender((prev: any) => !prev);
                }}
                sx={{ width: '350px' }}
              />
              <AutocompleteMuiCustom
                id="Part Name."
                label="Part"
                option_name="part_name"
                key={'part'}
                arrayofObj={partData}
                value={moduleData?.part_id || ''}
                onChange={(e, value) => {
                  moduleData.part_id = value;
                  setRender((prev: any) => !prev);
                }}
                sx={{ width: '350px' }}
              />
              {moduleData.part_id && (
                <AutocompleteMuiCustom
                  id="Tools"
                  label="Tool Name."
                  option_name="tool_name"
                  key={'tools'}
                  arrayofObj={toolsData}
                  value={
                    moduleData?.tool_id_autocomplete ||
                    moduleData?.tool_id ||
                    ''
                  }
                  onChange={(e, value) => {
                    moduleData.tool_id = value?._id;
                    moduleData.tool_id_autocomplete = value;
                    setRender((prev: any) => !prev);
                  }}
                  sx={{ width: '350px' }}
                />
              )}
            </Stack>
          )}
        </th>
        <th colSpan={3} align="left">
          Date :
          {isView ? (
            formatDate(moduleData.moduleData_Date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.moduleData_Date)}
              onChange={(date) => {
                moduleData.moduleData_Date = date;
                setRender((prev: any) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Department:
          {isView ? (
            moduleData?.department_id?.department_name
          ) : (
            <AutocompleteMuiCustom
              id="Department"
              label=""
              option_name="department_name"
              key={'tools'}
              arrayofObj={departmentData}
              value={
                moduleData?.department_id_autocomplete ||
                moduleData?.department_id ||
                ''
              }
              onChange={(e, value) => {
                moduleData.department_id = value?._id;
                moduleData.department_id_autocomplete = value;
                setRender((prev: any) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={3} align="left">
          Time:
          {isView ? (
            convToTime(moduleData.time)
          ) : (
            <TimePicker
              value={moment(moduleData.time)}
              onChange={(date) => {
                moduleData.time = date;
                setRender((prev: any) => !prev);
              }}
            />
          )}
        </th>
      </tr>

      <tr>
        {theadData.map((heading, index) => (
          <th key={heading + index} colSpan={heading === 'Remarks' ? 2 : 1}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
