export const listConstant = {
  childHeader: 'Measurment System Analysis  (MSA)',
  addButtonLable: 'New MSA',
  datePickerLabel: 'Search By Year',
};

export const createConstant = {
  childHeader: 'MSA Plan',
  theadHeader: 'MSA  Plan',
};

export const apiConstant = {
  deleteurl: '/api/msaRoute',
  gettableDataurl: '/api/msaRoute',
  getByid: '/api/msaRoute',
  createid: '/api/msaRoute',
  updateByid: '/api/msaRoute',
};

export const navigationConstant = {
  createPlanSchedule: '/common/CreateEditMsa',
};
