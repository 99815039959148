import { GrUser } from 'react-icons/gr';
import { TbAlertTriangleFilled, TbFaceIdError } from 'react-icons/tb';
import { FcCustomerSupport } from 'react-icons/fc';
import { GrUserWorker, GrWorkshop } from 'react-icons/gr';
// import { PiFinnTheHumanLight } from 'react-icons/pi';
import { MdDashboard, MdDriveFolderUpload } from 'react-icons/md';
import { BsFillPeopleFill } from 'react-icons/bs';

const tiles = [
  {
    sNo: 1,
    link: '/common/company_roles_responsibilities_listOfProcedures?documentType=clause8ListOfProcedure',
    isLink: true,
    icon: <MdDriveFolderUpload size="5rem" color="#003566" />,
    p: 'LIST OF',
    span: 'PROCEDURE',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/admin/customer_list?filter=createdAt',
    isLink: true,
    clauseNo: 8,
    p: 'MASTER LIST OF',
    span: 'CUSTOMER',
    icon: <BsFillPeopleFill size="5rem" color="#135089" />,
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/admin/defect_list?filter=createdAt',
    isLink: true,
    clauseNo: 8,
    icon: <TbFaceIdError size="5rem" color="#a12222" />,
    p: 'List of',
    span: 'DEFECTS',
    isIcon: true,
  },
  {
    sNo: 3,
    link: `/admin/customerComplaintRegister`,
    isLink: true,
    clauseNo: 8,
    icon: <FcCustomerSupport size="5rem" color="#003566" />,
    p: 'CUSTOMER COMPLAINT',
    span: 'REGISTER',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/admin/customerComplaintDashboard',
    isLink: true,
    clauseNo: 8,
    icon: <MdDashboard size="5rem" color="#135089" />,
    p: 'CUSTOMER COMPLAINT',
    span: 'DASHBOARD',
    isIcon: true,
  },
  // {
  //   sNo: 3,
  //   link: `/admin/CustomerReturnRegister`,
  //   isLink: true,
  //   clauseNo: 8,
  //   icon: <GrWorkshop size="5rem" color="#a12222" />,
  //   p: 'CUSTOMER RETURN',
  //   span: 'REGISTER',
  //   isIcon: true,
  // },
  // {
  //   sNo: 4,
  //   link: `/admin/CustomerReturnAndReworkRegister`,
  //   isLink: true,
  //   clauseNo: 8,
  //   icon: <PiFinnTheHumanLight size="5rem" color="#2b8a3e" />,
  //   p: 'CUSTOMER RETURN',
  //   span: '& REWORK REGISTER',
  //   isIcon: true,
  // },
  // {
  //   sNo: 3,
  //   link: '/admin/qualityAlert?documentType=qualityAlertModule&heading=QUALITY ALERT',
  //   isLink: true,
  //   clauseNo: 8,
  //   icon: <TbAlertTriangleFilled size="5rem" color="#135089" />,
  //   p: 'QUALITY',
  //   span: 'ALERT',
  //   isIcon: true,
  // },
];

export default tiles;
