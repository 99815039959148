import { TextField, FormControlLabel, Checkbox } from '@mui/material';
import React, { useState } from 'react';
import '../common/ui/rowHoverstyle.css';

const TBodySpec = ({
  moduleData,
  spec,
  sIdx,
  pIdx,
  handleSortingSpec,
}: any) => {
  const [render, setRender] = useState(false);

  const handleCheck = (e: any, pIdx: number, sIdx: number) => {
    let value = e.target.checked;
    moduleData.processes[pIdx].specs[sIdx][e.target.name] = value;
    setRender((prev) => !prev);
  };

  return (
    <>
      <tr
        className={sIdx % 2 === 0 ? 'rowEven' : 'rowOdd'}
        key={`${pIdx}-process`}
      >
        <td>
          <TextField
            label=""
            variant="outlined"
            type="number"
            size="small"
            value={spec?.s_no}
            onChange={(e) => {
              spec.s_no = +e.target.value;
              handleSortingSpec();
            }}
            sx={{ marginRight: 2, width: '100px' }}
          />
        </td>
        <td colSpan={3} style={{ textAlign: 'left' }}>
          {spec?.char_product || spec?.char_product}
        </td>
        <td>
          <FormControlLabel
            control={<Checkbox />}
            name="spec_to_pir"
            label="Line Inspection"
            sx={{ marginRight: 2 }}
            checked={spec?.spec_to_pir}
            onChange={(e) => handleCheck(e, pIdx, sIdx)}
            disabled={moduleData?.processes[pIdx]?.all_spec_to_pir}
          />
        </td>
        <td>
          <FormControlLabel
            control={<Checkbox />}
            label="Final Inspection"
            name="spec_to_pdir"
            sx={{ marginRight: 2 }}
            checked={spec?.spec_to_pdir}
            onChange={(e) => handleCheck(e, pIdx, sIdx)}
            disabled={moduleData?.processes[pIdx]?.all_spec_to_pdir}
          />
        </td>
        <td>
          <FormControlLabel
            control={<Checkbox />}
            name="spec_to_setup"
            label="FPA / LPA"
            checked={spec?.spec_to_setup}
            sx={{ marginRight: 2 }}
            onChange={(e) => handleCheck(e, pIdx, sIdx)}
            disabled={moduleData?.processes[pIdx]?.all_spec_to_setup}
          />
        </td>
      </tr>
    </>
  );
};

export default TBodySpec;
