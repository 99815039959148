import React from 'react';
import { TableInput } from '../../../../components/common';
import handleChangeSpec from '../../helpers/handleChangeSpec';
// import { handleChangeColorOnRemarks } from '../../helpers/handleChangeColorOnRemarks';
interface IProps {
  spec: any;
  isView: boolean;
  checkType: string;
  obsIndex: number;
  obsNumber: number;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
}
const ObservationInput = ({
  spec,
  isView,
  checkType,
  obsIndex,
  obsNumber,
  setRender,
}: IProps) => {
  if (checkType === 'check3') {
    return isView ? (
      spec[`check_${obsNumber}_values`][obsIndex] || ''
    ) : (
      <TableInput
        name="observation"
        type="text"
        value={spec[`check_${obsNumber}_values`][obsIndex] || ''}
        onChange={(e) => {
          handleChangeSpec(e, spec, obsIndex, obsNumber);
          setRender((prev) => !prev);
        }}
        style={{
          textAlign: 'center',
        }}
        isView={isView}
      />
    );
  }
  if (
    checkType === 'check1' ||
    checkType === 'check2' ||
    checkType === 'check4'
  ) {
    return (
      <TableInput
        name="observation"
        type="number"
        value={spec[`check_${obsNumber}_values`][obsIndex] || ''}
        onChange={(e) => {
          handleChangeSpec(e, spec, obsIndex, obsNumber);
          setRender((prev) => !prev);
        }}
        style={{
          backgroundColor: spec[`check_${obsNumber}_colors`][obsIndex],
          textAlign: 'center',
        }}
        isView={isView}
      />
    );
  }
  return <React.Fragment></React.Fragment>;
};
export default ObservationInput;
