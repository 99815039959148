import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { Checkbox } from '@mui/material';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'S.No.',
  // 'Date',
  'Part Name',
  'No. of Rejection to be Scraped',
  'Reason',
  'Sign. Production',
  'Sign. QA',
  'Remarks',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={8}
        rowSpan={3}
        labels={['Doc No.:', 'Rev No.:', 'Eff. Date:']}
        values={['FRM-QAS-A-20', '00', '01-04-2023']}
        heading="Scrap Note"
      />
      <tr>
        <th colSpan={2}>Date : </th>
        <th colSpan={6} align="left">
          {isView ? (
            formatDate(moduleData.moduleData_Date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.moduleData_Date)}
              onChange={(date) => {
                moduleData.moduleData_Date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={3}>Rejection at Setup Stage</th>
        <th colSpan={3}>Rejection at In-Process Stage</th>
        <th colSpan={2}>Rejection at Final Stage</th>
      </tr>
      <tr>
        <th colSpan={3}>
          {isView && (moduleData.rejection_at_setup === true ? '⬤' : '◯')}
          {!isView && (
            <Checkbox
              checked={moduleData.rejection_at_setup}
              onChange={(e) => {
                moduleData.rejection_at_setup = e.target.checked;
                moduleData.rejection_at_pdir = false;
                moduleData.rejection_at_pir = false;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={3}>
          {isView && (moduleData.rejection_at_pir === true ? '⬤' : '◯')}
          {!isView && (
            <Checkbox
              checked={moduleData.rejection_at_pir}
              onChange={(e) => {
                moduleData.rejection_at_pir = e.target.checked;
                moduleData.rejection_at_pdir = false;
                moduleData.rejection_at_setup = false;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={2}>
          {isView && (moduleData.rejection_at_pdir === true ? '⬤' : '◯')}
          {!isView && (
            <Checkbox
              checked={moduleData.rejection_at_pdir}
              onChange={(e) => {
                moduleData.rejection_at_pdir = e.target.checked;
                moduleData.rejection_at_pir = false;
                moduleData.rejection_at_setup = false;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading + index} colSpan={heading === 'Remarks' ? 2 : 1}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
