import { MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { TableInput } from '../../../../components/common';
import CustomSelect from '../../../../components/common/SelectField';
import handleChangeSpec from '../../helpers/handleChangeSpec';
import { handleChangeColorOnRemarks } from '../../helpers/handleChangeColorOnRemarks';
interface IProps {
  spec: any;
  isView: boolean;
  checkType: string;
  obsIndex: number;
  cavityIndex: number;
}
const ObservationInput = ({
  spec,
  isView,
  checkType,
  obsIndex,
  cavityIndex,
}: IProps) => {
  const [render, setRender] = useState(false);
  if (checkType === 'check3') {
    return (
      <>
        <td>
          <TableInput
            name="observation"
            type="text"
            value={spec?.[`check_${obsIndex}_values`]?.[cavityIndex] || ''}
            onChange={(e) => {
              handleChangeSpec(e, spec, obsIndex, cavityIndex);
              handleChangeColorOnRemarks(spec, spec.remarks);
              setRender((prev) => !prev);
            }}
            style={{
              backgroundColor:
                spec?.[`check_${obsIndex}_colors`]?.[cavityIndex],
              textAlign: 'center',
            }}
            isView={isView}
          />
        </td>
        <td style={{ textTransform: 'uppercase' }}>
          {isView ? (
            spec[`check_${obsIndex}_results`]?.[cavityIndex] || ''
          ) : (
            <CustomSelect
              name="dropdown"
              value={spec?.[`check_${obsIndex}_results`]?.[cavityIndex] || ''}
              onChange={(e) => {
                spec[`check_${obsIndex}_results`][cavityIndex] = e.target.value;
                setRender((prev) => !prev);
              }}
              sx={{
                textAlign: 'center',
              }}
            >
              <MenuItem value="">NONE</MenuItem>
              <MenuItem value="ok">OK</MenuItem>
              <MenuItem value="ng">NG</MenuItem>
            </CustomSelect>
          )}
        </td>
      </>
    );
  }
  if (
    checkType === 'check1' ||
    checkType === 'check2' ||
    checkType === 'check4'
  ) {
    return (
      <>
        <td
          style={{
            backgroundColor: `${
              isView && spec?.[`check_${obsIndex}_colors`]?.[cavityIndex]
            }`,
          }}
        >
          <TableInput
            name="observation"
            type="number"
            value={spec?.[`check_${obsIndex}_values`]?.[cavityIndex] || ''}
            onChange={(e) => {
              handleChangeSpec(e, spec, obsIndex, cavityIndex);
              handleChangeColorOnRemarks(spec, spec.remarks);
              setRender((prev) => !prev);
            }}
            style={{
              backgroundColor:
                spec?.[`check_${obsIndex}_colors`]?.[cavityIndex],
              textAlign: 'center',
            }}
            isView={isView}
          />
        </td>
        <td style={{ textTransform: 'uppercase' }}>
          {spec?.[`check_${obsIndex}_results`]?.[cavityIndex]}
        </td>
      </>
    );
  }
  return <React.Fragment></React.Fragment>;
};
export default ObservationInput;
