import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { initialStat } from './initialState';
import { IModuleData, IProcess } from './Interface';
import lodash from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '../../../hooks/UseQuery';

const useGetData = () => {
  const [moduleData, setModuleData] = useState<any>(
    lodash.cloneDeep(initialStat)
  );
  const { partId, controlPlanId } = useParams();
  const partName = useQuery('part_name');
  const partNumber = useQuery('part_number');
  const customer_name = useQuery('customer_name');
  const [renderParent, setRenderParent] = useState(false);
  const [prevControlPlanData, setPrevControlPlanData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [useEffectRender, setUseEffectRender] = useState(false);
  const navigate = useNavigate();
  const [render, setRender] = useState(false);

  const handleControlPlanImport = (e: any) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', e.target.files[0], 'CP-ICPL.xlsx');
    formData.append('customer_name', `${customer_name}`);
    // console.log(formData,'xxxxxx')
    axios
      .post(`/api/excelUploadRoute/cp`, formData)
      .then((res) => {
        if (!res.data.isExcel) {
          window.alert(
            'Something thing went wrong. Please check if you are uplaoding the correct excel sheet or the excel sheet might be currupted.'
          );
          //   setLoader(false);
          // setRender((prev) => !prev);
          // return;
        } else {
          const uploadData = res.data.newPart.processes;
          // console.log(uploadData)
          uploadData.forEach(
            (item: IProcess, index: number) =>
              (item.s_no = moduleData.processes.length + index + 1)
          );
          moduleData.processes = [...moduleData.processes, ...uploadData];
        }
        e.target.value = '';
        setIsLoading(false);
        setRender((prev) => !prev);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.err) {
          window.alert(err.response.data.err);
        } else {
          window.alert(
            'Something thing went wrong. Please check if you are uplaoding the correct excel sheet or the excel sheet might be currupted.'
          );
        }
      });
  };

  const handleSubmitRevision = (revisionData: any) => {
    if (lodash.isEmpty(revisionData)) {
      alert("You can't create revision");
      return;
    }

    delete moduleData._id;
    delete moduleData.createdAt;
    delete moduleData.updatedAt;
    delete moduleData.__v;
    moduleData.processes.forEach((process: any) => {
      delete process._id;
      process.specs.forEach((spec: any) => delete spec._id);
    });
    revisionData = Object.keys(revisionData).map((key) => ({
      rev_no: moduleData.revision_no,
      summary: revisionData[key].value,
      action_taken: revisionData[key].action_taken,
      sugg_to: revisionData[key].sugg_to,
      sugg_by: revisionData[key].sugg_by,
      status: revisionData[key].status,
      section: revisionData[key].section,
    }));

    if (moduleData.revisionData) {
      moduleData.revisionData = [...moduleData.revisionData, ...revisionData];
    } else {
      moduleData.revisionData = [...revisionData];
    }

    axios
      .post('/api/controlPlan', moduleData)
      .then(() => {
        alert('Control plan submitted successfully');
        navigate(
          `/admin/revisionTable/controlplan/${partId}?part_name=${partName}&part_number=${partNumber}`
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/api/controlPlan/${partId}/${controlPlanId}`)
      .then((res) => {
        if (res.data) {
          setModuleData(res.data);
          setPrevControlPlanData(lodash.cloneDeep(res.data));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err);
      });

    return () => {
      setModuleData(lodash.cloneDeep(initialStat));
    };
  }, [useEffectRender]);

  const handleProcessChange = useCallback(
    (processIndex: any, updatedProcess: any) => {
      const newModuleData = { ...moduleData };
      newModuleData.processes = newModuleData.processes.map(
        (process: any, index: any) =>
          index === processIndex ? updatedProcess : process
      );
      setModuleData(newModuleData);
    },
    [moduleData]
  );

  return {
    moduleData,
    isLoading,
    error,
    handleSubmitRevision,
    handleControlPlanImport,
    setRenderParent,
    setModuleData,
    setUseEffectRender,
    prevControlPlanData,
    setPrevControlPlanData,
    handleProcessChange,
    setIsLoading,
  };
};

export default useGetData;
