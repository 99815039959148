import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={10}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-QA-09', '00', '01-04-2011']}
        heading="MATERIAL INSPECTION REPORT INCOMING"
      />
      <tr>
        <th colSpan={2} align="left">
          Item Number:
          {moduleData.part_number
            ? moduleData.part_number
            : moduleData.part_id?.part_number}
        </th>
        <th colSpan={1} align="left">
          Part Number:
          {moduleData.part_number
            ? moduleData.part_number
            : moduleData.part_id?.part_number}
        </th>
        <th colSpan={3} align="left">
          Qty:
          <TableInput
            name="qty"
            type="text"
            value={moduleData.qty}
            onChange={(e) => {
              moduleData.qty = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={4} align="left">
          Date:
          {isView ? (
            formatDate(moduleData.pdir_date)
          ) : (
            <DatePickerMui
              label="Material Incoming Date"
              value={moment(moduleData.materialIncoming_date)}
              onChange={(date) => {
                moduleData.materialIncoming_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Supplier Name:
          {moduleData.supplier_name
            ? moduleData.supplier_name
            : moduleData.supplier_id?.supplier_name}
        </th>
        <th colSpan={3} align="left">
          Shift:
          <TableInput
            name="shift"
            type="text"
            value={moduleData.shift}
            onChange={(e) => {
              moduleData.shift = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={4} align="left">
          Invoice/Challan No & Date:
          <TableInput
            name="invoice_and_challan"
            type="text"
            value={moduleData.invoice_and_challan}
            onChange={(e) => {
              moduleData.invoice_and_challan = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        {['S.NO', 'Parameter', 'specification'].map((head) => (
          <th rowSpan={2} style={{ textTransform: 'uppercase' }}>
            {head}
          </th>
        ))}
        <th colSpan={6} style={{ textTransform: 'uppercase' }}>
          Observations
        </th>
        <th style={{ textTransform: 'uppercase', width: '13%' }} rowSpan={2}>
          Remarks
        </th>
      </tr>
      <tr>
        {Array.from({ length: 5 }, (_, index) => (
          <th style={{ width: '90px' }} key={index + 'Heading'}>
            {index + 1}
          </th>
        ))}
        <th style={{ textTransform: 'uppercase' }}>Status</th>
      </tr>
    </thead>
  );
};

export default THead;
