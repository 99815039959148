import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import { TableInput } from '../../../../../components/common';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { convToTime } from '../../../../../utils/helpers';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import TableHeaderHtmlCustom from '../../../../../components/common/customTable/TableHeaderHtmlCustom';

interface IProps {
  moduleData: any;
  isView: boolean;
  machineData: any;
  autoCompleteMachineValue: any;
  setAutoCompleteMachineValue: any;
  // autoCompleteEmployeeValue_1: any;
  // autoCompleteEmployeeValue_2: any;
  // setAutoCompleteEmployeeValue_1: any;
  // setAutoCompleteEmployeeValue_2: any;
  // employeeData: any;
  autoCompleteMachineName: any;
  setAutoCompleteMachineName: any;
}

const theadData = [
  [
    {
      text: 'Hour',
      colspan: 1,
      rowspan: 2,
    },

    {
      text: 'Product Desc.',
      colspan: 1,
      rowspan: 2,
    },
    {
      text: 'Target Shots',
      colspan: 1,
      rowspan: 2,
    },
    {
      text: 'Counter Reading',
      colspan: 2,
      rowspan: 1,
    },
    {
      text: 'Total Shots',
      colspan: 1,
      rowspan: 2,
    },
    {
      text: 'Off. Shots',
      colspan: 1,
      rowspan: 2,
    },
    {
      text: 'Rejection Detail',
      colspan: 9,
      rowspan: 1,
    },
    {
      text: 'Total Rej. Shots',
      colspan: 1,
      rowspan: 2,
    },
    {
      text: 'O.K. Shots',
      colspan: 1,
      rowspan: 2,
    },
    {
      text: 'Reason for Less Production',
      colspan: 10,
      rowspan: 1,
    },
  ],
  [
    {
      text: 'Start',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'End',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'Non Filling',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'Angle',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'Cutting',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ID Damage',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'Catching',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'OD Damage',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'Crack',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'Dent',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'Others',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'Die Maint.',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'Die Ch/over',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'Degassing',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'Sft. Ch/over',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'No Operator',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'M/c Maint',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'Power Cut',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'No Plan',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'Others',
      colspan: 2,
      rowspan: 1,
    },
  ],
];

const THead = ({
  moduleData,
  isView,
  machineData,
  autoCompleteMachineValue,
  setAutoCompleteMachineValue,
  // autoCompleteEmployeeValue_1,
  // autoCompleteEmployeeValue_2,
  // setAutoCompleteEmployeeValue_1,
  // setAutoCompleteEmployeeValue_2,
  // employeeData,
  autoCompleteMachineName,
  setAutoCompleteMachineName,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={28}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-PROD-11', '01', '29.08.2018']}
        heading="Hourly Production Report - PDC"
      />
      <tr>
        <th colSpan={3}>Machine No </th>
        <th colSpan={4} align="left">
          {isView ? (
            moduleData?.mc_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              option_name="machine_no"
              arrayofObj={machineData}
              value={autoCompleteMachineName}
              onChange={(e, value) => {
                if (!value) {
                  moduleData.mc_id = null;
                }
                moduleData.mc_id = value?._id;
                setAutoCompleteMachineName(value || '');
              }}
            />
          )}
        </th>
        <th colSpan={3}>Operator Name 1</th>
        <th colSpan={4} align="left">
          <TableInput
            isView={isView}
            name="operator"
            type="text"
            value={moduleData.operator}
            onChange={(e) => {
              moduleData['operator'] = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={3}>Operator Name 2</th>
        <th colSpan={4} align="left">
          <TableInput
            isView={isView}
            name="operator_2"
            type="text"
            value={moduleData.operator_2}
            onChange={(e) => {
              moduleData['operator_2'] = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={7}></th>
      </tr>
      <tr>
        <th colSpan={3}>Shift</th>
        <th colSpan={4}>
          {isView ? (
            moduleData.shift
          ) : (
            <CustomSelect
              name="shift"
              value={moduleData.shift || ''}
              onChange={(e) => {
                moduleData[e.target.name] = e.target.value;

                setRender((prev) => !prev);
              }}
            >
              <MenuItem value="A">A</MenuItem>
              <MenuItem value="B">B</MenuItem>=
            </CustomSelect>
          )}
        </th>
        <th colSpan={3}>Machine</th>
        <th colSpan={4} align="left">
          {isView ? (
            moduleData?.mc_no?.machine_name
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              option_name="machine_name"
              arrayofObj={machineData}
              value={autoCompleteMachineValue}
              onChange={(e, value) => {
                if (!value) {
                  moduleData.mc_no = null;
                }
                moduleData.mc_no = value?._id;
                setAutoCompleteMachineValue(value || '');
              }}
            />
          )}
        </th>

        <th colSpan={3}>Daily Production Date</th>
        <th colSpan={4} align="left">
          {isView ? (
            formatDate(moduleData.dailyProduction_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.dailyProduction_date)}
              onChange={(date) => {
                moduleData.dailyProduction_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={7}></th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={theadData} />
    </thead>
  );
};

export default THead;
