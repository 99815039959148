import React, { useState } from 'react';
import InputMuiCustom from '../../../../../components/common/inputs/InputMuiCustom';
import { Checkbox, Stack } from '@mui/material';

const Tolerance = ({ item }: any) => {
  const [render, setRender] = useState(false);
  const handleSpecInputs = (e: any) => {
    let value = e.target.value;
    if (!isNaN(Number(value))) {
      value = Number(value);
    }

    item[e.target.name] = value || null;
    e.target.name === 'ltl' && (item.ltolerance = item[e.target.name]);
    e.target.name === 'utl' && (item.utolerance = item[e.target.name]);

    if (item.utolerance === null && item.ltolerance === null) {
      item['appearance'] = 'check3';
      item['check4_symbol'] = '';
    } else if (item.utolerance === null && item.ltolerance !== null) {
      item['appearance'] = 'check4';
      item['check4_symbol'] = '>=';
    } else if (item.utolerance !== null && item.ltolerance === null) {
      item['appearance'] = 'check4';
      item['check4_symbol'] = '<=';
    } else {
      item['appearance'] = 'check1';
    }
    setRender((prev) => !prev);
  };

  return (
    <Stack direction="row" gap={2}>
      <InputMuiCustom
        type="number"
        name="mean"
        placeholder="Mean"
        onChange={handleSpecInputs}
        value={item?.mean}
      />
      <InputMuiCustom
        type="number"
        name="utl"
        placeholder="Upper Tolerance"
        onChange={handleSpecInputs}
        value={item?.utl}
      />
      <InputMuiCustom
        type="number"
        name="ltl"
        placeholder="Lower Tolerance"
        onChange={handleSpecInputs}
        value={item?.ltl}
      />

      <InputMuiCustom
        type="text"
        name="unit"
        placeholder="Unit"
        onChange={handleSpecInputs}
        value={item?.unit || ''}
      />
      <Checkbox
        name="is_gpt_disable"
        sx={{ marginRight: 2 }}
        checked={item?.is_gpt_disable}
        onChange={(e) => {
          item[e.target.name] = item[e.target.name] === true ? false : true;
          setRender((prev) => !prev);
        }}
      />
    </Stack>
  );
};

export default Tolerance;
