import { AiOutlineCloudUpload } from 'react-icons/ai';
import { GrTest } from 'react-icons/gr';
import { HiDocumentReport } from 'react-icons/hi';
import { RenderTiles } from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';

export type tilesType = {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number;
  p: string;
  span: string;
  icon?: any;
  style?: {};
}[];
const tiles = [
  {
    sNo: 1,
    // link: 'https://uc.secure.aqua.mushinlabs.com',
    link: '/common/createTestPaper',
    isLink: false,
    // target: '_blank',
    clauseNo: 5,
    icon: <GrTest size="5rem" color="#135089" />,
    p: 'CREATE TEST',
    span: 'PAPER',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/downloadTestPaper',
    isLink: true,
    clauseNo: 5,
    icon: <HiDocumentReport size="5rem" color="#135089" />,
    p: 'DOWNLOAD TEST',
    span: 'PAPER',
    isIcon: true,
  },
];

const EffectivenessTestHome = () => {
  return (
    <>
      <ChildHeader text="EFFECTIVENESS TEST PAPER" />
      <RenderTiles tilesObj={tiles} height={'100vh'} justify="start" />
    </>
  );
};

export default EffectivenessTestHome;
