import { AiOutlineCloudUpload, AiOutlineFundView } from 'react-icons/ai';
import { BsFillEaselFill } from 'react-icons/bs';
import { GrTest } from 'react-icons/gr';
import { ImUserTie } from 'react-icons/im';
import { MdDriveFolderUpload } from 'react-icons/md';

const tiles = [
  {
    sNo: 1,
    link: '/common/company_roles_responsibilities_listOfProcedures?documentType=clause5ListOfProcedure',
    isLink: true,
    icon: <MdDriveFolderUpload size="5rem" color="#003566" />,
    p: 'LIST OF',
    span: 'PROCEDURE',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/admin/employee_list?filter=createdAt',
    isLink: true,
    p: 'MASTER LIST OF',
    span: 'EMPLOYEE',
    isIcon: true,
    icon: <ImUserTie size="5rem" color="#135089" />,
  },
  {
    sNo: 2,
    link: '/admin/masterlistofdepartment?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    p: 'Master List Of',
    span: 'Department',
    icon: <AiOutlineFundView size="5rem" color="#343a40" />,
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/masterlistoffaculty?filter=createdAt',
    isLink: true,
    clauseNo: 5,
    p: 'Master List Of',
    span: 'FACULTY',
    isIcon: true,
    icon: <ImUserTie size="5rem" color="#135089" />,
  },
  {
    sNo: 1,
    link: '/common/effectivenessTestPaperHome',
    isLink: true,
    p: 'EFFECTIVENESS TEST',
    span: 'PAPER',
    isIcon: true,
    icon: <GrTest size="5rem" color="#135089" />,
  },
  {
    sNo: 4,
    link: '/common/trainingTniHome',
    isLink: true,
    p: 'TRAINING',
    clauseNo: 5,
    span: '',
    isIcon: true,
    icon: <BsFillEaselFill size="5rem" color="#135089" />,
  },
  {
    sNo: 5,
    link: '/common/skillEvaluationHome',
    isLink: true,
    p: 'Skill',
    span: 'Evaluation',
    clauseNo: 5,
    isIcon: true,
    icon: <BsFillEaselFill size="5rem" color="#135089" />,
  },
];

export default tiles;
