import { Box } from '@mui/material';
import { RenderTable } from '../../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  apiData: any;
}
const TController = ({ apiData }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <>
          <THead />
          <TBody apiData={apiData} />{' '}
        </>
      </RenderTable>
    </Box>
  );
};

export default TController;
