import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { handleChangeModuleData } from '../../checklist/helpers/handleChangeModuleData';
import { createConstant } from '../../constant';

interface Ifoot {
  moduleData: any;
  isView: any;
}
const TFoot = ({ moduleData, isView }: Ifoot) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
        <td align="left" colSpan={3}>
          Prepared By :
          <TableInput
            isView={isView}
            name="report_prepared_by"
            type="text"
            value={moduleData.report_prepared_by}
            onChange={(e: any) => {
              handleChangeModuleData(e, moduleData);
              setRender((prev: any) => !prev);
            }}
          />
        </td>
        <td align="left" colSpan={8}>
          Approved By :
          <TableInput
            isView={isView}
            name="report_approved_by"
            type="text"
            value={moduleData.report_approved_by}
            onChange={(e: any) => {
              handleChangeModuleData(e, moduleData);
              setRender((prev: any) => !prev);
            }}
          />
        </td>
      </tr>
    </tfoot>
  );
};

export default TFoot;
