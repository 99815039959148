import { Box, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';

import { TableCustom } from '../../../components/common';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import React from 'react';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import { useNavigate, useParams } from 'react-router-dom';
import useDebounce from '../../../hooks/useDebounce';
import formatDate from '../../../components/common/formatDate';

const getYearRange = (currentDate: any) => {
  if (!currentDate) return;
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Report Date', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const TableList = () => {
  const { partId } = useParams();
  const [tableData, setTableData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const navigate = useNavigate();
  const fetchData = () => {
    axios
      .get(`/api/productAuditReportRoute/${partId}`)
      .then((res) => {
        if (res.data) {
          delete res.data._id;
          setTableData(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useDebounce(fetchData, 100, [render]);

  const handleDelete = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this document?',
    );
    if (isDeleted) {
      axios
        .delete(`/api/productAuditReportRoute/${id}`)
        .then(() => {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  return (
    <Box>
      <ChildHeader text={`PRODUCT AUDIT REPORT`}>
        <AddButton
          label={`NEW PRODUCT AUIDT REPORT`}
          onClick={() => navigate(`/common/productAuditReportCreate/${partId}`)}
        />
      </ChildHeader>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {tableData &&
            tableData.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {formatDate(item.report_date)}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <ViewIconButton
                        tooltipTitle="create plan"
                        onClick={() => {
                          navigate(
                            `/common/productAuditReportCreate/${partId}/${item?._id}?isView=true`,
                          );
                        }}
                      />
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          navigate(
                            `/common/productAuditReportCreate/${partId}/${item?._id}?isView=false`,
                          );
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          handleDelete(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
    </Box>
  );
};

export default TableList;
