import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  ReactNode,
} from 'react';
import axios from 'axios';

import authReducer from './authReducer';

interface Iuser {
  userType?: number;
  _id?: string;
  name?: string;
  role?: 'superuser' | 'admin' | 'supervisor';
  profile?: string | File | Blob | MediaSource;
  username?: string;
  clauseIds?: any;
}

interface AuthContextType {
  login: () => Promise<void>;
  logout: () => Promise<void>;
  state: {
    user: Iuser | null;
    error: {} | null | string;
    loading: boolean;
    IS: {
      Admin: 2;
      Supervisor: 3;
    };
  };
}

type ApiResponse = {
  data: object;
  error: string;
  message: string;
};

const AuthContext = createContext<AuthContextType>({
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  state: {
    user: null,
    error: null,
    loading: true,
    IS: {
      Admin: 2,
      Supervisor: 3,
    },
  },
});
export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    error: null,
    loading: true,
    IS: {
      Admin: 2,
      Supervisor: 3,
    },
  });

  const login = async (): Promise<void> => {
    try {
      const res = await axios.get<ApiResponse>(`/api/userRoutes/user`, {
        withCredentials: true,
      });

      if (res.data.error) {
        dispatch({ type: 'ERROR', error: 'Not Authorized' });
        return;
      }
      dispatch({ type: 'LOGIN', user: res.data });
    } catch (error: unknown) {
      dispatch({ type: 'ERROR', error: (error as Error).message });
    }
  };

  const logout = async (): Promise<void> => {
    try {
      const res = await axios.delete<ApiResponse>('/api/userRoutes/logout', {
        withCredentials: true,
      });
      if (res.data.error) {
        dispatch({ type: 'ERROR', error: res.data.error });
        return;
      }
      dispatch({ type: 'LOGOUT' });
    } catch (error: unknown) {
      dispatch({ type: 'ERROR', error: (error as Error).message });
    }
  };

  useEffect(() => {
    login();
  }, []);

  const value = { login, logout, state };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
