import { HiDocumentReport } from 'react-icons/hi';
import { MdRoomPreferences } from 'react-icons/md';
import { FaHouseDamage } from 'react-icons/fa';
import { TbReportAnalytics } from 'react-icons/tb';
import { TbReport } from 'react-icons/tb';
import { TbFileReport } from 'react-icons/tb';

const tiles = [
  {
    sNo: 4,
    link: '/common/inhouserejection/dashboardList',
    isLink: true,
    icon: <FaHouseDamage size="5rem" color="#071624" />,
    p: 'In-House',
    span: 'REJECTION',
    isIcon: true,
  },
];

export default tiles;
