import axios from 'axios';
import { useState, useEffect } from 'react';
import { apiConstant } from '../constant';
import useConfirm from '../../../../components/common/useConfirm';
import lodash from 'lodash';

const useGetData = (id: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({ list: [] });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSync, setIsSync] = useState(false);
  const [Sync, confirmSync] = useConfirm(
    'Row',
    'Are you sure you want to add row?'
  );
  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const addRow = async () => {
    // const ans = await confirmSync();
    // if (!ans) return;
    let values = { ...moduleData };
    const state = { p: false, r: false };
    values.list.push({
      april: { ...lodash.cloneDeep(state) },
      may: { ...lodash.cloneDeep(state) },
      june: { ...lodash.cloneDeep(state) },
      july: { ...lodash.cloneDeep(state) },
      august: { ...lodash.cloneDeep(state) },
      september: { ...lodash.cloneDeep(state) },
      october: { ...lodash.cloneDeep(state) },
      november: { ...lodash.cloneDeep(state) },
      december: { ...lodash.cloneDeep(state) },
      january: { ...lodash.cloneDeep(state) },
      february: { ...lodash.cloneDeep(state) },
      march: { ...lodash.cloneDeep(state) },
    });
    setModuleData(values);
  };

  const handelSync = async () => {
    const ans = await confirmSync();
    if (!ans) return;
    setIsLoading(true);
    axios
      .get(`/api/${apiConstant.sync}/${id}`)
      .then((res) => {
        window.alert('Sync Succesfully');
        setIsSync((prev) => !prev);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      axios
        .get(`/api/${apiConstant.getPlanByid}/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, [isSync]);

  const handleDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.list.splice(rowIdx, 1);
    setModuleData(data);
  };

  return {
    DialogDeleteRow,
    handleDeleteRows,
    moduleData,
    isLoading,
    error,
    addRow,
    Sync,
    handelSync,
  };
};

export default useGetData;
