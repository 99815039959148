import { Checkbox } from '@mui/material';
import React from 'react';

interface Iprops {
  name: string;
  label: string;
  checked: any;
  onClick?: any;
}
const CheckBoxCustomMui = ({ name, checked, onClick, label }: Iprops) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: '130px',
        }}
      >
        <Checkbox
          name={name}
          checked={checked}
          onClick={onClick}
          sx={{ color: '#064a29' }}
        />
        <span>{label}</span>
      </div>
    </>
  );
};

export default CheckBoxCustomMui;
