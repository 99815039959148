import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import { TableInput } from '../../../../../components/common';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { convToTime } from '../../../../../utils/helpers';
import CustomSelect from '../../../../../components/common/SelectField';
import { Grid, MenuItem } from '@mui/material';
import { convertToObject } from 'typescript';
import CustomMobileTimePickerMui from '../../../../../components/common/CustomMobileTimePickerMui';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';

interface IProps {
  moduleData: any;
  isView: boolean;
  machineData: any;
  autoCompleteMachineValue: any;
  setAutoCompleteMachineValue: any;
  // setIsOpenPopup: any;
  // setPartIdx: any;
}

const theadData = [
  'घंटा',
  'पार्ट काउंट',
  'एक्चुअल प्रोडक्शन',
  'पार्ट काउंट इन्सर्ट बदलने के बाद',
  'इन्सर्ट / टूल का नाम',
  'रेवॉर्क क्वांटिटी',
  'रिजेक्ट क्वांटिटी',
  'प्रोडक्शन कम होने के कारण ( मिनटों मे )',
];
const theadData2 = [
  'रेवॉर्क',
  'इन्सर्ट / टूल बदलना',
  'इंस्पेक्शन',
  'सेटिंग',
  'पॉवरकत',
  'मशीन मेंटेनेंस',
  'नो फीड',
  'नो ऑपरेटर',
  'नो मटेरियल',
  'मशीन प्रॉब्लम',
  'रेवॉर्क डिटेल्स',
  'बाकि की प्रॉब्लम',
];

const THead = ({
  moduleData,
  isView,
  machineData,
  autoCompleteMachineValue,
  setAutoCompleteMachineValue,
}: // setIsOpenPopup,
// setPartIdx,
IProps) => {
  const [render, setRender] = useState(false);
  console.log(moduleData);
  return (
    <thead>
      <TableCompHead
        colSpan={21}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-PROD-02C', '00', '01.04.2021']}
        heading="Daily Hourly Production Report - CNC"
      />
      <tr>
        <th colSpan={2} align="left">
          मशीन न. :
        </th>
        <th colSpan={2} align="left">
          {isView ? (
            moduleData?.mc_no?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              option_name="machine_no"
              arrayofObj={machineData}
              value={autoCompleteMachineValue}
              onChange={(e, value) => {
                if (!value) {
                  moduleData.mc_no = null;
                }
                moduleData.mc_no = value?._id;
                setAutoCompleteMachineValue(value);
              }}
            />
          )}
        </th>
        <th colSpan={2} align="left">
          सेटर का नाम :
        </th>
        <th colSpan={2} align="left">
          <TableInput
            isView={isView}
            name="setter_name"
            type="text"
            value={moduleData.setter_name}
            onChange={(e) => {
              moduleData.setter_name = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={1} style={{ minWidth: '200px' }} align="left">
          ऑपरेटर का नाम :
        </th>
        {['operator1', 'operator2', 'operator3'].map((keys, ind) => (
          <th colSpan={4} align="left" key={keys + 100 + ind}>
            <TableInput
              isView={isView}
              name="operator"
              type="text"
              value={moduleData.operator[ind]}
              onChange={(e) => {
                moduleData.operator[ind] = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
        ))}
      </tr>
      <tr>
        <th colSpan={2} align="left">
          साइकिल टाइम :
        </th>
        {['cycleTime1', 'cycleTime2', 'cycleTime3'].map((keys, ind) => (
          <th colSpan={2} align="left" key={keys + 10.1 + ind}>
            {isView ? (
              convToTime(moduleData.cycle_time[ind])
            ) : (
              <CustomMobileTimePickerMui
                value={moduleData.cycle_time[ind]}
                onChange={(date: any) => {
                  moduleData.cycle_time[ind] = date;
                  setRender((prev) => !prev);
                }}
                ampm={true}
                openTo="hours"
                views={['hours', 'minutes']}
              />
            )}
          </th>
        ))}
        <th colSpan={1} style={{ minWidth: '200px' }} align="left">
          जॉ.क/ बे.क/च.न.:
        </th>
        {['jk1', 'jk2', 'jk3'].map((keys, ind) => (
          <th colSpan={4} align="left" key={keys + 20.2 + ind}>
            <TableInput
              isView={isView}
              name="jk_ck_ch_no"
              type="text"
              value={moduleData.jk_ck_ch_no[ind]}
              onChange={(e) => {
                moduleData.jk_ck_ch_no[ind] = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
        ))}
      </tr>
      <tr>
        <th colSpan={2} align="left">
          घंटे का टारगेट :
        </th>
        {['target_of_hour1', 'target_of_hour2', 'target_of_hour3'].map(
          (keys, ind) => (
            <th colSpan={2} align="left" key={keys + 30.1 + ind}>
              <TableInput
                isView={isView}
                name="target_of_hour"
                type="text"
                value={moduleData.target_of_hour[ind]}
                onChange={(e) => {
                  moduleData.target_of_hour[ind] = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </th>
          )
        )}
        <th colSpan={1} style={{ minWidth: '200px' }} align="left">
          प्रोग्राम न. :
        </th>
        {['program_no1', 'program_no2', 'program_no3'].map((keys, ind) => (
          <th colSpan={4} align="left" key={keys + 40.2 + ind}>
            <TableInput
              isView={isView}
              name="program_no"
              type="text"
              value={moduleData.program_no[ind]}
              onChange={(e) => {
                moduleData.program_no[ind] = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
        ))}
      </tr>
      <tr>
        <th colSpan={8}></th>

        <th colSpan={1} style={{ minWidth: '200px' }} align="left">
          पार्ट का नाम /न. :
        </th>
        {/* {['partnumber1', 'partnumber2', 'partnumber3'].map((keys, ind) => (
          <th colSpan={4} align="left" key={keys + 50.2 + ind}>
            {isView ? (
              moduleData?.part_id[ind] &&
              moduleData?.part_id[ind]?.part_name +
                ' & ' +
                moduleData.part_id[ind]?.part_number
            ) : (
              <Grid container xs={12} direction="row" alignItems={'center'}>
                <Grid item xs={10}>
                  <p
                    style={{
                      fontSize: '1.2rem',
                    }}
                  >
                    {typeof moduleData?.part_id[ind] === 'object' &&
                    moduleData?.part_id[ind] !== null
                      ? moduleData?.part_id[ind]?.part_name +
                        ' & ' +
                        moduleData.part_id[ind]?.part_number
                      : (moduleData?.partNumber &&
                          moduleData?.partName +
                            ' & ' +
                            moduleData?.partNumber) ||
                        ''}
                    {!moduleData?.part_id[ind] && 'Pick Part No.'}
                  </p>
                </Grid>

                {moduleData.part_id[ind] ? (
                  <Grid item xs={2}>
                    <RemoveIconButton
                      tooltipTitle="remove part"
                      onClick={() => {
                        moduleData.part_id[ind] = null;
                        moduleData.partNumber = null;
                        moduleData.partName = null;
                        setRender((prev) => !prev);
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={2}>
                    <AddIconButton
                      tooltipTitle="add part"
                      onClick={() => {
                        setIsOpenPopup(true);
                        setPartIdx(ind);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </th>
        ))} */}
      </tr>
      <tr>
        <th colSpan={8}></th>

        <th colSpan={1} style={{ minWidth: '200px' }} align="left">
          आपरेशन न. :
        </th>
        {['operation_no1', 'operation_no2', 'operation_no3'].map(
          (keys, ind) => (
            <th colSpan={4} align="left" key={keys + 60.2 + ind}>
              <TableInput
                isView={isView}
                name="operation_no"
                type="text"
                value={moduleData.operation_no[ind]}
                onChange={(e) => {
                  moduleData.operation_no[ind] = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </th>
          )
        )}
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th
            key={heading + index}
            rowSpan={
              heading === 'प्रोडक्शन कम होने के कारण ( मिनटों मे )' ? 1 : 2
            }
            colSpan={
              heading === 'प्रोडक्शन कम होने के कारण ( मिनटों मे )' ? 8 : 1
            }
          >
            {heading}
          </th>
        ))}
        <th colSpan={3}>
          शिफ्ट:
          {isView ? (
            moduleData?.shift
          ) : (
            <CustomSelect
              name="shift"
              value={moduleData?.shift || ''}
              onChange={(e) => {
                moduleData.shift = e.target.value;
                setRender((prev) => !prev);
              }}
            >
              <MenuItem value="A">A</MenuItem>
              <MenuItem value="B">B</MenuItem>=
            </CustomSelect>
          )}
        </th>
        <th colSpan={3}>
          दिनांक :{' '}
          {isView ? (
            formatDate(moduleData.dailyProduction_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.dailyProduction_date)}
              onChange={(date) => {
                moduleData.dailyProduction_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {theadData2.map((heading, index) => (
          <th key={heading + index}>{heading}</th>
        ))}
        <th colSpan={2}>Remarks</th>
      </tr>
    </thead>
  );
};

export default THead;
