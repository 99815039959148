import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import InputMuiCustom from '../../../../../../components/common/inputs/InputMuiCustom';

interface IProps {
  moduleData: any;
  allmonth: any;
  isView: boolean;
}

const allmonth = [
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
  'january',
  'february',
  'march',
];

const TBody = ({ moduleData, isView, allmonth }: IProps) => {
  const [render, setRender] = useState(false);
  const getAverage = (index: number) => {
    let sum = 0;
    for (const month in moduleData?.list[index]) {
      if (month !== 'item' && month != 'remarks' && month != '_id') {
        sum += +moduleData?.list[index][month];
      }
    }
    return (sum / 12).toFixed(2);
  };

  const getPlanedProuctionTimePerMachine = (monthName: any) => {
    const time_per_shift = moduleData?.list[1]?.[monthName];
    const no_of_shifts = +moduleData?.list[2]?.[monthName];
    moduleData.list[3][monthName] = time_per_shift * no_of_shifts;
    return time_per_shift * no_of_shifts;
  };
  const runTime = (monthName: any) => {
    const no_of_machines = moduleData?.list[4]?.[monthName];
    const time_per_machine = getPlanedProuctionTimePerMachine(monthName);
    const no_of_working_days = moduleData?.list[0]?.[monthName];
    moduleData.list[5][monthName] =
      no_of_machines * time_per_machine * no_of_working_days;
    return no_of_machines * time_per_machine * no_of_working_days;
  };
  const operationTime = (monthName: any) => {
    const run_time = runTime(monthName);
    const loss_time = moduleData?.list[6]?.[monthName];
    moduleData.list[7][monthName] = run_time - loss_time;
    return run_time - loss_time;
  };
  const mtbf = (monthName: any) => {
    const operation_time = operationTime(monthName);
    const no_of_failures = moduleData?.list[8]?.[monthName];
    console.log(operation_time, no_of_failures);
    if (no_of_failures === 0) {
      moduleData.list[9][monthName] = 0;
      return 0;
    }
    moduleData.list[9][monthName] = operation_time / no_of_failures;
    return (operation_time / no_of_failures).toFixed(2);
  };
  const getTargetAverage = () => {
    let sum = 0;
    for (const targetMonth in moduleData) {
      if (
        targetMonth !== 'title' &&
        targetMonth != 'date_range' &&
        targetMonth != '_id' &&
        targetMonth != 'list' &&
        targetMonth != 'createdAt' &&
        targetMonth != 'updatedAt'
      ) {
        sum += +moduleData[targetMonth];
      }
    }
    return (sum / 12).toFixed(2);
  };

  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: number) => (
        <>
          <tr key={`${index}_table_row`}>
            <td>{index + 1}</td>
            <td>{item?.item}</td>
            {allmonth.map((monthName: string) => {
              return (
                <td>
                  <TableInput
                    isView={
                      index === 1 ||
                      index === 3 ||
                      index === 5 ||
                      index === 7 ||
                      index === 9
                        ? true
                        : false
                    }
                    style={{
                      height: '80px',
                      textAlign: 'center',
                      paddingLeft: '10px',
                    }}
                    name=""
                    type="number"
                    value={
                      (index === 3 &&
                        getPlanedProuctionTimePerMachine(monthName)) ||
                      (index === 5 && runTime(monthName)) ||
                      (index === 7 && operationTime(monthName)) ||
                      (index === 9 && mtbf(monthName)) ||
                      item[monthName]
                    }
                    onChange={(e) => {
                      item[monthName] = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              );
            })}
            <td>{getAverage(index)}</td>
            <td>
              <InputMuiCustom
                placeholder={''}
                name=""
                type="text"
                value={item?.remarks}
                onChange={(e) => {
                  item.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        </>
      ))}
      <tr>
        <td></td>
        <td>Target</td>
        {allmonth.map((montName: string) => {
          return (
            <td>
              <TableInput
                isView={isView}
                style={{
                  height: '80px',
                  textAlign: 'center',
                  paddingLeft: '10px',
                }}
                name=""
                type="number"
                value={moduleData?.[`target_${montName}`]}
                onChange={(e) => {
                  moduleData[`target_${montName}`] = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          );
        })}
        <td>{getTargetAverage()}</td>
        <td></td>
      </tr>
    </tbody>
  );
};

export default TBody;
