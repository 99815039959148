import { AiOutlinePartition } from 'react-icons/ai';
import { AiOutlineFundView } from 'react-icons/ai';
import { BsFillPeopleFill } from 'react-icons/bs';
import { VscServerProcess } from 'react-icons/vsc';
import { TbReport } from 'react-icons/tb';
import { ImStatsDots } from 'react-icons/im';
import { SiPivotaltracker } from 'react-icons/si';
import { GrCertificate } from 'react-icons/gr';
import { MdDriveFolderUpload } from 'react-icons/md';

const tiles = [
  {
    sNo: 1,
    link: '/common/company_roles_responsibilities_listOfProcedures?documentType=clause9ListOfProcedure',
    isLink: true,
    icon: <MdDriveFolderUpload size="5rem" color="#003566" />,
    p: 'LIST OF',
    span: 'PROCEDURE',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/management5s',
    isLink: true,
    clauseNo: 4,
    icon: <SiPivotaltracker size="5rem" color="#a12222" />,
    p: '5S',
    span: 'TRACKER',
    isIcon: true,
  },
  {
    sNo: 19,
    link: `/common/procedure5SUpload/?documentType=procedure5SUpload&heading=5S PROCEDURES, CERTIFICATES AND STANDARD UPLOAD`,
    isLink: true,
    icon: <GrCertificate size="5rem" color="#003566" />,
    p: '5S Procedures, Certificates &',
    span: 'Standards Upload',
    isIcon: true,
  },
];

export default tiles;
