import React, { useState } from 'react';
import AutocompleteGridmui from '../../components/common/autocomplete/AutocompleteGridmui';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import lodash from 'lodash';

const CopyProcess = ({
  moduleData,
  setRenderParent,
  setOpenModal,
  setTabValue,
}: any) => {
  const processesArray = moduleData?.processes.map(
    (item: any, index: number) => {
      return {
        process_no_name: `${item.process_no}-${item.process_name}`,
        p_idx: index,
      };
    }
  );
  const [autoValue, setAutoValue] = useState<any>();

  const handleCopy = () => {
    if (!autoValue) {
      alert('Nothing to Copy!!!');
      return;
    }
    const getProcess = lodash.cloneDeep(moduleData.processes[autoValue.p_idx]);
    delete getProcess._id;
    getProcess.process_name = `${getProcess.process_name}-copied`;
    getProcess?.specs?.forEach((spec: any) => delete spec._id);
    moduleData.processes.push(getProcess);
    setRenderParent((prev: any) => !prev);
    setOpenModal(false);
    setTabValue(moduleData.processes.length);
  };

  return (
    <>
      <AutocompleteGridmui
        label={'SELECT A PROCESS'}
        id={'process_no_name'}
        option_name={'process_no_name'}
        value={autoValue || ''}
        arrayofObj={processesArray}
        onChange={(e, value) => {
          setAutoValue(value);
        }}
        last_child={1}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton label="Copy" type="button" onClick={handleCopy} />
      </div>
    </>
  );
};

export default CopyProcess;
