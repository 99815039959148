import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import {subTiles2} from './SubTiles';

const RotorSection = () => {
  return (
    <>
      <ChildHeader text="Rotor Section" />
      <RenderTiles tilesObj={subTiles2} height="100vh" justify="start" />
    </>
  );
};

export default RotorSection;
