import axios from 'axios';
import { useState, useEffect } from 'react';
import lodash from 'lodash';
import { npdMatrixConstant } from './constants';

const useGetData = (partId: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({
    phase_1: [],
    phase_2: [],
    phase_3: [],
    phase_4: [],
    phase_5: [],
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (partId) {
      axios
        .get(`/api/npdMatrixRoute/${partId}`)
        .then((res) => {
          if (res.data?.msg === 'document not found') {
            setModuleData(lodash.cloneDeep(npdMatrixConstant));
            return;
          }
          setModuleData(res.data);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, []);
  return { moduleData, isLoading, error };
};

export default useGetData;
