interface IDocument {
  [type: string]: any;
}
interface IProps {
  handleChangeStatus: (
    e: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => void;
  statusObj: {
    pending: number;
    accepted: number;
    rejected: number;
    [type: string]: any;
  };
  document: IDocument;
  documentIndex: number;
}
const ApprovalSelect = ({
  handleChangeStatus,
  statusObj,
  document,
  documentIndex,
}: IProps) => {
  return (
    <>
      <select
        style={{
          fontSize: '1.2rem',
          outline: 'none',
          border: 'none',
          padding: '5px',
          marginLeft: '0.5rem',
          width: '150px',
          textAlign: 'center',
          textTransform: 'capitalize',
          ...(document.is_admin_request === 'pending' && {
            backgroundColor: '#FDD835',
          }),
          ...(document.is_admin_request === 'accepted' && {
            backgroundColor: '#2b8a3e',
            color: '#fff',
          }),
          ...(document.is_admin_request === 'rejected' && {
            backgroundColor: '#f03e3e',
            color: '#fff',
          }),
        }}
        name="is_admin_request"
        value={
          statusObj[document?.is_admin_request] || document?.is_admin_request_no
        }
        onChange={(e) => handleChangeStatus(e, documentIndex)}
      >
        <option value="1">OPEN</option>
        <option value="2">CLOSE</option>
        <option value="3">SEND BACK</option>
      </select>
    </>
  );
};

export default ApprovalSelect;
