import { Box } from '@mui/material';
import { RenderTable } from '../../../../../../components/ui';
import THead from './THead';
import TBody from './TBody';
import TFoot from './TFoot';

interface IProps {
  moduleData: any;
  cc_type: any;
  isView: boolean;
}
const TControlllerAdc = ({ moduleData, isView, cc_type }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, isView, cc_type }} />
        <TBody {...{ moduleData, cc_type, isView }} />
        <TFoot
          preparedBy={moduleData.report_prepared_by}
          approvedBy={moduleData.report_approved_by}
        />
      </RenderTable>
    </Box>
  );
};

export default TControlllerAdc;
