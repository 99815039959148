import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../components/ui';
import { TimePicker } from '@mui/x-date-pickers';

interface IProps {
  moduleData: any;
  isView: boolean;
  cc_type: string;
}
const THead = ({ moduleData, isView, cc_type }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={7}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-QA-28', '01', '01.12.2019']}
        heading="METERIAL INSPECTION REPORT- INCOMING"
      />
      <tr>
        <th colSpan={1} align="left">
          Matrix:-
        </th>
        <th colSpan={1} align="left">
          Al_Si_Cu
        </th>
        <th colSpan={1} align="left">
          Invoice No:-
        </th>
        <th colSpan={2} align="left">
          <TableInput
            name="invoice_no"
            type="text"
            value={moduleData?.[cc_type].invoice_no}
            onChange={(e) => {
              moduleData[cc_type].invoice_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={1} align="left">
          Invoice Date:-
        </th>
        <th colSpan={1} align="left">
          <DatePickerMui
            label="Invoice Date"
            value={moment(moduleData[cc_type].invoice_date)}
            onChange={(date) => {
              moduleData[cc_type].invoice_date = date;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={1} align="left">
          Grade Name:-
        </th>
        <th colSpan={1} align="left">
          ADC-12
        </th>
        <th colSpan={1} align="left">
          Heat No:-
        </th>
        <th colSpan={2} align="left">
          <TableInput
            name="heat_no"
            type="text"
            value={moduleData[cc_type].heat_no}
            onChange={(e) => {
              moduleData[cc_type].heat_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={1} align="left">
          Testing Date:-
        </th>
        <th colSpan={1} align="left">
          <DatePickerMui
            label="Testing Date"
            value={moment(moduleData[cc_type].testing_date)}
            onChange={(date) => {
              moduleData[cc_type].testing_date = date;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={1} align="left">
          Suplier Name:-
        </th>
        <th colSpan={1} align="left">
          <TableInput
            name="supplier_name"
            type="text"
            value={moduleData[cc_type].supplier_name}
            onChange={(e) => {
              moduleData[cc_type].supplier_name = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={1} align="left">
          Sample:-
        </th>
        <th colSpan={2} align="left">
          <TableInput
            name="sample"
            type="text"
            value={moduleData[cc_type].sample}
            onChange={(e) => {
              moduleData[cc_type].sample = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={1} align="left">
          Testing Time:-
        </th>
        <th colSpan={1} align="left">
          <TimePicker
            value={moment(moduleData[cc_type].testing_time)}
            onChange={(date) => {
              moduleData[cc_type].testing_time = date;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={1} align="left">
          Inovice Qty/Kg:-
        </th>
        <th colSpan={1} align="left">
          <TableInput
            name="invoice_qty"
            type="text"
            value={moduleData[cc_type].invoice_qty}
            onChange={(e) => {
              moduleData[cc_type].invoice_qty = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={1} align="left">
          Reject Qty:-
        </th>
        <th colSpan={2} align="left">
          <TableInput
            name="reject_qty"
            type="text"
            value={moduleData[cc_type].reject_qty}
            onChange={(e) => {
              moduleData[cc_type].reject_qty = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={1} align="left">
          Accept Qty:-
        </th>
        <th colSpan={1} align="left">
          <TableInput
            name="accept_qty"
            type="text"
            value={moduleData[cc_type].accept_qty}
            onChange={(e) => {
              moduleData[cc_type].accept_qty = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        {[
          'Elements',
          'Std. Value',
          'Burn1',
          'Burn2',
          'Burn3',
          'Mean',
          'Remarks',
        ].map((head) => (
          <th rowSpan={2} style={{ textTransform: 'uppercase', width: '14%' }}>
            {head}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
