// interface Ifoot {
//   preparedBy: string | null | undefined;
//   approvedBy: string | null | undefined;
// }
const TFoot = () => {
  return (
    <tfoot>
      <tr>
        <th style={{ background: '#40c057' }} colSpan={6}>
          GREEN (G)
        </th>
        <th style={{ background: '#ffe066' }} colSpan={4}>
          YELLOW (Y)
        </th>
        <th style={{ background: '#e03131' }} colSpan={4}>
          RED (R)
        </th>
      </tr>
      <tr>
        <th colSpan={6}>Last time ok</th>
        <th colSpan={4}>Send for correction</th>
        <th colSpan={4}>Production stop and tool send for repair</th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
