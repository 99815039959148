import { RenderTiles } from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';
import { FcTodoList } from 'react-icons/fc';

import { CgFormatUppercase } from 'react-icons/cg';
import { useQuery } from '../../../hooks/UseQuery';
import { useParams } from 'react-router-dom';

const RIDeparment = () => {
  const { toolId } = useParams();
  const tool_name = useQuery('tool_name');
  const tool_no = useQuery('tool_no');

  const tiles = [
    {
      sNo: 3,
      link: `/common/tool/status/checklist/format/${toolId}`,
      isLink: true,
      icon: <CgFormatUppercase size="5rem" color="#064a29" />,
      p: 'TOOL CHECKLIST',
      span: 'FORMAT',
      isIcon: true,
    },
    {
      sNo: 3,
      link: `/common/tool/status/checklist/list/${toolId}?tool_name=${tool_name}&tool_no=${tool_no}`,
      isLink: true,
      icon: <FcTodoList size="5rem" color="#FcTodoList" />,
      p: 'TOOL CHECKLIST',
      span: '',
      isIcon: true,
    },
  ];
  return (
    <>
      <ChildHeader text={`Checklist Status`} />
      <RenderTiles
        height="100%"
        tilesObj={tiles}
        justify="start"
        columns="0fr"
      />
    </>
  );
};

export default RIDeparment;
