import { Checkbox, MenuItem } from '@mui/material';
import { Stack } from '@mui/system';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { useQuery } from '../../../../../hooks/UseQuery';
import { convToTime } from '../../../../../utils/helpers';
import CustomSelect from '../../../../../components/common/SelectField';

interface IProps {
  moduleData: any;
  isView: boolean;
  operation: string[];
  getProcess: any;
}
const tableHead: any = {
  setup: ['FRM-CMT-04', '00', '01-04-2023'],
  change4m: ['FRM-CMT-04', '00', '01-04-2023'],
  retro: ['', '00', '01-04-2023'],
  suspected: ['', '00', '01-04-2023'],
};

const heading: any = {
  setup: 'SETTING APPROVAL RECORDS',
  retro: 'RETROACTIVE CHECK INSPECTION RECORDS',
  suspected: 'SUSPECTED CHECK RECORDS',
  change4m: '4M CHANGE REPORT',
};
const TheadChange4m = ({
  getProcess,
  moduleData,
  isView,
  operation,
}: IProps) => {
  const [render, setRender] = useState(false);
  const documentType = useQuery('documentType') || '';
  const machineName = useQuery('machine_name') || '';
  const operation_name = useQuery('operation_name') || '';
  return (
    <thead>
      <TableCompHead
        colSpan={16}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Eff. Date']}
        values={tableHead[documentType]}
        heading={'In-Process Inspection Records'}
      />
      <tr>
        <th align="left" colSpan={3}>
          Customer Name:
          {moduleData?.customer_name ||
            moduleData.part_id?.customer_id?.customer_name}
        </th>
        <th align="left" colSpan={9}>
          Machine Name:{machineName}
        </th>
        <th colSpan={4} align="left">
          <Stack style={{ gap: '5px' }}>
            Date:-
            {isView ? (
              formatDate(moduleData.date)
            ) : (
              <DatePickerMui
                value={moment(moduleData.date || null)}
                onChange={(date) => {
                  moduleData.date = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </Stack>
        </th>
      </tr>
      <tr>
        <th colSpan={3}></th>
        <th colSpan={9} align="left">
          Operation Name & No. :
          {isView ? (
            moduleData.operation_name
          ) : (
            <CustomSelect
              name="operation_name"
              value={moduleData.operation_name || operation_name || ''}
              onChange={(e) => {
                getProcess(e.target.value);
                setRender((prev) => !prev);
              }}
            >
              <MenuItem value="">None</MenuItem>
              {operation.map((name) => (
                <MenuItem value={name}>{name}</MenuItem>
              ))}
            </CustomSelect>
          )}
        </th>

        <th colSpan={1} align="left">
          Sample Size :
          <TableInput
            isView={isView}
            name="sample_size"
            type="text"
            value={moduleData?.sample_size}
            onChange={(e) => {
              moduleData.sample_size = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={3} align="left">
          Mid.
          <TableInput
            isView={isView}
            name="mid"
            type="text"
            value={moduleData?.mid}
            onChange={(e) => {
              moduleData.mid = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          PART NAME: {moduleData.part_name || moduleData.part_id?.part_name}
        </th>
        <th colSpan={9} align="left">
          PART NO: {moduleData?.part_number || moduleData.part_id?.part_number}
        </th>
        <th colSpan={1} align="left">
          4Pcs.
          <TableInput
            isView={isView}
            name="pcs_4"
            type="text"
            value={moduleData?.pcs_4}
            onChange={(e) => {
              moduleData.pcs_4 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={3} align="left">
          End{' '}
          <TableInput
            isView={isView}
            name="end"
            type="text"
            value={moduleData?.end}
            onChange={(e) => {
              moduleData.end = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th rowSpan={3}>S.NO.</th>
        <th colSpan={2}>DESCRIPTION</th>
        <th rowSpan={3}>METHOD</th>
        <th colSpan={8}>OBSERVATIONS</th>
        <th rowSpan={3}>STATUS</th>
        <th rowSpan={3}>APPROVAL BY</th>
        <th rowSpan={3}>REMARKS</th>
        <th rowSpan={3} style={{ minWidth: '130px' }}>
          ACTION
        </th>
      </tr>
      <tr>
        <th rowSpan={2}>PARAMETER</th>
        <th rowSpan={2}>SPECIFICATION</th>
        <th colSpan={4}>Mid</th>
        <th colSpan={4}>End</th>
      </tr>
      <tr>
        {[1, 2, 3, 4, 1, 2, 3, 4].map((head: any, index) => (
          <th style={{ textTransform: 'uppercase' }}>{head}</th>
        ))}
      </tr>
    </thead>
  );
};

export default TheadChange4m;
