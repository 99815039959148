import { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../../components/common';
import CustomSelect from '../../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import formatDate from '../../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData &&
        moduleData?.processes?.map((process: any, index: number) => (
          <tr>
            <td>{index + 1}</td>
            <td style={{ minWidth: '300px', textAlign: 'center' }}>
              {process?.parameter}
            </td>
            <td style={{ minWidth: '200px', textAlign: 'center' }}>
              {process?.spec}
            </td>
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              {process?.method}
            </td>
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="obs"
                type="text"
                value={process.obs}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              {isView ? (
                process?.status
              ) : (
                <CustomSelect
                  name="dropdown"
                  value={process?.status || ''}
                  onChange={(e) => {
                    process.status = e.target.value;
                    setRender((prev) => !prev);
                  }}
                  sx={{
                    minWidth: '90px',
                    textAlign: 'center',
                  }}
                >
                  <MenuItem value="">NONE</MenuItem>
                  <MenuItem value="OK">OK</MenuItem>
                  <MenuItem value="NOT OK">NOT OK</MenuItem>
                </CustomSelect>
              )}
            </td>
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={process.remarks}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
      <tr>
        <th colSpan={3} style={{ textAlign: 'left' }}>
          Prev. Maint. Status:
          <TableInput
            isView={isView}
            name="status"
            type="text"
            value={moduleData.status}
            onChange={(e) => {
              moduleData.status = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={4} style={{ textAlign: 'left' }}>
          {/* Next PM Due Date :
          {isView ? (
            formatDate(moduleData.new_due_date)
          ) : (
            <DatePickerMui
              label=""
              value={
                (moduleData?.new_due_date && moment(moduleData.new_due_date)) ||
                null
              }
              onChange={(date) => {
                moduleData.new_due_date = date;
                setRender((prev) => !prev);
              }}
            />
          )} */}
        </th>
      </tr>
      <tr>
        <th colSpan={3} style={{ textAlign: 'left' }}>
          PM By :
          <TableInput
            isView={isView}
            name="pm_by"
            type="text"
            value={moduleData.pm_by}
            onChange={(e) => {
              moduleData.pm_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={4} style={{ textAlign: 'left' }}>
          Checked By :
          <TableInput
            isView={isView}
            name="checked_by"
            type="text"
            value={moduleData.checked_by}
            onChange={(e) => {
              moduleData.checked_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tbody>
  );
};

export default TBody;
