import React, { useState } from 'react';
import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  // customerData: any;
  isView: boolean;
  // handelDeleteRows: any;
  // setIsOpenPopup: any;
  // setPartIdx: any;
}

const TBody = ({
  moduleData,
  // customerData,
  isView,
}: // handelDeleteRows,
// setIsOpenPopup,
// setPartIdx,
IProps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <React.Fragment key={index + 1 + 'process'}>
              <TBodyRow
                {...{
                  moduleData,
                  process,
                  index,
                  isView,
                  // handelDeleteRows,
                  // setIsOpenPopup,
                  // setPartIdx,
                }}
              />
            </React.Fragment>
          ))}
        <tr>
          <th colSpan={2} align="left">
            Legends
          </th>
          <th colSpan={3}>असामन्य स्थिति है </th>
          <th>✓ </th>
          <th colSpan={3}>असामन्य स्थिति नहीं है </th>
          <th>⨯</th>
          <th colSpan={24}></th>
        </tr>
      </tbody>
    </>
  );
};

export default TBody;
