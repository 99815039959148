// import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
// import { TableInput } from '../../../../components/common';
// import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const numberCol = Array.from({ length: 31 }, (_, index) => index + 1);

const theadData = ['Sr.No.', 'Abnormal Situation', ...numberCol, 'Remarks'];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={34}
        rowSpan={3}
        labels={['Doc. No.:-', 'Rev. No. :-', 'Rev.Date :-']}
        values={['F-PROD-03', '00', '01.11.2020']}
        heading="ABNORMAL SITUATION LOG BOOK"
      />
      <tr>
        <th colSpan={2} align="left">
          Date :{' '}
        </th>
        <th colSpan={4} align="left">
          {isView ? (
            formatDate(moduleData.abnormalLogBook_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.abnormalLogBook_date)}
              onChange={(date) => {
                moduleData.abnormalLogBook_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={28}></th>
      </tr>
      <tr>
        {theadData.map((heading) => (
          <th key={heading}>{heading}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
