import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import { DeleteIconButton } from '../../../../../../components/common/button/CustomIconButton';

interface IProps {
  isView: boolean;
  moduleData: any;
  handelDeleteRows: any;
}
const TBody = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData?.processes?.map((item: any, index: number) => (
        <tr>
          <td style={{ width: '120px' }}>{index + 1}</td>
          <td>
            <TableInput
              name="parameter"
              type="text"
              value={item?.parameter}
              onChange={(e) => {
                item.parameter = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td>
            <TableInput
              name="spec"
              type="text"
              value={item?.spec}
              onChange={(e) => {
                item.spec = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td>
            <TableInput
              name="method"
              type="text"
              value={item?.method}
              onChange={(e) => {
                item.method = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td style={{ minWidth: '100px', textAlign: 'center' }}>
            <DeleteIconButton
              tooltipTitle={'delete'}
              onClick={() => handelDeleteRows(index)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TBody;
