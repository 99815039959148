import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TimePicker } from '@mui/x-date-pickers';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import TextAreaInput from '../../../../../components/common/TextAreaInput';
import { CustomButton } from '../../../../../components/common/button/CustomButtons';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  handleAddRow: any;
}
const TBody3 = ({
  moduleData,
  isView,
  handelDeleteRows,
  handleAddRow,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      <tr
        style={{
          fontSize: '2rem',
          background: '#ffec99',
        }}
      >
        <th colSpan={8}>Identified Root Couse Of Problem</th>
        <th></th>
      </tr>
      <tr
        style={{
          fontSize: '1.5rem',
        }}
      >
        <th colSpan={8}>
          <TextAreaInput
            isView={isView}
            name="action_taken"
            value={moduleData.action_taken}
            onChange={(e) => {
              moduleData.action_taken = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th></th>
      </tr>
      <tr
        style={{
          fontSize: '2rem',
          background: '#dee2e6',
        }}
      >
        <th colSpan={8}>Systemetic Action(s) Plan (For Prevention)</th>
        <th>
          <CustomButton
            sx={{
              backgroundColor: '#1c7ed6',
            }}
            onClick={() => {
              handleAddRow('systematic_action_plan');
            }}
          >
            ADD ROWS
          </CustomButton>
        </th>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'S. No.',
          'Action Plan',
          'Resp.',
          'Target Date',
          'Status',
          'Action',
        ].map((head, index) => (
          <th
            key={head}
            style={{ textTransform: 'uppercase' }}
            colSpan={index === 1 ? 4 : 1}
          >
            {head}
          </th>
        ))}
      </tr>
      {moduleData?.systematic_action_plan &&
        moduleData?.systematic_action_plan?.map((item: any, index: number) => (
          <tr>
            <td>{index + 1}</td>
            <td style={{ minWidth: '300px' }} colSpan={4}>
              <TextAreaInput
                isView={isView}
                name="action"
                value={item.action}
                onChange={(e) => {
                  item.action = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <TextAreaInput
                isView={isView}
                name="resp"
                value={item.resp}
                onChange={(e) => {
                  item.resp = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '180px' }}>
              {isView ? (
                formatDate(item.target_date)
              ) : (
                <DatePickerMui
                  label=""
                  onChange={(date) => {
                    item.target_date = moment(date || null);
                    setRender((prev: any) => !prev);
                  }}
                  value={
                    (item?.target_date && moment(item?.target_date)) || null
                  }
                />
              )}
            </td>

            <td>
              {isView ? (
                item.status
              ) : (
                <CustomSelect
                  label=""
                  name="status"
                  value={item.status}
                  onChange={(e: any) => {
                    item.status = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">NONE</MenuItem>
                  <MenuItem value="YES">OK</MenuItem>
                  <MenuItem value="NO">NOT OK</MenuItem>
                </CustomSelect>
              )}
            </td>
            {!isView && (
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() =>
                    handelDeleteRows(index, 'systematic_action_plan')
                  }
                />
              </td>
            )}
          </tr>
        ))}
    </tbody>
  );
};

export default TBody3;
