import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import MenuItem from '@mui/material/MenuItem';
import { IData } from './Interface';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import SelectGridMui from '../../components/common/selectscustom/SelectGridMui';
import useConfirm from '../../components/common/useConfirm';
import moment from 'moment';
import DateGridMui from '../../components/common/dateCustom/DateGridMui';
import AutocompleteGridmui from '../../components/common/autocomplete/AutocompleteGridmui';
import { DivStyled } from '../../assets/styles/components/DivStyledLableInputs';
import CustomUploadButton from '../../components/common/button/CustomUploadButton';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<IData>({
    employee_name: '',
    department_id: null,
    employee_type: 'Staff',
  });

  const [render, setRender] = useState(false);
  const [image, setImage] = useState(null);
  const [department, setDepartment] = useState([]);
  const [autoCompleteDepartment, setAutocompleteDepartment] = useState('');
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Employee' : 'Create Employee'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/employee/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setAutocompleteDepartment(res.data.department_id);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleModulData(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    if (!moduleData.department_id) {
      return alert('Please! also pick department');
    }
    const ans = await confirmSubmit();
    if (!ans) return;

    const formData = new FormData();
    if (
      typeof moduleData.department_id === 'object' &&
      moduleData.department_id
    ) {
      moduleData.department_id = moduleData.department_id?._id;
    }

    // moduleData.area_of_work_url =
    //   induction_file_url[addEmployeeData.area_of_work];
    for (const key in moduleData) {
      if (moduleData[key]) {
        formData.append(key, moduleData[key]);
      } else {
        delete moduleData[key];
      }
    }
    if (image) {
      formData.append('image', image);
    }
    if (id) {
      await axios
        .put(`/api/employee/${id}`, formData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/employee', formData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    axios
      .get(`/api/department`)
      .then((res) => setDepartment(res.data.results))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <GridInputs
        id={'employee_name'}
        name={'employee_name'}
        html_for={'employee_name'}
        label_name={'Employee Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.employee_name}
        onChange={handleModulData}
      />
      <GridInputs
        id={'designation'}
        name={'designation'}
        html_for={'designation'}
        label_name={'Designation:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.designation}
        onChange={handleModulData}
      />
      <GridInputs
        id={'doj'}
        name={'doj'}
        html_for={'doj'}
        label_name={'DOJ'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.doj}
        onChange={handleModulData}
      />
      <GridInputs
        id={'qualification'}
        name={'qualification'}
        html_for={'qualification'}
        label_name={'Qualification'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.qualification}
        onChange={handleModulData}
      />
      <GridInputs
        id={'skill_level'}
        disabled={true}
        name={'skill_level'}
        html_for={'skill_level'}
        label_name={'Skill Level'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.skill_level}
        onChange={handleModulData}
      />
      <SelectGridMui
        name="employee_type"
        value={moduleData?.employee_type || ''}
        lable={'Employee Type:'}
        onChange={(e: any) => handleModulData(e)}
      >
        <MenuItem value="Staff">Staff</MenuItem>
        <MenuItem value="Operator-Helper">Operator-Helper</MenuItem>
      </SelectGridMui>
      <AutocompleteGridmui
        label={'Department Name:'}
        id={'department_name'}
        option_name={'department_name'}
        value={autoCompleteDepartment}
        arrayofObj={department}
        onChange={(e, value) => {
          moduleData.department_id = value?._id;
          setAutocompleteDepartment(value);
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <DivStyled>
        <CustomUploadButton
          lable={'UPLOAD DOCUMENT'}
          accept="image/png,image/jpeg,image/jpg"
          onChange={(e) => setImage(e.target.files[0])}
        />
      </DivStyled>

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
