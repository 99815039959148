import React, { useEffect, useState } from 'react';
import lodash from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

import TControlllerAdc from './components/attachChemicalCompositionModule/components/adcToEcgrade/TControlllerAdc';
import TControllerChrono from './components/attachChemicalCompositionModule/components/chronoToCrca/TControllerChrono';
import { useQuery } from '../../hooks/UseQuery';
import { checmicalCompositionData } from './components/attachChemicalCompositionModule/checmicalCompositionData';
import useConfirm from '../../components/common/useConfirm';
import axios from 'axios';
import { useAuth } from '../../services/auth/AuthProvider';
import { Box } from '@mui/material';
import ChildHeader from '../../components/ui/ChildHeader';
import {
  SaveButton,
  SubmitButton,
} from '../../components/common/button/CustomButtons';
import ErrorPage from '../../error-page';

const ChemicalCompositionEdit = () => {
  const { materialId, ccId } = useParams();
  const cc_type = useQuery('cc_type');
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = false;

  const [moduleData, setModuleData] = useState({
    cc_type: cc_type,
    materialInspectionId: materialId,
    [cc_type as string]: cc_type
      ? lodash.cloneDeep(checmicalCompositionData[cc_type])
      : {
          processes_1: [],
          processes_2: [],
        },
  });
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );

  useEffect(() => {
    if (ccId) {
      axios
        .get(`/api/chemicalCompostion/${ccId}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [ccId]);

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    moduleData.report_prepared_by = state.user?.name;
    if (ccId) {
      await axios
        .put(`/api/chemicalCompostion/${ccId}`, moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Updated Successfully');
          }
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/chemicalCompostion', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      {cc_type === 'none' ? (
        <ErrorPage />
      ) : (
        <Box>
          <Box>
            <ChildHeader text="METERIAL INSPECTION REPORT- INCOMING">
              <SubmitButton label="Submit" onClick={() => handleSumbit()} />
            </ChildHeader>
          </Box>
          {cc_type === 'adc_12' ||
          cc_type === 'adc_10' ||
          cc_type === 'lm_6' ||
          cc_type === 'zinck' ||
          cc_type === 'ec_grade' ? (
            <TControlllerAdc {...{ moduleData, isView, cc_type }} />
          ) : (
            <TControllerChrono {...{ moduleData, isView, cc_type }} />
          )}
        </Box>
      )}

      <DialogSubmit />
    </>
  );
};

export default ChemicalCompositionEdit;
