export const spotWelding = [
  //spot welding
  {
    s_no: 1,
    check_point: 'मशीन की सफाई  करें ',
    judgement_criteria: 'धूल मिटटी  आदि ना हो और मशीन साफ़ हो ',
    method: 'कपड़े से और बाद में हवा से सफाई करें (हवा सूखी हो)  ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 2,
    check_point: 'मशीन में आवाज चेक करें ',
    judgement_criteria: 'मशीन में एब्नोर्मल आवाज ना हो',
    method: 'सुन कर और छू कर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 3,
    check_point: 'हवा की लीकेज चेक करें ',
    judgement_criteria: 'मशीन में से हवा की लीकेज ना हो ',
    method: 'सुन कर और छू कर  (मशीन को चलाते हुए भी)  ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 4,
    check_point: 'पानी की लीकेज चेक करें ',
    judgement_criteria: 'मशीन में से पानी की लीकेज ना हो',
    method: 'देख कर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 5,
    check_point: 'पानी के पाइप की ब्लोकेज चेक करें',
    judgement_criteria: 'पानी ब्लॉक ना हो ',
    method:
      'आउट लेट डिस्ट्रीब्यूटर से  सारे पाइप चेक करें  जो पाइप ब्लॉक हो एयर प्रेशर से साफ़ करें',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 6,
    check_point: 'स्लाइड की मूमेंट चेक करें',
    judgement_criteria: 'स्लाइड की मूमेंट स्मूथ हो ',
    method: 'चला कर और हिला कर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 7,
    check_point: 'नट बोल्ट की स्थिति चेक करें ',
    judgement_criteria:
      'सभी नट बोल्ट प्लेन वाशर  और स्प्रिंग वाशर के साथ सही टाइट हों   ',
    method: 'सही प्रॉपर टूल्स के साथ  ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 8,
    check_point: 'वेल्डिंग  ट्रांसफार्मर और बस बार के जॉइंट्स चेक  करें ',
    judgement_criteria: 'लूज़ जॉइंट्स और पिटींग ना हो ',
    method: 'जॉइंट्स खोल कर  चेक करें ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 9,
    check_point: 'वेल्डिंग मशीन की शंट चेक  करें ',
    judgement_criteria: 'शंट की पत्तियां  कटी हुई ना हों ',
    method: 'देख कर चेक करें',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 10,
    check_point: 'सभी स्विच की स्थिति चेक करें ',
    judgement_criteria: 'स्थिति सही हो और काम सही कर रहा हो',
    method: 'चला कर और देख कर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 11,
    check_point: 'वेलडिंग कॅंट्रोलर चेक करें ',
    judgement_criteria:
      'इंडिकेशन स्विच, कंट्रोलर स्विच और रेगुलेटर काम कर रहे हैं',
    method: 'चला कर और देख कर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 12,
    check_point: 'ट्रांसफार्मर और थायरिस्टर की गर्माहट  चेक करें ',
    judgement_criteria:
      'ट्रांसफार्मर गर्म ना हो (टेम्प्रेचर एम्बिएंट से चेक करें टेम्प्रेचर १० डिग्री से ज्यादा ना हो )',
    method: 'चलती मशीन को छू कर और टेम्प्रेचर  गन से चेक करें ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 13,
    check_point: 'ट्रांसफार्मर और थायरिस्टर का थर्मोस्टेट  चेक करें  ',
    judgement_criteria: 'सही लगा होना चाहिए ',
    method: 'देख कर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 14,
    check_point: 'अर्थिग  चेक करें ',
    judgement_criteria: 'मशीन डबल अर्थ  कनेक्शन टाइट कनेक्ट  होनी चाहिए ',
    method: 'देख कर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 15,
    check_point: 'हवा के सिलेंडर की मूमेंट चेक करें ',
    judgement_criteria: 'मूवमेंट स्मूथ हो',
    method: 'चलाकर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 16,
    check_point: 'हवा के सिलेंडर की अंदर से करें सफाई और ग्रीसिंग  करें  ',
    judgement_criteria:
      'सिलेंडर साफ़ हो,सील्स ठीक हों बैरल पर स्क्रैच के निशान ना हों ',
    method: 'सिलिंडर खोल कर  अंदर से   और बिना दस्ताने से ग्रीसिंग करें ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 17,
    check_point: 'फुट स्विच चेक करें ',
    judgement_criteria: 'कंडीशन वर्किंग एंड कनेक्शन',
    method: 'चलाकर और देख कर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 18,
    check_point: 'मशीन के कबर की स्थिति चेक करें ',
    judgement_criteria: 'सारे बोल्ट्स और लॉक्स  के साथ  टाइट हों ',
    method: 'हाथ से और सही टूल्स के साथ ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 19,
    check_point: 'फ़िल्टर रेग्युलेटर लुब्रीकेटर (FRL) चेक  करें ',
    judgement_criteria:
      '*फ़िल्टर का एलिमेंट साफ़ होना चाहिए *रेग्युलेटरसे प्रेशर कम ज्यादा होना चाहिए *प्रेशर गेज ठीक और वर्किंग ह *लुब्रिकेटर का तेल साफ़ होना चाहिए और प्रॉपर आयल ड्रॉपिंग होना चाहिए *FRLसही माउंट होना चाहिए ',
    method: 'देख कर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 20,
    check_point: 'सोलेनोइड वाल्व चेक करें  ',
    judgement_criteria: 'माउंटिंग और वर्किंग',
    method: 'देख कर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 21,
    check_point: 'केबल और पाइपिंग की ड्रेसिंग चेक  करें ',
    judgement_criteria: 'केबल और पाइपिंग लूज़ ना हों ',
    method: 'केबल टाई से प्रॉपर्ली ड्रेसिंग और टाइट  बांधें ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 22,
    check_point: 'इनकमिंग  पावर सप्लाई  MCCB बॉक्स कंडीशन ',
    judgement_criteria:
      'MCCB बॉक्स सही बंद हों  होल खुले ना हों कनेक्शन  सही हों ',
    method: 'देख कर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
];
export const projectionWelding = [
  // projection welding
  {
    s_no: 1,
    check_point: 'PM स्टार्ट करने से पहले मशीन की MCB बंद करें',
    judgement_criteria: 'MCB ऑफ़ होनी चाहिए',
    method: 'देख कर  ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 2,
    check_point: 'मशीन की सफाई  करें ',
    judgement_criteria: 'धूल मिटटी  आदि ना हो और मशीन साफ़ हो ',
    method: 'कपड़े से और बाद में हवा से सफाई करें (हवा सूखी हो)  ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 3,
    check_point: 'मशीन में आवाज चेक करें ',
    judgement_criteria: 'मशीन में से एब्नोर्मल आवाज ना हो',
    method: 'सुन कर और छू कर  ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 4,
    check_point: 'हवा और पानी की लीकेज चेक करें ',
    judgement_criteria:
      'मशीन में से हवा की लीकेज और पाइप में से पानी की लीकेज ना हो ',
    method: 'सुन कर छू और देख कर  (मशीन को चलाते समय)  ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 5,
    check_point: 'पानी के पाइप की ब्लोकेज चेक करें',
    judgement_criteria: 'पानी ब्लॉक ना हो ',
    method:
      'आउट लेट डिस्ट्रीब्यूटर से  सारे पाइप चेक करें  जो पाइप ब्लॉक हो एयर प्रेशर से साफ़ करें',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 6,
    check_point: 'स्लाइड की मूमेंट चेक करें',
    judgement_criteria: 'स्लाइड की मूमेंट स्मूथ हो  ',
    method: 'चला कर और हिला कर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 7,
    check_point: 'नट बोल्ट की स्थिति चेक करें ',
    judgement_criteria:
      'सभी नट बोल्ट प्लेन वाशर  और स्प्रिंग वाशर के साथ सही टाइट हों   ',
    method: 'सही प्रॉपर टूल्स के साथ  ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 8,
    check_point: 'वेल्डिंग  ट्रांसफार्मर और बस बार के जॉइंट्स चेक  करें ',
    judgement_criteria: 'लूज़ जॉइंट्स और पिटींग ना हो ',
    method: 'जॉइंट्स खोल कर  चेक करें ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 9,
    check_point: 'वेल्डिंग मशीन की शंट चेक  करें ',
    judgement_criteria: 'शंट की पत्तियां  कटी हुई ना हों ',
    method: 'देख कर चेक करें',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 10,
    check_point: 'वेलडिंग कॅंट्रोलर चेक करें  ',
    judgement_criteria:
      'इंडिकेशन स्विच, कंट्रोलर स्विच और रेगुलेटर काम कर रहे हैं',
    method: 'चला कर और देख कर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 11,
    check_point: 'ट्रांसफार्मर और थायरिस्टर की गर्माहट  चेक करें  ',
    judgement_criteria:
      'ट्रांसफार्मर गर्म ना हो (टेम्प्रेचर एम्बिएंट से चेक करें टेम्प्रेचर १० डिग्री से ज्यादा ना हो )',
    method: 'चलती मशीन को छू कर और टेम्प्रेचर  गन से चेक करें ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },

  {
    s_no: 12,
    check_point: 'ट्रांसफार्मर और थायरिस्टर का थर्मोस्टेट  चेक करें  ',
    judgement_criteria: 'सही लगा होना चाहिए ',
    method: 'देख कर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 13,
    check_point: 'हवा के सिलेंडर की मूमेंट चेक करें ',
    judgement_criteria: 'मूवमेंट स्मूथ हो',
    method: 'चलाकर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 14,
    check_point: 'हवा के सिलेंडर की अंदर से करें सफाई और ग्रीसिंग  करें   ',
    judgement_criteria:
      'सिलेंडर साफ़ हो,सील्स ठीक हों बैरल पर स्क्रैच के निशान ना हों ',
    method: 'सिलिंडर खोल कर  अंदर से करें और बिना दस्ताने से ग्रीसिंग करें  ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },

  {
    s_no: 15,
    check_point: 'फुट स्विच चेक करें ',
    judgement_criteria: 'कंडीशन वर्किंग एंड कनेक्शन',
    method: 'चलाकर और देख कर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 16,
    check_point: ' मशीन के कबर की स्थिति चेक करें ',
    judgement_criteria: 'सारे बोल्ट्स और लॉक्स  के साथ  टाइट हों ',
    method: 'हाथ से और सही टूल्स के साथ ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 17,
    check_point: 'फ़िल्टर रेग्युलेटर लुब्रीकेटर (FRL) चेक  करें ',
    judgement_criteria:
      '*फ़िल्टर का एलिमेंट साफ़ होना चाहिए *रेग्युलेटरसे प्रेशर कम ज्यादा होना चाहिए *प्रेशर गेज ठीक और वर्किंग ह *लुब्रिकेटर का तेल साफ़ होना चाहिए और प्रॉपर आयल ड्रॉपिंग होना चाहिए *FRLसही माउंट होना चाहिए ',
    method: 'देख कर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 18,
    check_point: 'सोलेनोइड वाल्व चेक करें  ',
    judgement_criteria: 'माउंटिंग और वर्किंग',
    method: 'देख कर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 19,
    check_point: 'केबल और पाइपिंग की ड्रेसिंग चेक  करें ',
    judgement_criteria: 'केबल और पाइपिंग लूज़ ना हों ',
    method: 'केबल टाई से प्रॉपर्ली ड्रेसिंग और टाइट  बांधें ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 20,
    check_point: 'इनकमिंग  पावर सप्लाई  MCCB बॉक्स कंडीशन ',
    judgement_criteria:
      'MCCB बॉक्स सही बंद हों  होल खुले ना हों कनेक्शन  सही हों ',
    method: 'देख कर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 21,
    check_point: ' कूलिंग सिस्टम',
    judgement_criteria: 'पोका योका चालू हो',
    method: 'देख कर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 22,
    check_point: 'ड्रेसिंग सिस्टम',
    judgement_criteria: 'पोका योका चालू हो',
    method: 'देख कर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 23,
    check_point: 'एयर प्रेशर 3 kg स्क्वार सेंटीमीटर से कम और ज्यादा करें ',
    judgement_criteria:
      'कम करने पे मशीन  नहीं चलनी चाहिए और ज्यादा करने पे मशीन चलनी चाहिए',
    method: 'देख कर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
];

export const pnumeticPress = [
  {
    s_no: 1,
    check_point: 'PM स्टार्ट करने से पहले मशीन की MCB बंद करें',
    judgement_criteria: 'MCB ऑफ़ होनी चाहिए',
    method: 'देख कर  ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 2,
    check_point: 'मशीन की सफाई  करें ',
    judgement_criteria: 'धूल मिटटी  आदि ना हो और मशीन साफ़ हो ',
    method: 'मशीन को देख कर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 3,
    check_point: 'प्रेस मशीन की सन्तरी के चकनट की चूड़ी चेक करे  ',
    judgement_criteria: 'सन्तरी के चकनट की चूड़ी ठीक होनी चाहिये',
    method: 'चूड़ी देखकर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 4,
    check_point: 'प्रेस मशीन की सेटिंग हाईट स्क्रू और चकनट की चूरी ',
    judgement_criteria: 'सेटिंग हाईट स्क्रू और चकनट की चूरी',
    method: 'चूड़ी देखकर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 5,
    check_point: 'प्रेस मशीन के हैमर की स्लाइड मोमन्ट चेक करे ',
    judgement_criteria: 'हैमर के स्लाइड मोमन्ट स्मूथ होनी चाहिए, लूज न हो',
    method: 'मशीन को चलाकर देखे ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 6,
    check_point: 'प्रेस मशीन के क्रैंक शाफ़्ट और बुश को चेक करे ',
    judgement_criteria: 'क्रैंक शाफ़्ट और बुश लूज नही होने चाहिए ',
    method: 'क्रैंक शाफ़्ट को ऊपर निचे दाये बाये घुमा कर देखे ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 7,
    check_point:
      'प्रेस मशीन के क्रैंक शाफ़्ट और फ्लाई व्हील के चाभी के गुरूप को चेक करे ',
    judgement_criteria:
      'क्रैंक शाफ़्ट और फ्लाई व्हील के चाभी के गुरूप कोर डैमेज नही होनी चाहिए तथा फ्लाई व्हील लूज न हो ',
    method:
      'क्रैंक शाफ़्ट और फ्लाई व्हील के चाभी के गुरूप कोर और फ्लाई व्हील को हिला कर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 8,
    check_point: 'प्रेस मशीन के फुट पैडल की वायरिंग  ',
    judgement_criteria: 'वायर कटी व लूज न हो ',
    method: 'देखकर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 9,
    check_point: ' प्रेस मशीन के फुट पैडल स्प्रिंग का टेंशन',
    judgement_criteria: 'फुट पैडल स्प्रिंग लूज न हो ',
    method: 'फुट पैडल चला कर देखे ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 10,
    check_point: 'प्रेस मशीन के क्लच शू और लाइनर  ',
    judgement_criteria: 'क्लच शू और लाइनर के वियर आउट नही होने चाहिए ',
    method: 'फुट पैडल को दबा कर देखे ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 11,
    check_point: 'प्रेस मशीन के इमरजेंसी स्विच  ',
    judgement_criteria: 'इमरजेंसी स्विच काम करना चाहिए ',
    method: 'इमरजेंसी स्विच दबाकर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 12,
    check_point: 'आयल पंप  ',
    judgement_criteria: 'आयल पंप चलना चाहिए ',
    method: 'आयल पंप चलाकर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 13,
    check_point: 'प्रेस मशीन के आयल पाइप  ',
    judgement_criteria: 'आयल पाइप के होल बंद न हो',
    method: 'आयल कप पाइप को देख कर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 14,
    check_point: 'प्रेस मशीन के इलेक्ट्रिक मोटर के नट और बोल्ट  ',
    judgement_criteria: 'इलेक्ट्रिक मोटर के नट और बोल्ट लूज न हो ',
    method: 'इलेक्ट्रिक मोटर को चला कर देखे',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 15,
    check_point: 'प्रेस मशीन के इलेक्ट्रिक मोटर की वायरिंग ',
    judgement_criteria:
      'इलेक्ट्रिक मोटर के तार के जॉइंट के  ऊपर टेप लगा होना चाहियें',
    method: 'इलेक्ट्रिक मोटर के तार के जॉइंट को देख कर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 16,
    check_point: 'प्रेस मशीन के इलेक्ट्रिक मोटर की अरथिंग ',
    judgement_criteria:
      'इलेक्ट्रिक मोटर की आरथिंग को चेक करे की आरथिंग तार से टाइट होना चाहियें',
    method: 'इलेक्ट्रिक मोटर के आरथिंग तार को देख कर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 17,
    check_point:
      'प्रेस मशीन के स्टार्टर की रिले चेक करे की मोटर हार्स पवेर से अधिक नही होनी चाहियें ',
    judgement_criteria:
      'स्टार्टर की रिले चेक करे की मोटर हार्स पवेर से अधिक नही होनी चाहियें',
    method: 'स्टार्टर  कवर को निकल कर के देखे ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 18,
    check_point: 'प्रेस मशीन का वाइब्रेशन कंपन चेक करे  ',
    judgement_criteria:
      'प्रेस मशीन के फाउंडेशन बोल्ट या  फाउंडेशन पैड को देखे की डैमेज तो नही है ',
    method: 'प्रेस मशीन के फाउंडेशन बोल्ट या फाउंडेशन पैड को देखे कर के ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 19,
    check_point: ' क्लच प्रेशर ',
    judgement_criteria: '50 ~ 70 PSI',
    method: 'मीटर देखकर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 20,
    check_point: 'क्लच नट बोल्ट  ',
    judgement_criteria: 'लूज़ न हो ',
    method: 'स्पैनर से ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
];

export const powerPress = [
  {
    s_no: 1,
    check_point: 'PM स्टार्ट करने से पहले मशीन की MCB बंद करें',
    judgement_criteria: 'MCB ऑफ़ होनी चाहिए',
    method: 'देख कर  ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 2,
    check_point: 'मशीन की सफाई  करें ',
    judgement_criteria: 'धूल मिटटी  आदि ना हो और मशीन साफ़ हो ',
    method: 'मशीन को देख कर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 3,
    check_point: 'संतरी के चकनट की चूड़ी   ',
    judgement_criteria: 'संतरी के चकनट की चूड़ी ख़राब न हो',
    method: 'चूड़ी देखकर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 4,
    check_point: 'सेटिंग हाइट स्क्रू और चक नट की चूड़ी ',
    judgement_criteria: 'स्क्रू और चक नट की चूड़ी ख़राब न हो ',
    method: 'चूड़ी देख कर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 5,
    check_point: 'हैमर के स्लाइड की मूवमेंट  ',
    judgement_criteria: 'हैमर के स्लाइड की मूवमेंट स्मूथ हो और लूज न हो',
    method: 'मशीन चलाकर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 6,
    check_point: 'क्रैंक शाफ़्ट और बुश ',
    judgement_criteria: 'क्रैंक शाफ़्ट और बुश लूज न हो ',
    method: 'शाफ़्ट को ऊपर निचे दाये बाये घुमा कर देखे ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 7,
    check_point: 'क्रैंक शाफ़्ट और फ्लाई व्हील के चाभी के ग्रूव ',
    judgement_criteria:
      'क्रैंक शाफ़्ट और फ्लाई व्हील के चाभी के ग्रूव कोर डैमेज न हो और फ्लाई व्हील लूज न हो ',
    method: ' फ्लाई व्हील को हिला कर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 8,
    check_point: ' ब्रेक पैडल  ',
    judgement_criteria: ' ब्रेक पैडल लूज न हो ',
    method: 'पैडल दबाकर  ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 9,
    check_point: ' ब्रेक पैडल का कनेक्टिंग रॉड ',
    judgement_criteria:
      'ब्रेक पैडल कनेक्टिंग रॉड की चूड़ी डैमेज न हो और रॉड टाइट हो ',
    method: 'ब्रेक पैडल कनेक्टिंग रॉड को घुमा कर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 10,
    check_point: 'ब्रेक पैडल स्प्रिंग का टेंशन ',
    judgement_criteria: 'ब्रेक पैडल स्प्रिंग लूज न हो ',
    method: 'ब्रेक पैडल चला कर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 11,
    check_point: 'ब्रेकशू और लाइनर ',
    judgement_criteria: 'ब्रेक शू और लाइनर वियर आउट न हो और बबलिंग न हो',
    method: 'ब्रेक पैडल को दबा कर  ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 12,
    check_point: 'आयल कप',
    judgement_criteria: 'आयल कप के होल बंद न हो',
    method: 'आयल कप को देख कर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 13,
    check_point: 'आयल पाइप',
    judgement_criteria: 'आयल पाइप के होल बंद न हो',
    method: 'आयल कप पाइप को देख कर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 14,
    check_point: 'मोटर की बियरिंग',
    judgement_criteria: 'लूज न हो',
    method: 'हाथ से घुमाकर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 15,
    check_point: 'मोटर के नट और बोल्ट',
    judgement_criteria: 'मोटर के नट और बोल्ट लूज न हो ',
    method: 'मोटर को चलाकर',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 16,
    check_point: 'मोटर की वायरिंग',
    judgement_criteria: 'तार कटे और लूज़ न हो ',
    method: 'तार देखकर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 17,
    check_point: 'इलेक्ट्रिक मोटर की अरथिंग',
    judgement_criteria: 'अर्थिंग कटी न हो ',
    method: 'तार देखकर  ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 18,
    check_point: 'स्टार्टर की रिले ',
    judgement_criteria: 'स्टार्टर की रिले मोटर हॉर्स पावर से अधिक न हो ',
    method: 'मल्टीमीटर से  ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
  {
    s_no: 19,
    check_point: 'मशीन का वाइब्रेशन ',
    judgement_criteria: 'मशीन वाइब्रेशन न हो ',
    method: 'मोटर को चला कर ',
    picture: '',
    cycle_1_obs_before: '',
    cycle_1_obs_after: '',
    cycle_1_action_taken_repair: '',
    cycle_1_action_taken_replace: '',
    cycle_1_action_taken_remark: '',
    cycle_2_obs_before: '',
    cycle_2_obs_after: '',
    cycle_2_action_taken_repair: '',
    cycle_2_action_taken_replace: '',
    cycle_2_action_taken_remark: '',
  },
];
