import React, { useEffect, useState } from 'react';
import ModalCustom from '../../components/common/Modal';
import {
  TableBody,
  TableCell,
  TextField,
  Tooltip,
  Checkbox,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
} from '@mui/material';
import { Delete, Save } from '@mui/icons-material';
import { SubmitButton } from '../../components/common/button/CustomButtons';

const checkArrayStrCondition = (currentValue: any, prevValue: any) => {
  const convertedCurrentValue =
    typeof currentValue === 'object' ? currentValue.join(',') : currentValue;
  const convertedPrevValue =
    typeof currentValue === 'object' ? prevValue.join(',') : prevValue;
  return convertedCurrentValue !== convertedPrevValue;
};

interface IProps {
  openPopup: boolean;
  closeModal: () => void;
  partData: any;
  partDataForRevision: any;
  documentType: any;
  submit: (revisionData: any) => void;
  ecnTitle: string;
}
const ECNModal = (props: IProps) => {
  const {
    openPopup,
    closeModal,
    partData,
    partDataForRevision,
    documentType,
    submit,
    ecnTitle,
  } = props;
  const pfdSymbols: any = {
    '': 'None',
    1: '◯ - Operation',
    2: '▭ - Inspection',
    3: '⧇ - Operation Inspection',
    4: '△ - Storage',
    5: '📦- Packing',
    6: '⇨ - Transportation',
  };
  const pfdParams = [
    { param: 'machine_pfd', placeholder: 'Machine' },
    { param: 'spec_to_achieve_pfd', placeholder: 'Specification to Achieve' },
    { param: 'variation', placeholder: 'Process Control Parameters' },
    { param: 'product_char', placeholder: 'Key Control Characteristics' },
    { param: 'symbol', placeholder: 'Symbol' },
    { param: 'key_control', placeholder: 'Key Control Characteristics' },
  ];
  const specParams = [
    { param: 'char_process', placeholder: 'Process Char.' },
    { param: 'char_product', placeholder: 'Product Char.' },
    { param: 'special_character', placeholder: 'Special Character' },
    { param: 'control_product', placeholder: 'Spec./Tolerance' },
    { param: 'methods_measure', placeholder: 'Evaluation' },
    { param: 'methods_size', placeholder: 'Sample Size' },
    { param: 'methods_by', placeholder: 'Sample Frequency' },
    { param: 'recording', placeholder: 'Recording' },
    { param: 'responsibility', placeholder: 'Responsibility' },
    { param: 'methods_process', placeholder: 'Control Method' },
    { param: 'reaction_plan', placeholder: 'Reaction Plan & Action' },
    { param: 'remarks', placeholder: 'Remarks' },
    { param: 'control_product_remark', placeholder: 'Tolerance Remarks' },
    { param: 'unit', placeholder: 'Unit' },
    { param: 'machine', placeholder: 'Machine' },
  ];

  const fmeaParams = [
    { param: 'requirement', placeholder: 'Requirement' },
    { param: 'potential_mode', placeholder: 'Potential Failure Mode' },
    { param: 'eof', placeholder: 'Potential Effect(s) of  Failure' },
    { param: 'severity', placeholder: 'Severity' },
    { param: 'failure_class', placeholder: 'Class' },
    { param: 'potential_cause', placeholder: 'Potential Cause(s) of  Failure' },
    {
      param: 'control_prevention',
      placeholder: 'Current Process Control Prevention',
    },
    {
      param: 'control_detection',
      placeholder: 'Current Process Control Detection',
    },
    { param: 'recommendation_action', placeholder: 'Recommended Actions' },
    { param: 'det', placeholder: 'Detection Rating (DET)' },
  ];
  const [controlData, setControlData] = useState([]);
  const [pfdData, setPfdData] = useState([]);
  const [processData, setProcessData] = useState([]);
  const [fmeaData, setFmeaData] = useState([]);
  const [revisionData, setRevisionData] = useState<any>({});
  const [render, setRender] = useState(false);
  useEffect(() => {
    partData.processes.map((process: any, index1: number) => {
      let prevProcess: any;
      if (!process._id) {
        revisionData[`${index1}_added`] = {
          index1: index1,
          value: `Process ${process.process_name} Created`,
          action_taken: '',
          sugg_to: '',
          sugg_by: '',
          status: '',
          addToRevisionSummary: true,
          type: 'create',
          section: 'process',
        };
      } else {
        prevProcess = partDataForRevision.processes.find(
          (item: any) => item._id === process._id
        );
      }
      if (!prevProcess) return;
      if (documentType === 'pfd') {
        pfdParams.map((pfdParam: any) => {
          if (process[pfdParam.param] !== prevProcess[pfdParam.param]) {
            revisionData[index1 + '_' + pfdParam.param] = {
              index1: index1,
              value: `${process.process_name}, ${
                pfdParam.placeholder
              } Changed 𝗙𝗥𝗢𝗠 ${
                pfdParam.param !== 'symbol'
                  ? prevProcess[pfdParam.param]
                  : pfdSymbols[prevProcess[pfdParam.param]]
              } 𝗧𝗢 ${
                pfdParam.param !== 'symbol'
                  ? process[pfdParam.param]
                  : pfdSymbols[process[pfdParam.param]]
              }`,
              action_taken: '',
              sugg_to: '',
              sugg_by: '',
              status: '',
              addToRevisionSummary: true,
              type: 'change',
              section: 'pfd',
            };
          }
        });
      }
      if (documentType === 'controlplan') {
        process.specs.map((spec: any, index2: number) => {
          if (!spec._id) {
            revisionData[index1 + '_' + index2 + '_added'] = {
              index1: index1,
              index2: index2,
              value: `${process.process_name}, ${spec.char_product} Created`,
              action_taken: '',
              sugg_to: '',
              sugg_by: '',
              status: '',
              addToRevisionSummary: true,
              type: 'create',
              section: 'control_plan',
            };
            return;
          }
          const prevSpec = prevProcess.specs.find(
            (item: any) => item._id === spec._id
          );
          if (!prevSpec) return;
          specParams.map((specParam) => {
            if (
              spec &&
              prevSpec &&
              spec[specParam.param] !== prevSpec[specParam.param]
            ) {
              let key = process._id + '_' + spec._id + '_' + specParam.param;
              if (revisionData[key]) {
                revisionData[key].value = `${process.process_name}, ${
                  spec.char_product
                } ${spec.char_process}, ${specParam.placeholder} Changed 𝗙𝗥𝗢𝗠 ${
                  prevSpec[specParam.param] ? prevSpec[specParam.param] : '" "'
                } 𝗧𝗢 ${spec[specParam.param]}`;
              } else {
                revisionData[key] = {
                  index1: process._id,
                  index2: spec._id,
                  field: specParam.param,
                  value: `${process.process_name}, ${spec.char_product}, ${
                    specParam.placeholder
                  } Changed 𝗙𝗥𝗢𝗠 ${
                    prevSpec[specParam.param]
                      ? prevSpec[specParam.param]
                      : '" "'
                  } 𝗧𝗢 ${spec[specParam.param]}`,
                  action_taken: '',
                  sugg_to: '',
                  sugg_by: '',
                  status: '',
                  addToRevisionSummary: true,
                  type: 'change',
                  section: 'control_plan',
                };
              }
            } else {
              let key = process._id + '_' + spec._id + '_' + specParam.param;
              if (revisionData[key]) {
                delete revisionData[key];
              }
            }
          });
        });
        prevProcess.specs.map((prevSpec: any, index2: number) => {
          const spec = process.specs.find(
            (item: any) => item._id === prevSpec._id
          );
          if (!spec) {
            revisionData[index1 + '+' + index2 + '_deleted'] = {
              index1: index1,
              index2: index2,
              value: `${prevProcess.process_name}, ${prevSpec.char_product} Deleted`,
              action_taken: '',
              sugg_to: '',
              sugg_by: '',
              status: '',
              addToRevisionSummary: true,
              type: 'delete',
              section: 'control_plan',
            };
          }
        });
      }
      if (documentType === 'fmea') {
        process.fmeas.map((fmea: any, index2: number) => {
          if (!fmea._id) {
            revisionData[index1 + '_' + index2 + '_added'] = {
              index1: index1,
              index2: index2,
              value: `${process.process_name}, ${fmea.potential_mode} Created`,
              action_taken: '',
              sugg_to: '',
              sugg_by: '',
              status: '',
              addToRevisionSummary: true,
              type: 'create',
              section: 'fmea',
            };
            return;
          }
          const prevFailure = prevProcess.fmeas.find(
            (item: any) => item._id === fmea._id
          );
          if (!prevFailure) return;
          fmeaParams.map((failureParam) => {
            if (
              fmea &&
              prevFailure &&
              checkArrayStrCondition(
                fmea[failureParam.param],
                prevFailure[failureParam.param]
              )
            ) {
              let key = process._id + '_' + fmea._id + '_' + failureParam.param;
              if (revisionData[key]) {
                revisionData[key].value = `${process.process_name}, ${
                  fmea.potential_mode
                }, ${failureParam.placeholder} Changed 𝗙𝗥𝗢𝗠 ${
                  prevFailure[failureParam.param]
                    ? prevFailure[failureParam.param]
                    : '" "'
                } 𝗧𝗢 ${fmea[failureParam.param]}`;
              } else {
                revisionData[key] = {
                  index1: process._id,
                  index2: fmea._id,
                  field: failureParam.param,
                  value: `${process.process_name}, ${fmea.potential_mode}, ${
                    failureParam.placeholder
                  } Changed 𝗙𝗥𝗢𝗠 ${
                    prevFailure[failureParam.param]
                      ? prevFailure[failureParam.param]
                      : '" "'
                  } 𝗧𝗢 ${fmea[failureParam.param]}`,
                  action_taken: '',
                  sugg_to: '',
                  sugg_by: '',
                  status: '',
                  addToRevisionSummary: true,
                  type: 'change',
                  section: 'fmea',
                };
              }
            } else {
              let key = process._id + '_' + fmea._id + '_' + failureParam.param;
              if (revisionData[key]) {
                delete revisionData[key];
              }
            }
          });
        });
        prevProcess.fmeas.map((prevFailure: any, index2: number) => {
          const fmeas = process.fmeas.find(
            (item: any) => item._id === prevFailure._id
          );
          if (!fmeas) {
            revisionData[index1 + '+' + index2 + '_deleted'] = {
              index1: index1,
              index2: index2,
              value: `${prevProcess.process_name}, ${prevFailure.potential_mode} Deleted`,
              action_taken: '',
              sugg_to: '',
              sugg_by: '',
              status: '',
              addToRevisionSummary: true,
              type: 'delete',
              section: 'fmea',
            };
          }
        });
      }
    });

    partDataForRevision.processes.map((prevProcess: any, index1: number) => {
      const process = partData.processes.find(
        (item: any) => item._id === prevProcess._id
      );
      if (!process) {
        revisionData[index1 + '_deleted'] = {
          index1: index1,
          value: `Process ${prevProcess.process_name} Deleted`,
          action_taken: '',
          sugg_to: '',
          sugg_by: '',
          status: '',
          addToRevisionSummary: true,
          type: 'delete',
          section: 'process',
        };
      }
    });
    setRevisionData(revisionData);
  }, [partData]);

  function SetupUI(revisionData: any, section: string, setData: any) {
    const data = Object.keys(revisionData).map((key, index) => {
      if (revisionData[key].section !== section) return;
      return (
        <>
          <TableBody>
            <TableCell>{index + 1}</TableCell>
            <TableCell>
              <TextField
                multiline
                //readonly
                style={{ width: '300px' }}
                value={revisionData[key]?.value}
                InputProps={{ style: { fontSize: 14 } }}
              />
            </TableCell>
            <TableCell>
              <TextField
                multiline
                value={revisionData[key]?.action_taken}
                onChange={(e) =>
                  setRevisionData({
                    ...revisionData,
                    [key]: {
                      ...revisionData[key],
                      action_taken: e.target.value,
                    },
                  })
                }
                InputProps={{ style: { fontSize: 14 } }}
              />
            </TableCell>
            <TableCell>
              <TextField
                multiline
                value={revisionData[key]?.sugg_to}
                onChange={(e) =>
                  setRevisionData({
                    ...revisionData,
                    [key]: {
                      ...revisionData[key],
                      sugg_to: e.target.value,
                    },
                  })
                }
                InputProps={{ style: { fontSize: 14 } }}
              />
            </TableCell>
            <TableCell>
              <TextField
                multiline
                value={revisionData[key]?.sugg_by}
                onChange={(e) =>
                  setRevisionData({
                    ...revisionData,
                    [key]: {
                      ...revisionData[key],
                      sugg_by: e.target.value,
                    },
                  })
                }
                InputProps={{ style: { fontSize: 14 } }}
              />
            </TableCell>
            <TableCell>
              <TextField
                multiline
                value={revisionData[key]?.status}
                onChange={(e) =>
                  setRevisionData({
                    ...revisionData,
                    [key]: {
                      ...revisionData[key],
                      status: e.target.value,
                    },
                  })
                }
                InputProps={{ style: { fontSize: 14 } }}
              />
            </TableCell>
            <TableCell align="center">
              <Tooltip title="Add to revision summary" placement="left" arrow>
                <Checkbox
                  name="addToRevisionSummary"
                  defaultChecked={revisionData[key]?.addToRevisionSummary}
                  onChange={(e) =>
                    (revisionData[key].addToRevisionSummary = e.target.checked)
                  }
                  style={{ color: '#064a29' }}
                />
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              <Tooltip title="Revert Changes" placement="left" arrow>
                <Button
                  color="primary"
                  size="large"
                  style={{ color: '#c80202' }}
                  startIcon={<Delete />}
                  onClick={() => {
                    if (revisionData[key].type === 'change') {
                      if (revisionData[key].section === 'control_plan') {
                        partData.processes
                          .find(
                            (item: any) => item._id === revisionData[key].index1
                          )
                          .specs.find(
                            (item: any) => item._id === revisionData[key].index2
                          )[revisionData[key].field] =
                          partDataForRevision.processes
                            .find(
                              (item: any) =>
                                item._id === revisionData[key].index1
                            )
                            .specs.find(
                              (item: any) =>
                                item._id === revisionData[key].index2
                            )[revisionData[key].field];
                      }
                      if (revisionData[key].section === 'fmea') {
                        partData.processes
                          .find(
                            (item: any) => item._id === revisionData[key].index1
                          )
                          .fmeas.find(
                            (item: any) => item._id === revisionData[key].index2
                          )[revisionData[key].field] =
                          partDataForRevision.processes
                            .find(
                              (item: any) =>
                                item._id === revisionData[key].index1
                            )
                            .fmeas.find(
                              (item: any) =>
                                item._id === revisionData[key].index2
                            )[revisionData[key].field];
                      }
                    } else if (revisionData[key].type === 'create') {
                      if (!revisionData[key].index2) {
                        partData.processes.splice(revisionData[key].index1, 1);
                      } else {
                        if (revisionData[key].section === 'control_plan') {
                          partData.processes[
                            revisionData[key].index1
                          ].specs.splice(revisionData[key].index2, 1);
                        }
                        if (revisionData[key].section === 'fmea') {
                          partData.processes[
                            revisionData[key].index1
                          ].specs.splice(revisionData[key].index2, 1);
                        }
                      }
                    } else if (revisionData[key].type === 'delete') {
                      if (!revisionData[key].index2) {
                        partData.processes.splice(
                          revisionData[key].index1,
                          0,
                          partDataForRevision.processes[
                            revisionData[key].index1
                          ]
                        );
                      } else {
                        if (revisionData[key].section === 'control_plan') {
                          partData.processes[
                            revisionData[key].index1
                          ].specs.splice(
                            revisionData[key].index2,
                            0,
                            partDataForRevision.processes[
                              revisionData[key].index1
                            ].specs[revisionData[key].index2]
                          );
                        }
                        if (revisionData[key].section === 'fmea') {
                          partData.processes[
                            revisionData[key].index1
                          ].fmeas.splice(
                            revisionData[key].index2,
                            0,
                            partDataForRevision.processes[
                              revisionData[key].index1
                            ].specs[revisionData[key].index2]
                          );
                        }
                      }
                    }
                    delete revisionData[key];
                    setRevisionData(revisionData);
                    setRender((prev) => !prev);
                  }}
                ></Button>
              </Tooltip>
            </TableCell>
          </TableBody>
        </>
      );
    });
    setData(data);
  }

  useEffect(() => {
    SetupUI(revisionData, 'process', setProcessData);
  }, [revisionData, render]);
  useEffect(() => {
    SetupUI(revisionData, 'control_plan', setControlData);
  }, [revisionData, render]);
  useEffect(() => {
    SetupUI(revisionData, 'pfd', setPfdData);
  }, [revisionData, render]);
  useEffect(() => {
    SetupUI(revisionData, 'fmea', setFmeaData);
  }, [revisionData, render]);

  const typeOfSections = [
    { title: 'PROCESS CHANGES', data: processData },
    { title: 'PARAMETERS CHANGES', data: pfdData },
    { title: 'PARAMETERS CHANGES', data: controlData },
    { title: 'PARAMETERS CHANGES', data: fmeaData },
  ];

  return (
    <ModalCustom title={ecnTitle} openModal={openPopup} closeModal={closeModal}>
      {typeOfSections.map((item) => {
        if (item.data.every((value) => value === undefined)) return;
        return (
          <>
            <div
              style={{
                fontWeight: '600',
                padding: '5px',
                margin: 'auto',
                position: 'relative',
                justifyContent: 'center',
                textAlign: 'center',
                textTransform: 'uppercase',
                marginTop: '1rem',
              }}
            >
              {item.title}
            </div>
            <TableContainer
              component={Paper}
              style={{
                width: '97%',
                margin: '0rem auto',
                marginTop: '0.6rem',
              }}
            >
              <Table aria-label="collapsible table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">#</TableCell>
                    <TableCell align="center">CHANGES</TableCell>
                    <TableCell align="center">ACTION TAKEN</TableCell>
                    <TableCell align="center">SUGG TO</TableCell>
                    <TableCell align="center">SUGG BY</TableCell>
                    <TableCell align="center">STATUS</TableCell>
                    <TableCell align="center">
                      ADD TO REVISION SUMMARY
                    </TableCell>
                    <TableCell align="center">REVERT</TableCell>
                  </TableRow>
                </TableHead>
                {item.data}
              </Table>
            </TableContainer>
          </>
        );
      })}
      <div
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label="SAVE"
          type="button"
          onClick={() => submit(revisionData)}
        />
      </div>{' '}
    </ModalCustom>
  );
};

export default ECNModal;
