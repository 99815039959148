import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { procCncTiles } from './SubTiles';

const CNCSection = () => {
  return (
    <>
      <ChildHeader text="CNC Section" />
      <RenderTiles tilesObj={procCncTiles} height="100vh" justify="start" />
    </>
  );
};

export default CNCSection;
