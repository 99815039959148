import moment from 'moment';

export const processes = [
  Object.seal(
  {
    date: moment(),
    process_date:moment(),
    part_id: null,
    prod_qty: null,
    rej_qty: null,
    status:'',
    rework_qty:null,
    insp_sign:'',
    remark: null,
    reasons_for_rej: Array.from({ length: 10 }, () => ({
      reason: '',
      rej_qty: null,
    })),
  },
  )
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  annual_month: '',
  processes: Array.from({ length: 15 }, () => ({ ...processes[0] })),
};
