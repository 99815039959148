const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.NO.', style: style1 },
    { text: 'CHECK POINTS', style: style1 },
    { text: 'METHOD', style: style1 },
    { text: 'STANDARD', style: style1 },
    { text: 'OBSERVATIONS', style: style1 },
    { text: 'JUDGEMENT (OK/NG)', style: style1 },
    { text: 'REMARK', style: style1 },
  ],
];

export const headerConstant = {
  tableCompHeadTitle: 'Process Audit Check Sheet For',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getById: 'statusCheckListRouteProcessAuditPlan',
  post: 'statusCheckListRouteProcessAuditPlan',
  update: 'statusCheckListRouteProcessAuditPlan',
  delete: 'statusCheckListRouteProcessAuditPlan',
  masterCheckListAutoComplete:
    'masterCheckListRouteProcessAuditPlan/masterCheckListAutoComplete',
  getMasterCheckListById: 'masterCheckListRouteProcessAuditPlan',
};

export const navigationConstant = {};
