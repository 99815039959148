import { Box } from '@mui/system';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { Loader } from '../../../components/common/Loader';
import { Stack } from '@mui/material';
import TController from './components/TController';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { useEffect, useState } from 'react';
import {
  CustomButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { MdOutlineDeleteOutline } from 'react-icons/md';

const Create = () => {
  const { partId } = useParams();
  const [moduleData, setModuleData] = useState({
    part_id: partId,
    processes: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const isView = useQuery('isView') === 'true' ? true : false;
  const [DialogSave, confirmSave] = useConfirm(
    'SAVE',
    'Are you sure you want to Update this?',
  );
  const [DialogDeleteFmea, deleteFmeasRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this fmea?',
  );
  const [DialogAddFmea, addFmeasRow] = useConfirm(
    'ADD',
    'Are you sure you want to add this fmea?',
  );
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/api/fmeaManagementRoute/fmeaFormat/${partId}`)
      .then((res) => {
        if (res.data) {
          setModuleData(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  const onSubmit = async () => {
    const ans = await confirmSave();
    if (!ans) return;
    if (partId) {
      axios
        .post(`/api/fmeaManagementRoute/fmeaFormat/${partId}`, moduleData)
        .then((res) => {
          if (res.data) {
            alert('updated successfully');
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const importExcelData = (e: any) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    e.target.value = '';
    setIsLoading(true);
    axios
      .post(`/api/fmeaManagementRoute/importExcel/data/${partId}`, formData)
      .then((res) => {
        if (res.data) {
          setIsLoading(false);
          window.alert('successfully uploaded');
          setModuleData({
            ...moduleData,
            processes: res.data.newPart,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const addFmeas = async (processIndex: number, data: any) => {
    const ans = await addFmeasRow();
    if (!ans) return;
    const values: any = { ...moduleData };
    values.processes[processIndex].fmeas.push(data);
    setModuleData(values);
  };
  const removeFmeas = async (processIndex: number, fmeaIndex: number) => {
    const ans = await deleteFmeasRow();
    if (!ans) return;
    const values: any = { ...moduleData };
    values.processes[processIndex].fmeas.splice(fmeaIndex, 1);
    setModuleData(values);
  };
  const handleChangeThead = (e: any) => {
    const { name, value } = e.target;
    const values: any = { ...moduleData };
    values[name] = value;
    setModuleData(values);
  };
  const handleChangeTbody = (
    e: any,
    processIndex: number,
    fmeaIndex?: number,
  ) => {
    const { name, value } = e.target;
    if (fmeaIndex !== undefined) {
      const values: any = { ...moduleData };
      values.processes[processIndex].fmeas[fmeaIndex][name] = value;
      setModuleData(values);
    } else {
      const values: any = { ...moduleData };
      values.processes[processIndex][name] = value;
      setModuleData(values);
    }
  };

  return (
    <Box>
      <Box>
        <Loader loader={isLoading} />
        <ChildHeader text="FMEA FORMAT">
          {!isView && (
            <>
              {partId && (
                <Stack
                  direction="row"
                  sx={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <CustomUploadButton
                    lable={'IMPORT DATA'}
                    accept=".xls,.xlsx"
                    onChange={(e) => {
                      importExcelData(e);
                    }}
                  />
                  <CustomButton
                    sx={{
                      backgroundColor: '#1c7ed6',
                    }}
                    onClick={async () => {
                      const values = { ...moduleData };
                      values.processes = [];
                      setModuleData(values);
                    }}
                    icon={<MdOutlineDeleteOutline />}
                  >
                    RESET
                  </CustomButton>
                  <SubmitButton label={'Save'} onClick={() => onSubmit()} />
                </Stack>
              )}
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          handleChangeThead,
          handleChangeTbody,
          moduleData,
          isView,
          removeFmeas,
          addFmeas,
        }}
      />
      <DialogSave isSubmitContent={false} />
      <DialogDeleteFmea isSubmitContent={false} />
      <DialogAddFmea isSubmitContent={false} />
    </Box>
  );
};

export default Create;
