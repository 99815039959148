import { useCallback, useEffect, useState } from 'react';
import { TableInput } from '../../../../components/common';
import TBodySpecs from './TBodySpecs';
import TableTextArea from '../../../../components/common/tableinputs/TableTextArea';
import {
  DeleteIconButton,
  UploadImageIconButton,
  ViewIconButton,
} from '../../../../components/common/button/CustomIconButton';
import { Stack } from '@mui/material';

interface IProps {
  moduleData: any;
  process: any;
  setRenderParent: any;
  onProcessChange: any;
  uploadImageFile: any;
  tabValue: number;
  openSpecToleranceModal: any;
  isView: boolean;
}

const TBodyProcess = ({
  moduleData,
  tabValue,
  isView,
  process,
  setRenderParent,
  onProcessChange,
  uploadImageFile,
  openSpecToleranceModal,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<any>(null);

  useEffect(() => {
    setSelectedRowIndex(null);
  }, [tabValue]);

  const handleRowClick = useCallback((specIdx: number) => {
    setSelectedRowIndex(specIdx);
  }, []);

  const onProcessNoChange = (e: any) => {
    const updatedProcess = { ...process, [e.target.name]: e.target.value };
    onProcessChange(updatedProcess);
  };

  const handleDeleteSpec = (index: number) => {
    if (window.confirm(`Are you sure you want to delete this spec???`)) {
      process?.specs?.splice(index, 1);
      setRender((prev) => !prev);
    }
  };
  return (
    <>
      {process?.specs?.length > 0 &&
        process?.specs.map((item: any, specIdx: number) => (
          <tr
            key={`${specIdx}-process`}
            onClick={() => handleRowClick(specIdx)}
            style={{
              backgroundColor:
                selectedRowIndex === specIdx ? '#e9ecef' : 'transparent',
            }}
          >
            {specIdx === 0 && (
              <td rowSpan={process?.specs?.length}>
                <TableInput
                  rowSpan={process?.specs?.length}
                  isView={isView}
                  name="process_flow_no"
                  type="text"
                  value={process?.process_flow_no}
                  onChange={onProcessNoChange}
                />
              </td>
            )}

            {specIdx === 0 && (
              <td rowSpan={process?.specs?.length}>
                <Stack
                  direction="row"
                  sx={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <UploadImageIconButton
                    id={`${specIdx}-process-upload`}
                    tooltipTitle="upload"
                    sx={{}}
                    onChange={(e) => {
                      uploadImageFile(e, tabValue);
                    }}
                  />
                  {process.files?.length > 0 ? (
                    <>
                      <DeleteIconButton
                        tooltipTitle={'delete'}
                        onClick={() => {
                          if (
                            window.confirm(
                              'Are you sure u want to delete this image!!!'
                            )
                          ) {
                            setRender((prev) => !prev);
                            process.files = [];
                          }
                        }}
                      />
                    </>
                  ) : (
                    <DeleteIconButton
                      sx={{
                        opacity: '0',
                        PointerEvent: 'none',
                        visibility: 'hidden',
                      }}
                      tooltipTitle=""
                    />
                  )}
                </Stack>
              </td>
            )}

            {specIdx === 0 && (
              <td rowSpan={process?.specs?.length}>
                <TableTextArea
                  style={{ minWidth: '130px' }}
                  rowSpan={process?.specs?.length}
                  isView={isView}
                  name="process_no"
                  type="text"
                  value={process?.process_no}
                  onChange={onProcessNoChange}
                />
              </td>
            )}
            {specIdx === 0 && (
              <td rowSpan={process?.specs?.length}>
                <TableTextArea
                  style={{ minWidth: '150px' }}
                  rowSpan={process?.specs?.length}
                  isView={isView}
                  name="process_name"
                  type="text"
                  value={process?.process_name}
                  onChange={onProcessNoChange}
                />
              </td>
            )}
            <TBodySpecs
              item={item}
              specIdx={specIdx}
              {...{
                isView,
                tabValue,
                moduleData,
                handleDeleteSpec,
                openSpecToleranceModal,
              }}
            />
          </tr>
        ))}
    </>
  );
};

export default TBodyProcess;
