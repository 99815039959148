import { useState } from 'react';
import { Checkbox, Grid } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';

import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
  isView: boolean;
  handleSumbit: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const TBody = ({
  moduleData,
  isView,
  handleSumbit,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <td style={{ minWidth: '300px' }}>
                <TableInput
                  isView={isView}
                  name="breakdown_details"
                  type="text"
                  value={process.breakdown_details}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '300px' }}>
                <TableInput
                  isView={isView}
                  name="action_taken"
                  type="text"
                  value={process.action_taken}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ width: '200px', minWidth: '280px' }}>
                <TableInput
                  isView={isView}
                  name="spare_part"
                  type="text"
                  value={process.spare_part}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '120px' }} colSpan={isView ? 2 : 1}>
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
        <tr>
          <th align="left">Result</th>
          {['Satisfactory', 'Not Satisfactory', 'Under Observation'].map(
            (option, index) => (
              <th
                key={option + index}
                colSpan={index === 2 ? 2 : 1}
                align="left"
              >
                {option}
                {/* {isView && (moduleData.type_of_breakdown === option ? '⬤' : '◯')} */}

                <Checkbox
                  checked={moduleData.result === option}
                  onChange={(e) => {
                    moduleData.result = option;

                    setRender((prev) => !prev);
                  }}
                  disabled={isView}
                />
              </th>
            ),
          )}
        </tr>
        <tr>
          <th align="left">
            Sign of Sup.(Prod):
            <TableInput
              isView={isView}
              name="sign_sup_prod"
              type="text"
              value={moduleData.sign_sup_prod}
              onChange={(e) => {
                moduleData.sign_sup_prod = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
          <td align="left" colSpan={2}>
            Sign of Sup(QA):
            <TableInput
              isView={isView}
              name="sign_sup_qa"
              type="text"
              value={moduleData.sign_sup_qa}
              onChange={(e) => {
                moduleData.sign_sup_qa = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td align="left" colSpan={2}>
            Sign of Sup (Maintenance):
            <TableInput
              isView={isView}
              name="sign_sup_maintenance"
              type="text"
              value={moduleData.sign_sup_maintenance}
              onChange={(e) => {
                moduleData.sign_sup_maintenance = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
        </tr>
      </tbody>
    </>
  );
};

export default TBody;
