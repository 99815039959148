import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { convToTime } from '../../../../utils/helpers';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../components/common/SelectField';
import useConfirm from '../../../../components/common/useConfirm';
import CustomMobileTimePickerMui from '../../../../components/common/CustomMobileTimePickerMui';

interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
  machineData?: any;
  // autoCompleteMachineValue?: any;
  // setAutoCompleteMachineValue?: any;
  cavity: any;
  setCavity: any;
}

const THead = ({
  tabValue,
  moduleData,
  isView,
  machineData,
  // autoCompleteMachineValue,
  // setAutoCompleteMachineValue,
  cavity,
  setCavity,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [DialogCavity, confirmCavity] = useConfirm(
    'Alert',
    'Are you sure you want to change the cavity?'
  );
  console.log(moduleData);

  return (
    <thead>
      <TableCompHead
        colSpan={15}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-QA-10B', '01', '22.03.2023']}
        heading="line inspection report"
      />
      <tr>
        <th colSpan={7} align="left">
          Customer:
          {moduleData.customer_name
            ? moduleData.customer_name
            : moduleData.customer_id?.customer_name}
        </th>
        <th colSpan={3} align="left">
          Number of Cavity:
        </th>
        <th
          colSpan={5}
          align="left"
          style={{
            backgroundColor: `${isView && '#d0ebff'}`,
          }}
        >
          {isView ? (
            moduleData.cavity
          ) : (
            <CustomSelect
              label=""
              name="part_type"
              value={cavity}
              onChange={async (e: any) => {
                const ans = await confirmCavity();
                if (!ans) return;
                moduleData.cavity = +e.target.value;
                setCavity(+e.target.value);
              }}
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
              <MenuItem value="10">10</MenuItem>
            </CustomSelect>
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Part Name:
          {moduleData.part_name
            ? moduleData.part_name
            : moduleData.part_id?.part_name}
        </th>
        <th colSpan={5} align="left">
          Part Number:
          {moduleData.part_number
            ? moduleData.part_number
            : moduleData.part_id?.part_number}
        </th>
        <th colSpan={5}></th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Date:{' '}
        </th>
        <th colSpan={2}>
          {isView ? (
            formatDate(moduleData.pir_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.pir_date)}
              onChange={(date) => {
                moduleData.pir_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th align="left">Shift:</th>
        <th colSpan={3}>
          <TableInput
            name="shift"
            key={tabValue + 'shift'}
            type="text"
            value={moduleData?.pirs[tabValue]?.shift}
            onChange={(e) => {
              moduleData.pirs[tabValue].shift = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left">M/C No:</th>
        <th colSpan={3}>
          {isView ? (
            moduleData?.pirs[tabValue]?.machine_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              key={tabValue + 'machine'}
              option_name="machine_no"
              arrayofObj={machineData}
              value={
                moduleData?.pirs[tabValue]?.machine_id_autocomplete ||
                moduleData?.pirs[tabValue]?.machine_id ||
                ''
              }
              onChange={(e, value) => {
                moduleData.pirs[tabValue].machine_id = value?._id;
                // setAutoCompleteMachineValue(value);
                moduleData.pirs[tabValue].machine_id_autocomplete = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th align="left">Heat No:</th>
        <th colSpan={2}>
          <TableInput
            name="heat_no"
            key={tabValue + 'heat_no'}
            type="text"
            value={moduleData?.pirs[tabValue]?.heat_no}
            onChange={(e) => {
              moduleData.pirs[tabValue].heat_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {['S.NO', 'PARAMETER', 'SPECIFICATION', 'MODE OF INSPECTION'].map(
          (head) => (
            <th rowSpan={2} style={{ textTransform: 'uppercase' }}>
              {head}
            </th>
          )
        )}
        <th colSpan={10}>OBSERVATIONS</th>
        <th rowSpan={2}>Remarks</th>
      </tr>
      <tr>
        {moduleData.pirs.length > 0 &&
          Array.from({ length: 5 }, () => 0).map((_, index) => (
            <>
              <th style={{ width: '120px' }}>
                {isView ? (
                  convToTime(moduleData.pirs[tabValue].times[index])
                ) : (
                  <TimePicker
                    value={moment(moduleData.pirs[tabValue].times[index])}
                    onChange={(date) => {
                      moduleData.pirs[tabValue].times[index] = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </th>
              <th>JUDGEMENT</th>
            </>
          ))}
      </tr>
      <DialogCavity isSubmitContent={false} />
    </thead>
  );
};

export default THead;
