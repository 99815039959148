import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
// import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  moduleData: any;
  isView: boolean;
  customer: any;
}

const TController = ({ moduleData, isView, customer }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, isView, customer }} />
        <TBody {...{ moduleData, isView }} />
        {/* <TFoot
          preparedBy={moduleData.report_prepared_by}
          approvedBy={moduleData.report_approved_by}
        /> */}
      </RenderTable>
    </Box>
  );
};

export default TController;
