import { useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

interface IProps {
  tniTopic: any;
  isView: boolean;
  item: any;
}
const TBodyRight = ({ item, tniTopic, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <>
      {tniTopic.map((topic: any) => (
        <td
          style={{
            padding: '0px',
            margin: '0px',
          }}
        >
          {isView ? (
            item[topic._id]
          ) : (
            <CustomSelect
              name={topic._id}
              value={item[topic._id] || ''}
              label={''}
              onChange={(e: any) => {
                item[topic._id] = e.target.value;
                setRender((prev) => !prev);
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="✓">✓</MenuItem>
              <MenuItem value="✗">✗</MenuItem>
            </CustomSelect>
          )}
        </td>
      ))}
    </>
  );
};

export default TBodyRight;
