import React, { useEffect, useState } from 'react';
import TController from './TController';
import { Box } from '@mui/material';
import { PersitedDatePicker } from '../../../../../../components/common';
import { useQuery } from '../../../../../../hooks/UseQuery';
import axios from 'axios';
import AutocompleteMuiCustom from '../../../../../../components/common/AutocompleteMuiCustom';
import BarChart from './BarChart';
interface IProps {
  apiData: any;
  year: number;
}
const options = {
  plugins: {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: '',
    },
  },
  responsive: true,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
      },
    },
  },
};
const backgroundColors = ['#fd7e14'];
const Summary = ({ apiData, year }: IProps) => {
  const { dashBoardMonthCounts } = apiData;
  const monthNames = [
    `Apr - ${year % 100}`,
    `May - ${year % 100}`,
    `Jun - ${year % 100}`,
    `Jul - ${year % 100}`,
    `Aug - ${year % 100}`,
    `Sep - ${year % 100}`,
    `Oct - ${year % 100}`,
    `Nov - ${year % 100}`,
    `Dec - ${year % 100}`,
    `Jan - ${year % 100}`,
    `Feb - ${year % 100}`,
    `Mar - ${year % 100}`,
  ];
  const datasets = [
    {
      label: '',
      data: dashBoardMonthCounts.map((item: any) => item.count),
      backgroundColor: backgroundColors,
      borderWidth: 1,
      barThickness: 50,
    },
  ];
  return (
    <div>
      <TController apiData={apiData} year={year} />
      <Box style={{ paddingBottom: '3rem', width: '80vw', margin: '150px' }}>
        <BarChart
          datasets={datasets}
          monthNames={monthNames}
          options={options}
        />
      </Box>
    </div>
  );
};

export default Summary;
