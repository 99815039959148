import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button, MenuItem } from '@mui/material';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import InputMuiCustom from '../../../../components/common/inputs/InputMuiCustom';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import { FlexBox } from '../../../../components/common';
import CustomSelect from '../../../../components/common/SelectField';
import { handleCpHeaderInputs } from '../../helpers/handleChange';
import { IModuleData } from '../../helpers/Interface';
import SelectGridMui from '../../../../components/common/selectscustom/SelectGridMui';
export interface IProps {
  moduleData: any;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  setModuleData: React.Dispatch<React.SetStateAction<any>>;
  id: string | undefined;
  currentLevelIndex: number;
  setCurrentLevelIndex: any;
}

export default function HeaderInputs({
  moduleData,
  setRender,
  setModuleData,
  id,
  currentLevelIndex,
  setCurrentLevelIndex,
}: IProps) {
  return (
    <Box sx={{ flexGrow: 1, width: '98%', margin: '2rem auto' }}>
      <SelectGridMui
        name="level"
        value={
          (moduleData && moduleData?.levels[currentLevelIndex]?.levels_type) ||
          ''
        }
        lable={'Select Level'}
        onChange={(e: any) => {
          setCurrentLevelIndex(+e.target.value.split('_')[1] - 1);
        }}
      >
        <MenuItem value="Level_1">Level 1</MenuItem>
        <MenuItem value="Level_2">Level 2</MenuItem>
        <MenuItem value="Level_3">Level 3</MenuItem>
        <MenuItem value="Level_4">Level 4</MenuItem>
      </SelectGridMui>
    </Box>
  );
}
