import React, { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import CustomSelect from '../../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

interface IProps {
  moduleData: any;
  isView: boolean;
  cc_type: string;
}
const calcMean = (val1: number, val2: number, val3: number) => {
  return ((+val1 || 0) + (+val2 || 0) + +(val3 || 0)) / 3;
};

const TBody = ({ moduleData, isView, cc_type }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {cc_type &&
        moduleData &&
        moduleData[cc_type] &&
        moduleData[cc_type]?.processes_1?.map((process: any, index: number) => (
          <tr key={index + 'body processes1'}>
            <td>{process?.elements}</td>
            <td>{process?.std_value}</td>
            <td>
              <TableInput
                name="burn_1"
                type="number"
                value={process.burn_1}
                onChange={(e) => {
                  process.burn_1 = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </td>
            <td>
              <TableInput
                name="burn_2"
                type="number"
                value={process.burn_2}
                onChange={(e) => {
                  process.burn_2 = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </td>
            <td>
              <TableInput
                name="burn_3"
                type="number"
                value={process.burn_3}
                onChange={(e) => {
                  process.burn_3 = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </td>
            <td>{calcMean(process.burn_1, process.burn_2, process.burn_3)}</td>
            <td>
              <CustomSelect
                name="remarks"
                value={process.remarks || ''}
                onChange={(e) => {
                  process.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
                sx={{
                  textAlign: 'center',
                }}
              >
                <MenuItem value="">NONE</MenuItem>
                <MenuItem value="ok">OK</MenuItem>
                <MenuItem value="ng">NOT OK</MenuItem>
              </CustomSelect>
            </td>
          </tr>
        ))}
      {cc_type &&
        moduleData &&
        moduleData[cc_type] &&
        moduleData[cc_type]?.processes_2?.map((process: any, index: number) => (
          <tr key={index + 'body processes1'}>
            <td colSpan={2}>{process?.elements}</td>

            <td>
              <CustomSelect
                name="burn_1"
                value={process.burn_1 || ''}
                onChange={(e) => {
                  process.burn_1 = e.target.value;
                  setRender((prev) => !prev);
                }}
                sx={{
                  textAlign: 'center',
                }}
              >
                <MenuItem value="">NONE</MenuItem>
                <MenuItem value="ok">OK</MenuItem>
                <MenuItem value="ng">NOT OK</MenuItem>
              </CustomSelect>
            </td>
            <td>
              <CustomSelect
                name="burn_2"
                value={process.burn_2 || ''}
                onChange={(e) => {
                  process.burn_2 = e.target.value;
                  setRender((prev) => !prev);
                }}
                sx={{
                  textAlign: 'center',
                }}
              >
                <MenuItem value="">NONE</MenuItem>
                <MenuItem value="ok">OK</MenuItem>
                <MenuItem value="ng">NOT OK</MenuItem>
              </CustomSelect>
            </td>
            <td>
              <CustomSelect
                name="burn_3"
                value={process.burn_3 || ''}
                onChange={(e) => {
                  process.burn_3 = e.target.value;
                  setRender((prev) => !prev);
                }}
                sx={{
                  textAlign: 'center',
                }}
              >
                <MenuItem value="">NONE</MenuItem>
                <MenuItem value="ok">OK</MenuItem>
                <MenuItem value="ng">NOT OK</MenuItem>
              </CustomSelect>
            </td>
            <td>
              <CustomSelect
                name="mean"
                value={process.mean || ''}
                onChange={(e) => {
                  process.mean = e.target.value;
                  setRender((prev) => !prev);
                }}
                sx={{
                  textAlign: 'center',
                }}
              >
                <MenuItem value="">NONE</MenuItem>
                <MenuItem value="ok">OK</MenuItem>
                <MenuItem value="ng">NOT OK</MenuItem>
              </CustomSelect>
            </td>
            <td>
              <CustomSelect
                name="remarks"
                value={process.remarks || ''}
                onChange={(e) => {
                  process.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
                sx={{
                  textAlign: 'center',
                }}
              >
                <MenuItem value="">NONE</MenuItem>
                <MenuItem value="ok">OK</MenuItem>
                <MenuItem value="ng">NOT OK</MenuItem>
              </CustomSelect>
            </td>
          </tr>
        ))}
      <tr>
        <th colSpan={6} rowSpan={2} style={{ textAlign: 'left' }}>
          Comments:-
          <TableInput
            name="comments"
            type="text"
            value={moduleData[cc_type].comments}
            onChange={(e) => {
              moduleData[cc_type].comments = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <td align="center">Status</td>
      </tr>
      <tr>
        <CustomSelect
          name="status"
          value={moduleData[cc_type].status || ''}
          onChange={(e) => {
            moduleData[cc_type].status = e.target.value;
            setRender((prev) => !prev);
          }}
          sx={{
            textAlign: 'center',
          }}
        >
          <MenuItem value="">NONE</MenuItem>
          <MenuItem value="ok">OK</MenuItem>
          <MenuItem value="ng">NOT OK</MenuItem>
        </CustomSelect>
      </tr>
    </tbody>
  );
};

export default TBody;
