import { Box } from '@mui/system';
import axios from 'axios';
import { useState } from 'react';
import { useQuery } from '../../../hooks/UseQuery';
import TController from './create/TController';
import useGetData from './helpers/useGetData';
import { Loader } from '../../../components/common/Loader';
import HeaderCreate from './HeaderCreate';
import { moduleConstant } from './constant';
import useConfirm from '../../../components/common/useConfirm';
import ModalCustom from '../../../components/common/Modal';
import PickPart from '../../../components/ui/PickPart';

interface MyData {
  moduleData: any;
  isLoading: boolean;
  setIsLoading: any;
  setModuleData: any;
  setRenderforUpdate: any;
  setParentRender: React.Dispatch<React.SetStateAction<boolean>>;
  error?: string | null;
  machineData: any;
}
const Create = () => {
  const date = useQuery('date');
  // const isView = useQuery('isView') === 'true' ? true : false;
  const [isView, setisView] = useState<boolean>(false);
  const [DialogUpdate, confirmUpdate] = useConfirm(
    'Save',
    'Are you sure you want to save?',
  );
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this row?',
  );

  const {
    error,
    moduleData,
    setModuleData,
    isLoading,
    setIsLoading,
    setParentRender,
    setRenderforUpdate,
    machineData,
  }: MyData = useGetData();

  const handleUpdate = async () => {
    return new Promise(async (resolve, reject) => {
      const ans = await confirmUpdate();
      if (!ans) return;

      axios
        .post(`${moduleConstant.apiConstant}`, moduleData)
        .then((res) => {
          setRenderforUpdate((prev: any) => !prev);
          alert('successfully updated');
          resolve(1);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  };

  const handleDelete = async (id: string, rowIndex: number) => {
    const ans = await confirmDelete();
    if (!ans) return;
    if (!id) {
      const values = [...moduleData];
      values.splice(rowIndex, 1);
      setModuleData(values);
      alert('row deleted successfully');
      return;
    }
    await axios
      .delete(`${moduleConstant.apiConstant}/${id}`)
      .then((res) => {
        if (res.data) {
          const values = [...moduleData];
          values.splice(rowIndex, 1);
          setModuleData(values);
          alert('row deleted successfully');
        }
      })
      .catch((err) => console.log(err));
  };

  const uploadImageFile = async (e: any, id: String) => {
    await handleUpdate();
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    formData.append('file', files[0]);
    setIsLoading(true);
    axios
      .patch(
        `${moduleConstant.apiConstant}/uploadFile/${id}
      `,
        formData,
      )
      .then((res) => {
        if (res.data) {
          setIsLoading(false);
          alert('document upload successly');
          setRenderforUpdate((prev: any) => !prev);
          e.target.fileInput.value = '';
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <Box>
      <Loader loader={isLoading} />
      <HeaderCreate
        {...{
          handleUpdate,
          moduleData,
          setModuleData,
        }}
      />
      <TController
        {...{
          moduleData,
          uploadImageFile,
          isView,
          handleDelete,
          setModuleData,
          setParentRender,
          machineData,
        }}
      />
      <DialogUpdate isSubmitContent={false} />
      <DialogDelete isSubmitContent={false} />
    </Box>
  );
};

export default Create;
