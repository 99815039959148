import moment from 'moment';
import { useEffect, useState } from 'react';

import { TableCompHead } from '../../../../../components/ui';
import formatDate from '../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import { TableInput } from '../../../../../components/common';
import { useQuery } from '../../../../../hooks/UseQuery';
import axios from 'axios';

interface IProps {
  moduleData: any;
  isView: boolean;
  customer: any;
}

const heading = [
  'S. No.',
  'Check Point',
  'Judgement Criteria',
  'Method',
  'Picture',
  'Observation',
  'Action Taken',
  'Observation',
  'Action Taken',
];
const heading2 = [
  'Before',
  'After',
  'Repair',
  'Replace',
  'Remarks',
  'Before',
  'After',
  'Repair',
  'Replace',
  'Remarks',
];
const THead = ({ moduleData, isView, customer }: IProps) => {
  const [render, setRender] = useState(false);
  const machin_no = useQuery('machin_no');

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const res = await axios
      .get(`/api/machineRoutes?search=${machin_no}`)
      .catch((err) => {
        console.log(err);
      });
    moduleData.s1 = res?.data?.results[0]?.department_name;
  };

  return (
    <thead>
      <TableCompHead
        colSpan={15}
        rowSpan={3}
        labels={['Doc. No.: ', 'Rev. No.:', 'Eff. Date:']}
        values={['FRM-MNT-06', '00', '01-04-2023']}
        heading="Machine PM Check Sheet"
      />
      <tr>
        <th colSpan={3} align="left">
          Machine Description: : {customer}
        </th>
        <th colSpan={2} align="left">
          Location :{' '}
          <TableInput
            isView={isView}
            name="s1"
            type="text"
            value={moduleData.s1}
            onChange={(e) => {
              moduleData.s1 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={5} align="left">
          PM Date :{' '}
          {isView ? (
            formatDate(moduleData.d1)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d1)}
              onChange={(date) => {
                moduleData.d1 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={5} align="left">
          PM Date :{' '}
          {isView ? (
            formatDate(moduleData.d2)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d2)}
              onChange={(date) => {
                moduleData.d2 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>

      <tr>
        <th colSpan={5} align="left">
          Machine No. :
          <TableInput
            isView={true}
            name="s2"
            type="text"
            value={machin_no || ''}
          />
        </th>
        <th colSpan={5}>Cycle 1</th>
        <th colSpan={5}>Cycle 2</th>
      </tr>

      <tr>
        {heading.map((header) => (
          <th
            key={header}
            colSpan={
              (header === 'Observation' && 2) ||
              (header === 'Action Taken' && 3) ||
              1
            }
            rowSpan={
              header === 'Observation' || header === 'Action Taken' ? 1 : 2
            }
          >
            {header}
          </th>
        ))}
      </tr>
      <tr>
        {heading2.map((header) => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
