interface IJson {
  [key: string]: {};
}
export const checmicalCompositionData: IJson = {
  adc_12: {
    supplier_name: null,
    invoice_qty: null,
    invoice_no: null,
    heat_no: null,
    sample: null,
    reject_qty: null,
    invoice_date: null,
    testing_date: null,
    testing_time: null,
    accept_qty: null,
    comments: null,
    status: null,

    processes_1: [
      {
        elements: 'Si',
        std_value: '9.60-12.0',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Fe',
        std_value: '1.30Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Cu',
        std_value: '1.50-3.50',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Mg',
        std_value: '0.30 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Mn',
        std_value: '1.0 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ti',
        std_value: '0.30 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Zn',
        std_value: '1.0 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ni',
        std_value: '0.50 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Pb',
        std_value: '0.20 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Sn',
        std_value: '0.20 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Cr',
        std_value: '------',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Be',
        std_value: '------',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Sr',
        std_value: '------',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Zr',
        std_value: '------',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ca',
        std_value: '------',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Co',
        std_value: '------',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'V',
        std_value: '------',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Al',
        std_value: 'Remainder',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'SludgeFactor:-(Fe+2*Mn+3*C)',
        std_value: '1.80 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
    ],
    processes_2: [
      {
        elements: 'Heat No should be Mention',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ingot free from Rust,Dust,Wet',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ingot colour should be bright silver',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
    ],
  },
  adc_10: {
    supplier_name: null,
    invoice_qty: null,
    invoice_no: null,
    heat_no: null,
    sample: null,
    reject_qty: null,
    invoice_date: null,
    testing_date: null,
    testing_time: null,
    accept_qty: null,
    comments: null,
    status: null,
    processes_1: [
      {
        elements: 'Cu (Copper)',
        std_value: '2.0-3.0',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Si (Silicon)',
        std_value: '7.50-9.50',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Mg (Magnesium)',
        std_value: '0.30 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Zn (Zinc)',
        std_value: '1.0 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Fe (Iron)',
        std_value: '0.90 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Mn (Manganese)',
        std_value: '0.50 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ni (Nickel)',
        std_value: '0.50 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Sn (Tin)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Al (Aluminium)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Pb (Lead)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Cd (Cadmium)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ti (Titanium)',
        std_value: '0.50 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ti (Titanium + Vanadium)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Manganese, Zirconium and Chromium',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'C (Carbon)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'S (Sulfer)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'P (Phosphorus)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
    ],
    processes_2: [
      {
        elements: 'Heat No should be Mention',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ingot free from Rust,Dust,Wet',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ingot colour should be bright silver',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
    ],
  },

  lm_6: {
    supplier_name: null,
    invoice_qty: null,
    invoice_no: null,
    heat_no: null,
    sample: null,
    reject_qty: null,
    invoice_date: null,
    testing_date: null,
    testing_time: null,
    accept_qty: null,
    comments: null,
    status: null,
    processes_1: [
      {
        elements: 'Cu (Copper)',
        std_value: '2.70-3.70',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Si (Silicon)',
        std_value: '8-10',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Mg (Magnesium)',
        std_value: '0.10-0.30',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Zn (Zinc)',
        std_value: '1.2',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Fe (Iron)',
        std_value: '1',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Mn (Manganese)',
        std_value: '0.6',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ni (Nickel)',
        std_value: '0.5',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Sn (Tin)',
        std_value: '0.20 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Al (Aluminium)',
        std_value: 'Remainder',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Pb (Lead)',
        std_value: '0.2',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Cd (Cadmium)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ti (Titanium)',
        std_value: '0.2',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ti (Titanium + Vanadium)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Manganese, Zirconium and Chromium',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'C (Carbon)',
        std_value: '0.2',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'S (Sulfer)',
        std_value: '0.2',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'P (Phosphorus)',
        std_value: '0.2',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
    ],
    processes_2: [
      {
        elements: 'Heat No should be Mention',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ingot free from Rust,Dust,Wet',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ingot colour should be bright silver',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
    ],
  },
  zinck: {
    supplier_name: null,
    invoice_qty: null,
    invoice_no: null,
    heat_no: null,
    sample: null,
    reject_qty: null,
    invoice_date: null,
    testing_date: null,
    testing_time: null,
    accept_qty: null,
    comments: null,
    status: null,
    processes_1: [
      {
        elements: 'Cu (Copper)',
        std_value: '0.75 to 1.25',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Si (Silicon)',
        std_value: '----------',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Mg (Magnesium)',
        std_value: '0.03 to 0.08',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Zn (Zinc)',
        std_value: 'Remainder',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Fe (Iron)',
        std_value: '0.10 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Mn (Manganese)',
        std_value: '----------',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ni (Nickel)',
        std_value: '----------',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Sn (Tin)',
        std_value: '0.003 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Al (Aluminium)',
        std_value: '3.50 to 4.30',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Pb (Lead)',
        std_value: '0.005 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Cd (Cadmium)',
        std_value: '0.004 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ti (Titanium)',
        std_value: '----------',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'C (Carbon)',
        std_value: '----------',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'S (Sulfer)',
        std_value: '----------',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'P (Phosphorus)',
        std_value: '----------',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
    ],
    processes_2: [
      {
        elements: 'Heat No should be Mention',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ingot free from Rust,Dust,Wet',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ingot colour should be bright silver',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
    ],
  },
  ec_grade: {
    supplier_name: null,
    invoice_qty: null,
    invoice_no: null,
    heat_no: null,
    sample: null,
    reject_qty: null,
    invoice_date: null,
    testing_date: null,
    testing_time: null,
    accept_qty: null,
    comments: null,
    status: null,
    processes_1: [
      {
        elements: 'Cu (Copper)',
        std_value: '0.04 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Si (Silicon)',
        std_value: '0.10 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Mg (Magnesium)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Zn (Zinc)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Fe (Iron)',
        std_value: '0.20 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Mn (Manganese)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ni (Nickel)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Sn (Tin)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Al (Aluminium)',
        std_value: '99.7 MIN',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Pb (Lead)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Cd (Cadmium)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ti (Titanium)',
        std_value: '0.02 Max',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Manganese, Zirconium and Chromium',
        std_value: '0.01 Max (Each)',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'C (Carbon)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'S (Sulfer)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'P (Phosphorus)',
        std_value: null,
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
    ],
    processes_2: [
      {
        elements: 'Heat No should be Mention',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ingot free from Rust,Dust,Wet',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
      {
        elements: 'Ingot colour should be bright silver',
        burn_1: null,
        burn_2: null,
        burn_3: null,
        mean: null,
        remarks: null,
      },
    ],
  },
  chrono_470: {
    processes_1: [
      { s_no: 1, content_in_per: 'C (Carbon)', crngo: '≥0.005 ' },
      { s_no: 2, content_in_per: 'Mn (Magnese)', crngo: '≥0.50' },
      { s_no: 3, content_in_per: 'S(Sulfer)', crngo: '≥0.01' },
      { s_no: 4, content_in_per: 'P (Phosphorus)', crngo: '≥0.03' },
      { s_no: 5, content_in_per: 'Si (Silicon)', crngo: '1.60-2.40' },
      { s_no: 6, content_in_per: 'Al (Aluminum)', crngo: '' },
      { s_no: 7, content_in_per: 'Hardness', crngo: '' },
    ],
    processes_2: [
      {
        elements: 'Heat No should be Mention',
        remarks: null,
      },
      {
        elements: 'Ingot free from Rust,Dust,Wet',
        remarks: null,
      },
      {
        elements: 'Ingot colour should be bright silver',
        remarks: null,
      },
    ],
  },
  chrono_600: {
    processes_1: [
      { s_no: 1, content_in_per: 'C (Carbon)', crngo: '≥0.005 ' },
      { s_no: 2, content_in_per: 'Mn (Magnese)', crngo: '≥0.50' },
      { s_no: 3, content_in_per: 'S(Sulfer)', crngo: '≥0.01' },
      { s_no: 4, content_in_per: 'P (Phosphorus)', crngo: '≥0.05' },
      { s_no: 5, content_in_per: 'Si (Silicon)', crngo: '0.90-1.50' },
      { s_no: 6, content_in_per: 'Al (Aluminum)', crngo: '' },
      { s_no: 7, content_in_per: 'Hardness', crngo: '' },
    ],
    processes_2: [
      {
        elements: 'Heat No should be Mention',
        remarks: null,
      },
      {
        elements: 'Ingot free from Rust,Dust,Wet',
        remarks: null,
      },
      {
        elements: 'Ingot colour should be bright silver',
        remarks: null,
      },
    ],
  },
  chrono_1000: {
    processes_1: [
      { s_no: 1, content_in_per: 'C (Carbon)', crngo: '≥0.005 ' },
      { s_no: 2, content_in_per: 'Mn (Magnese)', crngo: '≥0.50' },
      { s_no: 3, content_in_per: 'S(Sulfer)', crngo: '≥0.02' },
      { s_no: 4, content_in_per: 'P (Phosphorus)', crngo: '≥0.10' },
      { s_no: 5, content_in_per: 'Si (Silicon)', crngo: '0.20-0.80' },
      { s_no: 6, content_in_per: 'Al (Aluminum)', crngo: '' },
      { s_no: 7, content_in_per: 'Hardness', crngo: '' },
    ],
    processes_2: [
      {
        elements: 'Heat No should be Mention',
        remarks: null,
      },
      {
        elements: 'Ingot free from Rust,Dust,Wet',
        remarks: null,
      },
      {
        elements: 'Ingot colour should be bright silver',
        remarks: null,
      },
    ],
  },
  crca: {
    processes_1: [
      { s_no: 1, content_in_per: 'C (Carbon)', crngo: '0.08 Max' },
      { s_no: 2, content_in_per: 'Mn (Magnese)', crngo: '0.45 Max' },
      { s_no: 3, content_in_per: 'S(Sulfer)', crngo: '0.030 Max' },
      { s_no: 4, content_in_per: 'P (Phosphorus)', crngo: '0.020 Max' },
      { s_no: 5, content_in_per: 'Si (Silicon)', crngo: '0.1-0.30' },
      { s_no: 6, content_in_per: 'Al (Aluminum)', crngo: '0.02 Max' },
      { s_no: 7, content_in_per: 'Hardness', crngo: '120-135 VPN' },
    ],
    processes_2: [
      {
        elements: 'Heat No should be Mention',
        remarks: null,
      },
      {
        elements: 'Ingot free from Rust,Dust,Wet',
        remarks: null,
      },
      {
        elements: 'Ingot colour should be bright silver',
        remarks: null,
      },
    ],
  },
};
