import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { CiCircleCheck } from 'react-icons/ci';
import { Stack } from '@mui/material';
import CheckBoxCustomMui from '../common/ui/CheckBox';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { getTotalColsSpan } from '../../helpers/utils';
import {
  AddProcessButton,
  CopyProcessButton,
  CustomButton,
} from '../../../../components/common/button/CustomButtons';
import { ISpec } from '../../helpers/Interface';
import { spec } from '../../helpers/initialState';
import ModalCustom from '../../../../components/common/Modal';
import ListMuiCustom from '../common/ui/ListMuiCustom';
import { tableHeaderObj } from '../../constant';
interface IProps {
  moduleData: any;
  setRenderParent: any;
  controlPlanId: any;
  isView: boolean;
  tabValue: number;
}

const THead = ({
  moduleData,
  tabValue,
  isView,
  setRenderParent,
  controlPlanId,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  return (
    <thead
      style={{
        position: 'sticky',
        top: '0',
        zIndex: '100',
      }}
    >
      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
