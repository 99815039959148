// calculating the total colspan for the entire table based on tableHeaderObj,
export const getTotalColsSpan = (tableHeaderObj: any[]) => {
  let totalColspan = 0;
  if (tableHeaderObj.length === 0) return totalColspan;

  for (const cell of tableHeaderObj[0]) {
    totalColspan += Number(cell.colspan) || 1;
  }

  return totalColspan;
};
