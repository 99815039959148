import { useState } from 'react';
//mport ObservationInput from './ObservationInput';
import CustomSelect from '../../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { TableInput } from '../../../../../../components/common';

interface IProps {
  apiData: any;
  year: any;
}
const monthName = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const TBody = ({ apiData, year }: IProps) => {
  const [render, setRender] = useState(false);
  const { dashBoardMonthCounts } = apiData;
  return (
    <tbody>
      {dashBoardMonthCounts.map((item: any, index: any) => {
        const { sNo, month, count } = item;
        return (
          <tr>
            <td>{sNo}</td>
            <td>{month + ' - ' + (year % 100)}</td>
            <td>0</td>
            <td>{count}</td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TBody;
