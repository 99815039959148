import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import tiles from './Tiles';
const Clause = () => {
  return (
    <>
      <ChildHeader text="PROCESS REJECTION SHEET" />
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0fr"
      />
    </>
  );
};

export default Clause;
