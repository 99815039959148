import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import TController from './components/TController';

const CreateToolHistory = () => {
  const [moduleData, setModuleData] = useState<any>({});
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterListOfTools/${id}`)
        .then((res) => {
          const data = res.data;
          setModuleData(data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );

  const onSubmit = async () => {
    if (
      !moduleData?.changed_frequncy_prev &&
      moduleData?.changed_frequncy_prev !== 0 &&
      moduleData?.changed_frequncy >= 0
    ) {
      const ans = window.confirm(
        // Changed to confirm to better handle user response
        'Please verify the Tool PM Frequency. It can only be set once and cannot be changed later.'
      );
      if (!ans) return;
      moduleData.changed_frequncy_prev = moduleData?.changed_frequncy;
    }
    if (
      !moduleData?.initial_cumulative_prev &&
      moduleData?.initial_cumulative_prev !== 0 &&
      moduleData?.initial_cumulative >= 0
    ) {
      const ans = window.confirm(
        'Please verify the Starting Cumulative Frequency.It can only be set once and cannot be changed later.'
      );
      if (!ans) return;
      moduleData.initial_cumulative_prev = moduleData?.initial_cumulative;
    }

    const ans = await confirmSubmit();
    if (!ans) return;
    await axios
      .put(`/api/masterListOfTools/${id}`, moduleData)
      .then((res) => {
        if (res.data) {
          alert('Updated Successfully');
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Box>
        <ChildHeader text="HISTORY-CARD FOR TOOLINGS">
          {!isView && (
            <SubmitButton label="Submit" onClick={() => onSubmit()} />
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
        }}
      />
      <DialogSubmit />
    </>
  );
};

export default CreateToolHistory;
