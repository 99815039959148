import React, { useEffect, useState } from 'react';
import { Box, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';

import { PersitedDatePicker, TableCustom } from '../../../components/common';

import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { IData } from '../processRejection/helpers/interface';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../services/auth/AuthProvider';
// import SupervisorTable from './components/tablelist/SupervisorTable';
// import AdminTable from './components/tablelist/AdminTable';
import { getDailyProductionYearly } from '../processRejection/helpers/utils';
import { EditIconButton } from '../../../components/common/button/CustomIconButton';

const statusObj = {
  pending: 1,
  accepted: 2,
  rejected: 3,
};
const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },

    {
      text: 'MONTH & YEAR',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ACTION',
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const List = () => {
  const [monitoringList, setMoniteringList] = useState<
    { month_year: string }[]
  >([]);
  const { state } = useAuth();
  const heading = useQuery('heading') || '';
  const documentType = useQuery('documentType') || '';
  const date = useQuery('date');
  const [tableData, setTableData] = useState<IData[]>([]);
  const [render, setRender] = useState(false);
  const { user, IS } = state;
  const navigate = useNavigate();
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE CUSTOMER',
    'Are you sure you want to delete this document?'
  );
  useEffect(() => {
    let data = getDailyProductionYearly(date);
    setMoniteringList(data);
  }, [date]);
  return (
    <>
      <ChildHeader text={heading}></ChildHeader>
      <div style={{ display: 'flex', margin: '1rem' }}>
        <Box sx={{ margin: 2 }}>
          <PersitedDatePicker label="Year" views={['year']} />
        </Box>
      </div>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {monitoringList.map((item: any, index) => (
            <React.Fragment>
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{item.month_year}</TableCell>
                <TableCell align="center">
                  <EditIconButton
                    tooltipTitle="edit"
                    onClick={() =>
                      navigate(
                        `/common/processRejection/dashboard?&annual_date=${item.month_year}`
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </TableCustom>
      {/* <Box sx={{ margin: 2 }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
      </Box> */}
      {/* {user?.userType === IS.Admin && (
        <AdminTable
          {...{
            handleChangeStatus,
            deleteDocument,
            updateStatus,
            statusObj,
            tableData,
          }}
        />
      )}
      {user?.userType === IS.Supervisor && (
        <SupervisorTable {...{ deleteDocument, tableData }} />
      )}
      <DialogDelete isSubmitContent={false} /> */}
    </>
  );
};

export default List;
