import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';

import THead from './THead';

const TController = ({ moduleData, isView, handelDeleteRows }: any) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handelDeleteRows,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
