import Styled from 'styled-components';

export const DivStyled = Styled.div`
     /* border-top: 1px solid #868e96; */
     /* border-bottom: 1px solid #868e96; */
    /* border-left: 1px solid #ced4da; */
    /* border-right: 1px solid #ced4da; * */
    border-radius: 5px;
    border: 1px solid #adb5bd;
    /* padding: 0.5rem 0.5rem 0.6rem 1rem; */
    box-sizing: border-box;
    padding: 1rem
  `;

export const InputStyled = Styled.input`
        border: 1px solid #ced4da;
        padding: 0.5rem;
        margin: 0.5rem;
        border-radius: 5px;
        width: ${(props) => props.width};
    :focus{
        outline: none;
    }
`;

export const GridfourStyled = Styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 1fr));
  justify-items: center;
  
`;
