import { Radio } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';

interface IProps {
  moduleData: { [key: string]: any };
  isView: boolean;
  title?: string;
  phase: any;
}
const THead = ({ title, phase, moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const handleRadioButton = (e: any) => {
    moduleData.product_details_radio = e.target.value;
    setRender((prev) => !prev);
  };

  const handleChange = (e: any) => {
    moduleData[e.target.name] = e.target.value;
    setRender((prev) => !prev);
  };
  return (
    <thead>
      <tr>
        <th colSpan={8}>CUSTOMER NAME :</th>
        <th></th>
        <th colSpan={12}>PART NAME : {moduleData.part_id?.part_name}</th>
        <th colSpan={10}>
          PART NUMBER & MODEL : {moduleData.part_id?.part_number}
        </th>
      </tr>
      <tr>
        <th>A</th>
        <th colSpan={5}>
          Any Similar type of product has not been produced earlier .
        </th>
        <th colSpan={2}>
          <Radio
            checked={moduleData.product_details_radio === 'a'}
            onChange={(e) => {
              handleRadioButton(e);
            }}
            value={'a'}
            name="product_details_radio"
          />
        </th>
        <th></th>
        <th colSpan={12}></th>
        <th colSpan={4}></th>
        <th
          colSpan={3}
          rowSpan={4}
          style={{ textAlign: 'center', fontSize: '2rem' }}
        >
          🗸
        </th>
        <th colSpan={4} rowSpan={4}>
          ACTIVITY REQUIRED
        </th>
      </tr>
      <tr>
        <th>B</th>
        <th colSpan={5}>
          A Similar type of product has been produced earlier .
        </th>
        <th colSpan={2}>
          <Radio
            checked={moduleData.product_details_radio === 'b'}
            onChange={(e) => {
              handleRadioButton(e);
            }}
            value={'b'}
            name="product_details_radio"
          />
        </th>
        <th></th>
        <th colSpan={12}></th>
        <th colSpan={4}></th>
      </tr>
      <tr>
        <th>C</th>
        <th colSpan={5}>Existing Product ECN </th>
        <th colSpan={2}>
          <Radio
            checked={moduleData.product_details_radio === 'c'}
            onChange={(e) => {
              handleRadioButton(e);
            }}
            value={'c'}
            name="product_details_radio"
          />
        </th>
        <th></th>
        <th colSpan={12}></th>
        <th colSpan={4}></th>
      </tr>
      <tr>
        <th>D</th>
        <th colSpan={5}>Location Change/PCN </th>
        <th colSpan={2}>
          <Radio
            checked={moduleData.product_details_radio === 'd'}
            onChange={(e) => {
              handleRadioButton(e);
            }}
            value={'d'}
            name="product_details_radio"
          />
        </th>
        <th></th>
        <th colSpan={12}></th>
        <th colSpan={4}></th>
      </tr>
      <tr>
        <th colSpan={6} style={{ textAlign: 'center' }}>
          REVIEWED BY PROJECT LEADER (PL)
        </th>
        <th colSpan={2}>WEEKLY </th>
        <th></th>
        <th colSpan={12}></th>
        <th colSpan={4}></th>
        <th
          colSpan={3}
          rowSpan={3}
          style={{ textAlign: 'center', fontSize: '2rem' }}
        >
          ⨯
        </th>
        <th colSpan={4} rowSpan={3}>
          ACTIVITY NOT REQUIRED
        </th>
      </tr>
      <tr>
        <th colSpan={6} style={{ textAlign: 'center' }}>
          REVIEWED BY CFT
        </th>
        <th colSpan={2}>WEEKLY </th>
        <th></th>
        <th colSpan={12}></th>
        <th colSpan={4}></th>
      </tr>
      <tr>
        <th colSpan={6} style={{ textAlign: 'center' }}>
          REVIEWED BY TOP MANAGEMENT(TM )
        </th>
        <th colSpan={2}>WEEKLY </th>
        <th></th>
        <th colSpan={12}></th>
        <th colSpan={4}></th>
      </tr>
      <tr>
        <th colSpan={2} rowSpan={3}>
          CUSTOMER EVENTS
        </th>
        <th colSpan={2}>Customer Drawing :-</th>
        <th colSpan={2}>
          {isView ? (
            formatDate(moduleData.customer_drawing_date)
          ) : (
            <DatePickerMui
              label=""
              sx={{ width: '150px' }}
              value={moment(moduleData.customer_drawing_date || null)}
              onChange={(date) => {
                moduleData.customer_drawing_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={2}>Customer PO:-</th>
        <th colSpan={4}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="customer_po"
            type="text"
            value={moduleData.customer_po}
            onChange={handleChange}
          />
        </th>
        <th colSpan={5}>
          {isView ? (
            formatDate(moduleData.customer_po_date)
          ) : (
            <DatePickerMui
              sx={{ width: '150px' }}
              label=""
              value={moment(moduleData.customer_po_date || null)}
              onChange={(date) => {
                moduleData.customer_po_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={4}>T2 (IF REUIRED)</th>
        <th colSpan={2}>
          {isView ? (
            formatDate(moduleData.t2_date)
          ) : (
            <DatePickerMui
              sx={{ width: '150px' }}
              label=""
              value={moment(moduleData.t2_date || null)}
              onChange={(date) => {
                moduleData.t2_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={1}>QTY:</th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="t2_qty"
            type="text"
            value={moduleData.t2_qty}
            onChange={handleChange}
          />
        </th>
        <th colSpan={5}></th>
      </tr>
      <tr>
        <th colSpan={2}>Quotation:-</th>
        <th colSpan={2}>
          {isView ? (
            formatDate(moduleData.quatation_date)
          ) : (
            <DatePickerMui
              label=""
              sx={{ width: '150px' }}
              value={moment(moduleData.quatation_date || null)}
              onChange={(date) => {
                moduleData.quatation_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={2}>T1</th>
        <th colSpan={4}>
          {isView ? (
            formatDate(moduleData.t1_date)
          ) : (
            <DatePickerMui
              label=""
              sx={{ width: '150px' }}
              value={moment(moduleData.t1_date || null)}
              onChange={(date) => {
                moduleData.t1_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={2}>QTY</th>
        <th colSpan={3}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="t1_qty"
            type="text"
            value={moduleData.t1_qty}
            onChange={handleChange}
          />
        </th>
        <th colSpan={4}>T3 (IF REUIRED)</th>
        <th colSpan={2}>
          {isView ? (
            formatDate(moduleData.t3_date)
          ) : (
            <DatePickerMui
              label=""
              sx={{ width: '150px' }}
              value={moment(moduleData.t3_date || null)}
              onChange={(date) => {
                moduleData.t3_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={1}>QTY:</th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="t3_qty"
            type="text"
            value={moduleData.t3_qty}
            onChange={handleChange}
          />
        </th>
        <th style={{ backgroundColor: 'yellow' }}></th>
        <th style={{ backgroundColor: 'yellow', textAlign: 'center' }}>PLAN</th>
        <th style={{ backgroundColor: '#2f9e44' }}></th>
        <th
          colSpan={2}
          style={{ backgroundColor: '#2f9e44', textAlign: 'center' }}
        >
          DONE
        </th>
      </tr>
      <tr>
        <th colSpan={2}>PPAP Submission :-</th>
        <th colSpan={2}>
          {isView ? (
            formatDate(moduleData.ppap_submission_date)
          ) : (
            <DatePickerMui
              sx={{ width: '150px' }}
              label=""
              value={moment(moduleData.ppap_submission_date || null)}
              onChange={(date) => {
                moduleData.ppap_submission_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={6}>SOP:-</th>
        <th colSpan={5}>
          {isView ? (
            formatDate(moduleData.sop_date)
          ) : (
            <DatePickerMui
              sx={{ width: '150px' }}
              label=""
              value={moment(moduleData.sop_date ||null)}
              onChange={(date) => {
                moduleData.sop_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={9}></th>
        <th style={{ backgroundColor: 'yellow' }}></th>
        <th style={{ backgroundColor: 'yellow', textAlign: 'center' }}></th>
        <th style={{ backgroundColor: '#2f9e44' }}></th>
        <th
          colSpan={2}
          style={{ backgroundColor: '#2f9e44', textAlign: 'center' }}
        ></th>
      </tr>
      <tr>
        <th colSpan={26}></th>
        <th
          colSpan={2}
          style={{ backgroundColor: 'yellow', textAlign: 'center' }}
        >
          P- PLAN
        </th>
        <th
          colSpan={3}
          style={{ backgroundColor: '#2f9e44', textAlign: 'center' }}
        >
          A- ACTUAL
        </th>
      </tr>

      <tr>
        {[
          'S.N.',
          'PHASE',
          'ACTIVITY',
          'REQUIRED',
          'NOT REQUIRED',
          'RESPONSIBILITY',
          'MONTHS',
          '',
          'START DATE',
          'COMPLETE DATE',
          'REMARKS',
          'UPLOAD PROOF',
        ].map((heading, index) => (
          <th
            key={index + 'heading'}
            rowSpan={index === 6 ? 1 : 3}
            colSpan={index === 6 ? 20 : 1}
          >
            {heading}
          </th>
        ))}
      </tr>
      <tr>
        {Array(4)
          .fill(0)
          .map((_, index) => (
            <th colSpan={5}>
              {isView ? (
                formatDate(moduleData?.phase_months?.[index])
              ) : (
                <DatePickerMui
                  sx={{ width: '150px' }}
                  label=""
                  value={moment(moduleData?.phase_months?.[index] || null)}
                  onChange={(date) => {
                    moduleData.phase_months[index] = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </th>
          ))}
      </tr>
      <tr>
        {[
          'W1',
          'W2',
          'W3',
          'W4',
          'W5',
          'W1',
          'W2',
          'W3',
          'W4',
          'W5',
          'W1',
          'W2',
          'W3',
          'W4',
          'W5',
          'W1',
          'W2',
          'W3',
          'W4',
          'W5',
        ].map((heading) => (
          <th>{heading}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
