import { Box, MenuItem, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TableCustom, TableInput } from '../../../../components/common';
import {
  DeleteIconButton,
  ViewIconButton,
} from '../../../../components/common/button/CustomIconButton';
import SelectGridMui from '../../../../components/common/selectscustom/SelectGridMui';
import CustomSelect from '../../../../components/common/SelectField';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import useConfirm from '../../../../components/common/useConfirm';

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'CHECK POINT',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'STATUS',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'REMARK',
      colspan: 1,
      rowspan: 1,
    },
  ],
];
interface IProps {
  id: string | null;
  data: any;
  setIsFileOpenPopup: any;
  setId: any;
  setData: any;
}

const CheckPoints = ({
  id,
  data,
  setIsFileOpenPopup,
  setId,
  setData,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [files, setFiles] = useState<
    { _id: string; name: string; url: string }[] | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Checkpoints' : 'Create Checkpoints'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/devlopmentTracker/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    // axios
    //   .get(`/api/suppplierComplaint/${id}`)
    //   .then((res) => {
    //     if (res.data) {
    //       setFiles(res.data.files);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, [render]);
  const deleteFile = async (index: number) => {
    if (!window.confirm('Are you sure you want to delete this file?')) {
      return;
    }
    // axios
    //   .delete(`/api/suppplierComplaint/deleteFile/${id}/${index}`)
    //   .then((res) => {
    //     if (res.data) {
    //       alert('document deleted successfully');
    //       setRender((prev) => !prev);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    setIsLoading(true);
    if (id) {
      await axios
        .put(`/api/devlopmentTracker/${id}`, data)
        .then((res) => {
          setIsLoading(false);
          setIsFileOpenPopup(false);
          setId(null);
        })
        .catch((err) => console.log(err));
    }
  };

  // const index = data.map((item: any) => item._id).indexOf(id);
  return (
    <Box sx={{ width: 800 }}>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {data.checkPoints?.map((item: any, index: number) => {
            return (
              <TableRow>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.checkpoint}</TableCell>
                <TableCell>
                  <CustomSelect
                    name="status"
                    value={item.status || ''}
                    onChange={(e) => {
                      item.status = e.target.value;
                      setRender((prev) => !prev);
                    }}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <MenuItem value="NA">NA</MenuItem>
                    <MenuItem value="OK">OK</MenuItem>
                    <MenuItem value="NOT OK">NOT OK</MenuItem>
                  </CustomSelect>
                </TableCell>
                <TableCell>
                  <TableInput
                    //   isView={isView}
                    name="remark"
                    type="text"
                    value={item.remark}
                    onChange={(e) => {
                      item.remark = e.target.value;
                      setRender((prev) => !prev);
                    }}
                    isView={false}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableCustom>
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </Box>
  );
};

export default CheckPoints;
