import { Profile } from '../../pages/profile';
interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}

export const other: routes[] = [
  {
    path: 'profile',
    element: <Profile />,
  },
];
