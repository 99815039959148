import moment from 'moment';
import { useState } from 'react';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import TextAreaInput from '../../../../../components/common/TextAreaInput';
import { CustomButton } from '../../../../../components/common/button/CustomButtons';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  handleAddRow: any;
}
const TBody2 = ({
  moduleData,
  isView,
  handelDeleteRows,
  handleAddRow,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      <tr
        style={{
          fontSize: '2rem',
          background: '#ffec99',
        }}
      >
        <th colSpan={8}>Why Why Analysis : </th>
        <th>
          <CustomButton
            sx={{
              backgroundColor: '#1c7ed6',
            }}
            onClick={() => {
              handleAddRow('why_desc');
            }}
          >
            ADD ROWS
          </CustomButton>
        </th>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {['Why:', 'Discription', 'Action'].map((head, index) => (
          <th
            key={head}
            style={{ textTransform: 'uppercase' }}
            colSpan={index === 1 ? 7 : 1}
          >
            {head}
          </th>
        ))}
      </tr>
      {moduleData?.why_desc &&
        moduleData?.why_desc?.map((item: any, index: number) => (
          <tr>
            <td>{index + 1}</td>
            <td style={{ minWidth: '300px' }} colSpan={7}>
              <TextAreaInput
                isView={isView}
                name="desc"
                value={item.desc}
                onChange={(e) => {
                  item.desc = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handelDeleteRows(index, 'why_desc')}
              />
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody2;
