import { Box } from '@mui/system';
import axios from 'axios';
import { useState } from 'react';
import TController from './create/TController';
import useGetData from './helpers/useGetData';
import { Loader } from '../../../components/common/Loader';
import HeaderCreate from './HeaderCreate';
import { moduleConstant } from './constant';
import useConfirm from '../../../components/common/useConfirm';
import ModalCustom from '../../../components/common/Modal';
import PickPart from '../../../components/ui/PickPart';

interface MyData {
  moduleData: any;
  isLoading: boolean;
  setIsLoading: any;
  setModuleData: any;
  setRenderforUpdate: any;
  setParentRender: React.Dispatch<React.SetStateAction<boolean>>;
  error?: string | null;
  toolData: any;
}
const Create = () => {
  const [isView, setisView] = useState<boolean>(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [partIdx, setPartIdx] = useState<any>(0);
  const [DialogUpdate, confirmUpdate] = useConfirm(
    'Save',
    'Are you sure you want to save?',
  );
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this row?',
  );

  const {
    error,
    moduleData,
    setModuleData,
    isLoading,
    setIsLoading,
    setParentRender,
    setRenderforUpdate,
    toolData,
  }: MyData = useGetData();

  const handleUpdate = async () => {
    const ans = await confirmUpdate();
    if (!ans) return;

    axios
      .post(`${moduleConstant.apiConstant}`, moduleData)
      .then((res) => {
        setRenderforUpdate((prev: any) => !prev);
        alert('successfully updated');
      })
      .catch((err) => console.error(err));
  };

  const handleDelete = async (id: string, rowIndex: number) => {
    const ans = await confirmDelete();
    if (!ans) return;
    if (!id) {
      const values = [...moduleData];
      values.splice(rowIndex, 1);
      setModuleData(values);
      alert('row deleted successfully');
      return;
    }
    await axios
      .delete(`${moduleConstant.apiConstant}/${id}`)
      .then((res) => {
        if (res?.data) {
          const values = [...moduleData];
          values.splice(rowIndex, 1);
          setModuleData(values);
          alert('row deleted successfully');
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box>
      <Loader loader={isLoading} />
      <HeaderCreate
        {...{
          handleUpdate,
          moduleData,
          setModuleData,
        }}
      />
      <TController
        {...{
          moduleData,
          setPartIdx,
          isView,
          setIsOpenPopup,
          handleDelete,
          setModuleData,
          setParentRender,
          toolData,
        }}
      />
      <DialogUpdate isSubmitContent={false} />
      <DialogDelete isSubmitContent={false} />
      <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PickPart moduleData={moduleData[partIdx]} />
      </ModalCustom>
    </Box>
  );
};

export default Create;
