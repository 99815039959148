import styled from 'styled-components';

export const SearchFilterContainer = styled.div`
  display: grid;
  margin: 15px 40px;
  font-size: 1rem;
  grid-template-columns: ${({ filter }: { filter: boolean }) =>
    filter ? '80% 20%' : '100%'};
  align-items: center;
  justify-content: center;
  gap: 20px;

  & > * {
    font-size: 2rem;
  }
`;
