import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import { IData } from './Interface';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import { SelectChangeEvent } from '@mui/material';

const ModalInput = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<IData>({
    topic_paper_name: '',
    topics: [],
  });

  const [category, setCategory] = useState([]);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update' : 'Submit'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );

  useEffect(() => {
    axios
      .get(`/api/masterTniCategoryRoute/tniList/categoryForAutoComplete`)
      .then((res) => setCategory(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/tni/getOneTopicPaper/${id}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleTopicPaper(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>,
  ) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      axios
        .patch(`/api/tni/patchTopicPaper/${id}`, moduleData)
        .then((res) => {
          window.alert('success');
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post(`/api/tni/createTopicPaper`, moduleData)
        .then((res) => {
          window.alert('success');
          setIsModal(false);
        })
        .catch((err) => {
          if (err.response.data.error.message.includes('E11000')) {
            window.alert('Can not create duplicate category paper');
          }
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'topic_paper_name'}
        name={'topic_paper_name'}
        html_for={'topic_paper_name'}
        label_name={'Topic Paper Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.topic_paper_name}
        onChange={handleTopicPaper}
      />
      <AutocompleteGridmui
        label={'Cateogry'}
        id={'name'}
        option_name={'name'}
        value={moduleData.category_id_temp || moduleData?.category_id || ''}
        arrayofObj={category}
        onChange={(e, value) => {
          moduleData.category_id = value?._id;
          moduleData.category_id_temp = value;
          setRender((prev) => !prev);
        }}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default ModalInput;
