import { useState } from 'react';
import InteractiveIcon from '../../../../../components/common/InteractiveIcon';
import useConfirm from '../../../../../components/common/useConfirm';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';

interface IProps {
  item: any;
  isView: boolean;
  index: number;
  allmonth: any;
}
const TBodyRight = ({ item, isView, index, allmonth }: IProps) => {
  const [render, setRender] = useState(false);
  const [DialogisCheck, setDialogisCheck] = useConfirm(
    'ADD',
    'Are you sure you want to ADD PLAN?'
  );
  const [DialogRemoveCheck, setDialogRemoveCheck] = useConfirm(
    'Remove',
    'Are you sure you want remove you cant roll back?'
  );

  const handleDoubleClick = async (event: any, month: string, idx: any) => {
    if (event.detail === 2) {
      if (item[month].p) {
        const ans = await setDialogRemoveCheck();
        if (!ans) return;
        item[month].p = false;
        setRender((prev) => !prev);
      } else {
        const ans = await setDialogisCheck();
        if (!ans) return;
        item[month].p = true;
        setRender((prev) => !prev);
      }
    }
  };

  return (
    <>
      {allmonth.map((month: string) => (
        <td
          style={{
            padding: '0px',
            margin: '0px',
          }}
          onClick={(e) => handleDoubleClick(e, month, index)}
        >
          <InteractiveIcon isYellow={item[month].p}>
            {item[month].p && 'P'}{' '}
          </InteractiveIcon>
        </td>
      ))}

      <DialogisCheck isSubmitContent={false} />
      <DialogRemoveCheck isSubmitContent={false} />
    </>
  );
};

export default TBodyRight;
