import React, { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../../components/common';
import CustomSelect from '../../../../../../components/common/SelectField';
import { Checkbox, MenuItem } from '@mui/material';
import formatDate from '../../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const getTotal = (moduleData: any, type: any) => {
  if (type === 'c')
    return +moduleData.c1.reduce((sum: any, curr: any) => {
      if (curr.c === true) return sum + 5;
      return sum;
    }, 0);
  if (type === 'o')
    return +moduleData.c1.reduce((sum: any, curr: any) => {
      if (curr.o === true) return sum + 2;
      return sum;
    }, 0);
  return 0;
};
const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData &&
        moduleData?.c1?.map((process: any, index: number) => (
          <>
            {process.heading && (
              <tr style={{ background: '#f1f3f5' }}>
                <th colSpan={6} align="left">
                  {process?.heading}
                </th>
              </tr>
            )}
            <tr>
              <td align="left">{process?.s_no}</td>
              <th align="left">{process?.check_point}</th>
              <td align="left">
                <Checkbox
                  name="c"
                  checked={process?.c}
                  onChange={(e: any) => {
                    process.c = e.target.checked;
                    if (process.c === true) {
                      process.nc = false;
                      process.o = false;
                    }
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td align="left">
                <Checkbox
                  name="o"
                  checked={process?.o}
                  onChange={(e: any) => {
                    process.o = e.target.checked;
                    if (process.o === true) {
                      process.nc = false;
                      process.c = false;
                    }
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td align="left">
                <Checkbox
                  name="nc"
                  checked={process?.nc}
                  onChange={(e: any) => {
                    process.nc = e.target.checked;
                    if (process.nc === true) {
                      process.o = false;
                      process.c = false;
                    }
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td align="left">
                <TableInput
                  isView={isView}
                  name="actual_observation"
                  type="text"
                  value={process.actual_observation}
                  onChange={(e: any) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
            </tr>
          </>
        ))}
      <tr>
        <th colSpan={2}>
          Total Score Achieved :{' '}
          {getTotal(moduleData, 'c') + getTotal(moduleData, 'o')} / 110
        </th>
        <td>{getTotal(moduleData, 'c')}</td>
        <td>{getTotal(moduleData, 'o')}</td>
        <td>{getTotal(moduleData, 'nc')}</td>
        <th>Max. Score: 110</th>
      </tr>
    </tbody>
  );
};

export default TBody;
