const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.No.', style: style1 },
    { text: 'Checkpoint', style: style1 },
    { text: 'Judgement Criteria', style: style1 },
    { text: 'Method', style: style1 },
    { text: 'Observation Before', style: style1 },
    { text: 'After', style: style1 },
    { text: 'REMARKS', style: style1 },
    {
      text: 'Action',
      style: { ...style1, width: '180px', backgroundColor: '#c92a2a' },
    },
  ],
];

export const listConstant = {
  childHeader: 'Prevantive Maintenance Checksheet list',
  addButtonLable: 'New Checksheet',
};

export const headerConstant = {
  tableCompHeadTitle: 'MACHINE PREVENTIVE MAINTENANCE CHECKSHEET ',
};

export const apiConstant = {
  get: 'masterCheckListRoutePrevantiveMaintenancePlan',
  getById: 'masterCheckListRoutePrevantiveMaintenancePlan',
  post: 'masterCheckListRoutePrevantiveMaintenancePlan',
  update: 'masterCheckListRoutePrevantiveMaintenancePlan',
  delete: 'masterCheckListRoutePrevantiveMaintenancePlan',
  upload: 'masterCheckListRoutePrevantiveMaintenancePlan/uploadFile',
  machineDataForAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const tilesConstant = {
  childHeaderText: 'Prevantive Maintenance',
};

export const navigationConstant = {
  createEditChecklist: '/common/prevantiveMaintenance_NEW_ChecklistCreate',
};
