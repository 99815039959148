import { useState } from 'react';
//mport ObservationInput from './ObservationInput';
import CustomSelect from '../../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { TableInput } from '../../../../../../components/common';

interface IProps {
  apiData: any;
}
const TBody = ({ apiData }: IProps) => {
  return (
    <tbody>
      {apiData &&
        apiData.map((data: any, index: number) => {
          const { defectName, ngQty, percentage, cumulativePercentage } = data;
          return (
            <tr>
              <td>{index + 1}</td>
              <td>{defectName}</td>
              <td>{ngQty}</td>
              <td>{cumulativePercentage}</td>
              <td>{percentage}</td>
            </tr>
          );
        })}
    </tbody>
  );
};

export default TBody;
