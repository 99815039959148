import React, { useEffect, useState } from 'react';
import TController from './components/view/TController';
import { IData } from './helpers/interface';
import { initialState } from './helpers/initialState';
import lodash, { forEach } from 'lodash';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  CustomButton,
  SaveButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { useAuth } from '../../../services/auth/AuthProvider';
import { useQuery } from '../../../hooks/UseQuery';
import { processes } from './helpers/initialState';
import PartTable from './components/view/PartTable';
import ModalCustom from '../../../components/common/Modal';
import useConfirm from '../../../components/common/useConfirm';
import RejectionTable from './components/view/RejectionTable';

const IsLessRejection = (process: any) => {
  const ngQty = process.prod_qty - process.ok_qty;
  const totalRejectionQty = process.reasons_for_rej.reduce(
    (sum: number, item: any) => sum + +item.rej_qty,
    0
  );

  if (totalRejectionQty - ngQty < 0) {
    alert(`Please select ${ngQty - totalRejectionQty} more rejection Qty.`);
    return true;
  } else if (ngQty < totalRejectionQty) {
    alert(`Please select ${totalRejectionQty - ngQty} less rejection Qty.`);
    return true;
  }
  return false;
};

const View = () => {
  const [moduleData, setModuleData] = useState<any>({
    processes: Array.from({ length: 1 }, () => ({ ...processes[0] })),
  });
  const [render, setRender] = useState(false);
  const [partIdx, setPartIdx] = useState<number>(0);
  const [rejectionsIndx, setRejectionsIndx] = useState(0);
  const [isOpenPopupForRejections, setIsOpenPopupRejections] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const navigate = useNavigate();
  const annual_month = useQuery('annual_date') || '';
  const isView = useQuery('isView') === 'true' ? true : false;
  const [vendors, setVendors] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );
  const [DialogSummary, confirmSummary] = useConfirm(
    'Submit',
    'Are you sure you want to add this to 4M summary?'
  );
  useEffect(() => {
    axios
      .get(`/api/vendorRoute/vendor/forAutoComplete`)
      .then((res) => {
        setVendors(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(`/api/employee/employeeList/customerForAutoComplete`)
      .then((res) => {
        setEmployees(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    axios
      .get(
        `/api/materialInspectionRejectionRecords/monthly?annualMonth=${annual_month}`
      )
      .then((res) => {
        if (res.data) setModuleData(res.data);
      })
      .catch((err) => console.log(err));
  }, [render]);

  useEffect(() => {
    if (moduleData?._id) {
      axios
        .get(`/api/materialInspectionRejectionRecords/${moduleData?._id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [moduleData?._id]);

  const handleSumbit = async () => {
    if (moduleData?._id) {
      await axios
        .put(
          `/api/materialInspectionRejectionRecords/${moduleData?._id}`,
          moduleData
        )
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Updated Successfully');
            setRender((prev) => !prev);
          }
        })
        .catch((err) => console.log(err));
    } else {
      if (!annual_month) {
        return alert('Please pick annual year?');
      }
      moduleData.annual_month = annual_month;
      await axios
        .post('/api/materialInspectionRejectionRecords', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            setRender((prev) => !prev);
            // navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.processes.splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <>
      <Box>
        <ChildHeader text={'Material Inspection Rejection Records'}>
          {!isView && (
            <>
              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  const ans = await confirmAddRow();
                  if (!ans) return;
                  Array(1)
                    .fill(null)
                    .forEach(() => {
                      moduleData.processes.push(lodash.cloneDeep(processes[0]));
                    });
                  // setRender((prev) => !prev);
                }}
              >
                ADD ROWS
              </CustomButton>
              <SubmitButton
                label={moduleData?._id ? `UPDATE` : `SUBMIT`}
                onClick={() => handleSumbit()}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handleSumbit,
          handelDeleteRows,
          isOpenPopup,
          setIsOpenPopup,
          setPartIdx,
          setIsOpenPopupRejections,
          setRejectionsIndx,
          vendors,
          employees,
        }}
      />

      <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PartTable
          {...{
            moduleData,
            partIdx,
          }}
        />
      </ModalCustom>
      <ModalCustom
        title="Rejections List"
        openModal={isOpenPopupForRejections}
        closeModal={() => {
          // if (!IsLessRejection(moduleData.processes[rejectionsIndx]))
          setIsOpenPopupRejections(false);
        }}
      >
        <RejectionTable
          process={moduleData.processes[rejectionsIndx]}
          isView={isView}
        />
      </ModalCustom>
      <DialogDeleteRow isSubmitContent={false} />
      <DialogAddRow />
      <DialogSubmit />
      <DialogSummary />
    </>
  );
};

export default View;
