import React, { useEffect, useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Gauge Name', rowspan: 1, colspan: 1 },
    { text: 'Gauge No.', rowspan: 1, colspan: 1 },
    { text: 'Frequency', rowspan: 1, colspan: 1 },
    { text: 'Location', rowspan: 1, colspan: 1 },
    { text: 'Responsibility', rowspan: 1, colspan: 1 },
    { text: 'Customer Name', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [{ value: 'createdAt', name: 'Most Recent First' }];

const List = () => {
  const [data, setData] = useState<any>([]);
  const [filterCustomer, setFilterCustomer] = useState([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [departmentId, setMachineId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);

  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;
  const customer = useQuery('customer');

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?',
  );
  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        if (res.data) {
          const formattedData = res.data.map((customer: any) => ({
            name: customer.customer_name,
            value: customer.customer_name,
          }));
          setFilterCustomer(formattedData);
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/gaugesRoute?page=${page}&sortBy=${filter ? filter : 'createdAt'}&search=${search}&perPage=${perPage}&customer=${customer ? customer : ''}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteModuleData = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/gaugesRoute/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, customer]);

  return (
    <>
      <ChildHeader text="Gauge LIST">
        <AddButton onClick={() => setIsModal(true)} label="New Gauge" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ gap: 2 }}>
          <FilterBy
            sx={{ minWidth: '190px', maxWidth: '190px' }}
            name="customer"
            label="Filter By Customer"
            options={filterCustomer}
          />
          <FilterBy label="Sort" options={filterOptions} />
        </Stack>
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.name}</TableCell>
                  <TableCell align="center">{item?.number}</TableCell>
                  <TableCell align="center">{item?.freq}</TableCell>
                  <TableCell align="center">{item?.location}</TableCell>
                  <TableCell align="center">{item?.responsibility}</TableCell>
                  <TableCell align="center">
                    {item?.customer_id?.customer_name}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setMachineId(item?._id || '');
                          setIsModal(true);
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteModuleData(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setMachineId(null);
          setIsModal(false);
        }}
        title="Gauge"
      >
        <Create id={departmentId} setIsModal={setIsModal} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
