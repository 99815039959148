import React, { useEffect, useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { TableInput } from '../../../../../components/common';
import { RenderTable } from '../../../../../components/ui';
import { Table } from '../../../../../components/ui/renderTable/styled';
import axios from 'axios';
const getTotalReasonOfRejection = (reasons_for_rej: any) => {
  const total = reasons_for_rej.reduce(
    (sum: any, item: any) => sum + +item.rej_qty,
    0
  );
  return total;
};
const RejectionTable = (props: any) => {
  const { process, isView } = props;
  const [render, setRender] = useState(false);
  const [rejectionReasons, setRejectionReasons] = useState([]);

  useEffect(() => {
    axios
      .get('/api/rejectionReasonRoutes/defectList/defectForAutoComplete')
      .then((res) => {
        if (res?.data) {
          setRejectionReasons(res?.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Table style={{ width: '80vh' }}>
      <thead>
        <tr>
          <th>S.No.</th>
          <th>Reasons</th>
          <th>Rejection Qty</th>
        </tr>
      </thead>
      <tbody>
        {process?.reasons_for_rej?.map((item: any, index: number) => (
          <tr>
            <th>{index + 1}</th>
            <td style={{ minWidth: '300px', textAlign: 'center' }}>
              {isView ? (
                item.reason
              ) : (
                <CustomSelect
                  name="reason"
                  value={item.reason}
                  onChange={(e) => {
                    item.reason = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {rejectionReasons.map((reason: any) => (
                    <MenuItem value={reason.defect_name}>
                      {reason.defect_name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="rej_qty"
                key="rej_qty"
                type="number"
                value={item.rej_qty}
                onChange={(e) => {
                  item.rej_qty = e.target.value;
                  process.rej_qty = getTotalReasonOfRejection(
                    process?.reasons_for_rej
                  );
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={2}>Total Rejection</th>
          <th>{process.rej_qty}</th>
        </tr>
      </tfoot>
    </Table>
  );
};

export default RejectionTable;
