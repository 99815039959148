import { AiOutlinePartition } from 'react-icons/ai';
import { GrUser } from 'react-icons/gr';
import { HiDocumentReport } from 'react-icons/hi';
import { MdDriveFolderUpload } from 'react-icons/md';
const tiles = [
  {
    sNo: 1,
    link: '/common/company_roles_responsibilities_listOfProcedures?documentType=clause1ListOfProcedure',
    isLink: true,
    icon: <MdDriveFolderUpload size="5rem" color="#003566" />,
    p: 'LIST OF',
    span: 'PROCEDURE',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/npdPartList',
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'NPD PART',
    span: 'LIST',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/npdMatrixList',
    isLink: true,
    clauseNo: 1,
    icon: <AiOutlinePartition size="5rem" color="#a12222" />,
    p: 'NPD',
    span: 'MATRIX',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/npdTimePlanList',
    isLink: true,
    clauseNo: 1,
    icon: <AiOutlinePartition size="5rem" color="#a12222" />,
    p: 'NPD',
    span: 'TIME PLAN',
    isIcon: true,
  },
  {
    sNo: 4,
    link: `/common/devlopmentTracker`,
    isLink: true,
    icon: <GrUser size="5rem" color="#003566" />,
    p: 'Devlopment',
    span: 'Tracker',
    isIcon: true,
  },
];

export default tiles;
