import { Box } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
interface IProps {
  moduleData: any;
  isView: boolean;
  handleChangeTbody: (e: any, processIndex: number, fmeaIndex?: number) => void;
  handleChangeThead: (e: any) => void;
  removeFmeas: (processIndex: number, fmeaIndex: number) => void;
  addFmeas: (processIndex: number, data: any) => void;
}

const TController = ({
  handleChangeTbody,
  handleChangeThead,
  moduleData,
  isView,
  removeFmeas,
  addFmeas,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, isView, handleChangeThead }} />
        <TBody
          processes={moduleData.processes}
          {...{
            isView,
            handleChangeTbody,
            removeFmeas,
            addFmeas,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
