import { useState } from 'react';
import { TableInput } from '../../../../../components/common';

interface Ifoot {
  moduleData: any;
}
const TFoot = ({ moduleData }: Ifoot) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
        <th colSpan={5} align="left">
          Remarks : Samples wil be selected & checked as per above Sampling Plan
          but observations will recorded for 5 Samples only with Min. & Max.
          values.
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Prepared By:
          <TableInput
            isView={false}
            name="prepared_by"
            type="text"
            value={moduleData.prepared_by}
            onChange={(e) => {
              moduleData.prepared_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2} align="left">
          Approved By:
          <TableInput
            isView={false}
            name="approved_by"
            type="text"
            value={moduleData.approved_by}
            onChange={(e) => {
              moduleData.approved_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
