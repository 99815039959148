import { useState } from 'react';
import { SearchBar } from '../../../components/common/SearchBar';
import FilterBy from '../../../components/common/FilterBy';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import ChildHeader from '../../../components/ui/ChildHeader';
import { TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import ModalCustom from '../../../components/common/Modal';
import { AddButton } from '../../../components/common/button/CustomButtons';
import TableCustom from '../../../components/common/customTable/TableCustom';
import useDebounce from '../../../hooks/useDebounce';
import React from 'react';
import formatDate from '../../../components/common/formatDate';
import {
  DeleteIconButton,
  EditIconButton,
} from '../../../components/common/button/CustomIconButton';

import { useQuery } from '../../../hooks/UseQuery';
import CustomPagination from '../../../components/common/CustomPagination';
import useConfirm from '../../../components/common/useConfirm';
import AddEditPart, { IPart } from './components/AddEditPart';

const filterOptions = [
  { value: 'part_number', name: 'A-Z' },
  { value: 'createdAt', name: 'Most Recent First' },
  { value: 'createdAt_old', name: 'Oldest First' },
];

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Item Number', rowspan: 1, colspan: 1 },
    { text: 'Item Name/Description', rowspan: 1, colspan: 1 },
    { text: 'Created At', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const NpdPart = () => {
  const [data, setData] = useState<IPart[]>([]);
  const [isNewPartModal, setIsNewPartModal] = useState(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [partId, setPartId] = useState<string | null>(null);

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to DELETE this Document?'
  );
  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;
  const fetchData = async () => {
    const res: any = await axios
      .get(
        `/api/npdPartRoute?page=${page}&sortBy=${
          filter || 'part_number'
        }&search=${search}&perPage=${10}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };

  const deletePart = async (id: string) => {
    const isDelete = await confirmDelete();
    if (!isDelete) {
      return;
    }
    await axios.delete(`/api/npdPartRoute/riPart/${id}`);
    setRender((prev) => !prev);
  };

  useDebounce(fetchData, 1000, [page, search, render, filter, isNewPartModal]);

  return (
    <>
      <ChildHeader text="NPD PARTS">
        <AddButton
          onClick={() => setIsNewPartModal(true)}
          label="New NPD Part"
        />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search Part Name" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IPart, index: number) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.part_number}</TableCell>
                  <TableCell align="center">{item?.part_name}</TableCell>

                  <TableCell align="center">
                    {formatDate(item?.createdAt || '')}
                  </TableCell>

                  <TableCell align="center">
                    <EditIconButton
                      tooltipTitle="EDIT"
                      onClick={() => {
                        setPartId(item?._id!);
                        setIsNewPartModal(true);
                      }}
                    />
                    <DeleteIconButton
                      tooltipTitle="DELETE"
                      onClick={() => {
                        if (item._id) {
                          deletePart(item._id);
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />

      <ModalCustom
        openModal={isNewPartModal}
        closeModal={() => {
          setPartId(null);
          setIsNewPartModal(false);
        }}
        title="CREATE NEW NPD PART"
      >
        <AddEditPart
          setIsNewPartModal={setIsNewPartModal}
          id={partId}
          setPartId={setPartId}
        />
      </ModalCustom>
      <DialogDelete />
    </>
  );
};

export default NpdPart;
