import React, { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import CustomSelect from '../../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

interface IProps {
  moduleData: any;
  isView: boolean;
  cc_type: string;
}
const calcMean = (val1: number = 0, val2: number = 0, val3: number = 0) => {
  return (val1 + val2 + val3) / 3;
};

const TBody = ({ moduleData, isView, cc_type }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {cc_type &&
        moduleData &&
        moduleData[cc_type] &&
        moduleData[cc_type]?.processes_1?.map((process: any, index: number) => (
          <tr key={index + 'body processes1'}>
            <td>{process?.s_no}</td>
            <td>{process?.content_in_per}</td>
            <td>{process?.crngo}</td>
          </tr>
        ))}
      {cc_type &&
        moduleData &&
        moduleData[cc_type] &&
        moduleData[cc_type]?.processes_2?.map((process: any, index: number) => (
          <tr key={index + 'body processes1'}>
            <td colSpan={2}>{process?.elements}</td>

            <td>
              <CustomSelect
                name="remarks"
                value={process.remarks || ''}
                onChange={(e) => {
                  process.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
                sx={{
                  textAlign: 'center',
                }}
              >
                <MenuItem value="">NONE</MenuItem>
                <MenuItem value="ok">OK</MenuItem>
                <MenuItem value="ng">NOT OK</MenuItem>
              </CustomSelect>
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
