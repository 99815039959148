import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import TController from './components/create/TController';
import { tabData } from './helpers/constants';
import useGetData from './helpers/useGetData';
interface MyData {
  moduleData: any; // replace PirType with the type of your `pir` data
  isLoading: boolean;
  error?: string | null;
}
const Create = () => {
  const { partId } = useParams();
  const { state } = useAuth();
  const navigate = useNavigate();

  const isView = useQuery('isView') === 'true' ? true : false;
  const partName = useQuery('partName');
  const partNumber = useQuery('partNumber');
  const [tabValue, setTabValue] = useState(0);
  const { moduleData, isLoading, error }: MyData = useGetData(partId);

  const onSubmit = async () => {
    if (moduleData._id) {
      axios
        .put(`/api/npdMatrixRoute/${moduleData._id}`, moduleData)
        .then((res) => {
          if (res.data) {
            alert('created successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    } else {
      moduleData.part_id = partId;
      axios
        .post(`/api/npdMatrixRoute`, moduleData)
        .then((res) => {
          if (res.data) {
            alert('created successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <Box>
      <Box>
        <ChildHeader text="NPD MATRIX">
          {!isView && (
            <SubmitButton label="Submit" onClick={() => onSubmit()} />
          )}
        </ChildHeader>
      </Box>
      <Paper elevation={2} style={{ margin: '1rem 0.5rem 0rem 0.5rem' }}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          textColor="secondary"
        >
          <Tab label={'Phase – 1'} />
          <Tab label={'Phase – 2'} />
          <Tab label={'Phase – 3'} />
          <Tab label={'Phase – 4'} />
          <Tab label={'Phase – 5'} />
        </Tabs>
      </Paper>
      <TController
        {...{
          moduleData,
          isView,
        }}
        phase={tabData[tabValue].phase}
        title={tabData[tabValue].title}
      />
    </Box>
  );
};

export default Create;
