import { MenuItem } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import CustomSelect from '../../../../components/common/SelectField';

interface IProps {
  process: any;
  isView: boolean;
  partId: string;
  setRender: any;
}
const OperationSelect = ({ process, partId, isView, setRender }: IProps) => {
  const [operation, setOperation] = useState<any>([]);
  useEffect(() => {
    if (partId) {
      axios
        .get(`/api/controlPlan/controlPlan/processesAndTool/${partId}`)
        .then((res) => {
          if (res?.data) {
            const data = res.data;
            // here setting tool_id because for old production module tool_id field was not created based on process name
            if (!process?.tool_id && process?.operation_name) {
              const forSettingToolForOldRecord = data?.find(
                (op: any) => op?.process_name === process?.operation_name
              );
              process.tool_id = forSettingToolForOldRecord?.tool_id ?? null;
              console.log(process.operation_name, process.tool_id);
            }
            setOperation(res.data);
          }
        })

        .catch((err) => console.log(err));
    }
  }, [partId]);
  return (
    <>
      {isView ? (
        process?.operation_name
      ) : (
        <CustomSelect
          name="operation_name"
          value={process?.operation_name || ''}
          onChange={(e) => {
            const selectedOperation = operation?.find(
              (op: any) => op?.process_name === e.target.value
            );
            process.operation_name = e.target.value;
            process.tool_id = selectedOperation?.tool_id ?? null;
            setRender((prev: any) => !prev);
          }}
        >
          <MenuItem value="">None</MenuItem>
          {operation?.map((process: any) => (
            <MenuItem value={process?.process_name}>
              {process?.process_name}
            </MenuItem>
          ))}
        </CustomSelect>
      )}
    </>
  );
};

export default OperationSelect;
