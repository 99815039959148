import { MenuItem, Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useGetData from './helpers/useGetData';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import TController from './components/create/TController';
import { apiConstant } from '../constant';
import CustomSelect from '../../../../components/common/SelectField';
import TControllerOther from './components/createOther/TControllerOther';
interface MyData {
  moduleData: any; // replace PdirType with the type of your `pdir` data
  isLoading: boolean;
  error?: string | null;
}
const Create = () => {
  const { moduleId } = useParams();

  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const customer = useQuery('customer');
  const documentId = useQuery('documentId');
  const listItemObjectId = useQuery('listItemObjectId');
  const month = useQuery('month');

  const [checkSheetType, setCheckSheetType] = useState('none');
  const { moduleData, isLoading, error }: MyData = useGetData(
    moduleId,
    checkSheetType
  );
  const checkSheetArr = [
    { name: 'None', value: 'none' },
    { name: 'Process audit Checksheet', value: 'c1' },
    { name: 'Product audit Checksheet', value: 'c2' },
  ];

  const onSubmit = async () => {
    let response: any;
    if (moduleData._id) {
      response = await axios
        .put(`/api/${apiConstant.checklistData}/${moduleData._id}`, moduleData)
        .then((res) => {
          alert('updated  successfully');
          // navigate(-1);
        })
        .catch((err) => console.log(err));
    } else {
      response = await axios
        .post(
          `/api/${apiConstant.checklistData}?documentId=${documentId}&listItemObjectId=${listItemObjectId}&month=${month}`,
          moduleData
        )
        .then((res) => {
          alert('created successfully');
          navigate(-1);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Box>
      <Box>
        <ChildHeader text="Checklist">
          {!isView && (
            <>
              <SubmitButton label="Submit" onClick={() => onSubmit()} />
            </>
          )}
        </ChildHeader>
      </Box>

      <Box sx={{ margin: '2rem', width: '250px' }}>
        <CustomSelect
          label="select checklist"
          name="checkSheetType"
          value={checkSheetType}
          onChange={async (e: any) => {
            const value = e.target.value;
            await onSubmit();

            if (value === 'none' || value === undefined || value === '') {
              alert('Please select a check sheet type');
              setCheckSheetType('none');
              return;
            }
            const ans = window.confirm(
              'are you sure you want to choose this check type?'
            );
            if (ans) setCheckSheetType(value);
          }}
        >
          {checkSheetArr?.map((item: any, index: number) => {
            return (
              <MenuItem
                key={index}
                style={{ textTransform: 'uppercase' }}
                value={item.value}
              >
                {item.name}
              </MenuItem>
            );
          })}
        </CustomSelect>
      </Box>

      {checkSheetType === 'c1' && (
        <TController {...{ moduleData, isView, customer }} />
      )}
      {checkSheetType === 'c2' && (
        <TControllerOther {...{ moduleData, isView, customer }} />
      )}
    </Box>
  );
};

export default Create;
