import moment from 'moment';

export const processes = [
  {
    date: moment(),

    mc_no: null,
    part_id: null,
    total_production_qty: null,
    rejection_qty: null,
    inspection_date: moment(),
    rejection_percentage: null,
    // defects name start
    non_filling_qty: null,
    angle_qty: null,
    cutting_qty: null,
    i_d_damage_qty: null,
    catching_qty: null,
    o_d_damage_qty: null,
    // defects name end
    remarks: null,
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  dailyProduction_date: moment(),
  processes: Array.from({ length: 5 }, () => ({ ...processes[0] })),
};
