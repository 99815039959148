import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TimePicker } from '@mui/x-date-pickers';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import TextAreaInput from '../../../../../components/common/TextAreaInput';
import { CustomButton } from '../../../../../components/common/button/CustomButtons';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  handleAddRow: any;
}
const TBody = ({
  moduleData,
  isView,
  handelDeleteRows,
  handleAddRow,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      <tr
        style={{
          fontSize: '1.5rem',
          background: '#ffec99',
        }}
      >
        <th colSpan={8} align="left">
          Immediate Action(s) / Correction :
        </th>
        <th>
          <CustomButton
            sx={{
              backgroundColor: '#1c7ed6',
            }}
            onClick={() => {
              handleAddRow('immediate_action_correction');
            }}
          >
            ADD ROWS
          </CustomButton>
        </th>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'S. No.',
          'Action (s)',
          'Responsibility.',
          'Target',
          'Implemented on',
          'Status',
          'Action',
        ].map((head, index) => (
          <th
            key={head}
            style={{ textTransform: 'uppercase' }}
            colSpan={index === 1 ? 3 : 1}
          >
            {head}
          </th>
        ))}
      </tr>
      {moduleData?.immediate_action_correction &&
        moduleData?.immediate_action_correction?.map(
          (item: any, index: number) => (
            <tr>
              <td>{index + 1}</td>
              <td style={{ minWidth: '300px' }} colSpan={3}>
                <TextAreaInput
                  isView={isView}
                  name="action"
                  value={item.action}
                  onChange={(e) => {
                    item.action = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '200px' }}>
                <TextAreaInput
                  isView={isView}
                  name="resp"
                  value={item.resp}
                  onChange={(e) => {
                    item.resp = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '180px' }}>
                {isView ? (
                  formatDate(item.target_date)
                ) : (
                  <DatePickerMui
                    label=""
                    onChange={(date) => {
                      item.target_date = moment(date || null);
                      setRender((prev: any) => !prev);
                    }}
                    value={
                      (item?.target_date && moment(item?.target_date)) || null
                    }
                  />
                )}
              </td>
              <td style={{ minWidth: '180px' }}>
                {isView ? (
                  formatDate(item.implemented_on_date)
                ) : (
                  <DatePickerMui
                    label=""
                    onChange={(date) => {
                      item.implemented_on_date = moment(date || null);
                      setRender((prev: any) => !prev);
                    }}
                    value={
                      (item?.implemented_on_date &&
                        moment(item?.implemented_on_date)) ||
                      null
                    }
                  />
                )}
              </td>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  item.status
                ) : (
                  <CustomSelect
                    label=""
                    name="status"
                    value={item.status}
                    onChange={(e: any) => {
                      item.status = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="">NONE</MenuItem>
                    <MenuItem value="YES">OK</MenuItem>
                    <MenuItem value="NO">NOT OK</MenuItem>
                  </CustomSelect>
                )}
              </td>
              {!isView && (
                <td style={{ minWidth: '100px', textAlign: 'center' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() =>
                      handelDeleteRows(index, 'immediate_action_correction')
                    }
                  />
                </td>
              )}
            </tr>
          )
        )}
    </tbody>
  );
};

export default TBody;
