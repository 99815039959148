import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import { MenuItem } from '@mui/material';
import moment from 'moment';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';

const Create = ({
  id,
  setIsModal,
  setListId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setListId: any;
}) => {
  const [data, setData] = useState<any>({});
  const [categoryData, setCategoryData] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Customer' : 'Create Customer'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [department, setDepartment] = useState([]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterlistinstrument/${id}`)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    axios
      .get(`/api/department`)
      .then((res) => setDepartment(res.data.results))
      .catch((err) => console.log(err));

    axios
      .get('/api/masterlistinstrument/instrumentcategory')
      .then((res) => {
        if (res.data.result) {
          setCategoryData(res.data.result);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/masterlistinstrument/${id}`, data)
        .then((res) => {
          setIsSubmit(false);
          setListId(null);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/masterlistinstrument', data)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId(null);
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };
  return (
    <>
      <AutocompleteGridmui
        label={'Instrument Name:'}
        id={'name'}
        option_name={'name'}
        value={data?.category_id || ''}
        arrayofObj={categoryData}
        onChange={(e, value) => {
          data.category_id = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <GridInputs
        id={'id_no'}
        name={'id_no'}
        html_for={'id_no'}
        label_name={'Instruments ID No:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.id_no || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'range'}
        name={'range'}
        html_for={'range'}
        label_name={'Range:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.range || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'least_count'}
        name={'least_count'}
        html_for={'least_count'}
        label_name={'LEAST COUNT:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.least_count || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'make'}
        name={'make'}
        html_for={'make'}
        label_name={'Make:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.make || ''}
        onChange={handleData}
      />
      <AutocompleteGridmui
        label={'Location:'}
        id="department_name"
        option_name={'department_name'}
        value={data?.department_id || ''}
        arrayofObj={department}
        onChange={(e, value) => {
          data.department_id = value;
          setRender((prev) => !prev);
        }}
      />

      <SelectGridMui
        name="frequency_cycle"
        value={data?.frequency_cycle || ''}
        lable={'Calibration Frequency:'}
        onChange={(e: any) => handleData(e)}
      >
        <MenuItem value="">
          <em>NONE</em>
        </MenuItem>
        <MenuItem value={'1 Month'}>1 Month</MenuItem>
        <MenuItem value={'3 Month'}>3 Month</MenuItem>
        <MenuItem value={'6 Month'}>6 Month</MenuItem>
        <MenuItem value={'12 Month'}>12 Month</MenuItem>
      </SelectGridMui>

      {/* <GridInputs
        id={'calibration_done'}
        name={'calibration_done'}
        html_for={'calibration_done'}
        label_name={'CALIBRATION DONE:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.calibration_done || ''}
        onChange={handleData}
        last_child={2}
      /> */}
      <GridInputs
        id={'calibration_lab'}
        name={'calibration_lab'}
        html_for={'calibration_lab'}
        label_name={'CALIBRATION LAB:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.calibration_lab || ''}
        onChange={handleData}
        last_child={2}
      />

      <GridDatePicker
        last_child={2}
        label_name="Calibration Due:"
        html_for={'calibration_due'}
        value={data?.calibration_due || null}
        onChange={(date) => {
          data.calibration_due = moment(date._d);
          setRender((prev) => !prev);
        }}
      />

      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'REMARKS:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.remarks || ''}
        onChange={handleData}
        last_child={2}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
