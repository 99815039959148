import axios from 'axios';
import { useState, useEffect } from 'react';
import { apiConstant } from '../constant';
import useConfirm from '../../../../components/common/useConfirm';

const useGetData = (id: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSync, setIsSync] = useState(false);
  const [Sync, confirmSync] = useConfirm(
    'SYNC',
    'Are you sure you want to sync? Save any changes before sync!'
  );
  const handelSync = async () => {
    const ans = await confirmSync();
    if (!ans) return;
    setIsLoading(true);
    axios
      .get(`/api/${apiConstant.sync}/${id}`)
      .then((res) => {
        window.alert('Sync Succesfully');
        setIsSync((prev) => !prev);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      axios
        .get(`/api/${apiConstant.getPlanByid}/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, [isSync]);

  return { moduleData, isLoading, error, handelSync, Sync };
};

export default useGetData;
