import TableCompHead from '../../../../../../components/ui/TableCompHead';

const THead = () => {
  return (
    <thead>
      <TableCompHead
        colSpan={3}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading=""
      />
      <tr>
        {['S No.', 'Required Skill Level', 'Description'].map((item) => (
          <th
            align="center"
            style={{
              border: '1px solid black',
              textAlign: 'center',
              height: '50px',
              width: item === 'Topic Name' ? '800px' : '',
            }}
          >
            {item}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
