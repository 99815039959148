import TableCompHead from '../../../../../components/ui/TableCompHead';

const theadData = ['S.No', 'Lot Size (Unit)', 'Sample Size', '(2.5) AQL'];

const THead = () => {
  return (
    <thead>
      <TableCompHead
        colSpan={5}
        rowSpan={3}
        labels={['Doc No.:', 'Rev No.:', 'Eff. Date:']}
        values={['FRM-QAS-B-001', '00', '01-04-2023']}
        heading="Sampling Standard"
      />
      <tr>
        <th colSpan={5} align="left">
          Ref. IS 2500 Part 1, General Inspection Level I (Normal Inspection)
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th
            key={heading + index}
            colSpan={index === 3 ? 2 : 1}
            rowSpan={index === 3 ? 1 : 2}
          >
            {heading}
          </th>
        ))}
      </tr>
      <tr>
        <th>Ac</th>
        <th>Re</th>
      </tr>
    </thead>
  );
};

export default THead;
