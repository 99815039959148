import { AiOutlineCloudUpload } from 'react-icons/ai';
import { BsReverseListColumnsReverse } from 'react-icons/bs';
import { FaProductHunt } from 'react-icons/fa';
import { FcProcess } from 'react-icons/fc';
import { GrVmMaintenance } from 'react-icons/gr';
import {
  MdDriveFolderUpload,
  MdList,
  MdOutlineCalendarMonth,
} from 'react-icons/md';
import { TbReportSearch } from 'react-icons/tb';
const tiles = [
  {
    sNo: 1,
    link: '/common/company_roles_responsibilities_listOfProcedures?documentType=clause6ListOfProcedure',
    isLink: true,
    icon: <MdDriveFolderUpload size="5rem" color="#003566" />,
    p: 'LIST OF',
    span: 'PROCEDURE',
    isIcon: true,
  },
  // {
  //   sNo: 3,
  //   link: '/common/processAndProductAuditChecklistList',
  //   isLink: true,
  //   icon: <BsReverseListColumnsReverse size="5rem" color="#087f5b" />,
  //   p: 'Process & Product Audit',
  //   span: 'STATUS',
  //   isIcon: true,
  // },
  {
    sNo: 4,
    link: '/common/revalidationPlanTiles',
    isLink: true,
    clauseNo: 4,
    icon: <GrVmMaintenance size="5rem" color="#a12222" />,
    p: 'REVALIDATION',
    span: 'PLAN',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/masterListOfProcess',
    isLink: true,
    icon: <MdList size="5rem" color="#003566" />,
    p: 'MASTER LIST',
    span: 'OF PROCESS',
    isIcon: true,
  },
  // {
  //   sNo: 6,
  //   link: '/common/processAuditTiles',
  //   isLink: true,
  //   icon: <FcProcess size="5rem" color="#003566" />,
  //   p: 'PROCESS',
  //   span: 'AUDIT',
  //   isIcon: true,
  // },
  {
    sNo: 2,
    link: '/common/mispPartTable',
    isLink: true,
    icon: <MdOutlineCalendarMonth size="5rem" color="#343a40" />,
    p: 'MIS-P',
    span: 'STANDARDS',
    isIcon: true,
  },
  {
    sNo: 7,
    link: '/common/productAuditPlanTiles',
    isLink: true,
    icon: <TbReportSearch size="5rem" color="#003566" />,
    p: 'PROCESS & PRODUCT',
    span: 'AUDIT',
    isIcon: true,
  },
];

export default tiles;
