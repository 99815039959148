import React from 'react';
import {
  SpeedDial as MuiSpeedDial,
  SpeedDialAction as MuiSpeedDialAction,
  SpeedDialIcon,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { IoIosAdd } from 'react-icons/io';

const StyledSpeedDial = styled(MuiSpeedDial)(({ theme }) => ({
  '& .MuiFab-primary': {
    width: 'auto',
    minWidth: '50px',
    height: '41.7875px',
    minHeight: '41.7875px',
    padding: '20px',
    borderRadius: '4px',
    backgroundColor: '#343a40',
    '&:hover': {
      backgroundColor: '#212529',
    },
  },

  '& .MuiSpeedDial-fab': {
    borderRadius: '4px',
  },
}));

const StyledSpeedDialAction = styled(MuiSpeedDialAction)(({ theme }) => ({
  '& .MuiFab-root': {
    width: '100%',
    height: '100%',
  },
  '&:hover': {
    backgroundColor: '#c92a2a',
    color: theme.palette.common.white,
  },
}));

const actions = [
  { icon: '±', name: '±' },
  { icon: '~', name: '~' },
  { icon: 'Min', name: 'Min' },
  { icon: 'Max', name: 'Max' },
];

const SpeedDialCustom = ({ handleAction }: any) => {
  const [open, setOpen] = React.useState(false);

  return (
    <StyledSpeedDial
      sx={{
        position: 'absolute',
        right: 170,
        top: 5,
      }}
      ariaLabel="copy to clipboard"
      icon={`+ copy to clipboard`}
      direction="down"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      {actions.map((action) => (
        <StyledSpeedDialAction
          sx={{
            width: '100px',
            fontSize: '2rem',
            backgroundColor: '#edf0f2',
            color: '#c92a2a',
            borderRadius: '5px',
          }}
          key={action.name}
          icon={<span style={{ fontSize: '1.5rem' }}>{action.icon}</span>}
          tooltipTitle={action.name}
          onClick={() => {
            handleAction(action.name);
            setOpen(false); // Close the dial on action click
          }}
        />
      ))}
    </StyledSpeedDial>
  );
};

export default SpeedDialCustom;
