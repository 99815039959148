import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
  date?: any;
  setRender?: any;
  calculateOverAllRating?: any;
}

const TBodyAuditActionPlan = ({
  moduleData,
  isView,
  setRender,
  calculateOverAllRating,
}: IProps) => {
  return (
    <tbody>
      <tr style={{ background: '#d8f5a2' }}>
        <th colSpan={1} align="left">
          Audit Action Plan Submission(H)
        </th>
        <th colSpan={2}>(With in 15 Days 100% after 0%)</th>
        <th colSpan={11}> Weightage : 5% </th>
      </tr>
      <tr>
        <th align="left" style={{ minWidth: '180px' }}>
          With in 15 Days
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td key={'drsq' + 6001 + index} style={{ minWidth: '160px' }}>
            <TableInput
              isView={isView}
              name="dr"
              type="number"
              value={moduleData?.AAPS_within_15_days?.[index]}
              onChange={(e) => {
                moduleData.AAPS_within_15_days[index] = +e.target.value;
                setRender((prev: any) => !prev);
              }}
            />
          </td>
        ))}
      </tr>
      <tr>
        <th align="left" style={{ minWidth: '200px' }}>
          % 5 (H)
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td key={'drqc' + 6001 + index}>
            <TableInput
              isView={isView}
              name="cycle_time"
              type="number"
              value={moduleData?.AAPS_5_percent?.[index]}
              onChange={(e) => {
                moduleData.AAPS_5_percent[index] = +e.target.value;
                calculateOverAllRating(index);
                setRender((prev: any) => !prev);
              }}
            />
          </td>
        ))}
      </tr>
    </tbody>
  );
};

export default TBodyAuditActionPlan;
