const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.No.', style: style1 },
    { text: 'Inspection Item', style: style1 },
    { text: 'Specification', style: style1 },
    { text: 'Observation', style: style1, colspan: 5 },
    { text: 'Ok-o/Ng-x', style: style1 },
  ],
];

export const headerConstant = {
  tableCompHeadTitle: 'MACHINE REVALIDATION CHECKSHEET ',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getById: 'revalidationStatusCheckListRoute',
  post: 'revalidationStatusCheckListRoute',
  update: 'revalidationStatusCheckListRoute',
  delete: 'revalidationStatusCheckListRoute',
  masterCheckListAutoComplete:
    'revalidationMasterChecklistRoute/masterCheckListAutoComplete',
  getMasterCheckListById: 'revalidationMasterChecklistRoute',
};

export const navigationConstant = {};
