import { TableInput } from '../../../../components/common';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
  handleChangeThead: (e: any) => void;
}

const THead = ({ handleChangeThead, moduleData, isView }: IProps) => {
  const partNumber = useQuery('part_number');
  const partName = useQuery('part_name');
  return (
    <thead>
      <TableCompHead
        colSpan={29}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['FRM-ENG-08', '00', '01-04-2023']}
        heading="FAILURE MODE & EFFECT ANALYSIS"
      />
      <tr>
        <th colSpan={2} align="left">
          Prototype
        </th>
        <th align="left">Pre-Launch</th>
        <th colSpan={2} align="left">
          Production
        </th>
        <th colSpan={3} rowSpan={2} align="left">
          Key Contact / Phone :
        </th>
        <th colSpan={6} rowSpan={2} align="left">
          <TableInput
            name="key_contact"
            type="text"
            value={moduleData.key_contact}
            onChange={(e) => {
              handleChangeThead(e);
            }}
            isView={isView}
          />
        </th>
        <th rowSpan={2} colSpan={5} align="left">
          Date (Orig.)
        </th>
        <th rowSpan={2} colSpan={2} align="left"></th>
        <th rowSpan={2} colSpan={8} align="left">
          Date(Rev.):
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          FMEA Number :
        </th>
        <th colSpan={3} align="left">
          <TableInput
            name="fmea_number"
            type="text"
            value={moduleData.fmea_number}
            onChange={(e) => {
              handleChangeThead(e);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Part Number / Latest Change level:
        </th>
        <th colSpan={3} align="left">
          {partNumber}
        </th>
        <th colSpan={3} align="left">
          Core Team:
        </th>
        <th colSpan={6} align="left">
          <TableInput
            name="core_team"
            type="text"
            value={moduleData.core_team}
            onChange={(e) => {
              handleChangeThead(e);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={5} align="left">
          Customer Engineering Approval/Date( if Reqd.)
        </th>
        <th colSpan={2} align="left"></th>
        <th colSpan={8}></th>
      </tr>
      <tr style={{ height: '50px' }}>
        <th colSpan={2} align="left">
          Part Name / Description:
        </th>
        <th colSpan={3} align="left">
          {partName}
        </th>
        <th colSpan={3} align="left">
          Supplier/Plant Approval Date:
        </th>
        <th colSpan={6} align="left"></th>
        <th colSpan={5} align="left">
          Customer Quantity Approval/Date( if Reqd.)
        </th>
        <th colSpan={2} align="left"></th>
        <th colSpan={8}></th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Supplier/Plant:
        </th>
        <th colSpan={3}>
          <TableInput
            name="supplier_plant"
            type="text"
            value={moduleData.supplier_plant}
            onChange={(e) => {
              handleChangeThead(e);
            }}
            isView={isView}
          />
        </th>
        <th align="left" colSpan={3}>
          Supplier Code:
        </th>
        <th align="left" colSpan={6}>
          <TableInput
            name="supplier_code"
            type="text"
            value={moduleData.supplier_code}
            onChange={(e) => {
              handleChangeThead(e);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={5} align="left">
          Other Approval Date( if reqd.):
        </th>
        <th colSpan={2} align="left"></th>
        <th colSpan={8} align="left">
          Other Approval Date( if reqd.)
        </th>
      </tr>
      <tr
        style={{
          position: 'sticky',
          width: '100%',
          top: '0rem',
          border: '1px solid black',
          zIndex: '1',
          backgroundColor: 'rgb(79, 129, 189)',
          color: '#fff',
        }}
      >
        {[
          'S.NO',
          'PROCESS FUNCTION REQUIREMENT',
          'REQUIREMENTS',
          'POTENTIAL FAILURE MODE',
          'POTENTIAL EFFECT(S) OF FAILURE',
          'SEVERITY',
          'CLASSIFICATION',
          'POTENTIAL CAUSE(S) / MECHANISM(S) OF FAILURE',
          'OCCURRENCE',
          'POTENTIAL CONTROL',
          'CURRENT PROCESS CONTROLS',
          'DETECTION',
          'R. P. N.',
          'SEV. ZONE',
          'DET. ZONE',
          'PRIORITY LEVEL',
          'RECOMMENDED ACTION(S)',
          'RESPONSIBILITY & TARGET COMPLETION DATE',
          'ACTIONS TAKEN',
          'ACTION RESULTS',
          'ACTION',
        ].map((heading) => (
          <th
            style={{ minWidth: '150px' }}
            colSpan={
              heading === 'POTENTIAL EFFECT(S) OF FAILURE'
                ? 3
                : heading === 'ACTION RESULTS'
                  ? 7
                  : 1
            }
            rowSpan={
              heading === 'POTENTIAL EFFECT(S) OF FAILURE'
                ? 1
                : heading === 'ACTION RESULTS'
                  ? 1
                  : 2
            }
          >
            {heading}
          </th>
        ))}
      </tr>
      <tr
        style={{
          position: 'sticky',
          width: '100%',
          top: '28px',
          border: '1px solid black',
          zIndex: '1',
          backgroundColor: 'rgb(79, 129, 189)',
          color: '#fff',
        }}
      >
        {[
          'NEXT OPERATION',
          'CUSTOMER END',
          'END USER',
          'SEV.',
          'OCC.',
          'DET.',
          'R.P.N',
          'SEV. ZONE',
          'DET. ZONE',
          'PRIORITY LEVEL',
        ].map((heading) => (
          <th style={{ minWidth: '100px' }}>{heading}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
