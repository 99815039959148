import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  handleDelete: any;
  isView: boolean;
  setPartIdx: any;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const TBody = ({
  setIsOpenPopup,
  setPartIdx,
  moduleData,
  isView,
  handleDelete,
}: IProps) => {
  return (
    <tbody>
      {moduleData?.map((item: any, index: number) => (
        <TBodyRow
          {...{
            setPartIdx,
            setIsOpenPopup,
            item,
            index,
            isView,
            handleDelete,
          }}
        />
      ))}
    </tbody>
  );
};

export default TBody;
