import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';
import { useEffect, useState } from 'react';
import axios from 'axios';

interface IProps {
  moduleData: IData;
  isView: boolean;
  handleSumbit: any;
  // autoCompleteMachineValue: any;
  // autoCompleteSupplierValue_1: any;
  // autoCompleteSupplierValue_2: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const TController = ({
  moduleData,
  isView,
  handleSumbit,
  // autoCompleteMachineValue,
  // autoCompleteSupplierValue_1,
  // autoCompleteSupplierValue_2,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [machineData, setMachine] = useState({});
  const [supplierData, setSupplier] = useState({});

  // useEffect(() => {
  //   axios
  //     .get('/api/machineRoutes/')
  //     .then((res) => {
  //       setMachine(res.data.results);
  //     })
  //     .catch((err) => console.log(err));

  //   axios
  //     .get('/api/supplierRoutes/')
  //     .then((res) => {
  //       setSupplier(res.data.results);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handleSumbit,
            machineData,
            supplierData,
            // autoCompleteMachineValue,
            // autoCompleteSupplierValue_1,
            // autoCompleteSupplierValue_2,
            handelDeleteRows,
            isOpenPopup,
            setIsOpenPopup,
            setPartIdx,
          }}
        />
        <TFoot
          preparedBy={moduleData.report_prepared_by}
          approvedBy={moduleData.report_approved_by}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
