import styled from 'styled-components';

interface DivStyledProps {
  fist_child?: number;
  last_child?: number;
  maxWidth?: string;
}
export const DivStyled = styled.div<DivStyledProps>`
  display: grid;
  grid-template-columns: 50% 50%;
  border: 1px solid #ced4da;
  padding: 1rem 1rem 1.2rem 2rem;
  box-sizing: border-box;
  font-size: 1.6rem;
  width: 60vw;
  max-width: ${(props) => props?.maxWidth || '800px'};

  &:nth-child(${(props) => props?.fist_child || 1}) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:nth-last-child(${(props) => props?.last_child || ''}) {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  & > label {
    min-width: 200px;
  }

  & > input {
    border-left: 2px solid #ced4da;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    padding-left: 1rem;
    font-size: 1.6rem;
  }
  & > input:focus {
    outline: none;
  }
`;
