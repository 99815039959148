import axios from 'axios';
import { useState, useEffect } from 'react';
import { apiConstant } from '../constant';
import useConfirm from '../../../../components/common/useConfirm';

const useGetData = (id: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({ list: [] });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSync, setIsSync] = useState(false);
  const [Sync, confirmSync] = useConfirm(
    'Row',
    'Are you sure you want to add row?'
  );

  const addRow = async () => {
    const ans = await confirmSync();
    if (!ans) return;
    let values = { ...moduleData };
    values.list.push({
      april: {},
      may: {},
      june: {},
      july: {},
      august: {},
      september: {},
      october: {},
      november: {},
      december: {},
      january: {},
      february: {},
      march: {},
    });
    setModuleData(values);
  };

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      axios
        .get(`/api/${apiConstant.getPlanByid}/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, [isSync]);

  return { moduleData, isLoading, error, addRow, Sync };
};

export default useGetData;
