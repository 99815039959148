import { Box, Paper, Tab, Tabs } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';

import { PersitedDatePicker } from '../../../components/common';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ModalCustom from '../../../components/common/Modal';
import { SupervisorPartTable } from '../../../components/ui';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import Summary from './components/dashboard/summary/Summary';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';

const getYearRange = (currentDate: any) => {
  if (!currentDate) return;
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};
const CustomerConcernDashboardTabs = () => {
  const date = useQuery('date') || '';
  const [tabValue, setTabValue] = useState(0);
  const [tableData, setTableData] = useState<any[]>([]);
  const [data, setData] = useState({
    dashBoardMonthCounts: [],
  });
  const [year, setYear] = useState(0);
  const [customerData, setCustomerData] = useState([]);
  const [customer, setCustomer] = useState({ _id: '', customer_name: '' });

  const fetchData = async () => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        setCustomerData(res.data);
      })
      .catch((err) => console.log(err));
    if (tabValue == 0 && date && customer._id) {
      const res = await axios
        .get(
          `/api/customerComplaintRoutes/getByMonth/?date=${date}&customerId=${customer._id}`
        )
        .catch((err) => {
          console.log(err);
        });

      setData(res?.data);
      setYear(new Date(date).getFullYear());
    }
  };
  useEffect(() => {
    fetchData();
  }, [date, customer._id, tabValue]);

  return (
    <Box>
      <ChildHeader text="CUSTOMER COMPLAINT DASHBOARD"></ChildHeader>
      <Paper elevation={2}>
        <Tabs
          value={tabValue}
          onChange={(_, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          scrollButtons="auto"
          textColor="secondary"
          variant="fullWidth"
        >
          <Tab
            label={`Customer Complaint Trend Chart ${
              customer.customer_name
            } (${getYearRange(date)})`}
          />
        </Tabs>
      </Paper>
      {
        <section>
          {tabValue === 0 && (
            <>
              <Box sx={{ margin: 2, display: 'flex' }}>
                <PersitedDatePicker label="Search By Year" views={['year']} />
                <AutocompleteMuiCustom
                  id={'customer_name'}
                  label={'Select Customer Name'}
                  option_name={'customer_name'}
                  arrayofObj={customerData}
                  value={customer}
                  onChange={(e: any, value: any) => {
                    if (value != null) setCustomer(value);
                  }}
                  sx={{ width: '250px', marginLeft: '10px' }}
                />
              </Box>

              <Summary apiData={data} year={year} /*{...{ annualDate }} */ />
            </>
          )}
          {/* {tabValue === 1 && <Type apiData={type} year={year} />}
          {tabValue === 2 && <DefectWise apiData={defects} year={year} />} */}
        </section>
      }
    </Box>
  );
};

export default CustomerConcernDashboardTabs;
