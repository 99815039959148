// TableRowComponent.jsx
import React from 'react';
import { TableInput } from '../../../../../components/common';
import { useQuery } from '../../../../../hooks/UseQuery';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';

export const TableRowComponent = React.memo(
  ({ key, item, index, allmonth, updateModuleData, handleDelete }: any) => {
    const isView = useQuery('isView') === 'true' ? true : false;
    const rowId = item?._id || item?._tempId;

    const handleInputChange = (
      fieldName: string,
      value: string | number | null,
    ) => {
      updateModuleData('row', index, fieldName, { [fieldName]: value });
    };

    return (
      <React.Fragment key={key}>
        <tr>
          <td>{index + 1}</td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="inspection_item"
              type="text"
              value={item.inspection_item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="specification"
              type="text"
              value={item.specification}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="obs_1"
              type="text"
              value={item.obs_1}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="obs_2"
              type="text"
              value={item.obs_2}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="obs_3"
              type="text"
              value={item.obs_3}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="obs_4"
              type="text"
              value={item.obs_4}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="obs_5"
              type="text"
              value={item.obs_5}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="remark"
              type="text"
              value={item.remark}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          {!isView && (
            <td style={{ minWidth: '50px' }}>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handleDelete(index)}
              />
            </td>
          )}
        </tr>
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => {
    // Check if the relevant data for this row has changed
    return (
      prevProps.item === nextProps.item && prevProps.index === nextProps.index
    );
  },
);
