import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import moment from 'moment';
import Button from '@mui/material/Button';
import ClearAllIcon from '@mui/icons-material/ClearAll';

import { FlexBox } from '../../components/common';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import InputMuiCustom from '../../components/common/inputs/InputMuiCustom';
import FormControlMui, {
  StyledField,
  StyledNotesField,
} from '../../components/common/FormControlMui';
import DatePickerMui from '../../components/common/DatePickerMui';
import NotesTextfield from '../../components/common/NotesTextfield';
import useConfirm from '../../components/common/useConfirm';
import AutocompleteMuiCustom from '../../components/common/AutocompleteMuiCustom';
import CustomSelect from '../../components/common/SelectField';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import SelectGridMui from '../../components/common/selectscustom/SelectGridMui';
interface Iprop {
  setIsNewPartModal: Dispatch<SetStateAction<boolean>>;
  id?: string | object | null;
  setPartId: React.Dispatch<React.SetStateAction<string | null>>;
}

interface Customer {
  customer_name: string;
}
interface Supplier {
  supplier_name: string;
  supplier_code: string;
}

export interface IPart {
  isLocked: boolean;
  _id?: string;
  part_number: string | null;
  part_number_ideal: string | null;
  isSubmitted: 'draft' | 'publish';
  isAdminRequestNo: number | null;
  part_name: string | null;
  dimension: string | null;
  customer_id: any;
  key_contact: string | null;
  core_team: string | null;
  phone: string | null;
  supplier_id: string | null | Supplier;
  supplier_code: string | null;
  notes: string | null;
  frequency_cycle: string | null;
  date_orig: moment.Moment;
  createdAt?: Date;
  supplier_approval_date: moment.Moment;
  Other_approval_date: moment.Moment;
  category: null | string;
  wig_no: null | string;
}

const AddEditPart = ({ setIsNewPartModal, id, setPartId }: Iprop) => {
  const [partData, setPartData] = useState<IPart>({
    part_number: null,
    part_name: null,
    part_number_ideal: '',
    customer_id: null,
    category: null,
    isSubmitted: 'draft',
    isAdminRequestNo: null,
    key_contact: null,
    dimension: null,
    phone: null,
    core_team: null,
    supplier_id: null,
    supplier_code: null,
    notes: null,
    frequency_cycle: null,
    date_orig: moment(),
    supplier_approval_date: moment(),
    Other_approval_date: moment(),
    wig_no: null,
    isLocked: false,
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [autoCompleteCustomerValue, setAutoCompleteCustomerValue] = useState(
    {}
  );
  const [autoCompleteSupplierValue, setAutoCompleteSupplierValue] = useState(
    {}
  );
  const [render, setRender] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'DRAFT PART',
    `Are you sure you want to ${id ? 'update' : 'save'} this part?`
  );

  const handlePartData = (
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ): void => {
    setPartData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  useEffect(() => {
    axios
      .get('/api/customerRoutes/')
      .then((res) => {
        setCustomerData(res.data.results);
      })
      .catch((err) => console.log(err));

    axios
      .get('/api/supplierRoutes/')
      .then((res) => {
        setSupplierData(res.data.results);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/addPartRoute/${id}`)
        .then((res) => {
          setPartData(res.data);
          setAutoCompleteSupplierValue(res.data.supplier_id);
          setAutoCompleteCustomerValue(res.data.customer_id);
        })
        .catch((err) => console.log(err));
    } else {
      setPartData((prev) => prev);
    }
  }, [id]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) return;
    setIsSubmit(true);
    if (id) {
      await axios
        .put(`/api/addPartRoute/${id}`, partData)
        .then((res) => {
          setIsSubmit(false);
          setIsNewPartModal(false);
          setPartId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsSubmit(false);
        });
    } else {
      await axios
        .post('/api/addPartRoute', partData)
        .then((res) => {
          setIsSubmit(false);
          setIsNewPartModal(false);
          setPartId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsSubmit(false);
        });
    }
  };

  return (
    <>
      <Box>
        <FormControlMui handleSubmit={onSubmit}>
          <div
            className="btn_save"
            style={{
              position: 'absolute',
              right: '140px',
              top: '-0.6px',
            }}
          >
            <SubmitButton
              label={`${id ? 'update' : 'submit'}`}
              type="submit"
              disabled={isSubmit}
            />
          </div>
          <StyledField>
            <InputMuiCustom
              type="text"
              name="part_number"
              placeholder="Customer Part Number"
              required={true}
              onChange={handlePartData}
              value={partData?.part_number || ''}
            />
            <InputMuiCustom
              type="text"
              name="part_name"
              placeholder="Part Name"
              onChange={handlePartData}
              value={partData?.part_name || ''}
              required={true}
            />
            <InputMuiCustom
              type="text"
              name="part_number_ideal"
              placeholder="Ideal Part Number"
              required={true}
              onChange={handlePartData}
              value={partData?.part_number_ideal || ''}
            />
            <AutocompleteMuiCustom
              id="customer_name"
              label="Customer"
              option_name="customer_name"
              arrayofObj={customerData}
              value={autoCompleteCustomerValue}
              onChange={(e, value) => {
                partData.customer_id = value?._id;
                setAutoCompleteCustomerValue(value);
                setRender((prev) => !prev);
              }}
            />
            <CustomSelect
              name="category"
              label="Category"
              value={partData?.category || ''}
              onChange={handlePartData}
            >
              <MenuItem value="">NONE</MenuItem>
              <MenuItem value="BOP">BOP</MenuItem>
              <MenuItem value="MIG WELDING">MIG WELDING</MenuItem>
              <MenuItem value="PLATING & POWDER COATING">
                PLATING & POWDER COATING
              </MenuItem>
              <MenuItem value="PRESS SHOP">PRESS SHOP</MenuItem>
              <MenuItem value="WELD SHOP">WELD SHOP</MenuItem>
            </CustomSelect>
            <InputMuiCustom
              type="text"
              name="key_contact"
              placeholder="Key Contact"
              onChange={handlePartData}
              value={partData?.key_contact || ''}
            />
            <InputMuiCustom
              type="text"
              name="phone"
              placeholder="Phone"
              onChange={handlePartData}
              value={partData?.phone || ''}
            />
            <InputMuiCustom
              type="text"
              name="core_team"
              placeholder="Core Team"
              onChange={handlePartData}
              value={partData?.core_team || ''}
            />

            <InputMuiCustom
              type="text"
              name="dimension"
              placeholder="Dimension"
              onChange={handlePartData}
              value={partData?.dimension || ''}
            />

            <CustomSelect
              name="frequency_cycle"
              label={'Frequency'}
              value={partData?.frequency_cycle || ''}
              onChange={handlePartData}
            >
              <MenuItem value="">
                <em>NONE</em>
              </MenuItem>
              <MenuItem value={'One Year'}>One Year</MenuItem>
              <MenuItem value={'Customer Complaint'}>
                Customer Complaint
              </MenuItem>
              <MenuItem value={'High Rejection'}>High Rejection</MenuItem>
              <MenuItem value={'ECN'}>ECN</MenuItem>
            </CustomSelect>

            <AutocompleteMuiCustom
              id="Supplier"
              label="Supplier"
              option_name="supplier_name"
              arrayofObj={supplierData}
              value={autoCompleteSupplierValue}
              onChange={(e, value) => {
                if (!value) {
                  partData.supplier_code = null;
                  partData.supplier_id = null;
                }
                partData.supplier_code = value?.supplier_code;
                partData.supplier_id = value?._id;
                setAutoCompleteSupplierValue(value);
                setRender((prev) => !prev);
              }}
            />
            <InputMuiCustom
              type="text"
              name="supplier_code"
              placeholder="Supplier Code"
              onChange={handlePartData}
              value={
                partData?.supplier_code ||
                (typeof partData.supplier_id === 'object'
                  ? partData.supplier_id?.supplier_code
                  : null)
              }
            />
            <InputMuiCustom
              type="text"
              name="wig_no"
              placeholder="Wig No."
              onChange={handlePartData}
              value={partData?.wig_no || ''}
            />
            <DatePickerMui
              label="DATE (Original)"
              onChange={(date) => {
                partData.date_orig = date;
                setRender((prev) => !prev);
              }}
              value={moment(partData.date_orig) || null}
            />
            <FlexBox style={{ gap: '1rem' }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() =>
                  setPartData((prev: any) => ({
                    ...prev,
                    date_orig: null,
                    date_rev: null,
                  }))
                }
                startIcon={<ClearAllIcon />}
              >
                RESET DATE
              </Button>
              <p style={{ fontSize: '1.2rem', margin: 0 }}>
                Click to clear/reset all dates
              </p>
            </FlexBox>
          </StyledField>
          <StyledNotesField>
            <NotesTextfield
              onChange={handlePartData}
              value={partData.notes || ''}
            />
          </StyledNotesField>
        </FormControlMui>
      </Box>
      <DialogSubmit />
    </>
  );
};

export default AddEditPart;
