import moment from 'moment';

export const processes = [
  {
    date: moment(),
    shift: null,
    part_id: null,
    rotor_id: null,
    batch_number: null,
    material_size: null,
    material_grade: null,
    operator: null,
    supplier_id: null,
    time_start: null,
    time_stop: null,
    counter_start: null,
    counter_end: null,
    total_strokes: null,
    spm: null,
    total_run_time_inHrs: null,
    avg_strokes_per_hr: null,
    total_qty: null, // production in kg
    rej_qty: null, // rejection in kg  for productionStrokes report
    verified_sign: null,
    remarks: null,
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  dailyProduction_date: new Date(),
  mc_no: null,
  processes: Array.from({ length: 5 }, () => ({ ...processes[0] })),
};
