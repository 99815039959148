import { TableInput } from '../../../../../components/common';

interface IProps {
  rowSpan: number;
  fmeaIndex: number;
  processIndex: number;
  fmea: any;
  isView: any;
  process: any;
  handleChangeTbody: (e: any, processIndex: number, fmeaIndex: number) => void;
  topThreeRpn: number[];
}
const getRpn = (fmea: any): any => {
  let value = (+fmea.detection || 1) * +fmea.occ * (+fmea.severity || 1);
  if (isNaN(value)) {
    return '';
  }
  return value;
};
const TBodyRow = ({
  rowSpan,
  process,
  processIndex,
  fmeaIndex,
  handleChangeTbody,
  fmea,
  isView,
  topThreeRpn,
}: IProps) => {
  return (
    <tr>
      {fmeaIndex === 0 && <td rowSpan={rowSpan}>{process.process_no}</td>}
      {fmeaIndex === 0 && <td rowSpan={rowSpan}>{process.process_name}</td>}
      <td>{fmea.requirement}</td>
      <td>{fmea.potential_mode}</td>
      <td>{fmea.next_operation}</td>
      <td>{fmea.customer_end}</td>
      <td>{fmea.end_user}</td>
      <td>{fmea.severity}</td>
      <td>{fmea.classification}</td>
      <td>{fmea.potential_cause}</td>
      <td>{fmea.occ}</td>
      <td>{fmea.potential_control}</td>
      <td>{fmea.current_process_control}</td>
      <td>{fmea.detection}</td>
      <td
        style={{
          backgroundColor: topThreeRpn.includes(getRpn(fmea)) ? 'yellow' : '',
        }}
      >
        {getRpn(fmea)}
      </td>
      <td>
        <TableInput
          name="sev_zone"
          type="text"
          value={fmea.sev_zone}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="det_zone"
          type="text"
          value={fmea.det_zone}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="priority_level"
          type="text"
          value={fmea.priority_level}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="recommendation_action"
          type="text"
          value={fmea.recommendation_action}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="resp_target"
          type="text"
          value={fmea.resp_target}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="action_takens"
          type="text"
          value={fmea.action_takens}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="action_result_sev"
          type="text"
          value={fmea.action_result_sev}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="action_result_occ"
          type="text"
          value={fmea.action_result_occ}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="action_result_det"
          type="text"
          value={fmea.action_result_det}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="action_result_rpn"
          type="text"
          value={fmea.action_result_rpn}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="action_result_sev_zone"
          type="text"
          value={fmea.action_result_sev_zone}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="action_result_det_zone"
          type="text"
          value={fmea.action_result_det_zone}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="action_result_priority_level"
          type="text"
          value={fmea.action_result_priority_level}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
    </tr>
  );
};

export default TBodyRow;
