import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
interface IProps {
  moduleData: any;
  isView: boolean;
  tniTopic: any;
  handleDeleteRows: any;
  employeeData: any;
}

const TController = ({
  moduleData,
  isView,
  tniTopic,
  handleDeleteRows,
  employeeData,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ tniTopic, moduleData, isView }} />
        <TBody
          {...{
            moduleData,
            tniTopic,
            employeeData,
            isView,
            handleDeleteRows,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
