import axios from 'axios';
import { useState, useEffect } from 'react';

const useGetData = (partId: string | undefined, pirId: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({
    moduleData_date: new Date(),
    pirs: [],
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [machineData, setMachineData] = useState([]);

  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setIsLoading(true);
    if (pirId) {
      axios
        .get(`/api/pirPressShopRoute/${pirId}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      axios
        .get(`/api/pirPressShopRoute/getProcessFromControlPlan/${partId}`)
        .then((res) => {
          const response = res.data;
          setModuleData((item: any) => {
            item.part_id = partId;
            item.part_name = response.part_id?.part_name;
            item.part_number = response.part_id?.part_number;
            // for temp basic
            item.revision_no = response.revision_no;
            item.revision_date = response.revision_date;
            item.moduleData_date = new Date();
            item.pirs = response.processes.map((process: any) => {
              return {
                process_name: process.process_name,
                pir_list: process.specs.map((spec: any) => ({
                  parameter: spec.char_product || spec.char_process,
                  check4_symbol: spec.check4_symbol,
                  checkbox4_num: spec.checkbox4_num,
                  specification: spec.control_product,
                  methods: spec.methods_measure,
                  appearance: spec.appearance,
                  mean: spec.mean,
                  utolerance: spec.utolerance,
                  ltolerance: spec.ltolerance,
                  utl: spec.utl,
                  ltl: spec.ltl,
                  time: '',
                  check_0_colors: Array.from(Array(4)),
                  check_0_values: Array.from(Array(4)),
                })),
              };
            });
            return { ...item };
          });
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    }
  }, []);
  return { moduleData, isLoading, error, machineData };
};

export default useGetData;
