export const checkListList = {
  childHeader: 'REVALIDATION STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'revalidationStatusRoute/getAllSatusByYear',
  upadateListStatus: 'revalidationStatusRoute/handleStatusUpdate',
  checklistUpload: 'revalidationStatusRoute/handleUpload',
  checklistDeleteImage: 'revalidationStatusRoute/handleDeleteImage',
  machineDataForAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const navigationConstant = {
  checklistRouter: '/common/revalidationStatusCheckList',
};
