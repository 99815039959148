import {
  Box,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Tab,
  Tabs,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';

import { PersitedDatePicker } from '../../../components/common';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ModalCustom from '../../../components/common/Modal';
import { SupervisorPartTable } from '../../../components/ui';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import DefectWise from './components/dashboard/DefectWiseQty/DefectWiseQty';
import PartWise from './components/dashboard/partWiseQty/PartWiseQty';
import GridInputs from '../../../components/common/inputs/GridInputs';
import CustomSelect from '../../../components/common/SelectField';

const RejectionAnalysisDashboardTabs = () => {
  const annual_month = useQuery('annual_date') || '';
  const [tabValue, setTabValue] = useState(0);
  const [defects, setDefects] = useState<any>({
    defectsQtyArr: [],
  });
  const [parts, setParts] = useState<any>({
    partsQtyArr: [],
  });

  const fetchData = async () => {
    if (annual_month) {
      if (tabValue === 1) {
        const res = await axios
          .get(
            `/api/processRejection/dashboard/getByPartWise/?annualMonth=${annual_month}`
          )
          .catch((err) => {
            console.log(err);
          });
        setParts(res?.data);
      } else if (tabValue === 0) {
        const res = await axios
          .get(
            `/api/processRejection/dashboard/getByDefectWise/?annualMonth=${annual_month}`
          )
          .catch((err) => {
            console.log(err);
          });
        setDefects(res?.data);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [tabValue]);

  return (
    <Box>
      <ChildHeader text="PROCESS REJECTION DASHBOARD"></ChildHeader>
      <Paper elevation={2}>
        <Tabs
          value={tabValue}
          onChange={(_, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          scrollButtons="auto"
          textColor="secondary"
          variant="fullWidth"
        >
          <Tab label="Defect Wise Pareto Chart" />
          <Tab label="Part Wise Pareto Chart" />
        </Tabs>
      </Paper>
      <section>
        {tabValue === 0 && defects && defects?.defectsQtyArr && (
          <DefectWise apiData={defects?.defectsQtyArr} />
        )}
        {tabValue === 1 && parts && parts?.partsQtyArr && (
          <PartWise apiData={parts?.partsQtyArr} />
        )}
      </section>
    </Box>
  );
};

export default RejectionAnalysisDashboardTabs;
