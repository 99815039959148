export const listConstant = {
  childHeader: 'Prevantive Maintenance list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Prevantive Maintenance list',
  theadHeader: ' Prevantive Maintenance',
  extracolSpan: 3,
};

export const apiConstant = {
  // list api url
  gettableListDataurl: 'prevantiveMaintenancePlan_NEW_Route/monthlylist',
  deleteListurl: 'prevantiveMaintenancePlan_NEW_Route',
  getListPlanByid: 'prevantiveMaintenancePlan_NEW_Route',
  createListPlanByid: 'prevantiveMaintenancePlan_NEW_Route',

  // -----plan--
  sync: 'prevantiveMaintenancePlan_NEW_Route/handleSync',
  getAllPlanById: 'prevantiveMaintenancePlan_NEW_Route/allplansbyid',
  upadateCreateAllPlan:
    'prevantiveMaintenancePlan_NEW_Route/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'prevantiveMaintenancePlan_NEW_Route/maintenancePlanDeleteBulkById',
};

export const tilesConstant = {
  childHeaderText: 'Prevantive Maintenance',
};

export const navigationConstant = {
  planRouter: '/common/prevantiveMaintenance_NEW_PlanCreate',
};
