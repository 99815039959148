import { useState } from 'react';
import { TableCompHead } from '../../../../../components/ui';
import { createConstant } from '../../constant';
import { useQuery } from '../../../../../hooks/UseQuery';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const yearRange = useQuery('yearRange');
  const date = new Date(moduleData?.date_range)
    ?.getFullYear()
    .toString()
    .slice(-2);
  return (
    <thead>
      <TableCompHead
        colSpan={14 + createConstant.extracolSpan}
        rowSpan={3}
        labels={['DocNo :', 'RevNo. :', 'Eff. Date: ', 'Eff. Date: ']}
        values={['FRM-PUR-03', '00', '01-04-2023']}
        heading={`${createConstant.theadHeader} : ${yearRange}`}
      />

      {[
        'S. No.',
        'SUPPLIER NAME',
        'Type of Work',
        'Frequency',
        'PLAN VS ACTUAL',
      ].map((el, index) => (
        <th
          key={`${index}_head`}
          align="center"
          style={{
            textAlign: 'center',
            ...style1,
            minWidth: `${
              (el === 'SUPPLIER NAME' && '250px') ||
              (el === 'S. No.' && '50px') ||
              style1.minWidth
            }`,
          }}
        >
          {el}
        </th>
      ))}

      {[
        'APR.',
        'MAY',
        'JUN.',
        'JUL.',
        'AUG.',
        'SEP.',
        'OCT.',
        'NOV.',
        'DEC.',
        'JAN.',
        'FEB.',
        'MAR.',
      ].map((el, index) => (
        <th
          key={`${index}_headcol`}
          align="center"
          style={{ textAlign: 'center', ...style2 }}
        >
          {el}
          {/* -{index > 8 ? +date + 1 : date} */}
        </th>
      ))}
      <th style={{ textAlign: 'center', ...style1 }}>Remarks</th>
      <th style={{ textAlign: 'center', ...style1, minWidth: '60px' }}></th>
    </thead>
  );
};

export default THead;
