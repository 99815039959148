import axios from 'axios';
import React, { useEffect, useState } from 'react';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import { moduleConstant } from '../constant';

const ToolList = ({ item, setRowRender, partId }: any) => {
  const [toolData, setToolData] = useState([]);
  useEffect(() => {
    axios
      .get(
        `${moduleConstant.apiConstant}/getTools/AutocompleteForRecord/${partId}`,
      )
      .then((res) => {
        setToolData(res?.data);
      })
      .catch((err) => {
        console.log(err);
        setToolData([]);
      });
  }, [partId]);

  return (
    <>
      <AutocompleteMuiCustom
        id="tool_id"
        label=""
        option_name="tool_name"
        arrayofObj={toolData}
        value={item.tool_id || ''}
        onChange={(e, value) => {
          item.tool_id = value;
          setRowRender((prev: any) => !prev);
        }}
        sx={{ width: 200, margin: 2 }}
      />
    </>
  );
};

export default ToolList;
