import Admin from '../pages/adminHome/AdminHome';
import { clause4 } from './admin/clause4';
import { clause10 } from './admin/clause10';
import { clause7 } from './admin/clause7';
import { clause5 } from './admin/clause5';
import { other } from './admin/other';
import { clause18 } from './admin/clause18';
import { clause1 } from './admin/clause1';
import { clause8 } from './admin/clause8';
import { clause9 } from './admin/clause9';
import { clause3 } from './admin/clause3';
import { SupplierComplaintList } from '../pages/RIDeparment';
import { clause6 } from './admin/clause6';
import { Clause2Home } from '../pages/clause2';
import { Clause11Home } from '../pages/clause11';
import { Clause12Home } from '../pages/clause12';
import { Clause13Home } from '../pages/clause13';
import { Clause14Home } from '../pages/clause14';
import { Clause16Home } from '../pages/clause16';
import { Clause17Home } from '../pages/clause17';
import { Clause19Home } from '../pages/clause19';
// import { productions } from './admin/production';
interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}
export const IndexAdmin: routes[] = [
  {
    path: 'home',
    element: <Admin />,
  },
  {
    path: 'clause2',
    element: <Clause2Home />,
  },
  {
    path: 'clause11',
    element: <Clause11Home />,
  },
  {
    path: 'clause12',
    element: <Clause12Home />,
  },
  {
    path: 'clause13',
    element: <Clause13Home />,
  },
  {
    path: 'clause14',
    element: <Clause14Home />,
  },
  {
    path: 'clause16',
    element: <Clause16Home />,
  },
  {
    path: 'clause17',
    element: <Clause17Home />,
  },
  {
    path: 'clause19',
    element: <Clause19Home />,
  },
  {
    path: 'SupplierComplaintRegister',
    element: <SupplierComplaintList />,
  },
  // ...productions,
  ...clause1,
  ...clause3,
  ...clause4,
  ...clause5,
  ...clause6,
  ...clause7,
  ...clause8,
  ...clause9,
  ...clause10,
  ...clause18,
  ...other,
];
