import React, { useState } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { StatePagination, TableCustom } from '../../components/common';

import TableCustomisedForModal from '../../components/common/Modals/TableCustomisedForModal';
import axios from 'axios';
import useDebounce from '../../hooks/useDebounce';
import { useAuth } from '../../services/auth/AuthProvider';
import { IPart } from './AddEditPart';
import formatDate from '../../components/common/formatDate';
import { HiDocumentDuplicate } from 'react-icons/hi';
import { CustomIconButton } from '../../components/common/button/CustomIconButton';
const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Item Number', rowspan: 1, colspan: 1 },
    { text: 'Item Name/Description', rowspan: 1, colspan: 1 },
    { text: 'Created At', rowspan: 1, colspan: 1 },
    { text: 'status', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [{ value: 'part_number', name: 'A-Z' }];

const DulicatePart = ({
  setDuplicateOpenModal,
}: {
  setDuplicateOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { state } = useAuth();
  const [page, setPage] = useState(1);
  const [partData, setPartData] = useState<IPart[]>([]);
  const [perPage, setPerPage] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<string>('part_number');

  const fetchPartData = () => {
    axios
      .get(
        `/api/duplicatePart?page=${page}&sortBy=${filter}&search=${search}&perPage=${10}`
      )
      .then((res) => {
        setPerPage(res.data?.perPage);
        setPartData(res.data?.results);
        setTotalPages(res.data?.pages);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchPartData, 300, [page, filter, search]);

  const handleDuplicatePart = (partId: string, index: number) => {
    axios
      .post(`/api/duplicatePart/${partId}`)
      .then((res) => {
        alert('Part duplicated successfully');
        setDuplicateOpenModal(false);
      })
      .catch((err) => console.log(err));
  };
  return (
    <TableCustomisedForModal
      label={'search part'}
      filterOptions={filterOptions}
      {...{ search, setSearch, filter, setFilter }}
    >
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {partData &&
            partData.map((item: IPart, index: number) => (
              <React.Fragment key={item?._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item.part_number}</TableCell>
                  <TableCell align="center">{item.part_name}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.createdAt || '')}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '1.6rem',
                      outline: 'none',
                      width: '150px',
                      textAlign: 'center',
                      textTransform: 'capitalize',
                      ...(item.isSubmitted === 'draft' && {
                        backgroundColor: '#FDD835',
                        borderBottom: '1px solid #fff',
                      }),
                      ...(item.isSubmitted === 'publish' && {
                        backgroundColor: '#2b8a3e',
                        color: '#fff',
                        borderBottom: '1px solid #fff',
                      }),
                    }}
                  >
                    {item?.isSubmitted && item.isSubmitted.toUpperCase()}
                  </TableCell>
                  <TableCell align="center">
                    <CustomIconButton
                      sx={{ p: 0 }}
                      tooltipTitle={'duplicate'}
                      onClick={() => handleDuplicatePart(item._id!, index)}
                    >
                      <HiDocumentDuplicate color="#343a40" />
                    </CustomIconButton>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <StatePagination page={page} setPage={setPage} totalPage={totalPages} />
    </TableCustomisedForModal>
  );
};

export default DulicatePart;
