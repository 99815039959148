import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import formatDate from '../../../../components/common/formatDate';
import { TimePicker } from '@mui/x-date-pickers';
import CustomSelect from '../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import PickPartUi from '../../../../components/ui/PickPartUi';
import ToolList from './ToolList';

interface IProps {
  item: any;
  isView: any;
  handleDelete: any;
  index: number;
  setPartIdx: any;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const TBodyRow = ({
  setPartIdx,
  item,
  index,
  isView,
  handleDelete,
  setIsOpenPopup,
}: IProps) => {
  const [rowRender, setRowRender] = useState(false);
  const handleChange = (e: any) => {
    item[e.target.name] = e.target.value;
    setRowRender((prev: any) => !prev);
  };

  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td style={{ textAlign: 'center', minWidth: '160px' }}>
          <DatePickerMui
            label=""
            onChange={(date) => {
              item.breakdown_date = moment(date || null);
              setRowRender((prev: any) => !prev);
            }}
            value={
              (item?.breakdown_date && moment(item?.breakdown_date)) || null
            }
          />
        </td>
        <td style={{ minWidth: '150px' }}>
          {isView ? (
            formatDate(item.breakdown_time)
          ) : (
            <TimePicker
              value={moment(item.breakdown_time)}
              onChange={(date) => {
                item.breakdown_time = date;
                setRowRender((prev) => !prev);
              }}
            />
          )}
        </td>
        <td style={{ minWidth: '160px' }}>
          {item?.part_id && (
            <ToolList
              partId={item.part_id?._id || item.part_id}
              {...{ item, setRowRender }}
            />
          )}
        </td>
        <td style={{ minWidth: '160px' }}> {item?.tool_id?.tool_no}</td>
        <td style={{ minWidth: '250px' }}>
          <PickPartUi
            data={item}
            removePart={() => {
              item.tool_id = null;
              item.part_id = null;
              item.partNumber = null;
              item.partName = null;
              setRowRender((prev) => !prev);
            }}
            addPart={() => {
              setIsOpenPopup(true);
              setPartIdx(index);
            }}
            isView={isView}
          />
        </td>
        <td style={{ minWidth: '160px' }}>
          {item?.tool_id?.part_id?.customer_id?.customer_name}
        </td>
        <td style={{ textAlign: 'center', minWidth: '200px', width: '200px' }}>
          <TableInput
            isView={isView}
            type="text"
            name="reason"
            value={item?.reason ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center', minWidth: '200px', width: '200px' }}>
          <TableInput
            isView={isView}
            type="text"
            name="action_taken"
            value={item?.action_taken ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center', minWidth: '160px' }}>
          <DatePickerMui
            label=""
            onChange={(date) => {
              item.bd_rectified_date = moment(date || null);
              setRowRender((prev: any) => !prev);
            }}
            value={
              (item?.bd_rectified_date && moment(item?.bd_rectified_date)) ||
              null
            }
          />
        </td>
        <td style={{ minWidth: '150px' }}>
          {isView ? (
            formatDate(item.bd_rectified_time)
          ) : (
            <TimePicker
              value={moment(item.bd_rectified_time)}
              onChange={(date) => {
                item.bd_rectified_time = date;
                setRowRender((prev) => !prev);
              }}
            />
          )}
        </td>
        <td style={{ textAlign: 'center', width: '150px', minWidth: '150px' }}>
          <CustomSelect
            name="dropdown"
            value={item.status || ''}
            onChange={(e) => {
              item.status = e.target.value;
              setRowRender((prev) => !prev);
            }}
            sx={{
              minWidth: '90px',
              textAlign: 'center',
            }}
          >
            <MenuItem value="">NONE</MenuItem>
            <MenuItem value="OK">OK</MenuItem>
            <MenuItem value="NOT OK">NOT OK</MenuItem>
          </CustomSelect>
        </td>
        <td style={{ textAlign: 'center', width: '150px', minWidth: '150px' }}>
          <TableInput
            isView={isView}
            type="text"
            name="idle_time"
            value={item?.idle_time ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center', width: '150px', minWidth: '150px' }}>
          <TableInput
            isView={isView}
            type="text"
            name="sign"
            value={item?.sign ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="remarks"
            value={item?.remarks ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        {!isView && (
          <td style={{ textAlign: 'center' }}>
            <DeleteIconButton
              tooltipTitle="DELETE"
              onClick={() => {
                handleDelete(item?._id, index);
              }}
            />
          </td>
        )}
      </tr>
    </>
  );
};

export default TBodyRow;
