import React, { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../../../components/common';
import { Checkbox } from '@mui/material';
import TableTextArea from '../../../../../../../components/common/tableinputs/TableTextArea';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const getTotal = (moduleData: any, type: any) => {
  if (type === 'c')
    return +moduleData?.c1?.reduce((sum: any, curr: any) => {
      if (curr.c === true) return sum + 5;
      return sum;
    }, 0);
  if (type === 'o')
    return +moduleData?.c1?.reduce((sum: any, curr: any) => {
      if (curr.o === true) return sum + 2;
      return sum;
    }, 0);
  return 0;
};
const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData &&
        moduleData?.c1?.map((process: any, index: number) => (
          <>
            {process.heading && (
              <tr style={{ background: '#adb5bd', height: '50px' }}>
                <th colSpan={6} align="left" style={{ fontSize: '20px' }}>
                  {process?.heading}
                </th>
              </tr>
            )}
            <tr>
              <td align="left" style={{ fontSize: '15px' }}>
                {process?.s_no}
              </td>
              <th align="left" style={{ fontSize: '15px' }}>
                {process?.check_point}
              </th>
              <td align="left">
                <Checkbox
                  name="c"
                  checked={process?.c}
                  onChange={(e: any) => {
                    process.c = e.target.checked;
                    if (process.c === true) {
                      process.nc = false;
                      process.o = false;
                    }
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td align="left">
                <Checkbox
                  name="o"
                  checked={process?.o}
                  onChange={(e: any) => {
                    process.o = e.target.checked;
                    if (process.o === true) {
                      process.nc = false;
                      process.c = false;
                    }
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td align="left">
                <Checkbox
                  name="nc"
                  checked={process?.nc}
                  onChange={(e: any) => {
                    process.nc = e.target.checked;
                    if (process.nc === true) {
                      process.o = false;
                      process.c = false;
                    }
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td align="left">
                <TableTextArea
                  style={{ minWidth: '250px', width: '250px' }}
                  resize={'vertical'}
                  isView={isView}
                  name="actual_observation"
                  type="text"
                  value={process.actual_observation}
                  onChange={(e: any) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
            </tr>
          </>
        ))}
      <tr style={{ height: '50px' }}>
        <th colSpan={2} style={{ fontSize: '20px' }}>
          Total Score Achieved :{' '}
          {getTotal(moduleData, 'c') + getTotal(moduleData, 'o')} / 110
        </th>
        <td style={{ fontSize: '20px' }}>{getTotal(moduleData, 'c')}</td>
        <td style={{ fontSize: '20px' }}>{getTotal(moduleData, 'o')}</td>
        <td style={{ fontSize: '20px' }}>{getTotal(moduleData, 'nc')}</td>
        <th style={{ fontSize: '20px' }}>Max. Score: 110</th>
      </tr>
    </tbody>
  );
};

export default TBody;
