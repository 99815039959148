import React, { useEffect, useState } from 'react';
import { Box, MenuItem, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';

import { PersitedDatePicker, TableCustom } from '../../../components/common';

import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { IData } from './helpers/interface';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../services/auth/AuthProvider';
import SupervisorTable from './components/tablelist/SupervisorTable';
import AdminTable from './components/tablelist/AdminTable';
import { getDailyProductionYearly } from './helpers/utils';
import { EditIconButton } from '../../../components/common/button/CustomIconButton';
import GridSelect from '../../../components/common/inputs/GridSelect';
import CustomSelect from '../../../components/common/SelectField';

const statusObj = {
  pending: 1,
  accepted: 2,
  rejected: 3,
};
const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },

    {
      text: 'MONTH & YEAR',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ACTION',
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const List = () => {
  const [monitoringList, setMoniteringList] = useState<
    { month_year: string }[]
  >([]);
  const { state } = useAuth();
  const date = useQuery('date');
  const [tableData, setTableData] = useState<IData[]>([]);
  const [render, setRender] = useState(false);
  const [type, setType] = useState('pressShop1and2');
  const { user, IS } = state;
  const navigate = useNavigate();
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE CUSTOMER',
    'Are you sure you want to delete this document?',
  );
  useEffect(() => {
    let data = getDailyProductionYearly(date);
    setMoniteringList(data);
  }, [date]);

  // useEffect(() => {
  //   let userType = '';
  //   user?.userType === IS.Admin
  //     ? (userType = 'admin')
  //     : (userType = 'supervisor');

  //   axios
  //     .get(
  //       `/api/processRejection/monthly/${userType}/${date}?documentType=${documentType}`
  //     )
  //     .then((res) => {
  //       if (res.data) setTableData(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, [render, date]);

  // const deleteDocument = async (id: string) => {
  //   const ans = await confirmDelete();
  //   if (!ans) return;
  //   axios
  //     .delete(`/api/processRejection/${id}`)
  //     .then(() => {
  //       alert('document deleted successfully');
  //       setRender((prev) => !prev);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   return;
  // };

  // const updateStatus = async (id: string, documentIndex: number) => {
  //   let isConfirm = window.confirm('Are you sure you want to update status');
  //   if (isConfirm && tableData.length > 0) {
  //     axios
  //       .patch(`/api/processRejection/updateStatus/${id}`, {
  //         is_admin_request_no: tableData[documentIndex].is_admin_request_no,
  //         report_approved_by: user?.name,
  //       })
  //       .then((res) => {
  //         if (res.data.status === 'success') {
  //           alert('Status Change successfully');
  //         }
  //         setRender((prev) => !prev);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  // const handleChangeStatus = (
  //   e: React.ChangeEvent<HTMLSelectElement>,
  //   documentIndex: number
  // ) => {
  //   const values = [...tableData];
  //   values[documentIndex].is_admin_request = null;
  //   values[documentIndex].is_admin_request_no = +e.target.value;
  //   setTableData(values);
  // };
  return (
    <>
      <ChildHeader text={'IN-HOUSE REJECTION SHEET'}>
        {/* <AddButton
          onClick={() =>
            navigate(
              `/common/createEditDailyProduction?documentType=${documentType}`,
            )
          }
          label="NEW REPORT"
        /> */}
      </ChildHeader>

      <div style={{ display: 'flex', margin: '1rem' }}>
        <Box sx={{ margin: 2 }}>
          <PersitedDatePicker label="Year" views={['year']} />
        </Box>
        <Box sx={{ margin: 2, width: '200px' }}>
          <CustomSelect
            name="type"
            label="Change Document Type"
            // html_for={'type'}
            value={type}
            onChange={(e: any) => {
              setType(e.target.value);
            }}
          >
            {/* <MenuItem>None</MenuItem> */}
            <MenuItem value="pressShop1and2">Press Shop 1 and 2</MenuItem>
            <MenuItem value="pressShop3">Press Shop 3</MenuItem>
            <MenuItem value="weldShop">Weld Shop</MenuItem>
            <MenuItem value="pressShop1and2">All</MenuItem>
          </CustomSelect>
        </Box>
      </div>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {monitoringList.map((item: any, index) => (
            <React.Fragment>
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{item.month_year}</TableCell>
                <TableCell align="center">
                  {/* <ViewIconButton */}
                  {/*   tooltipTitle="view" */}
                  {/*   onClick={() => */}
                  {/*     navigate( */}
                  {/*       `/common/create4mSummary?isView=true&annual_date=${item.month_year}` */}
                  {/*     ) */}
                  {/*   } */}
                  {/* /> */}
                  <EditIconButton
                    tooltipTitle="edit"
                    onClick={() =>
                      navigate(
                        // `/common/create4mSummary?isView=false&annual_date=${item.month_year}`
                        `/common/createEditProcessRejection?documentType=${type}&annual_date=${item.month_year}`,
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </TableCustom>
      {/* <Box sx={{ margin: 2 }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
      </Box> */}
      {/* {user?.userType === IS.Admin && (
        <AdminTable
          {...{
            handleChangeStatus,
            deleteDocument,
            updateStatus,
            statusObj,
            tableData,
          }}
        />
      )}
      {user?.userType === IS.Supervisor && (
        <SupervisorTable {...{ deleteDocument, tableData }} />
      )}
      <DialogDelete isSubmitContent={false} /> */}
    </>
  );
};

export default List;
