import React from 'react';
import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
  date?: any;
  setRender?: any;
  calculateOverAllRating?: any;
}

const TBodyQualityRating = ({
  moduleData,
  isView,
  date,
  setRender,
  calculateOverAllRating,
}: IProps) => {
  const calculateSupplierRatingData = (index: number) => {
    if (
      moduleData.QR_quantity_received[index] === undefined ||
      moduleData.QR_quantity_received[index] === null ||
      moduleData.QR_quantity_rejected[index] === undefined ||
      moduleData.QR_quantity_rejected[index] === null
    ) {
      moduleData.QR_quantity_accepted[index] = null;
      moduleData.QR_acheived_ppm[index] = null;
      moduleData.QR_30_percent[index] = null;
    } else {
      moduleData.QR_quantity_accepted[index] =
        +moduleData.QR_quantity_received[index] -
        +moduleData.QR_quantity_rejected[index];

      moduleData.QR_acheived_ppm[index] = (
        (+moduleData.QR_quantity_rejected[index] /
          (+moduleData.QR_quantity_received[index] || 1)) *
        1000000
      ).toFixed(2);

      moduleData.QR_30_percent[index] = (
        (+moduleData.QR_quantity_accepted[index] /
          (+moduleData.QR_quantity_received[index] || 1)) *
        100 *
        0.3
      ).toFixed(2);
    }
    calculateOverAllRating(index);
  };
  return (
    <tbody>
      <tr style={{ background: '#ffe066' }}>
        <th colSpan={3} align="left">
          Quality Rating (A) (Target not achieved, A=0)
        </th>
        <th colSpan={10}>Weightage : 30%</th>
      </tr>
      <tr>
        <th key={`head`} align="center">
          CRITERIA
        </th>
        {[
          '04',
          '05',
          '06',
          '07',
          '08',
          '09',
          '10',
          '11',
          '12',
          '01',
          '02',
          '03',
        ].map((el, index) => (
          <th key={`${index}_headcol`} align="center">
            {el} /{index > 8 ? +date + 1 : date}
          </th>
        ))}
      </tr>

      <tr>
        <th align="left" style={{ minWidth: '180px' }}>
          Target (PPM)
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td key={'qrtpm' + index} style={{ minWidth: '160px' }}>
            <TableInput
              isView={isView}
              name="qr"
              type="number"
              value={moduleData?.QR_target_ppm?.[index]}
              onChange={(e) => {
                moduleData.QR_target_ppm[index] = +e.target.value;
                setRender((prev: any) => !prev);
              }}
            />
          </td>
        ))}
      </tr>
      <tr>
        <th align="left" style={{ minWidth: '200px' }}>
          Quantity Received
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td key={'qrqc' + index}>
            <TableInput
              isView={isView}
              name="cycle_time"
              type="number"
              value={moduleData?.QR_quantity_received?.[index]}
              onChange={(e) => {
                moduleData.QR_quantity_received[index] = +e.target.value;
                calculateSupplierRatingData(index);
                setRender((prev: any) => !prev);
              }}
            />
          </td>
        ))}
      </tr>
      <tr>
        <th align="left" style={{ minWidth: '200px' }}>
          Quantity Rejected
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td key={'qrqr' + index}>
            <TableInput
              isView={isView}
              name="cycle_time"
              type="number"
              value={moduleData?.QR_quantity_rejected?.[index]}
              onChange={(e) => {
                moduleData.QR_quantity_rejected[index] = +e.target.value;
                calculateSupplierRatingData(index);
                setRender((prev: any) => !prev);
              }}
            />
          </td>
        ))}
      </tr>
      <tr>
        <th align="left" style={{ minWidth: '200px' }}>
          Quantity Accepted
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td key={'qrqa' + index}>
            {moduleData?.QR_quantity_accepted?.[index]}
          </td>
        ))}
      </tr>
      <tr>
        <th align="left" style={{ minWidth: '200px' }}>
          Achieved PPM
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td key={'qrapm' + index}>{moduleData?.QR_acheived_ppm?.[index]}</td>
        ))}
      </tr>
      <tr>
        <th align="left" style={{ minWidth: '200px' }}>
          %30 (A)
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td key={'qr30per' + index}>{moduleData?.QR_30_percent?.[index]}</td>
        ))}
      </tr>
    </tbody>
  );
};

export default TBodyQualityRating;
