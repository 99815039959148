import { useState } from 'react';
import { TableInput, TextAreaInput } from '../../../../components/common';
import SimpleSelectRevised from '../../../../components/common/SimpleSelectRevised';

interface IProps {
  process: any;
  isView: any;
  handleDelete: any;
  index: number;
}

const TBodyRow = ({ process, index, isView, handleDelete }: IProps) => {
  const [rowRender, setRowRender] = useState(false);

  const handleChange = (e: any, process: any) => {
    process[e.target.name] = e.target.value;
  };

  return (
    <tr>
      <td>{index + 1}</td>
      <td style={{ minWidth: '100px' }}>
        <TextAreaInput
          disabled={true}
          value={process?.pokeyoke_id?.nameofpkyk}
        />
      </td>
      <td style={{ minWidth: '100px' }}>
        <TextAreaInput
          disabled={true}
          value={process?.pokeyoke_id?.machine_id?.machine_no}
        />
      </td>
      <td style={{ minWidth: '100px' }}>
        <TextAreaInput disabled={true} value={process?.pokeyoke_id?.pkykno} />
      </td>
      <td style={{ minWidth: '80px' }}>
        <TextAreaInput disabled={true} value={process?.pokeyoke_id?.freq} />
      </td>
      <td style={{ minWidth: '180px' }}>
        <TextAreaInput
          disabled={true}
          value={process?.pokeyoke_id?.provisionalctrl}
        />
      </td>
      <td style={{ minWidth: '180px' }}>
        <TextAreaInput
          disabled={true}
          value={process?.pokeyoke_id?.reactionplan}
        />
      </td>
      {process?.results?.map((_: any, indx: number) => (
        <td
          style={{
            minWidth: '100px',
            textAlign: 'center',
            fontSize: '2rem',
          }}
          key={index + 2.2 + indx + 'after' + 111.33}
        >
          <SimpleSelectRevised
            name={`results[${indx}]`}
            value={process.results[indx]}
            onChange={(e: any) => {
              process.results[indx] = e.target.value;
              setRowRender((prev) => !prev);
            }}
            options={[
              { value: '', label: 'none' },
              { value: 'true', label: '✓' },
              { value: 'false', label: '⨯' },
            ]}
          />
        </td>
      ))}
      <td style={{ minWidth: '150px', textAlign: 'center' }}>
        <TableInput
          isView={isView}
          name="remarks"
          type="text"
          value={process.remarks}
          onChange={(e) => {
            handleChange(e, process);
            setRowRender((prev) => !prev);
          }}
        />
      </td>
    </tr>
  );
};

export default TBodyRow;
