import React, { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';

interface IProps {
  moduleData: any;
  isView: any;
}

const TFoot = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tfoot>
      <tr>
        <th align="left" colSpan={2}>
          Auditor Name & Sign. :
          <TableInput
            isView={isView}
            name="s1"
            type="text"
            value={moduleData.s1}
            onChange={(e: any) => {
              handleChangeModuleData(e, moduleData);
              setRender((prev: any) => !prev);
            }}
          />
        </th>
        <th align="left" colSpan={4}>
          Auditee Name & Sign. :
          <TableInput
            isView={isView}
            name="s2"
            type="text"
            value={moduleData.s2}
            onChange={(e: any) => {
              handleChangeModuleData(e, moduleData);
              setRender((prev: any) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
