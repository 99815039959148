import { IconButton, MenuItem, Tooltip } from '@mui/material';
import ObservationInput from './ObservationInput';
import { useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableInput } from '../../../../../components/common';
import { handleChangeColorOnRemarks } from '../../helpers/handleChangeColorOnRemarks';
import TBodyRow from './TBodyRow';
interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
}
const TBody = ({ moduleData, tabValue, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData.pirs[tabValue] &&
        moduleData.pirs[tabValue].pir_list &&
        moduleData.pirs[tabValue].pir_list.map((spec: any, index: number) => (
          <tr>
            <td>{index + 1}</td>
            <td>{spec.parameter}</td>
            <td>{spec.specification}</td>
            <td>{spec.methods}</td>
            <TBodyRow spec={spec} isView={isView} />

            <th style={{ textTransform: 'uppercase' }}>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                defaultValue={spec.remarks}
                onChange={(e) => {
                  spec.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </th>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
