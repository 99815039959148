import moment from 'moment';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import TableHeaderHtmlCustom from '../../../../../components/common/customTable/TableHeaderHtmlCustom';
import formatDate from '../../../../../components/common/formatDate';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import { cpHeaderStandard } from '../../../constant';
import { getTotalColsSpan } from '../../../helpers/utils';
import CheckBoxCustomMui from '../../common/ui/CheckBox';

interface IProps {
  moduleData: any;
}

const THead = ({ moduleData }: IProps) => {
  return (
    <thead>
      <TableCompHead
        colSpan={getTotalColsSpan(cpHeaderStandard)}
        rowSpan={3}
        labels={[]}
        values={[]}
        heading="CONTROL PLAN"
      />
      <tr>
        <th
          data-val={
            moduleData.part_type === 'prototype'
              ? '✔ Proto Type'
              : 'Proto Type'
          }
          colSpan={2}
        >
          <CheckBoxCustomMui
            name="prototype"
            label="Proto Type"
            checked={moduleData.part_type === 'prototype'}
          />
        </th>
        <th
          data-val={
            moduleData.part_type === 'pre-Launch'
              ? '✔ Pre-Launch'
              : 'Pre-Launch'
          }
          colSpan={2}
        >
          <CheckBoxCustomMui
            name="change_type"
            label="Pre-Launch"
            checked={moduleData.part_type === 'pre-Launch'}
          />
        </th>
        <th
          data-val={
            moduleData.part_type === 'production'
              ? '✔ Production'
              : 'Production'
          }
          colSpan={2}
        >
          <CheckBoxCustomMui
            name="change_type"
            label="Production"
            checked={moduleData.part_type === 'production'}
          />
        </th>
        <th colSpan={2}>KEY CONTACT / PHONE</th>
        <td colSpan={5} align="left">
          {moduleData?.part_id?.key_contact}
        </td>
        <th colSpan={2}>COMPANY CODE - MODEL </th>
        <td colSpan={5} align="left">
          {moduleData?.compnay_code}
        </td>
      </tr>
      <tr
        style={{
          height: '50px',
        }}
      >
        <th colSpan={2}>PART NAME</th>
        <td colSpan={4} align="left">
          {moduleData?.part_id?.part_name}
        </td>
        <th colSpan={2}>CORE TEAM</th>
        <td colSpan={5} align="left">
          {moduleData?.part_id?.core_team}
        </td>

        <th colSpan={2}>ORG. PREPARATION DATE</th>
        <td colSpan={5} align="left">
          {formatDate(moduleData?.part_id?.date_orig)}
        </td>
      </tr>
      <tr
        style={{
          height: '50px',
        }}
      >
        <th colSpan={2}>PART NO.</th>
        <td colSpan={4} align="left">
          {moduleData?.part_id?.part_number}
        </td>
        <th colSpan={2}>CUSTOMER QUALITY APPROVAL & DATE</th>
        <td colSpan={5} align="left">
          {formatDate(moduleData.customer_quality_approval_date)}
        </td>
        <th colSpan={2}>OTHER APPROVAL & DATE</th>
        <td colSpan={5} align="left">
          {formatDate(moduleData.supplier_other_approval_date)}
        </td>
      </tr>
      <tr
        style={{
          height: '50px',
        }}
      >
        <th colSpan={2}>CUSTOMER NAME</th>
        <td colSpan={4} align="left">
          {moduleData?.part_id?.customer_id?.customer_name}
        </td>
        <th colSpan={2}>CUSTOMER PRODUCT NAME</th>
        <td colSpan={5} align="left">
          {moduleData?.customer_product_name}
        </td>

        <th colSpan={2}>CONTROL PLAN NO.</th>
        <td colSpan={5} align="left">
          {moduleData?.control_plan_no}
        </td>
      </tr>
      <tr
        style={{
          height: '50px',
        }}
      >
        <th colSpan={2}>CP REV. No.</th>
        <td colSpan={4} align="left">
          {moduleData?.revision_no}
        </td>
        <th colSpan={2}>CUSTOMER PRODUCT NUMBER</th>
        <td colSpan={5} align="left">
          {moduleData?.customer_product_number}
        </td>

        <th colSpan={2}>CP REV. Date.</th>
        <td colSpan={5} align="left">
          {formatDate(moduleData?.revision_date)}
        </td>
      </tr>

      <TableHeaderHtmlCustom tableHeaderObj={cpHeaderStandard} />
    </thead>
  );
};

export default THead;
