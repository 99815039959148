export const checkListList = {
  childHeader: 'PROCESS AUDIT PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'processAuditPlanStatusRoute/getAllSatusByYear',
  upadateListStatus: 'processAuditPlanStatusRoute/handleStatusUpdate',
  checklistUpload: 'processAuditPlanStatusRoute/handleUpload',
  checklistDeleteImage: 'processAuditPlanStatusRoute/handleDeleteImage',
  forAutoComplete:
    'masterListOfProcessRoute/processList/processForAutoComplete',
};

export const navigationConstant = {
  checklistRouter: '/common/processAuditPlan_StatusCheckList',
};
