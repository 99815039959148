import { TableCompHead } from '../../../../../../components/ui';

const THead = () => {
  return (
    <thead>
      <TableCompHead
        colSpan={5}
        rowSpan={3}
        labels={['Doc. No.: ', 'Rev. No.:', 'Eff. Date:']}
        values={['FRM-MNT-B-013', '00', '01-04-2023']}
        heading={'TOOL PREV. MAINT. CHECK SHEET FORMAT'}
      />

      <tr>
        {['S.No', 'Parameter', 'Specification', 'Method', 'Action'].map(
          (heading) => (
            <th key={heading}>{heading}</th>
          )
        )}
      </tr>
    </thead>
  );
};

export default THead;
