export const listConstant = {
  childHeader: 'MSA plan list',
  addButtonLable: 'New MSA',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'MSA plan list',
  theadHeader: 'MSA PLAN',
  extracolSpan: 6,
};

export const checkListList = {
  childHeader: 'MSA STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'msaPlan',
  gettableDataurl: 'msaPlan/monthly',
  getPlanByid: 'msaPlan',
  createPlanByid: 'msaPlan',
  updatePlanByid: 'msaPlan',
  sync: 'msaPlan/handleSync',
  getAllCheckList: 'msaPlan/getAllCheckList',
  checkListStatus: 'msaPlan/handleStatus',
  checklistUpload: 'msaPlan/handleUpload',
  checklistDeleteImage: 'msaPlan/handleDeleteImage',
};

export const navigationConstant = {
  createPlanSchedule: '/common/msaplancreate',
};
