const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.No.', style: style1 },
    { text: 'Parameter', style: style1 },
    { text: 'Specification', style: style1 },
    { text: 'Method', style: style1 },
    // { text: 'Observation Before', style: style1, colspan: 5 },
    { text: 'Result', style: style1 },
    // { text: 'Date', style: style1 },
    // { text: 'Next Due Date', style: style1 },
    { text: 'Sign.', style: style1 },
    { text: 'Remarks', style: style1 },
  ],
];

export const headerConstant = {
  tableCompHeadTitle: 'GAUGE MAINTENANCE CHECKSHEET ',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getById: 'statusCheckListRouteGaugeMaintenancePlan',
  post: 'statusCheckListRouteGaugeMaintenancePlan',
  update: 'statusCheckListRouteGaugeMaintenancePlan',
  delete: 'statusCheckListRouteGaugeMaintenancePlan',
  masterCheckListAutoComplete:
    'masterCheckListRouteGaugeMaintenancePlan/masterCheckListAutoComplete',
  getMasterCheckListById: 'masterCheckListRouteGaugeMaintenancePlan',
};

export const navigationConstant = {};
