import { TableCell, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { SearchBar, TableCustom } from '../../../components/common';
import {
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import formatDate from '../../../components/common/formatDate';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
interface IData {
  _id?: string;
  drawing_name: null | string;
  rev_no: null | number;
  checked_on?: Date;
  createdAt?: Date;
  description?: string;
}
interface IProps {
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  isOpenPopup: boolean;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFileOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
}
const TableList = ({
  setId,
  isOpenPopup,
  setIsOpenPopup,
  setIsFileOpenPopup,
}: IProps) => {
  const [data, setData] = useState([]);
  const [render, setRender] = useState(false);
  const documentType = useQuery('documentType');
  const { state } = useAuth();
  const search = useQuery('search');
  const tableHeaderObj = [
    [
      { text: '#', rowspan: 1, colspan: 1 },
      { text: 'DOCUMENT NAME', rowspan: 1, colspan: 1 },
      { text: 'REV NO.', rowspan: 1, colspan: 1 },
      {
        text: documentType === 'wiUpload' ? 'PROCESS' : 'DESCRIPTION',
        rowspan: 1,
        colspan: 1,
      },
      { text: 'CHECKED ON', rowspan: 1, colspan: 1 },
      { text: 'DATE UPLOAD', rowspan: 1, colspan: 1 },
      { text: 'ACTION', rowspan: 1, colspan: 1 },
    ],
  ];
  useEffect(() => {
    axios
      .get(`/api/listOfProcedures/list/${documentType}?search=${search}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [render, isOpenPopup, search]);
  const deleteDocument = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this document?',
    );
    if (isDeleted) {
      axios
        .delete(`/api/listOfProcedures/${id}`)
        .then(() => {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  return (
    <Box>
      <Box sx={{ margin: 2 }}>
        <SearchBar label="Search Document Name" />
      </Box>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        {data.map((drawing: IData, index: number) => (
          <TableRow key={drawing._id}>
            <TableCell align="center">{index + 1}</TableCell>
            <TableCell align="center">{drawing.drawing_name}</TableCell>
            <TableCell align="center">{drawing.rev_no}</TableCell>
            <TableCell align="center">{drawing.description}</TableCell>
            <TableCell align="center">
              {formatDate(drawing?.checked_on || '')}
            </TableCell>
            <TableCell align="center">
              {formatDate(drawing?.createdAt || '')}
            </TableCell>
            <TableCell align="center">
              <ViewIconButton
                tooltipTitle="view"
                onClick={() => {
                  setId(drawing._id || null);
                  setIsFileOpenPopup(true);
                }}
              />
              {state.user?.userType === 2 && (
                <>
                  <EditIconButton
                    tooltipTitle="EDIT"
                    onClick={() => {
                      setId(drawing?._id! || null);
                      setIsOpenPopup(true);
                    }}
                  />
                  <DeleteIconButton
                    tooltipTitle="DELETE"
                    onClick={() => {
                      deleteDocument(drawing._id || '');
                    }}
                  />
                </>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableCustom>
    </Box>
  );
};

export default TableList;
