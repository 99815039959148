import { IconButton, MenuItem, Tooltip } from '@mui/material';
import ObservationInput from './ObservationInput';
import { useState } from 'react';
import CustomSelect from '../../../../components/common/SelectField';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableInput } from '../../../../components/common';
import { handleChangeColorOnRemarks } from '../../helpers/handleChangeColorOnRemarks';

interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
  cavity: any;
  setCavity: any;
}
const TBody = ({ moduleData, tabValue, isView, cavity, setCavity }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData.pirs[tabValue] &&
        moduleData.pirs[tabValue].pir_list &&
        moduleData.pirs[tabValue].pir_list.map((spec: any, index: number) =>
          // for cavities
          Array.from({ length: cavity }, () => null).map((_, cavityIndex) => (
            <tr key={`${cavityIndex + 'cavityIndex'}`}>
              {cavityIndex === 0 && <td rowSpan={cavity}>{index + 1}</td>}
              {cavityIndex === 0 && <td rowSpan={cavity}>{spec.parameter}</td>}
              {cavityIndex === 0 && (
                <td rowSpan={cavity}>{spec.specification}</td>
              )}
              {cavityIndex === 0 && <td rowSpan={cavity}>{spec.methods}</td>}

              {Array.from({ length: 5 }, () => null).map((_, obsIndex) => (
                <ObservationInput
                  key={`${obsIndex + 'obs'}`}
                  {...{ spec, isView }}
                  obsIndex={obsIndex}
                  cavityIndex={cavityIndex}
                  checkType={spec.appearance}
                />
              ))}

              {cavityIndex === 0 && (
                <th rowSpan={cavity} style={{ textTransform: 'uppercase' }}>
                  {isView &&
                    (spec.remarks === 'other'
                      ? spec.remarks_other
                      : spec.remarks)}
                  {!isView &&
                    (spec.remarks === 'other' ? (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                          }}
                        >
                          <TableInput
                            isView={isView}
                            name="cycle_time"
                            type="text"
                            defaultValue={spec.remarks_other}
                            onChange={(e) => {
                              spec.remarks_other = e.target.value;
                              setRender((prev) => !prev);
                            }}
                          />
                          <Tooltip title="Delete">
                            <IconButton
                              aria-label="delete"
                              onClick={(e) => {
                                spec.remarks_other = '';
                                spec.remarks = '';
                                setRender((prev) => !prev);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </>
                    ) : (
                      <CustomSelect
                        name="dropdown"
                        value={spec.remarks || ''}
                        onChange={(e) => {
                          spec.remarks = e.target.value;
                          handleChangeColorOnRemarks(spec, spec.remarks);
                          setRender((prev) => !prev);
                        }}
                      >
                        <MenuItem value="none">NONE</MenuItem>
                        <MenuItem value="ok">OK</MenuItem>
                        <MenuItem value="under deviation">
                          UNDER DEVIATION
                        </MenuItem>
                        <MenuItem value="tbc">TBC</MenuItem>
                        <MenuItem value="operation not done">
                          OPERATION NOT DONE
                        </MenuItem>
                        <MenuItem value="other">OTHER</MenuItem>
                      </CustomSelect>
                    ))}
                </th>
              )}
            </tr>
          ))
        )}
    </tbody>
  );
};

export default TBody;
