import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { Navbar } from '../../components/ui';
import { useAuth } from '../../services/auth/AuthProvider';
import Footer from '../../components/ui/footer/Footer';

export const AdminProtectedRoute = () => {
  const location = useLocation();
  const { state } = useAuth();

  if (state.user?.userType === state.IS.Admin) {
    return (
      <>
        <Navbar />
        <Outlet />
        <Footer />
      </>
    );
  }
  return <Navigate to="/" state={{ from: location }} replace />;
};

export const SupervisorProtectedRoute = () => {
  const location = useLocation();
  const { state } = useAuth();

  if (state.user?.userType === state.IS.Supervisor) {
    return (
      <>
        <Navbar />
        <Outlet />
        <Footer />
      </>
    );
  }

  return <Navigate to="/" state={{ from: location }} replace />;
};

export const CommonProtectedRoutes = () => {
  const location = useLocation();
  const { state } = useAuth();

  if (state.user) {
    return (
      <>
        <Navbar />
        <Outlet />
        <Footer />
      </>
    );
  }
  return <Navigate to="/" state={{ from: location }} replace />;
};
