import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import { IData } from './helpers/Interface';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [customerData, setCustomerData] = useState<IData>({
    customer_name: null,
    vendor_code: '',
    product: null,
    type_of_industry: null,
    remarks: null,
  });
  const [isSubmit, setIsSubmit] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Customer' : 'Create Customer'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/customerRoutes/${id}`)
        .then((res) => setCustomerData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleCustomerData(e: React.ChangeEvent<HTMLInputElement>) {
    setCustomerData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/customerRoutes/${id}`, customerData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/customerRoutes', customerData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'customer_name'}
        name={'customer_name'}
        html_for={'customer_name'}
        label_name={'Customer Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={customerData?.customer_name || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'vendor_code'}
        name={'vendor_code'}
        html_for={'vendor_code'}
        label_name={'Vendor Code:'}
        input_type={'text'}
        focusType={'onblur'}
        value={customerData?.vendor_code || ''}
        onChange={handleCustomerData}
      />
      {/* <GridInputs
        id={'product'}
        name={'product'}
        html_for={'product'}
        label_name={'Product:'}
        input_type={'text'}
        focusType={'onblur'}
        value={customerData?.product || ''}
        onChange={handleCustomerData}
      /> */}
      {/* <GridInputs
        id={'type_of_industry'}
        name={'type_of_industry'}
        html_for={'type_of_industry'}
        label_name={'Type Of Industry:'}
        input_type={'text'}
        focusType={'onblur'}
        value={customerData?.type_of_industry || ''}
        onChange={handleCustomerData}
      /> */}
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={customerData?.remarks || ''}
        onChange={handleCustomerData}
        last_child={2}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
