import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import moment from 'moment';
import AlertMui from '../../../../components/common/AlertMui';

import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import InputMuiCustom from '../../../../components/common/inputs/InputMuiCustom';
import FormControlMui, {
  StyledField,
} from '../../../../components/common/FormControlMui';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import useConfirm from '../../../../components/common/useConfirm';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import TableInput from '../../../../components/common/TableInput';
interface Iprop {
  setIsNewPartModal: Dispatch<SetStateAction<boolean>>;
  id?: string | object | null;
  setPartId: React.Dispatch<React.SetStateAction<string | null>>;
}

export interface IPart {
  _id?: string;
  vendor_code?: string | null;
  part_number: string | null;
  part_name: string | null;
  vendor_id: any;
  date_orig: moment.Moment;
  createdAt?: Date;
  material?: string | null;
}

const AddEditPart = ({ setIsNewPartModal, id, setPartId }: Iprop) => {
  const [partData, setPartData] = useState<IPart>({
    part_number: null,
    part_name: '',
    vendor_id: null,
    date_orig: moment(),
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [supplierData, setSupplierData] = useState([]);
  const [autoCompleteSupplierValue, setAutoCompleteVendorValue] = useState({});
  const [render, setRender] = useState(false);
  const [alert, setAlert] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'DRAFT PART',
    `Are you sure you want to ${id ? 'update' : 'save'} this part?`,
  );

  const handlePartData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPartData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    axios
      .get('/api/vendorRoute/')
      .then((res) => {
        setSupplierData(res.data.results);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/riDepartment/riPart/${id}`)
        .then((res) => {
          setPartData({
            ...res.data,
            vendor_code: res.data?.vendor_id?.vender_code,
          });
          setAutoCompleteVendorValue(res.data.vendor_id);
        })
        .catch((err) => console.log(err));
    } else {
      setPartData((prev) => prev);
    }
  }, [id]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    // if (!partData.vendor_id) {
    //   setAlert(true);
    //   return;
    // }

    const ans = await confirmSubmit();
    if (!ans) return;
    setIsSubmit(true);
    if (id) {
      await axios
        .put(`/api/riDepartment/riPart/${id}`, partData)
        .then((res) => {
          setIsSubmit(false);
          setIsNewPartModal(false);
          setPartId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsSubmit(false);
        });
    } else {
      await axios
        .post('/api/riDepartment/riPart', partData)
        .then((res) => {
          setIsSubmit(false);
          setIsNewPartModal(false);
          setPartId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsSubmit(false);
        });
    }
  };

  return (
    <>
      <Box>
        <FormControlMui handleSubmit={onSubmit}>
          <div
            className="btn_save"
            style={{
              position: 'absolute',
              right: '140px',
              top: '-0.6px',
            }}
          >
            <SubmitButton
              label={`${id ? 'update' : 'submit'}`}
              type="submit"
              disabled={isSubmit}
            />
          </div>
          <StyledField>
            <InputMuiCustom
              type="text"
              name="part_number"
              placeholder="Part Name"
              required={true}
              onChange={handlePartData}
              value={partData?.part_number || ''}
            />
            {/* <InputMuiCustom
              type="text"
              name="part_name"
              placeholder="Customer Part Name"
              onChange={handlePartData}
              value={partData?.part_name || ''}
              required={true}
            /> */}
            <AutocompleteMuiCustom
              id="Vendor"
              label="Vendor"
              option_name="vender_name"
              arrayofObj={supplierData}
              value={autoCompleteSupplierValue}
              onChange={(e, value) => {
                if (!value) {
                  partData.vendor_code = null;
                  partData.vendor_id = null;
                }
                partData.vendor_id = value?._id;
                partData.vendor_code = value?.vender_code;
                setAutoCompleteVendorValue(value);
                setRender((prev) => !prev);
              }}
            />
            <InputMuiCustom
              type="text"
              name="vendor_code"
              placeholder="Vendor Code"
              // disabled
              value={partData?.vendor_code || ''}
            />
            <InputMuiCustom
              type="text"
              name="material"
              placeholder="Material"
              onChange={handlePartData}
              value={partData?.material || ''}
            />
            <DatePickerMui
              label="DATE (Original)"
              onChange={(date) => {
                partData.date_orig = date;
                setRender((prev) => !prev);
              }}
              value={moment(partData.date_orig) || null}
            />
          </StyledField>
        </FormControlMui>
      </Box>
      <DialogSubmit />
      <AlertMui
        alert={alert}
        message={
          'Please select a customer from the list. The customer field cannot be left empty'
        }
        setAlert={setAlert}
        severity={'warning'}
      />
    </>
  );
};

export default AddEditPart;
