import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
interface IProps {
  moduleData: any;
  isView: boolean;
  machineData:any;
}

const TController = ({ moduleData, isView ,machineData}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            machineData
          }}
        />
        <TBody {...{ moduleData,  machineData,isView }} />
        {/* <TFoot */}
        {/*   preparedBy={moduleData.report_prepared_by} */}
        {/*   approvedBy={moduleData.report_approved_by} */}
        {/* /> */}
      </RenderTable>
    </Box>
  );
};

export default TController;
