export const potentialMode = [
  { name: 'Angle Over Size', value: 'Angle Over Size' },
  { name: 'Angle Under Size', value: 'Angle Under Size' },
  { name: 'Bending O/S', value: 'Bending O/S' },
  { name: 'Bending U/S', value: 'Bending U/S' },
  { name: 'Blank O/S', value: 'Blank O/S' },
  { name: 'Blank U/S', value: 'Blank U/S' },
  { name: 'Black', value: 'Black' },
  { name: 'Black Spot', value: 'Black Spot' },
  { name: 'Blister', value: 'Blister' },
  { name: 'Burr', value: 'Burr' },
  { name: 'CD Over Size', value: 'CD Over Size' },
  { name: 'CD Under Size', value: 'CD Under Size' },
  { name: 'Chamfer  O/S', value: 'Chamfer  O/S' },
  { name: 'Chamfer U/S', value: 'Chamfer U/S' },
  { name: 'Crack', value: 'Crack' },
  { name: 'Creck', value: 'Creck' },
  { name: 'Damage', value: 'Damage' },
  { name: 'Dent', value: 'Dent' },
  { name: 'Dia O/S', value: 'Dia O/S' },
  { name: 'Dia U/S', value: 'Dia U/S' },
  { name: 'Different Passivation', value: 'Different Passivation' },
  { name: 'Dimension  NG', value: 'Dimension  NG' },
  { name: 'Dimension  O/S', value: 'Dim O/S' },
  { name: 'Dimension U/S', value: 'Dim U/S' },
  { name: 'Dimple Dia O/S', value: 'Dimple Dia O/S' },
  { name: 'Dimple Dia U/S', value: 'Dimple Dia U/S' },
  { name: 'Dull Plating', value: 'Dull Plating' },
  { name: 'Grade Variation', value: 'Grade Variation' },
  { name: 'Height O/S', value: 'Height O/S' },
  { name: 'Height U/S', value: 'Height U/S' },
  { name: 'Hole Dia  O/S', value: 'Hole Dia  O/S' },
  { name: 'Hole Dia  U/S', value: 'Hole Dia  U/S' },
  { name: 'Length  O/S', value: 'Length  O/S' },
  { name: 'Length  U/S', value: 'Length  U/S' },
  { name: 'Parting Profile  U/S', value: 'Parting Profile  U/S' },
  { name: 'Parting Profile  O/S', value: 'Parting Profile  O/S' },
  { name: 'Profile O/S', value: 'Profile O/S' },
  { name: 'Profile U/S', value: 'Profile U/S' },
  { name: 'Punching O/S', value: 'Punching O/S' },
  { name: 'Punching U/S', value: 'Punching U/S' },
  { name: 'Radius O/S', value: 'Radius O/S' },
  { name: 'Radius U/S', value: 'Radius U/S' },
  { name: 'Raw Material Defects', value: 'Raw Material Defects' },
  { name: 'Restriking Profile  O/S', value: 'Restriking Profile  O/S' },
  { name: 'Restriking Profile  U/S', value: 'Restriking Profile  U/S' },
  { name: 'Rust', value: 'Rust' },
  { name: 'Scratches', value: 'Scrateches' },
  { name: 'Slot O/S', value: 'Slot O/S' },
  { name: 'Slot U/S', value: 'Slot U/S' },
  { name: 'Shearing Length O/S', value: 'Shearing Length O/S' },
  { name: 'Thickness O/S', value: 'Thickness O/S' },
  { name: 'Thickness U/S', value: 'Thickness U/S' },
  { name: 'Thickness Variation', value: 'Thickness Variation' },
  { name: 'Trimming Outer Profile O/S', value: 'Trimming Outer Profile O/S' },
  { name: 'Trimming Outer Profile U/S', value: 'Trimming Outer Profile U/S' },
  { name: 'Water Mark', value: 'Water Mark' },
  { name: 'Welding Flash', value: 'Welding Flash' },
  { name: 'Welding Strength Low', value: 'Welding Strength Low' },
  { name: 'Welding Nugget', value: 'Welding Nugget' },
  { name: 'Welding Spatter', value: 'Welding Spatter' },
  { name: 'Welding Stangth', value: 'Welding Stangth' },
  { name: 'Width O/S', value: 'Width O/S' },
  { name: 'Width U/S', value: 'Width U/S' },
  { name: 'Width Variation', value: 'Width Variation' },
  { name: 'Wrong Packing', value: 'Wrong Packing' },
];
