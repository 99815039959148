import formatDate from '../../../../../components/common/formatDate';
import TableCompHead from '../../../../../components/ui/TableCompHead';

interface IProps {
  moduleData: any;
}

const THead = ({ moduleData }: IProps) => {
  return (
    <thead>
      <TableCompHead
        colSpan={22}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading=""
      />
      <tr>
        <th colSpan={6} align="left">
          Part Name:
          {moduleData?.part_id?.part_name}
        </th>
        <th colSpan={16} rowSpan={2} style={{ fontSize: '2rem' }}>
          Process Quality Control Table (II){' '}
        </th>
      </tr>

      <tr>
        <th colSpan={6} align="left">
          Part No.:
          {moduleData?.part_id?.part_number}
        </th>
      </tr>
      {/* body main header start  */}
      <tr style={{ height: '30px' }}>
        <th rowSpan={4}>MFG Order</th>
        <th colSpan={1}></th>
        <th
          colSpan={1}
          rowSpan={4}
          style={{ transform: 'rotate(-90deg)', width: '30px' }}
        >
          Failure Mode
        </th>
        <th rowSpan={4} style={{ transform: 'rotate(-90deg)', width: '30px' }}>
          Critical Item
        </th>
        <th colSpan={9} rowSpan={2}>
          Quality Characteristic
        </th>
        <th rowSpan={4} style={{ transform: 'rotate(-90deg)', width: '30px' }}>
          Critical Item
        </th>
        <th colSpan={7} rowSpan={2}>
          Production Condition Control{' '}
        </th>
        <th rowSpan={4}>Illustration</th>
      </tr>
      <tr>
        <th>Process Name</th>
      </tr>
      <tr>
        <th>Equipment</th>
        {[
          'NO',
          'Control Point',
          'Specification',
          'Checking Person Incharge',
          'Check Method',
          'Checking Frequency',
          'Data record form no.',
          'Data Recording ',
          'Submission frequency of Data',
          'Control point',
          'Control Value',
          'Person incharge',
          'Check Method',
          'Checking Frequency',
          'Data Record Form No.',
          'Data Recording',
        ].map((head, index) => (
          <th colSpan={1} rowSpan={2} key={index + head}>
            {head}
          </th>
        ))}
      </tr>
      <tr>
        <th>Supplier Name</th>
      </tr>
    </thead>
  );
};

export default THead;
