import moment from 'moment';
import { useCallback, useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import CustomSelect from '../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { CustomIconButton } from '../../../../components/common/button/CustomIconButton';
import { MdChecklist } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { TimePicker } from '@mui/x-date-pickers';
import { convToTime } from '../../../../utils/helpers';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const navigate = useNavigate();

  const navigateCheckList = useCallback(
    (item: any) => {
      navigate(
        `/common/prevantiveMaintenance_NEW_Status?date=${new Date()}&machine=${
          moduleData?._id
        }`
      );
    },
    [moduleData]
  );

  return (
    <tbody>
      {moduleData.list &&
        moduleData.list.map((item: any, index: number) => (
          <tr key={`${index}_row`}>
            <td style={{ minWidth: '120px', width: '120px' }}>
              {formatDate(item.date)}
            </td>
            <td style={{ minWidth: '160px', width: '160px' }}>
              <TableInput
                isView={true}
                name="actual_prod"
                type="number"
                value={item.actual_prod}
                // onChange={(e) => {
                //   item.actual_prod = e.target.value;
                //   calculateCumulative();
                //   setRender((prev) => !prev);
                // }}
              />
            </td>
            <td style={{ minWidth: '160px', width: '160px' }}>
              <TableInput
                isView={true}
                name="comulative"
                type="text"
                value={item.comulative}
              />
            </td>
            <td
              style={{
                ...(item.status === 'RED (R)' && { background: '#e03131' }),
                minWidth: '160px',
                width: '160px',
              }}
            >
              {/* {item.status} */}

              {/* if the client want to manually set the status then go for it and in calculateCumulative in createToolHistory file remove item.status  */}
              {isView ? (
                item.status
              ) : (
                <CustomSelect
                  name="status"
                  value={item.status}
                  sx={{
                    background: `
                      ${
                        (item.status === 'GREEN (G)' && '#40c057') ||
                        (item.status === 'YELLOW (Y)' && '#ffe066') ||
                        (item.status === 'RED (R)' && '#e03131')
                      }
                    `,
                  }}
                  onChange={(e) => {
                    item.status = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">NONE</MenuItem>
                  <MenuItem value="GREEN (G)">GREEN (G)</MenuItem>
                  <MenuItem value="YELLOW (Y)">YELLOW (Y)</MenuItem>
                  <MenuItem value="RED (R)">RED (R)</MenuItem>
                </CustomSelect>
              )}
            </td>
            <th style={{ minWidth: '160px', width: '160px' }}>
              {isView ? (
                convToTime(item.pm_start_time)
              ) : (
                <TimePicker
                  value={moment(item.pm_start_time)}
                  onChange={(date) => {
                    item.pm_start_time = date;
                    setRender((prev: any) => !prev);
                  }}
                />
              )}
            </th>
            <th style={{ minWidth: '160px', width: '160px' }}>
              {isView ? (
                convToTime(item.pm_end_time)
              ) : (
                <TimePicker
                  value={moment(item.pm_end_time)}
                  onChange={(date) => {
                    item.pm_end_time = date;
                    setRender((prev: any) => !prev);
                  }}
                />
              )}
            </th>
            <td>
              {item?.isCheckListNeeded && (
                <CustomIconButton
                  sx={{ p: 0 }}
                  tooltipTitle={'CREATE CHECKLIST'}
                  onClick={() => {
                    navigateCheckList(item);
                  }}
                >
                  <MdChecklist style={{ color: '#343a40' }} />
                </CustomIconButton>
              )}
            </td>
            <td style={{ minWidth: '90px' }}>
              <TableInput
                isView={isView}
                name="bd"
                type="text"
                value={item.bd}
                onChange={(e) => {
                  item.bd = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '90px' }}>
              <TableInput
                isView={isView}
                name="pm"
                type="text"
                value={item.pm}
                onChange={(e) => {
                  item.pm = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <TableInput
                isView={isView}
                name="reason"
                type="text"
                value={item.reason}
                onChange={(e) => {
                  item.reason = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <TableInput
                isView={isView}
                name="action_taken"
                type="text"
                value={item.action_taken}
                onChange={(e) => {
                  item.action_taken = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <TableInput
                isView={isView}
                name="idle_time"
                type="number"
                value={item.idle_time}
                onChange={(e) => {
                  item.idle_time = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={item.remarks}
                onChange={(e) => {
                  item.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <TableInput
                isView={isView}
                name="sign"
                type="text"
                value={item.sign}
                onChange={(e) => {
                  item.sign = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
