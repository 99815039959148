import React, { useEffect, useState } from 'react';
import TableCustom from '../../components/common/customTable/TableCustom';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import { SearchBar } from '../../components/common/SearchBar';
import FilterBy from '../../components/common/FilterBy';
import ChildHeader from '../../components/ui/ChildHeader';
import axios from 'axios';
import useDebounce from '../../hooks/useDebounce';
import formatDate from '../../components/common/formatDate';
import { ViewIconButton } from '../../components/common/button/CustomIconButton';
import { useQuery } from '../../hooks/UseQuery';
import { useNavigate } from 'react-router-dom';
import { CustomPagination } from '../../components/common';
const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Item Number', rowspan: 1, colspan: 1 },
    { text: 'Item Description', rowspan: 1, colspan: 1 },
    { text: 'Customer', rowspan: 1, colspan: 1 },
    { text: 'Category', rowspan: 1, colspan: 1 },
    { text: 'Wig No.', rowspan: 1, colspan: 1 },
    { text: 'Created At', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterCategoryOptions = [
  { value: '', name: 'NONE' },
  { value: 'BOP', name: 'BOP' },
  { value: 'MIG WELDING', name: 'MIG WELDING' },
  { value: 'PLATING & POWDER COATING', name: 'PLATING & POWDER COATING' },
  { value: 'PRESS SHOP', name: 'PRESS SHOP' },
  { value: 'WELD SHOP', name: 'WELD SHOP' },
];
const PartPickerForSingleDoc = ({ link }: { link: string }) => {
  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const [filterCustomer, setFilterCustomer] = useState([
    {
      name: 'None',
      value: '',
    },
  ]);

  const search = useQuery('search');
  const category = useQuery('category');
  const customer = useQuery('customer');
  const page = useQuery('page') || 1;

  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        if (res.data) {
          const formattedData = res.data.map((customer: any) => ({
            name: customer.customer_name,
            value: customer.customer_name,
          }));
          setFilterCustomer((prev) => [...prev, ...formattedData]);
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const fetchData = async () => {
    const res: any = await axios
      .get(
        `/api/addPartRoute/viewPpapPart/publish?page=${page}&customer=${customer}&category=${category}&search=${search}&perPage=${10}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };

  useDebounce(fetchData, 1000, [page, search, customer, category]);

  return (
    <div>
      <ChildHeader text="PART LIST"></ChildHeader>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        sx={{
          justifyItems: 'center',
          alignItems: 'center',
          margin: '15px',
          width: '100%',
          gap: 2,
        }}
      >
        <SearchBar
          label="Search Part"
          sx={{ width: { xs: '100%', sm: '67%' } }}
        />
        <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ gap: 2 }}>
          <FilterBy
            sx={{ minWidth: '190px', maxWidth: '190px' }}
            name="customer"
            label="Filter By Customer"
            options={filterCustomer}
            variant={'outlined'}
          />
          <FilterBy
            sx={{ minWidth: '180px', maxWidth: '180px' }}
            name="category"
            label="Filter By Category"
            options={filterCategoryOptions}
          />
        </Stack>
      </Stack>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell align="center" width={10}>
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.part_number}</TableCell>
                  <TableCell align="center">{item?.part_name}</TableCell>
                  <TableCell align="center">
                    {item?.customer_id &&
                      typeof item?.customer_id !== 'string' &&
                      item.customer_id.customer_name}
                  </TableCell>
                  <TableCell align="center">{item?.category} </TableCell>
                  <TableCell align="center">{item?.wig_no}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.createdAt || '')}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <ViewIconButton
                        tooltipTitle="VIEW"
                        onClick={() => {
                          navigate(
                            `${link}/${item?._id}?partName=${item.part_name}&partNumber=${item.part_number}&isView=false`,
                          );
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
    </div>
  );
};

export default PartPickerForSingleDoc;
