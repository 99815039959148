import { Box } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
import THead from './THead';

interface IProps {
  moduleData: any;
  setModuleData: any;
  tabValue: number;
  isView: boolean;
  setRenderParent?: any;
  controlPlanId?: any;
}

const TController = ({
  moduleData,
  tabValue,
  setRenderParent,
  setModuleData,
  controlPlanId,
  isView,
}: IProps) => {
  return (
    <Box>
      <THead
        {...{ moduleData, tabValue, isView, setRenderParent, controlPlanId }}
      />

      <RenderTable>
        <tbody>
          {moduleData?.processes?.map((process: any, pIdx: any) => (
            <TBody
              key={'process_' + pIdx}
              {...{
                moduleData,
                process,
                pIdx,
                isView,
                setRenderParent,
                tabValue,
              }}
            />
          ))}
        </tbody>
      </RenderTable>
    </Box>
  );
};

export default TController;
