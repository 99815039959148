import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
  date?: any;
  setRender?: any;
  calculateOverAllRating?: any;
}

const TBodyIncidentOfAccess = ({
  moduleData,
  isView,
  setRender,
  calculateOverAllRating,
}: IProps) => {
  return (
    <tbody>
      <tr style={{ background: '#faa2c1' }}>
        <th colSpan={1} align="left">
          Incident of Excess Freight (E)
        </th>
        <th colSpan={12}>Weightage : 5%</th>
      </tr>
      <tr>
        <th align="left" style={{ minWidth: '180px' }}>
          Target % of Excess freight
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td key={'drsq' + 4001 + index} style={{ minWidth: '160px' }}>
            <TableInput
              isView={isView}
              name="dr"
              type="number"
              value={moduleData?.IEF_target_percent?.[index]}
              onChange={(e) => {
                moduleData.IEF_target_percent[index] = +e.target.value;
                setRender((prev: any) => !prev);
              }}
            />
          </td>
        ))}
      </tr>
      <tr>
        <th align="left" style={{ minWidth: '200px' }}>
          Achieved % of Excess freights
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td key={'drqc' + 4001 + index}>
            <TableInput
              isView={isView}
              name="cycle_time"
              type="number"
              value={moduleData?.IEF_acheived_percent?.[index]}
              onChange={(e) => {
                moduleData.IEF_acheived_percent[index] = +e.target.value;
                setRender((prev: any) => !prev);
              }}
            />
          </td>
        ))}
      </tr>
      <tr>
        <th align="left" style={{ minWidth: '200px' }}>
          % 5 (E)
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td key={'scper' + 4001 + index}>
            <TableInput
              isView={isView}
              name="cycle_time"
              type="number"
              value={moduleData?.IEF_5_percent?.[index]}
              onChange={(e) => {
                moduleData.IEF_5_percent[index] = +e.target.value;
                calculateOverAllRating(index);
                setRender((prev: any) => !prev);
              }}
            />
          </td>
        ))}
      </tr>
    </tbody>
  );
};

export default TBodyIncidentOfAccess;
