import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ChildHeader from '../../components/ui/ChildHeader';
import { mergerRowsControlPlan } from './helpers/merger';
import { useQuery } from '../../hooks/UseQuery';
import { getControlPlanAsPerCustomer } from './helpers/selectcontrolplanController';
import { CustomButton } from '../../components/common/button/CustomButtons';
import { RiFileExcel2Line } from 'react-icons/ri';
import moment from 'moment';
import { Loader } from '../../components/common/Loader';

interface MyData {
  moduleData: any;
  isLoading: boolean;
  error?: string | null;
}
interface Iprops {
  hideHeader?: boolean;
}
const View = ({ hideHeader }: Iprops) => {
  const { partId, controlPlanId } = useParams();
  const [CPCOMPONENT, setCpcomponent] = useState<any>();
  const [moduleData, setModuleData] = useState({ revisionData: [] });
  const customer_name = useQuery('customer_name');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const ComponentToBeRender = getControlPlanAsPerCustomer(customer_name);
    setCpcomponent(<ComponentToBeRender {...{ moduleData }} />);
  }, [customer_name, moduleData]);

  useEffect(() => {
    axios
      .get(`/api/controlPlan/${partId}/${controlPlanId}`)
      .then((res) => {
        const data: any = mergerRowsControlPlan(res.data);
        setModuleData(data);
      })
      .catch((err) => console.log(err));
  }, []);

  async function excelDownload() {
    setIsLoading(true);
    const data = await document.getElementById('tableDownloadId')?.innerHTML;
    console.log(data);
    await axios
      .post(
        `/api/createExcelRoute`,
        { table: data, name: 'CP' },
        { responseType: 'blob' }
      )
      .then((response) => {
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `CP ${moment().format('dddd, MMMM Do YYYY, h:mm:ss a')}.xlsx`
        );
        // document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setIsLoading(false);
      });
  }

  return (
    <Box>
      {hideHeader !== true && (
        <Box>
          <Loader loader={isLoading} />
          <ChildHeader text="CONTROL PLAN">
            <CustomButton
              color="primary"
              sx={{ margin: '10px' }}
              icon={<RiFileExcel2Line />}
              disabled={false}
              size="large"
              onClick={() => excelDownload()}
            >
              Excel Download
            </CustomButton>
          </ChildHeader>
        </Box>
      )}
      {CPCOMPONENT}
    </Box>
  );
};

export default View;
