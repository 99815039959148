import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';
import { useEffect, useState } from 'react';
import axios from 'axios';

interface IProps {
  moduleData: IData;
  isView: boolean;
  handleSumbit: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  setIsOpenPopupRejections: any;
  setRejectionsIndx: any;
  vendors: any;
}

const TController = ({
  moduleData,
  isView,
  handleSumbit,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
  setIsOpenPopupRejections,
  setRejectionsIndx,
  vendors,
}: IProps) => {
  const [machineData, setMachine] = useState({});

  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        if (res.data) {
          setMachine(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handleSumbit,
            machineData,
            handelDeleteRows,
            isOpenPopup,
            setIsOpenPopup,
            setPartIdx,
            setIsOpenPopupRejections,
            setRejectionsIndx,
            vendors,
          }}
        />
        {/* <TFoot {...{ moduleData, isView }} /> */}
      </RenderTable>
    </Box>
  );
};

export default TController;
