import GridInputs from '../../../components/common/inputs/GridInputs';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import GridSelect from '../../../components/common/inputs/GridSelect';
import { MenuItem } from '@mui/material';
import { actionTakens, reasons } from '../helpers/data';

const ForMSummaryForm = ({
  addTo4mSummary,
  setData,
  process,
  field,
  data,
}: {
  addTo4mSummary: any;
  setData: any;
  data: any;
  process: any;
  field: any;
}) => {
  function handleChange(e: any) {
    setData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  return (
    <>
      <GridInputs
        id={field}
        name={field}
        html_for={field}
        label_name={'Operator:'}
        input_type={'text'}
        focusType={'onblur'}
        value={process[field] || ''}
        onChange={(e) => {
          process[field] = e.target.value;
        }}
      />
      <GridSelect
        name="change_type"
        label_name="Change Type"
        html_for={'change_type'}
        value={data?.change_type}
        onChange={handleChange}
      >
        <MenuItem>None</MenuItem>
        <MenuItem value="man">Man</MenuItem>
        <MenuItem value="machine">Machine</MenuItem>
        <MenuItem value="material">Material</MenuItem>
        <MenuItem value="method">Method</MenuItem>
      </GridSelect>
      <GridSelect
        name="reason"
        label_name="Reason"
        html_for={'reason'}
        value={data?.reason}
        onChange={handleChange}
      >
        {reasons[data.change_type] &&
          reasons[data.change_type].map((reason: any) => (
            <MenuItem value={reason.value}>{reason.name}</MenuItem>
          ))}
      </GridSelect>
      <GridSelect
        name="action_taken"
        label_name="Action Taken"
        html_for={'action_taken'}
        value={data?.action_taken}
        onChange={handleChange}
      >
        <MenuItem>None</MenuItem>
        {actionTakens[data.reason] &&
          actionTakens[data.reason].map((action: any) => (
            <MenuItem value={action.value}>{action.name}</MenuItem>
          ))}
      </GridSelect>

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`Add To 4M Summary`}
          type="button"
          onClick={addTo4mSummary}
        />
      </div>
    </>
  );
};

export default ForMSummaryForm;
