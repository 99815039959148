import { GiTestTubes } from 'react-icons/gi';
import { MdOutlineCompress } from 'react-icons/md';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
export type tilesType = {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number;
  p: string;
  span: string;
  icon?: any;
  style?: {};
}[];
const tiles = [
  {
    sNo: 1,
    link: '/common/finalInspectionRejectionRecordsPlatingList',
    isLink: true,
    icon: <GiTestTubes size="5rem" color="#1864ab" />,
    p: 'PLATING',
    span: '',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/finalInspectionRejectionRecordsPowderCoatingList',
    isLink: true,
    icon: <GiTestTubes size="5rem" color="#1864ab" />,
    p: 'POWDER',
    span: 'COATING',
    isIcon: true,
  },
];
const FinalInspectionRecordsSupplierTiles = () => {
  return (
    <>
      <ChildHeader text="FINAL INSPECTION REJECTION RECORD (SUPPLIER)" />
      <RenderTiles tilesObj={tiles} height="100vh" />
    </>
  );
};

export { FinalInspectionRecordsSupplierTiles };
