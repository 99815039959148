import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';

import { Bar, Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  LinearScale
);

interface IProps {
  apiData: any;
}
const ParetoChart = ({ apiData }: IProps) => {
  const cumulativePercentageData = apiData.map(
    (item: { cumulativePercentage: number }) => {
      return item.cumulativePercentage;
    }
  );

  const ngQtyData =
    apiData &&
    apiData.map((item: { ngQty: number }) => {
      return item.ngQty;
    });
  console.log(cumulativePercentageData, ngQtyData);
  const chartData: any = {
    labels: apiData.map((item: { partName: any }) => item.partName),
    datasets: [
      {
        label: 'NG Qty.',
        data: ngQtyData,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
      {
        label: 'Cumulative %',
        data: cumulativePercentageData,
        type: 'line',
        borderColor: '#c92a2a',
        fill: false,
        yAxisID: 'y-axis-percentage',
      },
    ],
  };

  const chartOptions: any = {
    plugins: {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: '',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Parts',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'NG Qty.',
        },
      },
      'y-axis-percentage': {
        beginAtZero: true,
        position: 'right',
        title: {
          display: true,
          text: 'Cumulative %',
        },
        grid: {
          display: false,
        },
        max: 100,
      },
    },
  };

  return (
    <div>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default ParetoChart;
