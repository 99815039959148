import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../components/ui';
import { convToTime } from '../../../../../../utils/helpers';

interface IProps {
  year: number;
}
const THead = ({ year }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead style={{ background: '#9775fa', color: 'white' }}>
      <tr>
        <th colSpan={4}>Annexure</th>
      </tr>
      <tr>
        {['S. No.', 'Month Name', 'Target', 'Total no. of Complaint'].map(
          (headingData) => (
            <th>{headingData}</th>
          )
        )}
      </tr>
    </thead>
  );
};

export default THead;
