import { IconButton, MenuItem, Tooltip } from '@mui/material';
import ObservationInput from './ObservationInput';
import { useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableInput } from '../../../../../components/common';
import { handleChangeColorOnRemarks } from '../../helpers/handleChangeColorOnRemarks';
import TBodyRow from './TBodyRow';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import moment from 'moment';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData.list.map((item: any, index: number) => (
        <TBodyRow item={item} index={index} isView={isView} />
      ))}
    </tbody>
  );
};

export default TBody;
