import { useState } from 'react';
// import { Grid } from '@mui/material';
import { TableInput } from '../../../../../components/common';
// import moment from 'moment';

// import {
//   AddIconButton,
//   DeleteIconButton,
//   RemoveIconButton,
// } from '../../../../../components/common/button/CustomIconButton';
// import formatDate from '../../../../../components/common/formatDate';
import { handleChange } from '../../helpers/handleChange';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <th>{index + 1}</th>
              <td style={{ minWidth: '250px' }}>{process.lot_size}</td>
              <td style={{ minWidth: '120px' }}>
                <TableInput
                  isView={isView}
                  name="sample_size"
                  type="number"
                  value={process.sample_size}
                  onChange={(e) => {
                    handleChange(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '120px' }}>
                <TableInput
                  isView={isView}
                  name="ac"
                  type="number"
                  value={process.ac}
                  onChange={(e) => {
                    handleChange(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '120px' }}>
                <TableInput
                  isView={isView}
                  name="re"
                  type="number"
                  value={process.re}
                  onChange={(e) => {
                    handleChange(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {/* {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )} */}
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
