import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
interface IProps {
  moduleData: any;
  isView: boolean;
  phase?: any;
  title?: string;
}

const TController = ({ moduleData, isView, phase, title }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
          }}
        />
        <TBody {...{ title, moduleData, phase, isView }} />
      </RenderTable>
    </Box>
  );
};

export default TController;
