import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface Iprops {
  loader: boolean;
  setLoader?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const Loader = (props: Iprops) => {
  const { loader, setLoader } = props;

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
