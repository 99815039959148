import { MenuItem } from '@mui/material';
import { TableInput, TextAreaInput } from '../../../../components/common';
import {
  AddIconButton,
  DeleteIconButton,
} from '../../../../components/common/button/CustomIconButton';
import CustomSelect from '../../../../components/common/SelectField';
import TableTextArea from '../../../../components/common/tableinputs/TableTextArea';
import { potentialMode } from '../helpers/data';

interface IProps {
  rowSpan: number;
  fmeaIndex: number;
  processIndex: number;
  fmea: any;
  isView: any;
  process: any;
  handleChangeTbody: (e: any, processIndex: number, fmeaIndex?: number) => void;
  removeFmeas: (processIndex: number, fmeaIndex: number) => void;
  addFmeas: (processIndex: number, data: any) => void;
}
const getRpn = (fmea: any) => {
  let value = (+fmea.detection || 1) * (+fmea.occ || 2) * (+fmea.severity || 1);
  if (isNaN(value)) {
    return '';
  }
  return value;
};
const TBodyRow = ({
  processIndex,
  fmeaIndex,
  handleChangeTbody,
  fmea,
  process,
  isView,
  rowSpan,
  removeFmeas,
  addFmeas,
}: IProps) => {
  return (
    <tr>
      {fmeaIndex === 0 && (
        <td rowSpan={rowSpan}>
          <TableInput
            name="process_no"
            type="text"
            value={process.process_no}
            onChange={(e) => {
              handleChangeTbody(e, processIndex);
            }}
            isView={isView}
          />
        </td>
      )}
      {fmeaIndex === 0 && (
        <td rowSpan={rowSpan}>
          <TableInput
            name="process_name"
            type="text"
            value={process.process_name}
            onChange={(e) => {
              handleChangeTbody(e, processIndex);
            }}
            isView={isView}
          />
        </td>
      )}
      <td>
        <CustomSelect
          name="requirement"
          value={fmea.requirement || ''}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          sx={{
            minWidth: '250px',
            textAlign: 'center',
          }}
        >
          <MenuItem value="">NONE</MenuItem>
          {fmea.cp_data.map((requirement: string) => (
            <MenuItem value={requirement}>{requirement}</MenuItem>
          ))}
        </CustomSelect>
      </td>
      <td style={{ minWidth: '250px' }}>
        <CustomSelect
          name="potential_mode"
          value={fmea.potential_mode}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
        >
          <MenuItem value="">None</MenuItem>
          {potentialMode.map((item: any) => (
            <MenuItem value={item.value}>{item.name}</MenuItem>
          ))}
        </CustomSelect>
      </td>
      <td>
        <TableTextArea
          style={{ minWidth: '180px' }}
          isView={isView}
          name="next_operation"
          type="text"
          value={fmea.next_operation}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
        />
      </td>
      <td>
        <TableInput
          name="customer_end"
          type="text"
          value={fmea.customer_end}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="end_user"
          type="text"
          value={fmea.end_user}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="severity"
          type="text"
          value={fmea.severity}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="classification"
          type="text"
          value={fmea.classification}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableTextArea
          style={{ minWidth: '180px' }}
          isView={isView}
          name="potential_cause"
          type="text"
          value={fmea.potential_cause}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
        />
      </td>
      <td>2</td>
      <td>
        <TableTextArea
          style={{ minWidth: '180px' }}
          isView={isView}
          name="potential_control"
          type="text"
          value={fmea.potential_control}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
        />
      </td>
      <td>
        <TableTextArea
          style={{ minWidth: '180px' }}
          isView={isView}
          name="current_process_control"
          type="text"
          value={fmea.current_process_control}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
        />
      </td>
      <td>
        <TableInput
          name="detection"
          type="number"
          value={fmea.detection}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>{getRpn(fmea)}</td>
      <td>
        <TableInput
          name="sev_zone"
          type="number"
          value={fmea.sev_zone}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="det_zone"
          type="number"
          value={fmea.det_zone}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="priority_level"
          type="number"
          value={fmea.priority_level}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="recommendation_action"
          type="text"
          value={fmea.recommendation_action}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="resp_target"
          type="text"
          value={fmea.resp_target}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="action_takens"
          type="text"
          value={fmea.action_takens}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="action_result_sev"
          type="text"
          value={fmea.action_result_sev}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="action_result_occ"
          type="text"
          value={fmea.action_result_occ}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="action_result_det"
          type="text"
          value={fmea.action_result_det}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="action_result_rpn"
          type="text"
          value={fmea.action_result_rpn}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="action_result_sev_zone"
          type="text"
          value={fmea.action_result_sev_zone}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="action_result_det_zone"
          type="text"
          value={fmea.action_result_det_zone}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="action_result_priority_level"
          type="text"
          value={fmea.action_result_priority_level}
          onChange={(e) => {
            handleChangeTbody(e, processIndex, fmeaIndex);
          }}
          isView={isView}
        />
      </td>
      <td>
        {rowSpan - 1 === fmeaIndex && (
          <AddIconButton
            tooltipTitle={'ADD FMEAS'}
            onClick={() => addFmeas(processIndex, { cp_data: fmea.cp_data })}
          />
        )}
        <DeleteIconButton
          tooltipTitle={'DELETE FMEAS'}
          onClick={() => removeFmeas(processIndex, fmeaIndex)}
        />
      </td>
    </tr>
  );
};

export default TBodyRow;
