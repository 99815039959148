import moment from 'moment';
import React from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { useQuery } from '../../../../hooks/UseQuery';

export const TableRowComponent = React.memo(
  ({ key, item, index, allmonth, updateModuleData }: any) => {
    const isView = useQuery('isView') === 'true' ? true : false;
    const rowId = item?._id || item?._tempId;

    const handleInputChange = (
      fieldName: string,
      value: string | number | null
    ) => {
      updateModuleData('row', index, fieldName, { [fieldName]: value });
    };

    return (
      <React.Fragment key={key}>
        <tr>
          <td>{index + 1}</td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="parameter"
              type="text"
              value={item.parameter}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="specification"
              type="text"
              value={item.specification}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="method"
              type="text"
              value={item.method}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          {/* <td style={{ minWidth: '100px' }}>
            <TableInput
              isView={isView}
              name="obs_1"
              type="text"
              value={item.obs_1}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td style={{ minWidth: '100px' }}>
            <TableInput
              isView={isView}
              name="obs_2"
              type="text"
              value={item.obs_2}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td style={{ minWidth: '100px' }}>
            <TableInput
              isView={isView}
              name="obs_3"
              type="text"
              value={item.obs_3}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td style={{ minWidth: '100px' }}>
            <TableInput
              isView={isView}
              name="obs_4"
              type="text"
              value={item.obs_4}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td style={{ minWidth: '100px' }}>
            <TableInput
              isView={isView}
              name="obs_5"
              type="text"
              value={item.obs_5}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td> */}
          <td>
            <TableInput
              isView={isView}
              name="result"
              type="text"
              value={item.result}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          {/* <td>
            {isView ? (
              formatDate(item.date)
            ) : (
              <DatePickerMui
                label=""
                value={item?.date ? moment(item.date) : null}
                onChange={(date) => {
                  handleInputChange('date', date);
                }}
              />
            )}
          </td>
          <td>
            {isView ? (
              formatDate(item.next_due_date)
            ) : (
              <DatePickerMui
                label=""
                value={item?.next_due_date ? moment(item.next_due_date) : null}
                onChange={(date) => {
                  handleInputChange('next_due_date', date);
                }}
              />
            )}
          </td> */}
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="sign"
              type="text"
              value={item.sign}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="remarks"
              type="text"
              value={item.remarks}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
        </tr>
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => {
    // Check if the relevant data for this row has changed
    return (
      prevProps.item === nextProps.item && prevProps.index === nextProps.index
    );
  }
);
