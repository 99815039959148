// for handling color change of obs based on remarks

export const handleChangeColorOnRemarks = (spec: any, remarks: string) => {
  if (remarks === 'under deviation' || remarks === 'tbc') {
    Array(5)
      .fill(0)
      .map((_, obsIdx) => {
        spec?.[`check_${obsIdx}_colors`]?.forEach(
          (color: string, index: number) => {
            if (color === '#f7aeae') {
              spec[`check_${obsIdx}_colors`][index] = '#fbfbac';
            }
          }
        );
      });
  }
  if (
    remarks === 'ok' ||
    remarks === 'none' ||
    remarks === 'other' ||
    remarks === 'operation not done'
  ) {
    Array.from({ length: 5 }, () => null).map((_, obsIdx) => {
      spec?.[`check_${obsIdx}_colors`]?.forEach(
        (color: string, index: number) => {
          if (color === '#fbfbac') {
            spec[`check_${obsIdx}_colors`][index] = '#f7aeae';
          }
        }
      );
    });
  }
};
