import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
  date?: any;
  setRender?: any;
  calculateOverAllRating?: any;
}

const TBodyDeliveryRating = ({
  moduleData,
  isView,
  date,
  setRender,
  calculateOverAllRating,
}: IProps) => {
  const calculateDeliveryRatingData = (index: number) => {
    if (
      moduleData.DR_schedule_quantity[index] === undefined ||
      moduleData.DR_schedule_quantity[index] === null ||
      moduleData.DR_quantity_received[index] === undefined ||
      moduleData.DR_quantity_received[index] === null
    ) {
      moduleData.DR_delivery_percent_acheived[index] = null;
      moduleData.DR_20_percent[index] = null;
    } else {
      moduleData.DR_delivery_percent_acheived[index] = (
        (+moduleData.DR_quantity_received[index] /
          (+moduleData.DR_schedule_quantity[index] || 1)) *
        100
      ).toFixed(2);

      moduleData.DR_20_percent[index] = (
        +moduleData.DR_delivery_percent_acheived[index] * 0.2
      ).toFixed(2);
    }
    calculateOverAllRating(index);
  };
  return (
    <tbody>
      <tr style={{ background: '#c0eb75' }}>
        <th colSpan={1} align="left">
          Delivery Rating (B)
        </th>
        <th colSpan={12}>Weightage : 20%</th>
      </tr>
      <tr>
        <th key={`head`} align="center">
          CRITERIA
        </th>
        {[
          '04',
          '05',
          '06',
          '07',
          '08',
          '09',
          '10',
          '11',
          '12',
          '01',
          '02',
          '03',
        ].map((el, index) => (
          <th key={`${index}_headcol`} align="center">
            {el} /{index > 8 ? +date + 1 : date}
          </th>
        ))}
      </tr>

      <tr>
        <th align="left" style={{ minWidth: '180px' }}>
          Schedule Quantity
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td key={'drsq' + index} style={{ minWidth: '160px' }}>
            <TableInput
              isView={isView}
              name="dr"
              type="number"
              value={moduleData?.DR_schedule_quantity?.[index]}
              onChange={(e) => {
                moduleData.DR_schedule_quantity[index] = +e.target.value;
                calculateDeliveryRatingData(index);
                setRender((prev: any) => !prev);
              }}
            />
          </td>
        ))}
      </tr>
      <tr>
        <th align="left" style={{ minWidth: '200px' }}>
          Quantity Received{' '}
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td key={'drqc' + index}>
            <TableInput
              isView={isView}
              name="cycle_time"
              type="number"
              value={moduleData?.DR_quantity_received?.[index]}
              onChange={(e) => {
                moduleData.DR_quantity_received[index] = +e.target.value;
                calculateDeliveryRatingData(index);
                setRender((prev: any) => !prev);
              }}
            />
          </td>
        ))}
      </tr>
      <tr>
        <th align="left" style={{ minWidth: '200px' }}>
          Delivery % Achieved
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td key={'drper' + index}>
            {moduleData?.DR_delivery_percent_acheived?.[index]}
          </td>
        ))}
      </tr>
      <tr>
        <th align="left" style={{ minWidth: '200px' }}>
          % 20 (B)
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td key={'dr30%' + index}>{moduleData?.DR_20_percent?.[index]}</td>
        ))}
      </tr>
    </tbody>
  );
};

export default TBodyDeliveryRating;
