import { Clause7 } from '../../pages/clause7page';
import List from '../../pages/supplier/List';

export const clause7 = [
  {
    path: 'clause7',
    element: <Clause7 />,
  },
  {
    path: 'supplier_list',
    element: <List />,
  },
];
