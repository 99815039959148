import { Box } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: (index: number) => void;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPartIdx: React.Dispatch<React.SetStateAction<number>>;
  onSubmit: () => void;
  pictorial: any;
  setPictorial: any;
  setIsTrainingFileOpenPopup: any;
  triningFileIdRef: any;
}

const TController = ({
  handelDeleteRows,
  moduleData,
  onSubmit,
  isView,
  pictorial,
  setIsOpenPopup,
  setPictorial,
  setPartIdx,
  setIsTrainingFileOpenPopup,
  triningFileIdRef,
}: IProps) => {
  return (
    <Box>
      <div>
        <RenderTable>
          <THead
            {...{
              moduleData,
              isView,
            }}
          />
          <TBody
            {...{
              onSubmit,
              setPartIdx,
              pictorial,
              setPictorial,
              setIsOpenPopup,
              handelDeleteRows,
              moduleData,
              isView,
              setIsTrainingFileOpenPopup,
              triningFileIdRef,
            }}
          />
        </RenderTable>
      </div>
    </Box>
  );
};

export default TController;
