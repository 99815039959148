import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  moduleData: any;
  isView: boolean;
  operation: string[];
  // getProcess: any;
}

const TController = ({
  moduleData,
  isView,
  operation /*getProcess */,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            // getProcess,
            isView,
            operation,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
          }}
        />
        <TFoot
          {...{
            moduleData,
            isView,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
