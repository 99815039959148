import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  console.log('jhghg', moduleData);
  return (
    <thead>
      <TableCompHead
        colSpan={12}
        rowSpan={3}
        labels={['Doc No', 'Rev No.', 'Eff.Dated']}
        values={['FRM-QAS-D-001', '00', '01-04-2023']}
        heading="FINAL INSPECTION REPORT"
      />
      <tr>
        <th colSpan={3} align="left">
          Customer:{' '}
          {moduleData.customer_name
            ? moduleData.customer_name
            : moduleData.customer_id?.customer_name}
        </th>
        <th colSpan={4} align="left"></th>
        <th
          data-val={`Date: ${formatDate(moduleData?.pdir_date)}`}
          colSpan={5}
          align="left"
        >
          Date:{' '}
          {isView ? (
            formatDate(moduleData.pdir_date)
          ) : (
            <DatePickerMui
              label="Final Inspection Date"
              value={moment(moduleData.pdir_date)}
              onChange={(date) => {
                moduleData.pdir_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Part Name:
          {moduleData.part_name
            ? moduleData.part_name
            : moduleData.part_id?.part_name}
        </th>
        <th colSpan={4}></th>
        <th colSpan={5} align="left">
          Part No.:
          {moduleData.part_number
            ? moduleData.part_number
            : moduleData.part_id?.part_number}
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Bill No.:
          <TableInput
            name="bill_no"
            type="text"
            value={moduleData.bill_no}
            onChange={(e) => {
              moduleData.bill_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={4}>
          {/Honda/i.test(
            moduleData?.customer_name
              ? moduleData.customer_name
              : moduleData.customer_id?.customer_name || ''
          ) && <>WIG No. : {moduleData.wig_no}</>}
        </th>
        <th colSpan={5} align="left">
          Lot Qty:
          <TableInput
            name="qty"
            type="text"
            value={moduleData.qty}
            onChange={(e) => {
              moduleData.qty = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        {/* <th colSpan={3} align="left">
          Material Code:
          <TableInput
            name="material code"
            type="text"
            value={moduleData.material_code}
            onChange={(e) => {
              moduleData.material_code = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th> */}
      </tr>
      <tr>
        {['S.No', 'Parameter', 'Standard Specification', 'Method'].map(
          (head) => (
            <th rowSpan={2} style={{ textTransform: 'uppercase' }}>
              {head}
            </th>
          )
        )}
        <th colSpan={6} style={{ textTransform: 'uppercase' }}>
          Observation
        </th>
        <th style={{ textTransform: 'uppercase' }} rowSpan={2} colSpan={2}>
          Remarks
        </th>
      </tr>
      <tr>
        {Array.from({ length: 5 }, (_, index) => (
          <th style={{ width: '90px' }} key={index + 'Heading'}>
            {index + 1}
          </th>
        ))}
        <th style={{ textTransform: 'uppercase' }}>Status</th>
      </tr>
    </thead>
  );
};

export default THead;
