import { TableInput } from '../../../../components/common';

interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
}
const TBody = ({ isView, moduleData, tabValue }: IProps) => {
  return (
    <tbody>
      {moduleData.incomings[tabValue] &&
        moduleData.incomings[tabValue].incoming_list &&
        moduleData.incomings[tabValue].incoming_list.map(
          (spec: any, index: number) => (
            <tr>
              <td>{index + 1}</td>
              <td>{spec.parameter}</td>
              <td>{spec.specification}</td>
              <td style={{ width: '13%' }}>{spec.methods}</td>
              <td style={{ width: '13%' }}>
                {
                  <TableInput
                    name="remarks"
                    type="text"
                    defaultValue={spec.remarks}
                    isView={isView}
                    onChange={(e) => {
                      spec.remarks = e.target.value;
                    }}
                  />
                }
              </td>
            </tr>
          )
        )}
    </tbody>
  );
};

export default TBody;
