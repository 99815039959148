import { Box } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
import THead from './THead';

interface IProps {
  moduleData: any;
  handleDelete: any;
  setModuleData: any;
  isView: boolean;
  setPartIdx: any;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const TController = ({
  moduleData,
  isView,
  handleDelete,
  setModuleData,
  setPartIdx,
  setIsOpenPopup,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            isView,
          }}
        />
        <TBody
          {...{
            setIsOpenPopup,
            moduleData,
            isView,
            handleDelete,
            setPartIdx,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
