import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';

interface IProps {
  moduleData: any;
  isView: boolean;
  removeRow: any;
  handleChange: any;
}

const TController = ({
  handleChange,
  moduleData,
  removeRow,
  isView,
}: IProps) => {
  return (
    <RenderTable>
      <THead />
      {moduleData.list.map((list: any, index: number) => (
        <TBody item={list} {...{ handleChange, index, isView, removeRow }} />
      ))}
    </RenderTable>
  );
};

export default TController;
