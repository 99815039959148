import formatDate from '../../../../components/common/formatDate';
import TableCompHead from '../../../../components/ui/TableCompHead';

interface IProps {
  moduleData: any;
  tabValue: number;
}
type revisionObj = {
  rev_no: number;
  summary: string;
  [key: string]: string | number;
};

const THead = ({ moduleData, tabValue }: IProps) => {
  return (
    <thead>
      <TableCompHead
        colSpan={8}
        rowSpan={3}
        labels={['Doc.No.:- ', 'Rev.No.:- ', 'Date:- ']}
        values={['F-HRD-02S', '04', '12.01.20']}
        heading=""
      />
      <tr>
        <th align="left" colSpan={4} style={{ border: '1px solid black' }}>
          Level:{' '}
          {moduleData?.levels[tabValue]?.levels_type?.split('_').join(' ')}
        </th>
        <th align="left" colSpan={4} style={{ border: '1px solid black' }}>
          Name -
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={4} style={{ border: '1px solid black' }}>
          Date -
        </th>
        <th align="left" colSpan={4} style={{ border: '1px solid black' }}>
          Dept. -
        </th>
      </tr>
      <tr>
        {[
          'S. NO.',
          'POINTS TO BE VERIFIED',
          'OPTIONS',
          'MARKS OBTAINED 0 / 1',
          'MAXIMUM MARKS',
        ].map((item) => (
          <th
            align="center"
            colSpan={item === 'OPTIONS' ? 4 : 1}
            style={{
              border: '1px solid black',
              textAlign: 'center',
              height: '50px',
              width: item === 'OPTIONS' ? '200px' : '',
            }}
          >
            {item}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
