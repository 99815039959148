import { TableCompHead } from '../../../../../components/ui';
import { useQuery } from '../../../../../hooks/UseQuery';

const THead = () => {
  const partName = useQuery('partName');
  return (
    <thead>
      <TableCompHead
        colSpan={5}
        rowSpan={3}
        labels={[]}
        values={[]}
        heading="MIS-P STANDARDS"
      />

      <tr>
        <th colSpan={11} align="left">
          Part Name:{partName}
        </th>
      </tr>
      <tr>
        {['S.NO', 'CHECK POINT', 'DIMENSIONS', 'METHOD', 'ACTION'].map(
          (head) => (
            <th rowSpan={1} style={{ textTransform: 'uppercase' }}>
              {head}
            </th>
          ),
        )}
      </tr>
    </thead>
  );
};

export default THead;
