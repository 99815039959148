import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { Checkbox } from '@mui/material';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'S.No.',
  'Tranining Need Description',
  'Effected Area',
  'Training Description',
  'Trainer',
  'Effected Person',
  'Training Hrs.',
  'Date Of Training',
  'Signature Of Trainee',
  'Effectiveness',
  'Remark',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={13}
        rowSpan={3}
        labels={['Doc No.:', 'Rev No.:', 'Eff. Date:']}
        values={['FRM-CMT-06', '00', '01-04-2023']}
        heading="On Job Training Records"
      />
      <tr>
        {theadData.map((heading, index) => (
          <th
            key={heading + index}
            colSpan={
              heading === 'Effected Person' || heading === 'Remark' ? 2 : 1
            }
            rowSpan={heading === 'Effected Person' ? 1 : 2}
          >
            {heading}
          </th>
        ))}
      </tr>
      <tr>
        <th>Shop</th>
        <th>Name</th>
      </tr>
    </thead>
  );
};

export default THead;
