import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';
import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import GridSelect from '../../../components/common/inputs/GridSelect';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../components/common/Loader';
import { DivStyled } from '../../../assets/styles/components/DivStyledLableInputs';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../components/common/button/CustomIconButton';
import PartTable from './components/PartTable';
import ModalCustom from '../../../components/common/Modal';
const checkPoints: any = [
  {
    checkpoint: 'Feasibility Go ahead',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Approved Cad Available',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Approved Drawing Available',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Drawing request',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Child parts Approved drawing',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'List of child parts & Target Date',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Part code for Out source Process',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'List of tooling & Target Date',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'List of Fixtures & Target Date',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'List Of gauges & Target Date',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Leakage Testing',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Packaging STD',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'List Packaging Material',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Trolly for parts',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Rack and Bin requirement',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'Cad aling Accoding to drawing',
    status: '',
    remark: '',
  },
  {
    checkpoint: 'NPS Sheet',
    status: '',
    remark: '',
  },
];
const Create = ({
  id,
  setIsModal,
  disable,
  data,
  setData,
  setId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  disable: boolean;
  data: any;
  setData: any;
  setId: any;
}) => {
  const [files, setFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [isPartModal, setIsPartModal] = useState(false);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Devlopment Tracker' : 'Create Devlopment Tracker'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/devlopmentTracker/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  console.log('hhhhhhhhhh', id, data);
  useEffect(() => {
    axios
      .get(`/api/customerRoutes/customerList/customerForAutoComplete`)
      .then((res) => {
        console.log(res);
        setVendors(res.data);
      })
      .catch((err) => console.log(err));
  }, [id]);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    setIsLoading(true);
    // const formData = new FormData();
    // if (typeof data.vendor_id === 'object') {
    //   data.vendor_id = data.vendor_id._id;
    // }
    // for (const key in data) {
    //   if (data[key]) {
    //     if (key !== 'files') formData.append(key, data[key]);
    //   }
    // }
    // if (files) {
    //   for (const key of Object.keys(files)) {
    //     formData.append('files', files[key]);
    //   }
    // }
    if (id) {
      await axios
        .put(`/api/devlopmentTracker/${id}`, data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      data.checkPoints = checkPoints;
      await axios
        .post('/api/devlopmentTracker', data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <Grid item xs={3}>
        <AutocompleteMuiCustom
          id={'customer_name'}
          label={'Customer'}
          option_name={'customer_name'}
          value={data.customer_name || data.customer_id || ''}
          arrayofObj={vendors}
          onChange={(e, value) => {
            data.customer_id = value._id;
            data.customer_name = value;
          }}
          // readOnly={disable}
        />
      </Grid>
      <GridInputs
        id={'project_model'}
        name={'project_model'}
        html_for={'project_model'}
        label_name={'Project Model:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.project_model || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part Number'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '1S00%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof data?.part_id === 'object' && data?.part_id !== null
                  ? data?.part_id?.part_number
                  : data?.partNumber || ''}
              </p>
            </Grid>

            {data.part_id ? (
              <Grid item xs={2}>
                <RemoveIconButton
                  tooltipTitle="remove part"
                  onClick={() => {
                    data.part_id = null;
                    data.partNumber = null;
                    data.partName = null;

                    setRender((prev) => !prev);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={2}>
                <AddIconButton
                  tooltipTitle="add part"
                  onClick={() => {
                    setIsPartModal(true);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </DivStyled>
      <GridInputs
        id={'volume'}
        name={'volume'}
        html_for={'volume'}
        label_name={'Volume:'}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.volume || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'resp'}
        name={'resp'}
        html_for={'resp'}
        label_name={'Resp. :'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.resp || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridDatePicker
        label_name="RFQ. Date"
        html_for={'rfq_date'}
        value={data?.rfq_date || null}
        onChange={(date) => {
          data.rfq_date = date;
        }}
        disabled={disable}
      />
      <h1>Tech Query Status</h1>
      <GridSelect
        name="tech_query_plan"
        label_name="Plan"
        html_for={'tech_query_plan'}
        value={data?.tech_query_plan}
        onChange={handleCustomerData}
        disabled={disable}
      >
        <MenuItem disabled={disable}>NONE</MenuItem>
        <MenuItem disabled={disable} value="DONE">
          DONE
        </MenuItem>
        <MenuItem disabled={disable} value="NOT DONE">
          NOT DONE
        </MenuItem>
      </GridSelect>
      <GridDatePicker
        label_name="Target Date"
        html_for={'tech_query_target_date'}
        value={data?.tech_query_target_date || null}
        onChange={(date) => {
          data.tech_query_target_date = date;
        }}
        disabled={disable}
      />
      <GridDatePicker
        label_name="Complete Date"
        html_for={'tech_query_complete_date'}
        value={data?.tech_query_complete_date || null}
        onChange={(date) => {
          data.tech_query_complete_date = date;
        }}
        disabled={disable}
      />
      <GridSelect
        name="tech_query_approval"
        label_name="Approval"
        html_for={'tech_query_approval'}
        value={data?.tech_query_approval}
        onChange={handleCustomerData}
        disabled={disable}
      >
        <MenuItem disabled={disable}>NONE</MenuItem>
        <MenuItem disabled={disable} value="DONE">
          DONE
        </MenuItem>
        <MenuItem disabled={disable} value="NOT DONE">
          NOT DONE
        </MenuItem>
      </GridSelect>
      <h1>Sample sub. Status</h1>
      <GridSelect
        name="sample_plan"
        label_name="Plan / RE. SUB."
        html_for={'sample_plan'}
        value={data?.sample_plan}
        onChange={handleCustomerData}
        disabled={disable}
      >
        <MenuItem disabled={disable}>NONE</MenuItem>
        <MenuItem disabled={disable} value="DONE">
          DONE
        </MenuItem>
        <MenuItem disabled={disable} value="NOT DONE">
          NOT DONE
        </MenuItem>
      </GridSelect>
      <GridDatePicker
        label_name="Target Date"
        html_for={'sample_target_date'}
        value={data?.sample_target_date || null}
        onChange={(date) => {
          data.sample_target_date = date;
        }}
        disabled={disable}
      />
      <GridInputs
        id={'sample_qty'}
        name={'sample_qty'}
        html_for={'sample_qty'}
        label_name={'Qty: '}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.sample_qty || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridDatePicker
        label_name="Complete Date"
        html_for={'sample_complete_date'}
        value={data?.sample_complete_date || null}
        onChange={(date) => {
          data.sample_complete_date = date;
        }}
        disabled={disable}
      />
      <GridSelect
        name="sample_approval"
        label_name="Approval"
        html_for={'sample_approval'}
        value={data?.sample_approval}
        onChange={handleCustomerData}
        disabled={disable}
      >
        <MenuItem disabled={disable}>NONE</MenuItem>
        <MenuItem disabled={disable} value="DONE">
          DONE
        </MenuItem>
        <MenuItem disabled={disable} value="NOT DONE">
          NOT DONE
        </MenuItem>
      </GridSelect>
      <h1>PPAP Status</h1>
      <GridSelect
        name="ppap_plan"
        label_name="Plan"
        html_for={'ppap_plan'}
        value={data?.ppap_plan}
        onChange={handleCustomerData}
        disabled={disable}
      >
        <MenuItem disabled={disable}>NONE</MenuItem>
        <MenuItem disabled={disable} value="DONE">
          DONE
        </MenuItem>
        <MenuItem disabled={disable} value="NOT DONE">
          NOT DONE
        </MenuItem>
      </GridSelect>
      <GridDatePicker
        label_name="Target Date"
        html_for={'ppap_target_date'}
        value={data?.ppap_target_date || null}
        onChange={(date) => {
          data.ppap_target_date = date;
        }}
        disabled={disable}
      />
      <GridDatePicker
        label_name="Complete Date"
        html_for={'ppap_complete_date'}
        value={data?.ppap_complete_date || null}
        onChange={(date) => {
          data.ppap_complete_date = date;
        }}
        disabled={disable}
      />
      <GridSelect
        name="ppap_approval"
        label_name="Approval"
        html_for={'ppap_approval'}
        value={data?.ppap_approval}
        onChange={handleCustomerData}
        disabled={disable}
      >
        <MenuItem disabled={disable}>NONE</MenuItem>
        <MenuItem disabled={disable} value="DONE">
          DONE
        </MenuItem>
        <MenuItem disabled={disable} value="NOT DONE">
          NOT DONE
        </MenuItem>
      </GridSelect>

      <h1>Handing over status</h1>
      <GridSelect
        name="handing_over_plan"
        label_name="Plan"
        html_for={'handing_over_plan'}
        value={data?.handing_over_plan}
        onChange={handleCustomerData}
        disabled={disable}
      >
        <MenuItem disabled={disable}>NONE</MenuItem>
        <MenuItem disabled={disable} value="DONE">
          DONE
        </MenuItem>
        <MenuItem disabled={disable} value="NOT DONE">
          NOT DONE
        </MenuItem>
      </GridSelect>
      <GridDatePicker
        label_name="Target Date"
        html_for={'handing_over_target_date'}
        value={data?.handing_over_target_date || null}
        onChange={(date) => {
          data.handing_over_target_date = date;
        }}
        disabled={disable}
      />
      <GridDatePicker
        label_name="Complete Date"
        html_for={'handing_over_complete_date'}
        value={data?.handing_over_complete_date || null}
        onChange={(date) => {
          data.handing_over_complete_date = date;
        }}
        disabled={disable}
      />
      <GridSelect
        name="handing_over_approval"
        label_name="Approval"
        html_for={'handing_over_approval'}
        value={data?.handing_over_approval}
        onChange={handleCustomerData}
        disabled={disable}
      >
        <MenuItem disabled={disable}>NONE</MenuItem>
        <MenuItem disabled={disable} value="DONE">
          DONE
        </MenuItem>
        <MenuItem disabled={disable} value="NOT DONE">
          NOT DONE
        </MenuItem>
      </GridSelect>
      <GridInputs
        id={'remark'}
        name={'remark'}
        html_for={'remark'}
        label_name={'Remark:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.remark || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      {/* 
      {!disable && (
        <Grid item xs={3}>
          <CustomUploadButton
            lable={'UPLOAD DOCUMENT'}
            multiple
            accept="image/png,image/jpeg,image/jpg,application/pdf"
            onChange={(e) => setFiles(e.target.files)}
            // disabled={disable}
          />{' '}
        </Grid>
      )} */}
      {!disable && (
        <div
          className="btn_save"
          style={{
            position: 'absolute',
            right: '140px',
            top: '-0.6px',
          }}
        >
          <SubmitButton
            label={`${id ? 'update' : 'submit'}`}
            type="button"
            onClick={handleSumbit}
          />
        </div>
      )}
      <DialogSubmit />
      <ModalCustom
        openModal={isPartModal}
        closeModal={() => {
          setIsPartModal(false);
        }}
        title="PART"
      >
        <PartTable moduleData={data} />
      </ModalCustom>
    </>
  );
};

export default Create;
