import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IProps {
  datasets: any;
  monthNames: any;
  options: any;
}
const BarChart = ({ datasets, monthNames, options }: IProps) => {
  const data = {
    labels: monthNames,
    datasets: datasets,
  };
  return <Bar options={options} data={data} />;
};

export default BarChart;
