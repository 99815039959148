import { SetStateAction, useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import { Grid, MenuItem, Tooltip } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { TimePicker } from '@mui/x-date-pickers';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import { convToTime } from '../../../../../utils/helpers';
import CustomMobileTimePickerMui from '../../../../../components/common/CustomMobileTimePickerMui';

interface IProps {
  moduleData: any;
  isView: boolean;
  handleSumbit: any;
  machineData: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  setIsOpenPopupRejections: any;
  setRejectionsIndx: any;
  vendors: any;
}
const IsComplete = (process: any) => {
  const totalRejectionQty = process.reasons_for_rej.reduce(
    (sum: number, item: any) => sum + +item.rej_qty,
    0
  );
  if (process.rej_qty - totalRejectionQty === 0) return true;
  return false;
};

const TBody = ({
  moduleData,
  isView,
  handleSumbit,
  machineData,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
  setIsOpenPopupRejections,
  setRejectionsIndx,
  vendors,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <td
                width={'150px'}
                style={{ fontWeight: 'bold', minWidth: '100px' }}
              >
                {index + 1}
              </td>
              <td style={{ minWidth: '200px' }}>
                <DatePickerMui
                  label=""
                  value={moment(process.process_date || '')}
                  onChange={(date) => {
                    process.process_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ width: '250px', minWidth: '250px' }}>
                {isView ? (
                  process?.part_id && process?.part_id?.part_name
                ) : (
                  <Grid container xs={12} direction="row" alignItems={'center'}>
                    <Grid item xs={10}>
                      <p
                        style={{
                          fontSize: '1.6rem',
                        }}
                      >
                        {typeof process?.part_id === 'object' &&
                        process?.part_id !== null
                          ? process?.part_id?.part_name
                          : process?.partName || ''}
                        {!process?.part_id && 'Pick Part Name'}
                      </p>
                    </Grid>
                    {process.part_id ? (
                      <Grid item xs={2}>
                        <RemoveIconButton
                          tooltipTitle="remove part"
                          onClick={() => {
                            process.part_id = null;
                            process.partNumber = null;
                            process.partName = null;
                            setRender((prev) => !prev);
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <AddIconButton
                          tooltipTitle="add part"
                          onClick={() => {
                            setIsOpenPopup(true);
                            setPartIdx(index);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </td>

              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="prod_qty"
                  type="number"
                  value={process.prod_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                {process.prod_qty - process.rej_qty}
              </td>
              <td style={{ minWidth: '100px' }}>{process.rej_qty}</td>
              <td
                style={{
                  width: '280px',
                  minWidth: '280px',
                  textAlign: 'center',
                }}
              >
                <Grid
                  container
                  xs={12}
                  direction="row"
                  alignItems={'center'}
                  sx={
                    IsComplete(process)
                      ? { background: 'green' }
                      : { background: 'red' }
                  }
                >
                  <Grid item xs={10}>
                    <p
                      style={{
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                      }}
                    >
                      Click on + icon for reason and qty
                    </p>
                  </Grid>
                  <Grid item xs={2}>
                    <AddIconButton
                      tooltipTitle="add reasons and qty"
                      onClick={() => {
                        setIsOpenPopupRejections(true);
                        setRejectionsIndx(index);
                      }}
                    />
                  </Grid>
                </Grid>
              </td>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  process.status
                ) : (
                  <CustomSelect
                    name="status"
                    value={process.status || ''}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="OK">OK</MenuItem>
                    <MenuItem value="NOT OK">NOT OK</MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="rework_qty"
                  type="number"
                  value={process.rework_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '200px' }}>
                <TableInput
                  isView={isView}
                  name="insp_sign"
                  type="text"
                  value={process.insp_sign}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '200px' }}>
                <TableInput
                  isView={isView}
                  name="remark"
                  type="text"
                  value={process.remark}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
