
interface IProps {
  moduleData: any;
  isView: boolean;
}
const TBody = ({ moduleData, isView }: IProps) => {
  return (
    <tbody>
      {moduleData?.specs?.length > 0 &&
        moduleData.specs.map((item: any, index: number) => (
          <tr key={`${index}_tbody`}>
            <td>{index + 1}</td>
            <td>{item.parameter}</td>
            <td>{item.control_product}</td>
            <td>{item.methods_measure}</td>
            <td>{item.methods_size}</td>
            <td>{item.methods_by}</td>
            <td>{item.responsibility}</td>
            <td>{item.reaction_plan}</td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
