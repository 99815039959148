import moment from 'moment';
import React, { useState } from 'react';
import { TableInput } from '../../../components/common';
import DatePickerMui from '../../../components/common/DatePickerMui';
import formatDate from '../../../components/common/formatDate';
import { TableCompHead } from '../../../components/ui';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../components/common/SelectField';
import { useQuery } from '../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  process: any;
  setModuleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView, process, setModuleData }: IProps) => {
  const [render, setRender] = useState(false);
  const part_number = useQuery('part_number');
  const part_name = useQuery('part_name');

  return (
    <thead>
      <TableCompHead
        colSpan={8}
        rowSpan={3}
        labels={['Doc No', 'Rev No', 'Eff. Date']}
        values={['FRM-ENG-14', '00', '01-04-2023']}
        heading="Process Standard"
      />
      <tr>
        <th colSpan={2}>Part Name:</th>
        <th colSpan={2}>{part_name}</th>
        <th colSpan={2}>Part No.: </th>
        <th colSpan={2}>{part_number}</th>
      </tr>
      <tr>
        <th colSpan={2}>Operation Name : </th>
        <th colSpan={2}>
          {isView ? (
            moduleData.process_name
          ) : (
            <AutocompleteMuiCustom
              id={'process_name'}
              label=""
              option_name={'process_name'}
              value={moduleData || ''}
              arrayofObj={process}
              onChange={(e, value) => {
                setModuleData(value || {});
              }}
            />
          )}
        </th>
        <th colSpan={2}>Operation No.: </th>
        <th colSpan={2}>{moduleData?.process_no}</th>
      </tr>

      <tr>
        <th colSpan={8}>Operation Photo Sketch</th>
      </tr>
      <tr>
        <th
          colSpan={8}
          style={{
            height: '500px',
          }}
        >
          <img height="100%" width="auto" src={moduleData.image} alt="image" />
        </th>
      </tr>
      <tr>
        <th colSpan={8}>Operator Instruction For Set up ,Safety Etc.</th>
      </tr>
      <tr>
        <th align="left" colSpan={8}>
          सबसे पहले मशीन और औजारो आदि की सफाई करें I
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={8}>
          प्रोडक्ट के अनुसार डाई का चुनाव करें और उसे अच्छी तरह साफ़ करें I
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={8}>
          मशीन को चेक करें की कहीं कोई खराबी तो नहीं I
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={8}>
          दस्तानों का इस्तेमाल करें एवं कार्य करते समय सावधानी बरतें I
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={8}>
          कोई समस्या होने पर तुरंत सुपरवाइजर को सूचित करें I
        </th>
      </tr>
      <tr>
        <th colSpan={8}>Inspection Check Points :</th>
      </tr>

      <tr>
        {[
          'S.No.',
          'Inspection Parameter',
          'Specification',
          'Inspection Method',
          'Sample Size',
          'Freq',
          'Resp.',
          'Reaction Plan',
        ].map((item, index) => (
          <React.Fragment key={`heading_${index}`}>
            <th style={{ height: '80px' }}>{item}</th>
          </React.Fragment>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
