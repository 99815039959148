import styled from 'styled-components';
export const GraphHead = styled.div`
  flex: 0.5;
  border: 1px solid black;
  p {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1.2rem;
    letter-spacing: 1.6px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #1d1d1d;
    margin: 0;
    color: white;
  }
`;
