import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import TController from './components/TController';
import { Loader } from '../../../components/common/Loader';

const CreateMachineMaintenanceCard = () => {
  const [moduleData, setModuleData] = useState<any>({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const isView = useQuery('isView') === 'true' ? true : false;

  useEffect(() => {
    setLoading(true);
    if (id) {
      axios
        .get(`/api/machineRoutes/${id}`)
        .then((res) => {
          const data = res.data;
          setModuleData(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [id]);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );

  const onSubmit = async () => {
    if (
      !moduleData?.maintenance_changed_frequncy_prev &&
      moduleData?.maintenance_changed_frequncy_prev !== 0 &&
      moduleData?.maintenance_changed_frequncy >= 0
    ) {
      const ans = window.confirm(
        // Changed to confirm to better handle user response
        'Please verify the PM Frequency. It can only be set once and cannot be changed later.'
      );
      if (!ans) return;
      moduleData.maintenance_changed_frequncy_prev =
        moduleData?.maintenance_changed_frequncy;
    }
    if (
      !moduleData?.maintenance_initial_cumulative_prev &&
      moduleData?.maintenance_initial_cumulative_prev !== 0 &&
      moduleData?.maintenance_initial_cumulative >= 0
    ) {
      const ans = window.confirm(
        'Please verify the Starting Cumulative Frequency.It can only be set once and cannot be changed later.'
      );
      if (!ans) return;
      moduleData.maintenance_initial_cumulative_prev =
        moduleData?.maintenance_initial_cumulative;
    }

    const ans = await confirmSubmit();
    if (!ans) return;
    await axios
      .put(`/api/machineRoutes/${id}`, moduleData)
      .then((res) => {
        if (res.data) {
          alert('Updated Successfully');
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Loader loader={loading} />
      <Box>
        <ChildHeader text="MACHINE MAINTENANCE-CARD">
          {!isView && (
            <SubmitButton label="Submit" onClick={() => onSubmit()} />
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
        }}
      />
      <DialogSubmit />
    </>
  );
};

export default CreateMachineMaintenanceCard;
