import React, { useEffect, useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import moment from 'moment';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import {
  CustomPagination,
  PersitedDatePicker,
  TableCustom,
} from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
  ListIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { IData } from './helpers/interfaces';
import ViewFile from './components/ViewFile';
import CheckPoints from './components/CheckPoints';
import formatDate from '../../../components/common/formatDate';
import { BsFileEarmarkTextFill } from 'react-icons/bs';
import TableScroll from '../../../components/ui/renderTable/TableScroll';

const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1, style: { minWidth: '100px' } },
    { text: 'CUSTOMER', rowspan: 1, colspan: 1, style: { minWidth: '100px' } },
    {
      text: 'PROJECT MODEL',
      rowspan: 1,
      colspan: 1,
      style: { minWidth: '100px' },
    },
    {
      text: 'PART NUMBER',
      rowspan: 1,
      colspan: 1,
      style: { minWidth: '100px' },
    },
    { text: 'VOLUME', rowspan: 1, colspan: 1, style: { minWidth: '100px' } },
    { text: 'RESP.', rowspan: 1, colspan: 1, style: { minWidth: '100px' } },
    { text: 'RFQ. DATE', rowspan: 1, colspan: 1, style: { minWidth: '100px' } },
    { text: 'PLAN', rowspan: 1, colspan: 1, style: { minWidth: '100px' } },
    {
      text: 'TARGET DATE',
      rowspan: 1,
      colspan: 1,
      style: { minWidth: '100px' },
    },
    {
      text: 'COMPLETE DATE',
      rowspan: 1,
      colspan: 1,
      style: { minWidth: '100px' },
    },
    { text: 'APPROVAL', rowspan: 1, colspan: 1, style: { minWidth: '100px' } },
    {
      text: 'PLAN/RE. SUB.',
      rowspan: 1,
      colspan: 1,
      style: { minWidth: '100px' },
    },
    {
      text: 'TARGET DATE',
      rowspan: 1,
      colspan: 1,
      style: { minWidth: '100px' },
    },
    { text: 'QTY', rowspan: 1, colspan: 1, style: { minWidth: '100px' } },
    {
      text: 'COMPLETE DATE',
      rowspan: 1,
      colspan: 1,
      style: { minWidth: '100px' },
    },
    { text: 'APPROVAL', rowspan: 1, colspan: 1, style: { minWidth: '100px' } },
    { text: 'PLAN', rowspan: 1, colspan: 1, style: { minWidth: '100px' } },
    {
      text: 'TARGET DATE',
      rowspan: 1,
      colspan: 1,
      style: { minWidth: '100px' },
    },
    {
      text: 'COMPLETE DATE',
      rowspan: 1,
      colspan: 1,
      style: { minWidth: '100px' },
    },
    { text: 'APPROVAL', rowspan: 1, colspan: 1, style: { minWidth: '100px' } },
    { text: 'PLAN', rowspan: 1, colspan: 1, style: { minWidth: '100px' } },
    {
      text: 'TARGET DATE',
      rowspan: 1,
      colspan: 1,
      style: { minWidth: '100px' },
    },
    {
      text: 'COMPLETE DATE',
      rowspan: 1,
      colspan: 1,
      style: { minWidth: '100px' },
    },
    { text: 'APPROVAL', rowspan: 1, colspan: 1, style: { minWidth: '100px' } },
    { text: 'REMARK', rowspan: 1, colspan: 1, style: { minWidth: '100px' } },
    { text: 'ACTIONS', rowspan: 1, colspan: 1, style: { minWidth: '100px' } },
  ],
];

const tableHeaderObj1 = [
  [
    {
      text: 'DEVELOPMENT STATUS',
      rowspan: 1,
      colspan: 7,
      style: { minWidth: '700px' },
    },
    {
      text: 'Tech Query Status',
      rowspan: 1,
      colspan: 4,
      style: { background: '#eebefa', minWidth: '400px' },
    },
    {
      text: 'Sample sub. Status',
      rowspan: 1,
      colspan: 5,
      style: { background: '#fcc2d7', minWidth: '500px' },
    },
    {
      text: 'PPAP Status',
      rowspan: 1,
      colspan: 4,
      style: { background: '#ffd8a8', minWidth: '400px' },
    },
    {
      text: 'Handing over status',
      rowspan: 1,
      colspan: 4,
      style: { background: '#fcc2d7', minWidth: '400px' },
    },
  ],
];

const filterOptions = [
  { value: 'createdAt', name: 'A-Z' },
  { value: 'rfq_dateDes', name: 'Most recent RFQ. date' },
  { value: 'rfq_dateAsc', name: 'Oldest defect RFQ. date' },
];

const List = () => {
  const [moduleData, setModuleData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [id, setId] = useState<string | null>(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
  const [isDisable, setDisable] = useState(false);
  const [data, setData] = useState([]);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const date = useQuery('date');

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/devlopmentTracker/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}&date=${date}`
      )
      .catch((err) => {
        console.log(err);
      });
    setModuleData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteCustomer = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/devlopmentTracker/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, filter, date]);
  console.log('id', id);
  return (
    <>
      <ChildHeader text="Devlopment Tracker">
        <AddButton
          onClick={() => {
            setId(null);
            setIsModal(true);
          }}
          label="NEW Devlopment Tracker"
        />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search By Customer Name" />
        <PersitedDatePicker label="Filter By Year" views={['year']} />
        {/* <FilterBy options={filterOptions} /> */}
      </SearchFilterContainer>
      <TableScroll>
        <TableCustom tableHeaderObj={tableHeaderObj1}></TableCustom>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {moduleData &&
              moduleData.map((item: IData, index: number) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center">
                      {perPage * (+page - 1) + index + 1}
                    </TableCell>
                    <TableCell align="center">
                      {item.customer_id?.customer_name}
                    </TableCell>
                    <TableCell align="center">{item?.project_model}</TableCell>
                    <TableCell align="center">
                      {item?.part_id?.part_number}
                    </TableCell>
                    <TableCell align="center">{item?.volume}</TableCell>
                    <TableCell align="center">{item?.resp}</TableCell>
                    <TableCell align="center">
                      {item.rfq_date && formatDate(item?.rfq_date)}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={
                        item?.tech_query_plan === 'DONE'
                          ? { background: 'green' }
                          : item?.tech_query_plan === 'NOT DONE'
                          ? { background: 'red' }
                          : { background: '' }
                      }
                    >
                      {item?.tech_query_plan}
                    </TableCell>
                    <TableCell align="center">
                      {item.tech_query_target_date &&
                        formatDate(item?.tech_query_target_date)}
                    </TableCell>
                    <TableCell align="center">
                      {item.tech_query_complete_date &&
                        formatDate(item?.tech_query_complete_date)}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={
                        item?.tech_query_approval === 'DONE'
                          ? { background: 'green' }
                          : item?.tech_query_approval === 'NOT DONE'
                          ? { background: 'red' }
                          : { background: '' }
                      }
                    >
                      {item?.tech_query_approval}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={
                        item?.sample_plan === 'DONE'
                          ? { background: 'green' }
                          : item?.sample_plan === 'NOT DONE'
                          ? { background: 'red' }
                          : { background: '' }
                      }
                    >
                      {item?.sample_plan}
                    </TableCell>
                    <TableCell align="center">
                      {item.sample_target_date &&
                        formatDate(item?.sample_target_date)}
                    </TableCell>
                    <TableCell align="center">{item?.sample_qty}</TableCell>
                    <TableCell align="center">
                      {item.sample_complete_date &&
                        formatDate(item?.sample_complete_date)}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={
                        item?.sample_approval === 'DONE'
                          ? { background: 'green' }
                          : item?.sample_approval === 'NOT DONE'
                          ? { background: 'red' }
                          : { background: '' }
                      }
                    >
                      {item?.sample_approval}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={
                        item?.ppap_plan === 'DONE'
                          ? { background: 'green' }
                          : item?.ppap_plan === 'NOT DONE'
                          ? { background: 'red' }
                          : { background: '' }
                      }
                    >
                      {item?.ppap_plan}
                    </TableCell>
                    <TableCell align="center">
                      {item.ppap_target_date &&
                        formatDate(item?.ppap_target_date)}
                    </TableCell>
                    <TableCell align="center">
                      {item.ppap_complete_date &&
                        formatDate(item?.ppap_complete_date)}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={
                        item?.ppap_approval === 'DONE'
                          ? { background: 'green' }
                          : item?.ppap_approval === 'NOT DONE'
                          ? { background: 'red' }
                          : { background: '' }
                      }
                    >
                      {item?.ppap_approval}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={
                        item?.handing_over_plan === 'DONE'
                          ? { background: 'green' }
                          : item?.handing_over_plan === 'NOT DONE'
                          ? { background: 'red' }
                          : { background: '' }
                      }
                    >
                      {item?.handing_over_plan}
                    </TableCell>
                    <TableCell align="center">
                      {item.handing_over_target_date &&
                        formatDate(item?.handing_over_target_date)}
                    </TableCell>
                    <TableCell align="center">
                      {item.handing_over_complete_date &&
                        formatDate(item?.handing_over_complete_date)}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={
                        item?.handing_over_approval === 'DONE'
                          ? { background: 'green' }
                          : item?.handing_over_approval === 'NOT DONE'
                          ? { background: 'red' }
                          : { background: '' }
                      }
                    >
                      {item?.handing_over_approval}
                    </TableCell>
                    <TableCell align="center">{item?.remark}</TableCell>
                    <TableCell>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <ListIconButton
                          tooltipTitle="View Development Checkpoints"
                          onClick={() => {
                            setId(item._id || null);
                            setIsFileOpenPopup(true);
                          }}
                        />
                        <ViewIconButton
                          tooltipTitle="VIEW"
                          onClick={() => {
                            setId(item?._id || null);
                            setDisable(true);
                            setIsModal(true);
                          }}
                        />
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            setId(item?._id || null);
                            setDisable(false);
                            setIsModal(true);
                          }}
                        />

                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            deleteCustomer(item?._id || '');
                          }}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
      </TableScroll>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setId(null);
          setIsModal(false);
          setData([]);
          setDisable(false);
        }}
        title="Devlopment Tracker"
      >
        <Create
          id={id}
          setIsModal={setIsModal}
          disable={isDisable}
          data={data}
          setData={setData}
          setId={setId}
        />
      </ModalCustom>
      <ModalCustom
        title="Development Check Points"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <CheckPoints
          id={id}
          data={data}
          setIsFileOpenPopup={setIsFileOpenPopup}
          setId={setId}
          setData={setData}
        />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
