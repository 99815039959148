import React, { useState } from 'react';
import {
  Checkbox,
  Grid,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import axios from 'axios';

import { StatePagination, TableCustom } from '../../../../components/common';
import useDebounce from '../../../../hooks/useDebounce';
import useConfirm from '../../../../components/common/useConfirm';
import StateCustomTable from '../../../../components/common/StateCustomTable';
import { apiConstant } from '../constant';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';

const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'Employee Name', rowspan: 1, colspan: 1 },
    { text: 'Employee Id', rowspan: 1, colspan: 1 },
    { text: 'Qualification', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'createdAt', name: 'Most Recent' },
  { value: 'employee_name', name: 'Employee Name' },
];

const EmployeeList = ({
  item,
  setIsModal,
}: {
  item?: any;
  setIsModal: any;
}) => {
  const [moduleData, setModuleData] = useState<any>({
    employee_id: item.employee_id,
  });
  const [data, setData] = useState<any[]>([]);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<string>('createdAt');
  const [page, setPage] = useState(1);
  const [render, setRender] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `update`,
    `Are you sure you want to ${'update'} this?`,
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/employeeRoute/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (item._id) {
      await axios
        .put(
          `/api/${apiConstant.checkListStatus}/${item.docId}/${item._id}/${item.month}`,
          moduleData,
        )
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleChange = (id: string) => {
    const employeeIdx = moduleData.employee_id.indexOf(id);
    if (employeeIdx === -1) {
      moduleData.employee_id.push(id);
    } else {
      moduleData.employee_id.splice(employeeIdx, 1);
    }
      setRender((prev) => !prev);
  };
  useDebounce(fetchData, 500, [page, search, filter]);

  return (
    <>
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          onClick={() => {
            handleSumbit();
          }}
          label={'Save'}
          type="button"
        />
      </div>
      <StateCustomTable
        filterOptions={filterOptions}
        {...{ search, setSearch, filter, setFilter }}
        label="Search Employee"
      >
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data &&
              data.map((item: any, index: number) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center">
                      {perPage * (+page - 1) + index + 1}
                    </TableCell>
                    <TableCell align="center">{item.employee_name}</TableCell>
                    <TableCell align="center">{item.employee_id}</TableCell>
                    <TableCell align="center">{item.qualification}</TableCell>
                    <TableCell>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <Checkbox
                          checked={moduleData.employee_id.includes(
                            item._id || '',
                          )}
                          onChange={() => handleChange(item._id || '')}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
      </StateCustomTable>
      <StatePagination page={page} totalPage={totalPages} setPage={setPage} />
      <DialogSubmit />
    </>
  );
};
export default EmployeeList;
