import React, { useState } from 'react';
import { TableInput, TextAreaInput } from '../../../../components/common';
import { useQuery } from '../../../../hooks/UseQuery';
import { spclChar } from '../../../cp/helpers/SpecialCharacters';

interface IProps {
  moduleData: any;
}
const minWidth = { minWidth: '120px' };
const colSpanBodyHeader = (head: string) => {
  if (head === 'Charecteristics / Parameters') return 3;
  if (head === 'Methods') return 6;
  return 1;
};
const stickStylesRow = {
  position: 'sticky',
  width: '100%',
  top: '-.5rem',
  border: '1px solid black',
  zIndex: '2',
  height: '50px',
} as const;
const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: '1',
  height: '50px',
  backgroundColor: '#a61e4d',
  color: '#fff',
} as const;
const TBody = ({ moduleData }: IProps) => {
  const [render, setRender] = useState(false);
  const isView = useQuery('isView') === 'true' ? true : false;

  return (
    <tbody>
      <tr
        style={{ ...stickStylesRow, backgroundColor: '#0ca678', color: '#fff' }}
      >
        {[
          'Part/ Process Number',
          'Process Name/ Operation Description',
          'Machine,  Device, Jigs, Tools for Mfg.',
          'Charecteristics / Parameters',
          'Spl. Char. Class',
          'Methods',
          'Resp.',
          'Reaction Plan',
          'Corrective Action',
          'Process Capability',
          'Observation 1',
          'Observation 2',
          'Observation 3',
          'Observation 4',
          'Observation 5',
          'Remarks',
        ].map((head, index) => (
          <th
            colSpan={colSpanBodyHeader(head)}
            rowSpan={index === 3 || index === 5 ? 1 : 3}
            key={index + head}
            style={
              index <= 3
                ? {
                    ...stickStylesColumn,
                    left: 120 * index,
                    // width: '50px',
                    ...minWidth,
                    backgroundColor: '#0ca678',
                  }
                : {}
            }
          >
            {head}
          </th>
        ))}
      </tr>
      <tr
        style={{
          backgroundColor: '#0ca678',
          color: '#fff',
          position: 'sticky',
          top: '4.4rem',
          zIndex: '2',
        }}
      >
        {[
          'No.',
          'Product',
          'Process',
          'Product/Process Specification/ Tolerance.',
          'Evalution Mesurements Techniques',
          'Error Proofing',
          'Sample',
          'Current Process Controls',
        ].map((head, index) => (
          <th
            colSpan={index === 6 ? 2 : 1}
            rowSpan={index === 6 ? 1 : 2}
            key={index + head}
            style={
              index <= 2
                ? {
                    ...stickStylesColumn,
                    left: 360 + 120 * index,
                    // width: '50px',
                    ...minWidth,
                    backgroundColor: '#0ca678',
                    zIndex: 2,
                  }
                : {}
            }
          >
            {head}
          </th>
        ))}
      </tr>
      <tr
        style={{
          backgroundColor: '#0ca678',
          color: '#fff',
          position: 'sticky',
          top: '7.8rem',
        }}
      >
        <th colSpan={1}>Size</th>
        <th colSpan={1}>Freq</th>
      </tr>
      {moduleData.processes &&
        moduleData.processes.map((process: any, index: number) => (
          <React.Fragment key={index}>
            {process.specs.map((spec: any, indx: number) => (
              <React.Fragment key={indx}>
                <tr style={{}}>
                  {indx === 0 && (
                    <>
                      <td
                        rowSpan={process.specs.length}
                        // style={{ ...minWidth }}
                        style={{
                          ...stickStylesColumn,
                          left: 0,
                          // width: '50px',
                          ...minWidth,
                        }}
                        align="center"
                      >
                        {process.process_no}
                      </td>
                      <td
                        // style={{ ...minWidth }}
                        style={{
                          ...stickStylesColumn,
                          left: 120,
                          // width: '50px',
                          ...minWidth,
                        }}
                        rowSpan={process.specs.length}
                        align="center"
                      >
                        {process.process_name}
                      </td>
                    </>
                  )}
                  <td
                    // style={{ ...minWidth }}
                    style={{
                      ...stickStylesColumn,
                      left: 240,
                      // width: '50px',
                      ...minWidth,
                    }}
                    rowSpan={(spec?.machine_rowSpan as number) || 1}
                  >
                    {spec?.machine}
                    {spec?.machine && spec?.tool_no ? '/' : ''}
                    {spec?.tool_no}
                  </td>

                  <td // style={{ ...minWidth }}
                    style={{
                      ...stickStylesColumn,
                      left: 360,
                      // width: '50px',
                      ...minWidth,
                    }}
                  >
                    {indx + 1}
                  </td>

                  {/* product or process */}
                  <td
                    // style={{ ...minWidth }}
                    style={{
                      ...stickStylesColumn,
                      left: 480,
                      // width: '50px',
                      ...minWidth,
                    }}
                    rowSpan={(spec?.char_product_rowSpan as number) || 1}
                  >
                    {spec?.char_product}
                  </td>
                  <td
                    // style={{ ...minWidth }}
                    style={{
                      ...stickStylesColumn,
                      left: 600,
                      // width: '50px',
                      ...minWidth,
                    }}
                    rowSpan={(spec?.char_process_rowSpan as number) || 1}
                  >
                    {spec?.char_process}
                  </td>
                  <td style={{ ...minWidth }}>
                    {spec.special_character &&
                      spclChar[
                        spec.special_character === 'M'
                          ? 'OP'
                          : spec.special_character === 'C'
                          ? 'CP'
                          : 'MP'
                      ]}
                  </td>
                  <td style={{ ...minWidth }}>
                    {spec.control_product}
                    {spec.control_process && spec.control_product ? '/' : ''}
                    {spec.control_process}
                    {spec.control_product_remark &&
                      `* ${spec.control_product_remark}`}
                  </td>
                  <td style={{ ...minWidth }}>{spec.methods_measure}</td>

                  <td
                    style={{ ...minWidth }}
                    rowSpan={(spec?.error_proofing_rowSpan as number) || 1}
                  >
                    {spec.error_proofing}
                  </td>

                  {/* Sample Size	 */}
                  <td
                    style={{ ...minWidth }}
                    rowSpan={(spec?.methods_size_rowSpan as number) || 1}
                  >
                    {spec?.methods_size}
                  </td>

                  <td
                    style={{ ...minWidth }}
                    rowSpan={(spec?.methods_by_rowSpan as number) || 1}
                  >
                    {spec?.methods_by}
                  </td>

                  {/* CONTROL METHOD	 */}
                  <td
                    style={{ ...minWidth }}
                    rowSpan={(spec?.methods_process_rowSpan as number) || 1}
                  >
                    {spec?.methods_process}
                  </td>

                  <td
                    style={{ ...minWidth }}
                    rowSpan={(spec?.responsibility_rowSpan as number) || 1}
                  >
                    {spec?.responsibility}
                  </td>

                  <td
                    style={{ ...minWidth }}
                    rowSpan={(spec?.reaction_plan_rowSpan as number) || 1}
                  >
                    {spec?.reaction_plan}
                  </td>

                  <td
                    style={{ ...minWidth }}
                    rowSpan={(spec?.corrective_action_rowSpan as number) || 1}
                  >
                    {spec.corrective_action}
                  </td>
                  <td
                    style={{ ...minWidth }}
                    rowSpan={(spec?.process_capability_rowSpan as number) || 1}
                  >
                    {spec.process_capability}
                  </td>
                  <td style={{ minWidth: '250px' }}>
                    <TextAreaInput
                      isView={isView}
                      value={spec.observation_1}
                      onChange={(e: any) => {
                        spec.observation_1 = e.target.value;
                        setRender((prev) => !prev);
                      }}
                    />
                  </td>
                  <td style={{ minWidth: '250px' }}>
                    <TextAreaInput
                      isView={isView}
                      value={spec.observation_2}
                      onChange={(e: any) => {
                        spec.observation_2 = e.target.value;
                        setRender((prev) => !prev);
                      }}
                    />
                  </td>
                  <td style={{ minWidth: '250px' }}>
                    <TextAreaInput
                      isView={isView}
                      value={spec.observation_3}
                      onChange={(e: any) => {
                        spec.observation_3 = e.target.value;
                        setRender((prev) => !prev);
                      }}
                    />
                  </td>
                  <td style={{ minWidth: '250px' }}>
                    <TextAreaInput
                      isView={isView}
                      value={spec.observation_4}
                      onChange={(e: any) => {
                        spec.observation_4 = e.target.value;
                        setRender((prev) => !prev);
                      }}
                    />
                  </td>
                  <td style={{ minWidth: '250px' }}>
                    <TextAreaInput
                      isView={isView}
                      value={spec.observation_5}
                      onChange={(e: any) => {
                        spec.observation_5 = e.target.value;
                        setRender((prev) => !prev);
                      }}
                    />
                  </td>
                  <td style={{ minWidth: '200px' }}>
                    <TextAreaInput
                      isView={isView}
                      value={spec.remarks}
                      onChange={(e: any) => {
                        spec.remarks = e.target.value;
                        setRender((prev) => !prev);
                      }}
                    />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
    </tbody>
  );
};

export default TBody;
