import { Grid } from '@mui/material';
import {
  AddIconButton,
  RemoveIconButton,
} from '../common/button/CustomIconButton';
interface IProps {
  isView: boolean;
  data: any;
  removePart: () => void;
  addPart: () => void;
}
const PickPartUi = ({ isView, data, removePart, addPart }: IProps) => {
  return (
    <div>
      {isView ? (
        data?.part_id && data?.part_id?.part_name
      ) : (
        <Grid
          sx={{ border: '2px solid #d3f9d8' }}
          container
          xs={12}
          direction="row"
          alignItems={'center'}
        >
          <Grid item xs={10}>
            <p
              style={{
                fontSize: '1.2rem',
              }}
            >
              {typeof data?.part_id === 'object' && data?.part_id !== null
                ? data?.part_id?.part_name
                : data?.partName || ''}
              {!data?.part_id && 'Pick Part Name'}
            </p>
          </Grid>

          {data.part_id ? (
            <Grid item xs={2}>
              <RemoveIconButton
                tooltipTitle="remove part"
                onClick={removePart}
              />
            </Grid>
          ) : (
            <Grid item xs={2}>
              <AddIconButton tooltipTitle="add part" onClick={addPart} />
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export default PickPartUi;
