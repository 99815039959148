import { Box } from '@mui/system';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import TController from './components/create/TController';
const heading: any = {
  setup: 'SETTING APPROVAL RECORDS',
  retro: 'RETROACTIVE CHECK INSPECTION RECORDS',
  suspected: 'SUSPECTED REPORT',
};
const Create = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const rowId = useQuery('rowId') || '';
  const annual_month = useQuery('annual_date') || '';
  const documentType = useQuery('documentType') || '';
  const operation_name = useQuery('operation_name') || '';
  const summary_date = useQuery('summary_date') || '';
  const [operation, setOperation] = useState([]);
  const [moduleData, setModuleData] = useState<any>({});

  const isView = useQuery('isView') === 'true' ? true : false;

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/holdCardRoute/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  const onSubmit = async () => {
    if (id !== 'undefined') {
      await axios
        .put(`/api/holdCardRoute/${id}`, moduleData)
        .then((res) => {
          if (res.data) {
            alert('updated successfully ');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    } else {
      // moduleData.annual_month = annual_month;
      moduleData.summary_row_id = rowId;
      // moduleData.document_type = documentType;
      await axios
        .post(
          `/api/holdCardRoute/${rowId}/${annual_month}`, // ?documentType=${documentType}
          moduleData
        )
        .then((res) => {
          alert('created successfully');
          navigate(-1);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Box>
      <Box>
        <ChildHeader text={heading[documentType]}>
          {!isView && (
            <>
              <SubmitButton
                label={moduleData._id ? `UPDATE` : `SUBMIT`}
                onClick={() => onSubmit()}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          // getProcess,
          operation,
          isView,
        }}
      />
    </Box>
  );
};

export default Create;
