import InteractiveIcon from '../../../../../components/common/InteractiveIcon';
import useConfirm from '../../../../../components/common/useConfirm';
import React from 'react';

interface IProps {
  item: any;
  isView: boolean;
  index: number;
  allmonth: any;
  updateModuleData: any;
}

const PlanType = {
  isPlan: 'P',
  isReplanPlan: 'R',
};

const TBodyRight = ({
  item,
  isView,
  index,
  allmonth,
  updateModuleData,
}: IProps) => {
  const [DialogisCheck, setDialogisCheck] = useConfirm(
    'Chnage Plan',
    'Are you sure you want to Change Plan?'
  );

  const handleDoubleClick = async (event: any, month: string) => {
    if (event.detail === 2) {
      const ans = await setDialogisCheck();
      if (!ans) return;

      // Determine the new state for `p`
      let newP = '';
      if (item[month].p === '') {
        newP = PlanType.isPlan;
      } else if (item[month].p === PlanType.isPlan) {
        newP = PlanType.isReplanPlan;
      }

      updateModuleData(index, month, { p: newP });
    }
  };

  return (
    <>
      {allmonth.map((month: string) => (
        <td
          style={{
            padding: '0px',
            margin: '0px',
          }}
          onClick={(e) => handleDoubleClick(e, month)}
        >
          <InteractiveIcon
            isYellow={item[month].p === PlanType.isPlan}
            isBlue={item[month].p === PlanType.isReplanPlan}
          >
            {item[month].p}
          </InteractiveIcon>
        </td>
      ))}
      <DialogisCheck isSubmitContent={false} />
    </>
  );
};

export default React.memo(TBodyRight);
