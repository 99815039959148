export const checkListList = {
  childHeader: 'Maintenance STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'gaugeMaintenancePlanStatusRoute/getAllSatusByYear',
  upadateListStatus: 'gaugeMaintenancePlanStatusRoute/handleStatusUpdate',
  checklistUpload: 'gaugeMaintenancePlanStatusRoute/handleUpload',
  checklistDeleteImage: 'gaugeMaintenancePlanStatusRoute/handleDeleteImage',
  autoCompleteUrl: 'gaugesRoute/gauges/ForAutocomplete',
};

export const navigationConstant = {
  checklistRouter: '/common/gaugeMaintenanceStatusCheckList',
};
