import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { TableCustom } from '../../../../../components/common';
import ApprovalSelect from './ApprovalSelect';
import formatDate from '../../../../../components/common/formatDate';
import {
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import { UpdateButton } from '../../../../../components/common/button/CustomButtons';
import { useAuth } from '../../../../../services/auth/AuthProvider';
import CustomUploadButton from '../../../../../components/common/button/CustomUploadButton';

interface IProps {
  handleChangeStatus: (
    e: React.ChangeEvent<HTMLSelectElement>,
    documentIndex: number,
  ) => void;
  deleteDocument: (id: string) => void;
  updateStatus: (id: string, documentIndex: number) => void;
  statusObj: {
    pending: number;
    accepted: number;
    rejected: number;
  };
  tableData: {
    [key: string | number]: any;
  }[];
  uploadImage: (id: string) => void;
  setImages: React.Dispatch<React.SetStateAction<null>>;
}

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ITEM NUMBER',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ITEM NAME/DESCRIPTION',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'WELD SHOP INSPECTION DATE',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'NAME',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'STATUS',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ACTION',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'UPDATE STATUS',
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const AdminTable = ({
  handleChangeStatus,
  deleteDocument,
  updateStatus,
  statusObj,
  tableData,
  uploadImage,
  setImages,
}: IProps) => {
  const navigate = useNavigate();
  const { state } = useAuth();
  const { user } = state;
  return (
    <React.Fragment>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {tableData.map(
            (document: { [key: string]: any }, documentIndex: number) => (
              <TableRow>
                <TableCell align="center">{documentIndex + 1}</TableCell>
                <TableCell align="center">
                  {document.part_id?.part_number}
                </TableCell>
                <TableCell align="center">
                  {document.part_id?.part_name}
                </TableCell>
                <TableCell align="center">
                  {formatDate(document.pir_date)}
                </TableCell>
                <TableCell align="center">
                  {document.report_prepared_by}
                </TableCell>

                {/*<TableCell align="center">
                  <CustomUploadButton
                    lable={'UPLOAD DRAWING'}
                    accept="image/png,image/jpeg,image/jpg"
                    onChange={(e) => setImages(e.target.files)}
                  />
                </TableCell>*/}
                <TableCell align="center">
                  <ApprovalSelect
                    {...{
                      handleChangeStatus,
                      document,
                      documentIndex,
                      statusObj,
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <ViewIconButton
                    tooltipTitle="view"
                    onClick={() =>
                      navigate(
                        `/common/weldshopcreate/${document.part_id?._id}/${document._id}?isView=true&partName=${document.part_id?.part_name}&partNumber=${document.part_id?.part_number}`,
                      )
                    }
                  />
                  <EditIconButton
                    tooltipTitle="edit"
                    onClick={() =>
                      navigate(
                        `/common/weldshopcreate/${document.part_id?._id}/${document._id}?isView=false&partName=${document.part_id?.part_name}&partNumber=${document.part_id?.part_number}`,
                      )
                    }
                  />
                  <DeleteIconButton
                    tooltipTitle="delete"
                    onClick={() => deleteDocument(document._id)}
                  />
                  {/*<UpdateButton
                    label="update img"
                    onClick={() => uploadImage(document._id)}
                  />*/}
                </TableCell>
                <TableCell align="center">
                  <UpdateButton
                    label="update"
                    size="small"
                    onClick={() => {
                      updateStatus(document._id, documentIndex);
                    }}
                  />
                </TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </TableCustom>
    </React.Fragment>
  );
};

export default AdminTable;
