import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../../components/ui/ChildHeader';
import {
  CustomButton,
  UpdateButton,
} from '../../../../components/common/button/CustomButtons';
import { useAuth } from '../../../../services/auth/AuthProvider';
import { useQuery } from '../../../../hooks/UseQuery';
import useConfirm from '../../../../components/common/useConfirm';
import TController from './components/TController';
import ModalCustom from '../../../../components/common/Modal';
import PickPartAnnual from './components/PickPartAnnualPlan';
import { apiConstant } from '../constant';

const CreateSummarySheet = () => {
  const [moduleData, setModuleData] = useState<any>({ summary: [] });
  const navigate = useNavigate();
  const [render, setRender] = useState(false);
  const { state } = useAuth();
  const { id } = useParams();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [partIdx, setPartIdx] = useState(0);
  const isView = useQuery('isView') === 'true' ? true : false;
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/${apiConstant.getPlanByid}/${id}`)
        .then((res) => {
          setModuleData({ ...moduleData, ...res.data });
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?',
  );
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?',
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?',
  );

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;

    axios
      .put(`/api/${apiConstant.updatePlanByid}/${id}`, moduleData)
      .then((res) => alert('updated successfully '))
      .catch((err) => console.log(err));
  };

  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.summary.splice(rowIdx, 1);
    setModuleData(data);
  };
  return (
    <>
      <Box>
        <ChildHeader text="Process & Product Audit Summary Sheet">
          {!isView && (
            <>
              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  const ans = await confirmAddRow();
                  if (!ans) return;
                  moduleData.summary.push({});
                  setRender((prev) => !prev);
                }}
              >
                ADD ROWS
              </CustomButton>
              <UpdateButton label="Update" onClick={() => handleSumbit()} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handelDeleteRows,
          setIsOpenPopup,
          setPartIdx,
        }}
      />
      <DialogDeleteRow isSubmitContent={false} />
      <DialogAddRow />
      <DialogSubmit />
      <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PickPartAnnual
          moduleData={moduleData.summary[partIdx]}
          {...{
            partIdx,
          }}
        />
      </ModalCustom>
    </>
  );
};

export default CreateSummarySheet;
