import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import {
  CustomButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../components/common/Loader';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import TController from './components/create/TController';

const Create = () => {
  const { partId } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [moduleData, setModuleData] = useState({ part_id: partId, list: [] });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/mispStandardRoute/${partId}`)
      .then((res) => {
        if (res.data) {
          setModuleData(res.data);
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onSubmit = async () => {
    if (partId) {
      await axios
        .post(`/api/mispStandardRoute/${partId}`, moduleData)
        .then((res) => {
          if (res.data) {
            alert('save successfully');
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const addRow = () => {
    const ans = window.confirm('Are you sure you want to add row');
    if (!ans) return;
    let values: any = { ...moduleData };
    values.list.push({});
    setModuleData(values);
  };
  const removeRow = (index: number) => {
    const ans = window.confirm('Are you sure you want to add row');
    if (!ans) return;
    let values: any = { ...moduleData };
    values.list.splice(index, 1);
    setModuleData(values);
  };
  const handleChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const values: any = { ...moduleData };
    values.list[index][name] = value;
    setModuleData(values);
  };
  const importData = (e: any) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', e.target.files[0], 'iqs.xlsx');
    e.target.value = '';
    axios
      .post('/api/mispStandardRoute/excelUpload/importData', formData)
      .then((res) => {
        if (res.data) {
          const uploadData = res.data.partsArray;
          const values: any = { ...moduleData };
          values.list = [...uploadData, ...moduleData.list];
          setModuleData(values);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.err) {
          window.alert(err.response.data.err);
        } else {
          window.alert(
            'Something thing went wrong. Please check if you are uplaoding the correct excel sheet or the excel sheet might be currupted.',
          );
        }
      });
  };
  return (
    <Box>
      <Box>
        <Loader loader={isLoading} />
        <ChildHeader text="MIS-P STANDARDS">
          {!isView && (
            <>
              <CustomUploadButton lable={'UPLOAD'} onChange={importData} />
              <CustomButton
                sx={{
                  backgroundColor: '#228be6',
                  color: '#e7f5ff',
                  mr: 2,
                }}
                icon={<MdAdd />}
                size="large"
                onClick={() => addRow()}
              >
                ADD ROW
              </CustomButton>
              <SubmitButton label={'Save'} onClick={() => onSubmit()} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          handleChange,
          moduleData,
          removeRow,
          addRow,
          isView,
        }}
      />
    </Box>
  );
};

export default Create;
