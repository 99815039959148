const style1 = {
  background: '#FFF2CC',
  color: '#343a40',
  minWidth: '100px',
  height: '50px',
};
const style2 = {
  backgroundColor: '#1971c2',
  color: '#f1f3f5',
  minWidth: '100px',
};

export const tableHeaderObj = [
  [
    { text: 'PART / PROCESS FLOW NO.', style: style2, rowspan: 2 },
    { text: 'PART / PROCESS DIAGRAM', style: style2, rowspan: 2 },
    {
      text: 'PART / PROCESS NO.',
      style: style2,
      rowspan: 2,
    },
    { text: 'PROCESS NAME / OPERATION DESCRIPTION', style: style2, rowspan: 2 },
    {
      text: 'MACHINE. DEVICE, TOOLS & JIGS FOR MFG.',
      style: style2,
      rowspan: 2,
    },
    { text: 'NO.', style: style2, rowspan: 2 },
    { text: 'PRODUCT CHARACTERISTICS', style: style2, rowspan: 2 },
    { text: 'PROCESS CHARACTERISTICS', style: style2, rowspan: 2 },
    { text: 'SPL. CHAR. CLASS', style: style2, rowspan: 2 },
    {
      text: 'PRODUCT / PROCESS SPECIFICATION TOLERANCES',
      style: style2,
      rowspan: 2,
    },
    { text: 'EVALUATION MEASUREMENT TECHNIQUES', style: style2, rowspan: 2 },
    { text: 'SAMPLE SIZE', style: style2, rowspan: 2 },
    { text: 'SAMPLE FREQUENCY', style: style2, rowspan: 2 },
    {
      text: 'CONTROL METHOD \n INSPECTION  / TESTING / MEASUREMENT',
      style: style2,
      rowspan: 2,
    },
    { text: 'RECORDS', style: style2, rowspan: 2 },
    { text: 'REACTION PLAN', style: style2, rowspan: 2 },
    { text: 'ERROR PROOFING', style: style2, rowspan: 2 },
    { text: 'CORRECTIVE ACTION', style: style2, rowspan: 2 },
    { text: 'RESPONSIBILITY', style: style2, rowspan: 2 },
    { text: 'PROCESS CAPABILITY', style: style2, rowspan: 2 },
    { text: 'CONTROL INCHARGE', style: style2, rowspan: 2 },
    { text: 'SUBMISSION FREQUENCY OF DATA', style: style2, rowspan: 2 },
    { text: 'PRODUCTION CONDITION CONTROL', style: style2, colspan: 7 },
    { text: 'REMARKS', style: style2, colspan: 2, rowspan: 2 },
  ],
  [
    { text: 'CONTROL POINT', style: style2 },
    { text: 'CONTROL VALUE', style: style2 },
    { text: 'PERSON INCHARGE', style: style2 },
    { text: 'CHECK METHOD', style: style2 },
    { text: 'CHECKING FREQUENCY', style: style2 },
    { text: 'DATA RECORD FORM NO.', style: style2 },
    { text: 'DATA RECORDING', style: style2 },
  ],
];

export const cpHeaderStandard = [
  [
    { text: 'PART / PROCESS FLOW NO.', style: style2 },
    { text: 'PART / PROCESS DIAGRAM', style: style2 },
    {
      text: 'PART / PROCESS NO.',
      style: style2,
    },
    { text: 'PROCESS NAME / OPERATION DESCRIPTION', style: style2 },
    { text: 'MACHINE. DEVICE, TOOLS & JIGS FOR MFG.', style: style2 },
    { text: 'NO.', style: style2 },
    { text: 'PRODUCT CHARACTERISTICS', style: style2 },
    { text: 'PROCESS CHARACTERISTICS', style: style2 },
    { text: 'SPL. CHAR. CLASS', style: style2 },
    { text: 'PRODUCT / PROCESS SPECIFICATION TOLERANCES', style: style2 },
    { text: 'EVALUATION MEASUREMENT TECHNIQUES', style: style2 },
    { text: 'SAMPLE SIZE', style: style2 },
    { text: 'SAMPLE FREQUENCY', style: style2 },
    {
      text: 'CONTROL METHOD \n INSPECTION  / TESTING / MEASUREMENT',
      style: style2,
    },
    { text: 'RECORDING', style: style2 },
    { text: 'REACTION PLAN', style: style2 },
    { text: 'CORRECTIVE ACTION', style: style2 },
    { text: 'RESPONSIBILITY', style: style2 },
    { text: 'CHECKED BY', style: style2 },
    { text: 'REMARKS', style: style2 },
  ],
];
