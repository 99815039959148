import React, { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { IProcess } from '../../helpers/Interface';
// import { spclChar } from '../../helpers/SpecialCharacters';

interface IProps {
  moduleData: any;
  tabValue: number;
}

const TBody = ({ moduleData, tabValue }: IProps) => {
  return (
    <tbody>
      {moduleData &&
        moduleData?.levels[tabValue]?.question.map(
          (item: any, index: number) => (
            <>
              <tr>
                <td rowSpan={2}>{item.s_no}</td>
                <td height="50px" rowSpan={2}>
                  {item.question}
                </td>

                <td height="50px" width="30px" align="center">
                  <span
                    style={{
                      fontSize: '1rem',
                      fontWeight: '500',
                    }}
                  >
                    a
                  </span>
                </td>
                <td height="50px">{item.option_1}</td>
                <td height="50px" width="30px" align="center">
                  <span
                    style={{
                      fontSize: '1rem',
                      fontWeight: '500',
                    }}
                  >
                    b
                  </span>
                </td>
                <td height="50px">{item.option_2}</td>
                <td rowSpan={2}></td>
                <td rowSpan={2}></td>
              </tr>
              <tr>
                <td height="50px" width="30px" align="center">
                  <span
                    style={{
                      fontSize: '1rem',
                      fontWeight: '500',
                    }}
                  >
                    c
                  </span>
                </td>
                <td height="50px">{item.option_3}</td>
                <td height="50px" width="30px" align="center">
                  <span
                    style={{
                      fontSize: '1rem',
                      fontWeight: '500',
                    }}
                  >
                    d
                  </span>
                </td>
                <td height="50px">{item.option_4}</td>
              </tr>
            </>
          )
        )}
    </tbody>
  );
};

export default TBody;
