import {
  Clause8,
  CustomerComplaintList,
  ListOfDefects,
  CustomerComplaintDashboardTabs,
} from '../../pages/clause8Page';

// main route of clause 10

export const clause8 = [
  {
    path: 'clause8',
    element: <Clause8 />,
  },
  {
    path: 'defect_list',
    element: <ListOfDefects />,
  },
  {
    path: 'customerComplaintRegister',
    element: <CustomerComplaintList />,
  },
  {
    path: 'customerComplaintDashboard',
    element: <CustomerComplaintDashboardTabs />,
  },
];
