export const listConstant = {
  childHeader: 'Mean Time To Repair (MTTR)',
  addButtonLable: 'New MTTR',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW MTTR',
};

export const createConstant = {
  childHeader: 'MTTR list',
  theadHeader: 'Mean Time To Repair (MTTR)',
  extracolSpan: 3,
};

export const checkListList = {
  childHeader: 'LAYOUT STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'mttrRoute',
  gettableDataurl: 'mttrRoute/yearly',
  getPlanByid: 'mttrRoute',
  createPlanByid: 'mttrRoute',
  updatePlanByid: 'mttrRoute',
  sync: 'layoutplanRoute/handleSync',
  getAllCheckList: 'layoutplanRoute/getAllCheckList',
  checkListStatus: 'layoutplanRoute/handleStatus',
};

export const navigationConstant = {
  createPlanSchedule: '/common/mttrCreate',
};
