export const apiConstant = {
  // list api url
  gettableListDataurl: 'revalidationPlanRoute/monthlylist',
  deleteListurl: 'revalidationPlanRoute',
  getListPlanByid: 'revalidationPlanRoute',
  createListPlanByid: 'revalidationPlanRoute',
  // -----plan--
  getAllPlanById: 'revalidationPlanRoute/allplansbyid',
  upadateCreateAllPlan: 'revalidationPlanRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'revalidationPlanRoute/maintenancePlanDeleteBulkById',
  sync: 'revalidationPlanRoute/handleSync',
  // ----status---
  getAllStatus: 'revalidationPlanRoute/getAllSatusByYear',
  upadateListStatus: 'revalidationPlanRoute/handleStatusUpdate',

  //
  updatePlanByid: 'revalidationPlanRoute',
  checklistUpload: 'revalidationPlanRoute/handleUpload',
  checklistDeleteImage: 'revalidationPlanRoute/handleDeleteImage',
  checklistData: 'revalidationPlanRoute/checklistRoute',
};

export const tilesConstant = {
  childHeaderText: 'REVALIDATION',
};
