import { Box } from '@mui/system';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import TController from './components/create/TController';
import { useEffect, useState } from 'react';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import { Stack } from '@mui/material';

const getTopRpn = (processes: any) => {
  let rpnValues: number[] = [];

  // Flatten the processes array and extract all RPN values
  processes?.forEach((process: any) => {
    process.fmeas?.forEach((fmea: any) => {
      const rpn = (+fmea.detection || 1) * +fmea.occ * (+fmea.severity || 1);
      rpnValues.push(rpn);
    });
  });

  // Sort the array of RPN values in descending order
  rpnValues.sort((a, b) => b - a);

  // Take the top three RPN values
  const topThreeRpn = rpnValues.slice(0, 3);

  return topThreeRpn;
};

const Create = () => {
  const { id } = useParams();
  const [moduleData, setModuleData] = useState({ processes: [] });
  const [topThreeRpn, setTopThreeRpn] = useState<number[]>([]);
  const isView = useQuery('isView') === 'true' ? true : false;
  const [DialogSave, confirmSave] = useConfirm(
    'SAVE',
    'Are you sure you want to Update this?',
  );
  useEffect(() => {
    axios
      .get(`/api/fmeaManagementRoute/generateFmea/ById/${id}`)
      .then((res) => {
        if (res.data) {
          setModuleData(res.data);
          let rpnArr = getTopRpn(res.data.processes);
          setTopThreeRpn(rpnArr);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const onSubmit = async () => {
    const ans = await confirmSave();
    if (!ans) return;
    if (id) {
      axios
        .put(`/api/fmeaManagementRoute/generateFmea/${id}`, moduleData)
        .then((res) => {
          if (res.data) {
            alert('updated successfully');
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const handleChangeThead = (e: any) => {
    const { name, value } = e.target;
    const values: any = { ...moduleData };
    values[name] = value;
    setModuleData(values);
  };
  const handleChangeTbody = (
    e: any,
    processIndex: number,
    fmeaIndex: number,
  ) => {
    const { name, value } = e.target;
    const values: any = { ...moduleData };
    values.processes[processIndex].fmeas[fmeaIndex][name] = value;
    setModuleData(values);
  };

  return (
    <Box>
      <Box>
        <ChildHeader text="GENERATED FMEA REPORT">
          {!isView && (
            <>
              <Stack
                direction="row"
                sx={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <SubmitButton label={'Save'} onClick={() => onSubmit()} />
              </Stack>
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          topThreeRpn,
          handleChangeThead,
          handleChangeTbody,
          moduleData,
          isView,
        }}
      />
      <DialogSave isSubmitContent={false} />
    </Box>
  );
};

export default Create;
