import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { Table } from '../../../../components/ui/renderTable/styled';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
  itemData: any;
  isView?: boolean;
}
const style1 = {
  fontSize: '1.8rem',
};
const MachineTable = ({ itemData, isView = false }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <Table style={{ width: '84vh' }}>
      <thead>
        <tr>
          <th colSpan={5} style={{ ...style1 }} align="left">
            Details of Machinary & Inspection Equipments:
          </th>
        </tr>
        <tr>
          <th>S.No</th>
          <th>Description</th>
          <th>Quantity</th>
          <th>Make</th>
          <th>Date of Mfg.</th>
        </tr>
      </thead>
      <tbody>
        {itemData?.map((item: any, index: number) => (
          <tr>
            <th style={{ width: '20px' }}>{index + 1}</th>

            <td style={{ textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="desc"
                key="desc"
                type="text"
                value={item.desc}
                onChange={(e) => {
                  item.desc = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="qty"
                key="qty"
                type="number"
                value={item.qty}
                onChange={(e) => {
                  item.qty = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="make"
                key="make"
                type="text"
                value={item.make}
                onChange={(e) => {
                  item.make = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ width: '180px' }}>
              <DatePickerMui
                label=""
                value={moment(item.date_of_mfg)}
                onChange={(date) => {
                  item.date_of_mfg = date;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default MachineTable;
