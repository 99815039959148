import React, { useEffect, useRef, useState } from 'react';
import GridInputs from '../../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import useConfirm from '../../../../components/common/useConfirm';
import GridDatePicker from '../../../../components/common/inputs/GridDatePicker';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import { Grid, MenuItem, SelectChangeEvent, Stack } from '@mui/material';
import GridSelect from '../../../../components/common/inputs/GridSelect';
import { Loader } from '../../../../components/common/Loader';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [machineData, setMachineData] = useState([]);
  const [isPartModal, setIsPartModal] = useState(false);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update BreakDown Log' : 'Create BreakDown Log'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        const data = res.data;
        data.forEach((res: any) => {
          res[
            'machine_no_name'
          ] = `${res['machine_no']} - ${res['machine_name']}`;
        });
        setMachineData(data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/maintenaceLogRoute/${id}`)
        .then((res) => {
          const data = res.data;
          if (data.machine_id) {
            data.machine_id.machine_no_name = `${data.machine_id['machine_no']} - ${data.machine_id['machine_name']}`;
          }
          setData(data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    if (!data.machine_id) {
      alert('Please enter Machine Name');
      return;
    }
    if (!data.complaint_recevied_at) {
      alert('Please enter Complaint recevied');
      return;
    }
    if (!data.complaint_rectified_at) {
      alert('Please enter Complaint Rectified');
      return;
    }

    const ans = await confirmSubmit();
    if (!ans) return;

    setIsLoading(true);
    const formData = new FormData();

    if (typeof data.machine_id === 'object') {
      data.machine_id = data.machine_id._id;
    }

    for (const key in data) {
      console.log(key, data[key]);
      if (data[key]) {
        formData.append(key, data[key]);
      }
    }

    if (id) {
      await axios
        .put(`/api/maintenaceLogRoute/${id}`, data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/maintenaceLogRoute', data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };
  return (
    <>
      <Loader loader={isLoading} />
      <Stack spacing={1}>
        <GridDatePicker
          label_name="Date"
          html_for={'date'}
          value={data?.date || null}
          onChange={(date) => {
            data.date = date;
          }}
        />
        <GridInputs
          id={'location'}
          name={'location'}
          html_for={'location'}
          label_name={'Location:'}
          input_type={'text'}
          focusType={'onblur'}
          value={data?.location || ''}
          onChange={handleCustomerData}
        />
        <Grid item xs={3} style={{ marginTop: '10px' }}>
          <AutocompleteMuiCustom
            id={'machine_name'}
            label={'Machine name'}
            option_name={'machine_no_name'}
            arrayofObj={machineData}
            value={data.machine_name || data.machine_id || ''}
            onChange={(e, value) => {
              data.machine_id = value;
              setRender((prev) => !prev);
            }}
          />
        </Grid>
        <div
          style={{
            display: 'flex',
            marginLeft: '100px',
            marginRight: '100px',
          }}
        >
          <div style={{ marginRight: '20px' }}>
            <TimePicker
              label="Complaint Recevied At"
              views={['hours', 'minutes']}
              ampm={false}
              value={moment(data?.complaint_recevied_at || null)}
              onChange={(date: any) => {
                data.complaint_recevied_at = date;
                setRender((prev) => !prev);
              }}
            />
          </div>
          <div>
            <TimePicker
              label="Complaint Rectified At"
              views={['hours', 'minutes']}
              ampm={false}
              value={moment(data?.complaint_rectified_at || null)}
              onChange={(date: any) => {
                data.complaint_rectified_at = date;
                setRender((prev) => !prev);
              }}
            />
          </div>
        </div>

        <GridInputs
          id={'complaint_recevied'}
          name={'complaint_recevied'}
          html_for={'complaint_recevied'}
          label_name={'Complaint Recevied:'}
          input_type={'text'}
          focusType={'onblur'}
          value={data?.complaint_recevied || ''}
          onChange={handleCustomerData}
        />
        <GridInputs
          id={'work_done'}
          name={'work_done'}
          html_for={'work_done'}
          label_name={'Work Done:'}
          input_type={'text'}
          focusType={'onblur'}
          value={data?.work_done || ''}
          onChange={handleCustomerData}
        />
        <GridInputs
          id={'spare_part'}
          name={'spare_part'}
          html_for={'spare_part'}
          label_name={'Add Spare Part'}
          input_type={'text'}
          focusType={'onblur'}
          value={data?.spare_part || ''}
          onChange={handleCustomerData}
        />

        <GridInputs
          id={'spare_qty'}
          name={'spare_qty'}
          html_for={'spare_qty'}
          label_name={'No. of spare used:'}
          input_type={'number'}
          focusType={'onblur'}
          value={data?.spare_qty || ''}
          onChange={handleCustomerData}
        />
        <GridSelect
          last_child={1}
          name="status"
          label_name="Remarks/Status"
          html_for={'status'}
          value={data?.status || ''}
          onChange={handleCustomerData}
        >
          <MenuItem value="OK">OK</MenuItem>
          <MenuItem value="NOT OK">NOT OK</MenuItem>
        </GridSelect>
      </Stack>
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
