import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';

interface IProps {
  name: any;
  moduleData: any;
  handleChange: any;
  phase: any;
  index: number;
  isView: boolean;
}
const SelectResponsibleDeparment = ({
  name,
  moduleData,
  handleChange,
  phase,
  index,
  isView,
}: IProps) => {
  return (
    <>
      {isView ? (
        moduleData[phase][index][name]
      ) : (
        <CustomSelect
          name={name}
          value={moduleData[phase][index][name]}
          sx={{ width: '70px' }}
          onChange={(e) => {
            handleChange(e, phase, index);
          }}
        >
          <MenuItem value="">NONE</MenuItem>
          <MenuItem value="A">A</MenuItem>
          <MenuItem value="B">B</MenuItem>
        </CustomSelect>
      )}
    </>
  );
};

export default SelectResponsibleDeparment;
