import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../components/ui';
import { convToTime } from '../../../../../../utils/helpers';
import { useQuery } from '../../../../../../hooks/UseQuery';

interface IProps {}
const THead = ({}: IProps) => {
  const annual_month = useQuery('annual_date') || '';
  return (
    <thead>
      <TableCompHead
        colSpan={5}
        rowSpan={2}
        labels={[]}
        values={[]}
        heading="DEFECT WISE PARETO CHART"
      />
      <tr style={{ background: '#9775fa', color: 'white' }}>
        <th
          colSpan={5}
          style={{ background: 'orange', color: 'black', fontWeight: 'bold' }}
        >
          For The Month : {annual_month}
        </th>
      </tr>
      <tr>
        {['Sr No.', 'Defect', 'NG Qty.', 'Cumm %', '%'].map((headingData) => (
          <th>{headingData}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
