import { TableCell } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';

const TableCellStatus = ({ partId }: { partId: string | undefined }) => {
  const [data, setData] = useState<any>({});
  useEffect(() => {
    if (partId) {
      axios
        .get(`/api/addPartRoute/isControlAndDrawingExits/${partId}`)
        .then((res) => {
          if (res.data) {
            setData(res.data);
          }
        });
    }
  }, [partId]);
  return (
    <>
      <TableCell align="center">
        <span
          style={{
            background: data.countCp === 0 ? '#f03e3e' : '#37b24d',
            padding: '10px 14px',
            borderRadius: '50%',
            color: 'white',
          }}
        >
          {data.countCp}
        </span>
      </TableCell>
      {/* <TableCell align="center">
        <span
          style={{
            background: data.countDrawing === 0 ? 'red' : 'green',
            padding: '10px 14px',
            borderRadius: '50%',
            color: 'white',
          }}
        >
          {data.countDrawing}
        </span>
      </TableCell> */}
    </>
  );
};

export default TableCellStatus;
