import { useState } from 'react';
import { TableCompHead } from '../../../../../components/ui';
import { createConstant } from '../../constant';
import { useQuery } from '../../../../../hooks/UseQuery';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '200px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
const stickStylesRow = {
  position: 'sticky',
  width: '100%',
  top: '-.5rem',
  border: '1px solid black',
  zIndex: '2',
  height: '50px',
} as const;
const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: '1',
  height: '50px',
  backgroundColor: '#4F81BD',
  color: '#fff',
} as const;
interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const yearRange = useQuery('yearRange');
  const date = new Date(moduleData?.date_range)
    ?.getFullYear()
    .toString()
    .slice(-2);
  return (
    <thead>
      <TableCompHead
        colSpan={24 + createConstant.extracolSpan}
        rowSpan={4}
        labels={['Format No.:', 'Issue Date:', 'Rev. No.', 'Rev. Date:']}
        values={['FRM-QS-007', '01/04/2019', '01', '01/09/2021']}
        heading={`${createConstant.theadHeader} (YEAR : ${yearRange})`}
      />
      <tr style={{ ...stickStylesRow }}>
        {[
          'S. No.',
          'Part Name',
          'Subpart 1',
          'Subpart 2',
          'Subpart 3',
          'Subpart 4',
          'Subpart 5',
          'Subpart 6',
          'Subpart 7',
          'Subpart 8',
          'Subpart 9',
          'Subpart 10',
          'Plan/Actual',
        ].map((el, index) => (
          <th
            key={`${index}_head`}
            align="center"
            style={
              index <= 1
                ? {
                    ...stickStylesColumn,
                    left: 50 * index,
                    width: '50px',
                    minWidth: '50px',
                  }
                : {
                    textAlign: 'center',
                    ...style1,
                    minWidth: `${
                      (el === 'S. No.' && '50px') || style1.minWidth
                    }`,
                  }
            }
          >
            {el}
          </th>
        ))}

        {[
          'APR',
          'MAY',
          'JUN',
          'JUL',
          'AUG',
          'SEP',
          'OCT',
          'NOV',
          'DEC',
          'JAN',
          'FEB',
          'MAR',
        ].map((el, index) => (
          <th
            key={`${index}_headcol`}
            align="center"
            style={{ textAlign: 'center', ...style2 }}
          >
            {el}-{index > 8 ? +date + 1 : date}
          </th>
        ))}
        <th style={{ textAlign: 'center', ...style1 }}>Remarks</th>
        <th style={{ textAlign: 'center', ...style1 }}>ACTION</th>
      </tr>
    </thead>
  );
};

export default THead;
