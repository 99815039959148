import criticalParameters from '../img/criticalParameters.png';
import minorParameters from '../img/minorParmeters.png';
import outSourceProcess from '../img/outSourceProcess.png';
interface SpclProp {
  [key: string]: JSX.Element;
}
export const spclChar: SpclProp = {
  CP: (
    <img
      src={criticalParameters}
      alt="critical parameteres"
      height={30}
      width={50}
    />
  ),
  MP: (
    <img src={minorParameters} alt="minor parameteres" height={30} width={50} />
  ),
  OP: (
    <img
      src={outSourceProcess}
      alt="outsource process"
      height={30}
      width={50}
    />
  ),
};
