import { useEffect, useState } from 'react';
import axios from 'axios';
import lodash from 'lodash';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../../components/ui/ChildHeader';
import {
  CustomButton,
  UpdateButton,
} from '../../../../components/common/button/CustomButtons';
import { useQuery } from '../../../../hooks/UseQuery';
import useConfirm from '../../../../components/common/useConfirm';
import TController from './components/TController';
import {
  checklist_for_doc_updation,
  effectiveness_verification,
  horizontol_deployement,
  immediate_action_correction,
  spare_part_used,
  systematic_action_plan,
  why_desc,
} from '../helpers/analysis';

const Create = () => {
  const [moduleData, setModuleData] = useState<any>({});
  const [render, setRender] = useState(false);
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/breakdownRecordRoute/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id, render]);

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    await axios
      .put(`/api/breakdownRecordRoute/${id}`, moduleData)
      .then((res) => {
        if (res.data) {
          alert('Updated Successfully');
          setRender((prev) => !prev);
        }
      })
      .catch((err) => console.log(err));
  };

  const handelDeleteRows = async (rowIdx: number, key: string) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };

    switch (key) {
      case 'immediate_action_correction':
        data?.['immediate_action_correction']?.splice(rowIdx, 1);
        setModuleData(data);
        return;
      case 'why_desc':
        data?.['why_desc']?.splice(rowIdx, 1);
        setModuleData(data);
        return;
      case 'systematic_action_plan':
        data?.['systematic_action_plan']?.splice(rowIdx, 1);
        setModuleData(data);
        return;
      case 'checklist_for_doc_updation':
        data?.['checklist_for_doc_updation']?.splice(rowIdx, 1);
        setModuleData(data);
        return;
      case 'spare_part_used':
        data?.['spare_part_used']?.splice(rowIdx, 1);
        setModuleData(data);
        return;
      case 'horizontol_deployement':
        data?.['horizontol_deployement']?.splice(rowIdx, 1);
        setModuleData(data);
        return;
      case 'effectiveness_verification':
        data?.['effectiveness_verification']?.splice(rowIdx, 1);
        setModuleData(data);
        return;
      default:
        return;
    }
  };

  const handleAddRow = async (key: string) => {
    const ans = await confirmAddRow();
    if (!ans) return;
    let data = { ...moduleData };
    switch (key) {
      case 'immediate_action_correction':
        data?.['immediate_action_correction']?.push(
          lodash.cloneDeep(immediate_action_correction)
        );
        setModuleData(data);
        return;
      case 'why_desc':
        data?.['why_desc']?.push(lodash.cloneDeep(why_desc));
        setModuleData(data);
        return;
      case 'systematic_action_plan':
        data?.['systematic_action_plan']?.push(
          lodash.cloneDeep(systematic_action_plan)
        );
        setModuleData(data);
        return;
      case 'checklist_for_doc_updation':
        data?.['checklist_for_doc_updation']?.push(
          lodash.cloneDeep(checklist_for_doc_updation)
        );
        setModuleData(data);
        return;
      case 'spare_part_used':
        data?.['spare_part_used']?.push(lodash.cloneDeep(spare_part_used));
        setModuleData(data);
        return;
      case 'horizontol_deployement':
        data?.['horizontol_deployement']?.push(
          lodash.cloneDeep(horizontol_deployement)
        );
        setModuleData(data);
        return;
      case 'effectiveness_verification':
        data?.['effectiveness_verification']?.push(
          lodash.cloneDeep(effectiveness_verification)
        );
        setModuleData(data);
        return;

      default:
        return;
    }
  };

  return (
    <>
      <Box>
        <ChildHeader text="BREAKDOWN ANALYSIS REPORT">
          {!isView && (
            <UpdateButton label="Update" onClick={() => handleSumbit()} />
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handelDeleteRows,
          handleAddRow,
        }}
      />
      <DialogDeleteRow isSubmitContent={false} />
      <DialogAddRow />
      <DialogSubmit />
    </>
  );
};

export default Create;
