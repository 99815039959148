import { HiDocumentReport } from 'react-icons/hi';
import { RenderTiles } from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';

export type tilesType = {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number;
  p: string;
  span: string;
  icon?: any;
  style?: {};
}[];
const tiles = [
  {
    sNo: 1,
    isLink: true,
    link: '/common/tniTiles',
    clauseNo: 5,
    icon: <HiDocumentReport size="5rem" color="#135089" />,
    p: 'IDENTIFY TRAINING NEEDS &',
    span: 'TRAINING TOPICS',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/trainingCalenderTiles',
    isLink: false,
    clauseNo: 5,
    icon: <HiDocumentReport size="5rem" color="#135089" />,
    p: 'TRAINING',
    span: 'CALENDARS',
    isIcon: true,
  },
];

const TrainingTniHome = () => {
  return (
    <>
      <ChildHeader text="TRAINING" />
      <RenderTiles tilesObj={tiles} height={'100vh'} justify="start" />
    </>
  );
};

export default TrainingTniHome;
