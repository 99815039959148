import moment from 'moment';
import { useState } from 'react';
import { Divider, Stack } from '@mui/material';
import ResponsiveGridForm from '../../../../components/common/dynamicForm/ResponsiveGridForm';
import { createFormFields } from './formFieldsFactory';

interface IProps {
  moduleData: any;
  setRenderParent: any;
  controlPlanId: any;
  isView: boolean;
  tabValue: number;
}

const ProcessOverview = ({
  moduleData,
  tabValue,
  isView,
  setRenderParent,
  controlPlanId,
}: IProps) => {
  const [render, setRender] = useState(false);

  const formFields: any = createFormFields(moduleData, isView, setRender);

  return (
    <>
      <Divider sx={{ m: 1 }} />
      <ResponsiveGridForm cols={8} formFieldArr={formFields} />
      <Divider sx={{ m: 1 }} />
    </>
  );
};

export default ProcessOverview;
