import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
interface IProps {
  moduleData: any;
  isView: boolean;
  handleChangeTbody: (e: any, fmeaIndex: number, processIndex: number) => void;
  handleChangeThead: (e: any) => void;
  topThreeRpn: number[];
}

const TController = ({
  handleChangeThead,
  handleChangeTbody,
  moduleData,
  topThreeRpn,
  isView,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            handleChangeThead,
            moduleData,
            isView,
          }}
        />
        <TBody
          processes={moduleData.processes}
          {...{
            topThreeRpn,
            handleChangeTbody,
            isView,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
