const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.No.', style: style1 },
    { text: 'Parameter', style: style1 },
    { text: 'Specification', style: style1 },
    { text: 'Method', style: style1 },
    // { text: 'Observation Before', style: style1, colspan: 5 },
    { text: 'Result', style: style1 },
    // { text: 'Date', style: style1 },
    // { text: 'Next Due Date', style: style1 },
    { text: 'Sign.', style: style1 },
    { text: 'Remarks', style: style1 },
    { text: 'Action', style: { ...style1, backgroundColor: '#c92a2a' } },
  ],
];

export const listConstant = {
  childHeader: 'Gauge Maintenance Checksheet list',
  addButtonLable: 'New Checksheet',
};

export const headerConstant = {
  tableCompHeadTitle: 'Guages PREVENTIVE MAINTENANCE CHECKSHEET ',
};

export const apiConstant = {
  get: 'masterCheckListRouteGaugeMaintenancePlan',
  getById: 'masterCheckListRouteGaugeMaintenancePlan',
  post: 'masterCheckListRouteGaugeMaintenancePlan',
  update: 'masterCheckListRouteGaugeMaintenancePlan',
  delete: 'masterCheckListRouteGaugeMaintenancePlan',
  upload: 'masterCheckListRouteGaugeMaintenancePlan/uploadFile',
  machineDataForAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const tilesConstant = {
  childHeaderText: 'Gauge Maintenance',
};

export const navigationConstant = {
  createEditChecklist: '/common/gaugeMaintenanceChecklistCreate',
};
