import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import InputMuiCustom from '../../../../../../components/common/inputs/InputMuiCustom';
interface IProps {
  moduleData: any;
  allmonth: any;
  isView: boolean;
  breakDownTime: any;
}

const allmonth = [
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
  'january',
  'february',
  'march',
];

const TBody = ({ moduleData, isView, allmonth, breakDownTime }: IProps) => {
  const [render, setRender] = useState(false);

  const getAverage = (index: number) => {
    let sum = 0;
    for (const month in moduleData?.list[index]) {
      if (month !== 'item' && month != 'remarks' && month != '_id') {
        sum += +moduleData?.list[index][month];
      }
    }
    return (sum / 12).toFixed(2);
  };

  const getTargetAverage = () => {
    let sum = 0;
    for (const targetMonth in moduleData) {
      if (
        targetMonth !== 'title' &&
        targetMonth != 'date_range' &&
        targetMonth != '_id' &&
        targetMonth != 'list' &&
        targetMonth != 'createdAt' &&
        targetMonth != 'updatedAt'
      ) {
        sum += +moduleData[targetMonth];
      }
    }
    return (sum / 12).toFixed(2);
  };
  const getBreakDownTime = (monthName: string) => {
    moduleData.list[0][monthName] = breakDownTime[monthName];
    return breakDownTime[monthName];
  };
  const getMttr = (monthName: any) => {
    const breakDown_time = getBreakDownTime(monthName);
    const no_of_reapairs = moduleData?.list[1]?.[monthName];
    if (no_of_reapairs === 0) {
      moduleData.list[2][monthName] = 0;
      return 0;
    }
    moduleData.list[2][monthName] = breakDown_time / no_of_reapairs;
    return (breakDown_time / no_of_reapairs).toFixed(2);
  };

  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: number) => (
        <>
          <tr key={`${index}_table_row`}>
            <td>{index + 1}</td>
            <td>{item?.item}</td>
            {allmonth.map((monthName: string) => {
              return (
                <td>
                  <TableInput
                    isView={index === 0 || index === 2 ? true : false}
                    style={{
                      height: '80px',
                      textAlign: 'center',
                      paddingLeft: '10px',
                    }}
                    name=""
                    type="number"
                    value={
                      (index == 0 && getBreakDownTime(monthName)) ||
                      (index === 2 && getMttr(monthName)) ||
                      item[monthName]
                    }
                    onChange={(e) => {
                      item[monthName] = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              );
            })}
            <td>{getAverage(index)}</td>
            <td>
              <InputMuiCustom
                placeholder={''}
                name=""
                type="text"
                value={item?.remarks}
                onChange={(e) => {
                  item.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        </>
      ))}
      <tr>
        <td></td>
        <td>Target</td>
        {allmonth.map((montName: string) => {
          return (
            <td>
              <TableInput
                isView={isView}
                style={{
                  height: '80px',
                  textAlign: 'center',
                  paddingLeft: '10px',
                }}
                name=""
                type="number"
                value={moduleData?.[`target_${montName}`]}
                onChange={(e) => {
                  moduleData[`target_${montName}`] = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          );
        })}
        <td>{getTargetAverage()}</td>
        <td></td>
      </tr>
    </tbody>
  );
};

export default TBody;
