import { useEffect, useState } from 'react';
import { Box, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import {
  AddButton,
  UpdateButton,
} from '../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { PersitedDatePicker, TableCustom } from '../../../../components/common';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../../components/common/button/CustomIconButton';
import { useQuery } from '../../../../hooks/UseQuery';
import useConfirm from '../../../../components/common/useConfirm';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../../services/auth/AuthProvider';
import formatDate from '../../../../components/common/formatDate';
import ModalCustom from '../../../../components/common/Modal';
import StatusModal from './StatusModal';

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'NAME',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'PM Date',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ACTION',
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const List = () => {
  const { toolId } = useParams();
  const date = useQuery('date');
  const tool_name = useQuery('tool_name');
  const tool_no = useQuery('tool_no');
  const [tableData, setTableData] = useState<any>([]);
  const [render, setRender] = useState(false);
  const navigate = useNavigate();
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this document?'
  );
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [listId, setListId] = useState('');

  useEffect(() => {
    if (!date) return;
    axios
      .get(`/api/toolCheckSheetRoute/monthly/${date}/${toolId}`)
      .then((res) => {
        if (res?.data) setTableData(res?.data);
      })
      .catch((err) => console.log(err));
  }, [render, date, isOpenPopup]);

  const deleteDocument = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    axios
      .delete(`/api/toolCheckSheetRoute/${id}`)
      .then(() => {
        alert('document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  };

  return (
    <>
      <ChildHeader text="TOOL PREV. MAINT. CHECK SHEET">
        <AddButton
          onClick={() =>
            navigate(
              `/common/tool/status/checklist/create?toolId=${toolId}&tool_name=${tool_name}&tool_no=${tool_no}`
            )
          }
          label="NEW REPORT"
        />
      </ChildHeader>
      <Box sx={{ margin: 2 }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
      </Box>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {tableData.map((document: any, documentIndex: number) => (
            <TableRow>
              <TableCell align="center">{documentIndex + 1}</TableCell>
              <TableCell align="center">{tool_name}</TableCell>

              <TableCell align="center">
                {document?.pm_date && formatDate(document.pm_date)}
              </TableCell>
              <TableCell align="center">
                <EditIconButton
                  tooltipTitle="Edit"
                  onClick={() =>
                    navigate(
                      `/common/tool/status/checklist/create/${document._id}?tool_name=${tool_name}&tool_no=${tool_no}&isView=false`
                    )
                  }
                />
                <ViewIconButton
                  tooltipTitle="edit"
                  onClick={() =>
                    navigate(
                      `/common/tool/status/checklist/create/${document._id}?tool_name=${tool_name}&tool_no=${tool_no}&isView=true`
                    )
                  }
                />
                <DeleteIconButton
                  tooltipTitle="delete"
                  onClick={() => deleteDocument(document._id)}
                />
                <UpdateButton
                  label="update status"
                  sx={{ margin: '10px' }}
                  size="small"
                  onClick={() => {
                    setListId(document);
                    setIsOpenPopup(true);
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
