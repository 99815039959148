import React, { useState } from 'react';
import ObservationInput from './ObservationInput';
import { TableInput } from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
import moment from 'moment';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
interface IProps {
  item: any;
  index: any;
  isView: boolean;
}

const TBodyRow = ({ index, item, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>
          {isView ? (
            formatDate(item.date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(item?.date || null)}
              onChange={(date) => {
                item.date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </td>
        <td>
          <TableInput
            name="operator_name"
            type="text"
            value={item?.operator_name}
            onChange={(e) => {
              item.operator_name = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </td>
        <td>
          <TableInput
            name="current_apm"
            type="number"
            value={item?.current_apm}
            onChange={(e) => {
              item.current_apm = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </td>
        <td>
          <TableInput
            name="Voltate"
            type="number"
            value={item?.Voltate}
            onChange={(e) => {
              item.Voltate = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </td>
        <td>
          <TableInput
            name="mig_welding_length"
            type="number"
            value={item?.mig_welding_length}
            onChange={(e) => {
              item.mig_welding_length = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </td>
        <td>
          <TableInput
            name="appearance"
            type="text"
            value={item?.appearance}
            onChange={(e) => {
              item.appearance = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </td>
        <td>
          <TableInput
            name="production_qty"
            type="number"
            value={item?.production_qty}
            onChange={(e) => {
              item.production_qty = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </td>
        <td></td>
      </tr>
    </>
  );
};

export default TBodyRow;
