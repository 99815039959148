import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import { IData } from './helpers/Interface';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';

import lodash from 'lodash';
import { initialState } from './helpers/initialState';
import MachineTable from './components/create/MachineTable';
import PersonHeadTable from './components/create/PersonHeadTable';
import SelectGridMui from '../../components/common/selectscustom/SelectGridMui';
import { Checkbox, MenuItem } from '@mui/material';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [vendorData, setVendorData] = useState<IData>({
    ...lodash.cloneDeep(initialState),
  });
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Vendor' : 'Create Ventor'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [render, setRender] = useState(false);
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/vendorRoute/${id}`)
        .then((res) => {
          if (!(res.data.machine_details.length > 0)) {
            res.data.machine_details = initialState.machine_details;
          }
          setVendorData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleCustomerData(e: React.ChangeEvent<HTMLInputElement>) {
    setVendorData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/vendorRoute/${id}`, vendorData)
        .then((res: any) => {
          if (res?.data?.error?.message === 'vendor must be unique') {
            return alert('same vendor name exists');
          }
          setIsModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .post('/api/vendorRoute', vendorData)
        .then((res: any) => {
          if (res?.data?.error?.message === 'vendor must be unique') {
            return alert('same vendor name exists');
          }
          setIsModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'vender_name'}
        name={'vender_name'}
        html_for={'vender_name'}
        label_name={'Supplier Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.vender_name || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'vender_code'}
        name={'vender_code'}
        html_for={'vender_code'}
        label_name={'Supplier Code:'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.vender_code || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'address'}
        name={'address'}
        html_for={'address'}
        label_name={'Address: '}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.address || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'district'}
        name={'district'}
        html_for={'district'}
        label_name={'District'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.district || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'pincode'}
        name={'pincode'}
        html_for={'pincode'}
        label_name={'Pincode'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.pincode || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'state'}
        name={'state'}
        html_for={'state'}
        label_name={'State'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.state || ''}
        onChange={handleCustomerData}
      />

      <GridInputs
        id={'contact_person'}
        name={'contact_person'}
        html_for={'contact_person'}
        label_name={'MD Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.contact_person || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'contact_no'}
        name={'contact_no'}
        html_for={'contact_no'}
        label_name={'Contact No.:'}
        input_type={'number'}
        focusType={'onblur'}
        value={vendorData?.contact_no || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'fax'}
        name={'fax'}
        html_for={'fax'}
        label_name={'Fax:'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.fax || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'email_id'}
        name={'email_id'}
        html_for={'email_id'}
        label_name={'Email ID:'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.email_id || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'commodity'}
        name={'commodity'}
        html_for={'commodity'}
        label_name={'Material:'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.commodity || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.remarks || ''}
        onChange={handleCustomerData}
        last_child={2}
      />
      <GridInputs
        id={'factory_reg_no'}
        name={'factory_reg_no'}
        html_for={'factory_reg_no'}
        label_name={'Factory Registration No:'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.factory_reg_no || ''}
        onChange={handleCustomerData}
        last_child={2}
      />
      <GridInputs
        id={'gst_reg_no'}
        name={'gst_reg_no'}
        html_for={'gst_reg_no'}
        label_name={'Gst Registration No.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.gst_reg_no || ''}
        onChange={handleCustomerData}
        last_child={2}
      />
      <SelectGridMui
        name="iso_9001_attach_copy"
        value={vendorData?.iso_9001_attach_copy || ''}
        lable={'ISO9001/ IATF (Attach print copy)'}
        onChange={(e: any) => handleCustomerData(e)}
      >
        <MenuItem value="YES">YES</MenuItem>
        <MenuItem value="NO">NO</MenuItem>
      </SelectGridMui>
      <div>
        <Checkbox
          name="exclude_report"
          checked={vendorData?.exclude_report}
          onChange={(e) => {
            vendorData.exclude_report = e.target.checked;
            setRender((prev) => !prev);
          }}
        />

        <span
          style={{
            fontWeight: '500',
            fontSize: '20px',
          }}
        >
          Exclude from report
        </span>
      </div>
      <PersonHeadTable moduleData={vendorData} />
      <MachineTable itemData={vendorData?.machine_details} />

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
