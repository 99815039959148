import React, { useState } from 'react';
import Heading from './moduleBody/Heading';
import { IModuleData, IProcess } from '../../helpers/Interface';
// import Process from './moduleBody/Process';
import useConfirm from '../../../../components/common/useConfirm';
import GridInputs from '../../../../components/common/inputs/GridInputs';
import InputMuiCustom from '../../../../components/common/inputs/InputMuiCustom';
import { FlexBox } from '../../../../components/common';
import ExpandLess from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  DivStyled,
  InputStyled,
  GridfourStyled,
} from '../../questionPaperStyle';

interface IProps {
  moduleData: any;
  setModuleData: React.Dispatch<React.SetStateAction<any>>;
  currentLevelIndex: number;
}

const ModuleBody = ({
  moduleData,
  setModuleData,
  currentLevelIndex,
}: IProps) => {
  const [expandState, setExpandState] = useState<any>([]);
  const [render, setRender] = useState(false);
  const [DialogRemoveProcess, confirmRemoveProcess] = useConfirm(
    'REMOVE',
    'Are you sure you want to remove this question?'
  );
  const removeProcess = async (index: number) => {
    const isDelete = await confirmRemoveProcess();
    if (!isDelete) return;
    // if (isDelete) {
    const values = { ...moduleData };
    values.processes && values.levels.splice(index, 1);
    setModuleData(values);
    // }
  };
  const handleChangeProcess = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    moduleData.levels[index][name as keyof IProcess] = value;
    moduleData.levels?.sort(
      (a: { s_no: any }, b: { s_no: any }) => Number(a.s_no) - Number(b.s_no)
    );
    setRender((prev) => !prev);
  };
  const sortQuestionPaper = (e: any, index: number) => {
    moduleData.levels[currentLevelIndex].question[index].s_no = +e.target.value;
    moduleData.levels[currentLevelIndex].question.sort(
      (a: any, b: any) => Number(a.s_no) - Number(b.s_no)
    );
    setRender((prev) => !prev);
  };

  return (
    <>
      <Heading
        {...{
          setExpandState,
          moduleData,
          setRender,
          setModuleData,
        }}
        currentLevelIndex={currentLevelIndex}
      />
      {moduleData &&
        moduleData?.levels[currentLevelIndex].question.map(
          (item: any, index: any) => (
            <div
              style={{
                marginTop: '0.5rem',
                marginLeft: '2rem',
                marginRight: '2rem',
              }}
            >
              <div
                // key={process?.key}
                style={{
                  marginBottom: '0.3rem',
                  marginTop: '0.6rem',
                  padding: '0rem',
                  border: '1px solid #f0f2f0',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    background: '#fafafa',
                    alignItems: 'center',
                  }}
                  className="spec-header"
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '2rem',
                    }}
                  >
                    <FlexBox>
                      <InputMuiCustom
                        type="number"
                        name="s_no"
                        placeholder=""
                        onBlur={(e) => sortQuestionPaper(e, index)}
                        defaultValue={item?.s_no}
                        sx={{ width: '70px' }}
                      />
                    </FlexBox>
                    <div style={{ fontSize: '1rem' }}>{item?.question}</div>
                  </div>

                  <FlexBox>
                    {expandState[index] ? (
                      <FlexBox
                        style={{
                          height: '45px',
                          width: '130px',
                          backgroundColor: '#003566',
                          cursor: 'pointer',
                          color: 'white',
                          alignItems: 'center',
                          gap: '0.5rem',
                          // justifyContent: "center",
                        }}
                        onClick={() =>
                          setExpandState((prev: any) => {
                            prev[index] = false;
                            return [...prev];
                          })
                        }
                      >
                        <ExpandLess style={{ marginLeft: '0.5rem' }} />
                        <h1>Collapse</h1>
                      </FlexBox>
                    ) : (
                      <FlexBox
                        style={{
                          height: '45px',
                          width: '130px',
                          backgroundColor: '#003566',
                          cursor: 'pointer',
                          color: 'white',
                          gap: '0.5rem',
                          // justifyContent: "center",
                        }}
                        onClick={() =>
                          setExpandState((prev: any) => {
                            prev[index] = true;
                            return [...prev];
                          })
                        }
                      >
                        <ExpandMore style={{ marginLeft: '0.5rem' }} />
                        <h1>Expand</h1>
                      </FlexBox>
                    )}

                    <FlexBox
                      style={{
                        height: '45px',
                        width: '45px',
                        // backgroundColor: " #212529",
                        color: '#f1f3f5',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '0.5rem',
                        backgroundColor: '#D70012',
                      }}
                      justify="center"
                      onClick={() => removeProcess(index)}
                    >
                      <DeleteIcon style={{ color: '#f8f9fa' }} />
                    </FlexBox>
                  </FlexBox>
                </div>
              </div>
              {expandState[index] && (
                <DivStyled>
                  <FlexBox>
                    <h1>Q{item.s_no}:</h1>
                    <InputMuiCustom
                      // width="95%"
                      sx={{
                        width: '92vw',
                        marginBottom: '15px',
                        marginTop: '10px',
                      }}
                      type="text"
                      name="question"
                      placeholder="Question"
                      defaultValue={item?.question}
                      onBlur={(e) => {
                        item[e.target.name] = e.target.value;
                      }}
                    />
                  </FlexBox>

                  <GridfourStyled>
                    <FlexBox>
                      <h1>A:</h1>
                      <InputMuiCustom
                        // width="30vw"
                        sx={{ width: '30vw', marginBottom: '15px' }}
                        type="text"
                        name="option_1"
                        placeholder="Option 1"
                        defaultValue={item?.option_1}
                        onBlur={(e) => {
                          item[e.target.name] = e.target.value;
                        }}
                      />
                    </FlexBox>
                    <FlexBox>
                      <h1>B:</h1>
                      <InputMuiCustom
                        // width="30vw"
                        sx={{ width: '30vw', marginBottom: '15px' }}
                        type="text"
                        name="option_2"
                        placeholder="Option 2"
                        defaultValue={item?.option_2}
                        onBlur={(e) => {
                          item[e.target.name] = e.target.value;
                        }}
                      />
                    </FlexBox>
                    <FlexBox>
                      <h1>C:</h1>
                      <InputMuiCustom
                        // width="30vw"
                        sx={{ width: '30vw', marginBottom: '15px' }}
                        type="text"
                        name="option_3"
                        placeholder="Option 3"
                        defaultValue={item?.option_3}
                        onBlur={(e) => {
                          item[e.target.name] = e.target.value;
                        }}
                      />
                    </FlexBox>
                    <FlexBox>
                      <h1>D:</h1>
                      <InputMuiCustom
                        // width="30vw"
                        sx={{ width: '30vw', marginBottom: '15px' }}
                        type="text"
                        name="option_4"
                        placeholder="Option 4"
                        defaultValue={item?.option_4}
                        onBlur={(e) => {
                          item[e.target.name] = e.target.value;
                        }}
                      />
                    </FlexBox>
                  </GridfourStyled>
                </DivStyled>
              )}
            </div>
          )
        )}
      <DialogRemoveProcess />
    </>
  );
};

export default ModuleBody;
