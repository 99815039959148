export const listConstant = {
  childHeader: 'Mean Time Between Failures (MTBF)',
  addButtonLable: 'New MTBF',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW MTBF',
};

export const createConstant = {
  childHeader: 'MTBF list',
  theadHeader: 'Mean Time Between Failures (MTBF)',
  extracolSpan: 3,
};

export const checkListList = {
  childHeader: 'LAYOUT STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'mtbfRoute',
  gettableDataurl: 'mtbfRoute/yearly',
  getPlanByid: 'mtbfRoute',
  createPlanByid: 'mtbfRoute',
  updatePlanByid: 'mtbfRoute',
  sync: 'layoutplanRoute/handleSync',
  getAllCheckList: 'layoutplanRoute/getAllCheckList',
  checkListStatus: 'layoutplanRoute/handleStatus',
};

export const navigationConstant = {
  createPlanSchedule: '/common/mtbfCreate',
};
