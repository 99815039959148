import { useState } from 'react';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
      <tr>
        <th colSpan={10}>
          Criteria for identifying category of product development has been
          defined as below :
        </th>
        <th colSpan={5}>Notes:</th>
      </tr>
      <tr>
        <th rowSpan={2} colSpan={1}>
          S No.
        </th>
        <th rowSpan={2} colSpan={6}>
          Type of Development
        </th>
        <th rowSpan={2} colSpan={3}>
          Category
        </th>
        <th style={{ borderTop: '0', borderBottom: 'none' }} colSpan={5}>
          Department Codes :
        </th>
      </tr>
      <tr>
        <th style={{ borderTop: '0', borderBottom: 'none' }} colSpan={5}>
          NPD Head- New Product Development{' '}
        </th>
      </tr>
      <tr>
        <th rowSpan={2} colSpan={1}>
          1
        </th>
        <th rowSpan={2} colSpan={6}>
          Any similar type of product has not been produced earlier
        </th>
        <th rowSpan={2} colSpan={3}>
          A
        </th>
        <th style={{ borderTop: '0', borderBottom: 'none' }} colSpan={5}>
          QA-Quality Appraiser ,
        </th>
      </tr>
      <tr>
        <th style={{ borderTop: '0', borderBottom: 'none' }} colSpan={5}>
          CFT-Cross Functional Team,
        </th>
      </tr>

      <tr>
        <th rowSpan={2} colSpan={1}>
          2
        </th>
        <th rowSpan={2} colSpan={6}>
          A similar type of product has been produced earlier.
        </th>
        <th rowSpan={2} colSpan={3}>
          B
        </th>
        <th style={{ borderTop: '0', borderBottom: 'none' }} colSpan={5}>
          PR-Production,
        </th>
      </tr>
      <tr>
        <th style={{ borderTop: '0', borderBottom: 'none' }} colSpan={5}>
          MN-Maintenance
        </th>
      </tr>

      <tr>
        <th colSpan={1}>3</th>
        <th colSpan={6}>Existing product ECN</th>
        <th colSpan={3}>C</th>
        <th style={{ borderTop: '0', borderBottom: 'none' }} colSpan={5}>
          MKT & PUR:- Marketing & Purchase
        </th>
      </tr>
      <tr>
        <th colSpan={1}>4</th>
        <th colSpan={6}>Location Change /PCN </th>
        <th colSpan={3}>D</th>
        <th style={{ borderTop: '0' }} colSpan={5}>
          HR HEAD:- Human resources
        </th>
      </tr>

      <tr>
        <th rowSpan={2} colSpan={1}>
          S No
        </th>
        <th rowSpan={2} colSpan={2} style={{ minWidth: '500px' }}>
          Activity
        </th>
        <th colSpan={4}>Category of Development</th>
        <th colSpan={8}>Responsible Departments (P-Primary, S-Secondary)</th>
      </tr>
      <tr>
        <th>A</th>
        <th>B</th>
        <th>C</th>
        <th>D</th>
        <th>NPD Head</th>
        <th>QA</th>
        <th>CFT PR</th>
        <th> PR</th>
        <th>MN</th>
        <th>MKT & PUR</th>
        <th>HR HEAD</th>
      </tr>
    </thead>
  );
};

export default THead;
