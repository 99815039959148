import styled from 'styled-components';
import { styled as muiStyled, createTheme, ThemeProvider } from '@mui/system';
import TextField from '@mui/material/TextField';

export const MyStyledInput = muiStyled(TextField)({
  padding: 1, // means "1px", NOT "theme.spacing(1)"
});

export const MyTextField = styled(TextField)`
  margin: 9rem;
`;

export const LoginContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 62% 38%;
  background-color: white;
`;

export const LeftSideContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const LoginBox = styled.div`
  padding: 0px 34px;
  background-color: #1d1d1d;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > h1 {
    margin-bottom: 3rem;
    font-size: 44px;
    color: #fafafa;
  }
`;

export const Form = styled.form`
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  width: 63%;
  padding: 1rem;
  overflow: hidden;
  background: white;
  display: flex;
  gap: 5px;
  font-size: 1.6rem;
  flex-direction: column;
  background-color: #f8f9fa;
  padding: 50px;
`;

export const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex: 0.4;
`;

export const StyledLabel = styled.label`
  font-size: 1.6rem;
  margin-bottom: 0.9rem;
`;
