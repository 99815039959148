import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

interface ifeildType {
  id: string;
  option_name: string;
}

const AutocompleteMuiCustom = ({
  id,
  option_name,
  option2,
  onChange,
  value,
  arrayofObj,
  label,
  sx,
  ...rest
}: {
  id: string;
  value: string | {};
  option_name: string;
  option2?: string;
  arrayofObj: [] | any;
  label?: string;
  variant?: 'standard';
  sx?: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, value?: any) => void;
}) => {
  return (
    <>
      <Autocomplete
        id={id}
        options={arrayofObj}
        getOptionLabel={(option) => {
          const properties = option_name.split('.');
          const option1Label =
            properties.reduce(
              (obj: any, property) => obj?.[property],
              option
            ) || '';

          // Use option2 if provided
          let option2Label = '';
          if (option2) {
            const properties2 = option2.split('.');
            option2Label =
              properties2.reduce(
                (obj: any, property) => obj?.[property],
                option
              ) || '';
          }

          return option2 ? `${option1Label} - ${option2Label}` : option1Label;
        }}
        isOptionEqualToValue={(option, value) => {
          const properties = option_name.split('.');
          const optionValue = properties.reduce(
            (obj: any, property) => obj?.[property],
            option
          );
          const selectedValue = properties.reduce(
            (obj: any, property) => obj?.[property],
            value
          );
          return optionValue === selectedValue || true;
        }}
        value={value}
        onChange={(e: any, value) => onChange(e, value)}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
            label={label}
            {...rest}
          />
        )}
        sx={{ ...sx }}
      />
    </>
  );
};

export default AutocompleteMuiCustom;
