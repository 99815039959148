import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { apiConstant } from './constant';
import useConfirm from '../../../../components/common/useConfirm';
import { useQuery } from '../../../../hooks/UseQuery';

const useGetData = (id: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({
    list: [],
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [machineData, setMachineData] = useState([{}]);
  const [Sync, confirmSync] = useConfirm(
    'SYNC',
    'Are you sure you want to sync? Save any changes before sync!',
  );

  const handleDelete = useCallback((index: number) => {
    setModuleData((prev: any) => {
      const newList = prev?.list?.filter((_: any, i: number) => i !== index);
      return { ...prev, list: newList };
    });
  }, []);

  const updateModuleData = useCallback(
    (
      updateType: 'key-pair' | 'row',
      rowIndex: number | null,
      key: string,
      newValues: any,
    ) => {
      setModuleData((prev: any) => {
        const updatedModuleData: any = { ...prev };
        if (updateType === 'key-pair') {
          updatedModuleData[key] = newValues[key];
        } else if (updateType === 'row') {
          //specific row that needs updating
          if (rowIndex === null) return;
          const updatedRow = { ...updatedModuleData.list[rowIndex] };
          updatedRow[key] = newValues[key];
          updatedModuleData.list[rowIndex] = updatedRow;
        }
        return updatedModuleData;
      });
    },
    [],
  );

  const addRow = useCallback(() => {
    setModuleData((prev: any) => {
      return {
        ...prev,
        list: [...prev.list, { _tempId: `temp-id-${new Date().getTime()}` }],
      };
    });
  }, []);

  useEffect(() => {
    axios
      .get(`/api/${apiConstant.machineDataForAutoComplete}`)
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      axios
        .get(`/api/${apiConstant.getById}/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, [id]);

  return {
    moduleData,
    isLoading,
    setIsLoading,
    error,
    Sync,
    updateModuleData,
    handleDelete,
    machineData,
    addRow,
  };
};

export default useGetData;
