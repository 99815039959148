import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import { TableCompHead } from '../../../../../../components/ui';
import { useQuery } from '../../../../../../hooks/UseQuery';
import { StyledCircle } from './StyledCircle';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '200px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData: any;
  isView: boolean;
  tniTopic: any;
}
const THead = ({ isView, tniTopic, moduleData }: IProps) => {
  const yearRange = useQuery('yearRange');
  const category = useQuery('category');
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={7 + tniTopic.length}
        rowSpan={4}
        labels={['DOC. NO.', 'REV. NO.', 'LAST UPDATED', 'NEXT REVIEW DATE']}
        values={['F09 HRD 01', '00', '03.10.2022', '03.10.2022']}
        heading={`SKILL MATRIX ${category} (YEAR : ${yearRange})`}
      />
      <tr>
        <th style={{ fontSize: '3rem' }}>◔</th>
        <th align="left" colSpan={6 + tniTopic.length}>
          <TableInput
            name="l1_legend"
            type="text"
            value={moduleData.l1_legend}
            onChange={(e) => {
              moduleData.l1_legend = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th style={{ fontSize: '2rem' }}>◑</th>
        <th align="left" colSpan={6 + tniTopic.length}>
          <TableInput
            name="l2_legend"
            type="text"
            value={moduleData.l2_legend}
            onChange={(e) => {
              moduleData.l2_legend = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th style={{ fontSize: '3rem' }}>◕</th>
        <th align="left" colSpan={6 + tniTopic.length}>
          <TableInput
            name="l3_legend"
            type="text"
            value={moduleData.l3_legend}
            onChange={(e) => {
              moduleData.l3_legend = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th style={{ fontSize: '4rem' }}>●</th>
        <th align="left" colSpan={6 + tniTopic.length}>
          <TableInput
            name="l4_legend"
            type="text"
            value={moduleData.l4_legend}
            onChange={(e) => {
              moduleData.l4_legend = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={7 + tniTopic.length}>
          Note : Skill Matrix to be reviewed Within Six month.
        </th>
      </tr>
      <tr>
        {[
          'S. No.',
          'Name of Employee',
          'Department',
          'Current Skill Level',
          'Skill Evaluation Criteria',
        ].map((el, index) => (
          <th
            key={`${index}_head`}
            align="center"
            rowSpan={el === 'Skill Evaluation Criteria' ? 1 : 2}
            style={{
              textAlign: 'center',
              ...style1,
              minWidth: `${(el === 'S. No.' && '50px') || style1.minWidth}`,
            }}
          >
            {el}
          </th>
        ))}
        {tniTopic.map((el: any, index: number) => (
          <th
            key={`${index}_headcol`}
            align="center"
            style={{ textAlign: 'center', ...style2 }}
          >
            {el.name}
          </th>
        ))}
        <th
          rowSpan={2}
          align="center"
          style={{ textAlign: 'center', ...style1 }}
        >
          Remarks
        </th>
        <th
          rowSpan={2}
          align="center"
          style={{ textAlign: 'center', ...style1 }}
        >
          Action
        </th>
      </tr>
      <tr>
        <th style={{ ...style1 }}>Minimum Skill Required</th>
        {tniTopic.map((el: any, index: number) => (
          <StyledCircle item={el} />
        ))}
      </tr>
    </thead>
  );
};

export default THead;
