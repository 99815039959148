import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputMuiCustom from '../../components/common/inputs/InputMuiCustom';
import moment from 'moment';
import DatePickerMui from '../../components/common/DatePickerMui';
import AutocompleteMuiCustom from '../../components/common/AutocompleteMuiCustom';
import axios from 'axios';

const CreateHeader = ({
  customer_name,
  partName,
  partNumber,
  msaData,
}: any) => {
  const [render, setRender] = useState<Boolean>(false);
  const [instrumentData, setInstrumentData] = useState<any>([]);

  useEffect(() => {
    axios
      .get(`/api/msaRoute/masterListInstrument`)
      .then((res) => {
        const temp = res.data;
        temp.forEach((data: any) => {
          data.all = `${data?.category_id?.name}, ${data?.id_no}, ${data?.range}, ${data?.department_id?.department_name}`;
          return data;
        });
        setInstrumentData(temp);
      })
      .catch((err) => console.log(err));
  }, []);
  // console.log(instrumentData);
  // console.log(msaData);

  return (
    <>
      <Box sx={{ flexGrow: 1, width: '98%', margin: '2rem auto' }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <InputMuiCustom
              type="string"
              name="Customer_name"
              variant={'outlined'}
              placeholder={'Customer Name'}
              value={customer_name || ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <InputMuiCustom
              type="string"
              name="part_name"
              variant={'outlined'}
              placeholder={'Part Name'}
              value={partName || ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <InputMuiCustom
              type="string"
              name="part_no"
              variant={'outlined'}
              placeholder={'Part No.'}
              value={partNumber || ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={2}>
            <AutocompleteMuiCustom
              id="category_id.name"
              label="Instrument Name"
              option_name="all"
              arrayofObj={instrumentData}
              value={msaData.instrument_id || ''}
              onChange={(e, value) => {
                msaData.instrument_id = value;
                msaData.range = value?.range;
                msaData.location = value?.department_id?.department_name;
                // msaData.location=value?.;
                // console.log(value);
                setRender((prev) => !prev);
              }}
            />
          </Grid>

          <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="range"
              placeholder={'Range'}
              value={msaData.range || ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="Location"
              placeholder={'Location'}
              value={msaData?.location || ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 0.2 }}>
          <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="guage_no"
              placeholder={'Gauge No'}
              onChange={(e) => {
                msaData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              value={msaData.guage_no}
            />
          </Grid>
          <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="characteristics"
              placeholder={'Characteristics'}
              onChange={(e) => {
                msaData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              value={msaData.characteristics}
            />
          </Grid>
          <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="specification"
              placeholder={'Specification'}
              onChange={(e) => {
                msaData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              value={msaData.specification || ''}
            />
          </Grid>
          <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="number"
              name="no_of_appraisers"
              placeholder={'No. of Appraisers'}
              value={msaData.no_of_appraisers || 3}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="number"
              name="no_of_trail"
              placeholder={'No fo Trail'}
              value={msaData.no_of_trail || 3}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 0.2 }}>
          <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="number"
              name="part_qty"
              placeholder={'Parts Qty'}
              onChange={(e) => {
                msaData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              value={msaData.part_qty}
            />
          </Grid>
          <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="appraisers_A"
              placeholder={'Appraisers A'}
              onChange={(e) => {
                msaData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              value={msaData.appraisers_A}
            />
          </Grid>
          <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="appraisers_B"
              placeholder={'Appraisers B'}
              onChange={(e) => {
                msaData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              value={msaData.appraisers_B}
            />
          </Grid>
          <Grid item xs={2}>
            <InputMuiCustom
              variant={'outlined'}
              type="text"
              name="appraisers_C"
              placeholder={'Appraisers C'}
              onChange={(e) => {
                msaData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              value={msaData.appraisers_C}
            />
          </Grid>
          <Grid item xs={2}>
            <DatePickerMui
              label="Date"
              value={moment(msaData.date || null)}
              onChange={(date) => {
                msaData.date = date;
                setRender((prev) => !prev);
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateHeader;
function useSate(arg0: boolean): [any, any] {
  throw new Error('Function not implemented.');
}
