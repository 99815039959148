import { Box } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  moduleData: any;
  tabValue: number;
}

const TController = ({ moduleData, tabValue }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, tabValue }} />
        <TBody {...{ moduleData, tabValue }} />
        {/* <TFoot /> */}
      </RenderTable>
    </Box>
  );
};

export default TController;
