import React, { useState, useEffect, useRef } from 'react';
import { Box, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import moment from 'moment';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import {
  CustomPagination,
  TableCustom,
  TextAreaInput,
} from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { IData } from './helpers/interfaces';
import ViewFile from './components/ViewFile';
import formatDate from '../../../components/common/formatDate';
import TableScroll from '../../../components/ui/renderTable/TableScroll';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';

const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1 },
    { text: 'Date of Occurance', rowspan: 1, colspan: 1 },
    { text: 'PART NAME', rowspan: 1, colspan: 1 },
    { text: 'Customer Name', rowspan: 1, colspan: 1 },
    { text: 'Defects', rowspan: 1, colspan: 1 },
    {
      text: 'Complaint category (Critical/Major)',
      rowspan: 1,
      colspan: 1,
    },
    { text: 'ROOT CAUSE', rowspan: 1, colspan: 1 },
    { text: 'ACTION TAKEN', rowspan: 1, colspan: 1 },
    { text: 'TARGET Date', rowspan: 1, colspan: 1 },
    { text: 'Actual Date', rowspan: 1, colspan: 1 },
    { text: 'RESP.', rowspan: 1, colspan: 1 }, // new
    { text: '1', rowspan: 1, colspan: 1 },
    { text: '2', rowspan: 1, colspan: 1 },
    { text: '3', rowspan: 1, colspan: 1 },
    { text: '4', rowspan: 1, colspan: 1 },
    { text: '5', rowspan: 1, colspan: 1 },
    { text: '6', rowspan: 1, colspan: 1 },
    { text: 'Horizontal Deployment', rowspan: 1, colspan: 1 }, // new
    { text: 'Quality Alert', rowspan: 1, colspan: 1 },
    { text: 'DRG / PFD', rowspan: 1, colspan: 1 },
    { text: 'FMEA/ QA Matrix', rowspan: 1, colspan: 1 },
    { text: 'Control Plan', rowspan: 1, colspan: 1 },
    { text: 'Inspection Std.', rowspan: 1, colspan: 1 }, // new
    { text: 'Work Instructions', rowspan: 1, colspan: 1 },
    { text: 'Lessons Learnt / Others', rowspan: 1, colspan: 1 }, // new
    { text: 'Remark/Status', rowspan: 1, colspan: 1 },
    { text: 'DEFECT PHOTO', rowspan: 1, colspan: 1 },
    { text: 'SUPPORTING DOCUMENTS', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [complaintId, setComplaintId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [id, setId] = useState<string | null>(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
  const [contentType, setContentType] = useState('');
  const [filterCustomer, setFilterCustomer] = useState<any>([]);
  const [filterDefectsReported, setFilterDefectsReported] = useState<any>([]);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const customer = useQuery('customer');
  const defect = useQuery('defects');
  const [all, setAll] = useState(false);
  const [docFiles, setDocFiles] = useState<any>({});
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenFiles, setIsOpenFiles] = useState(false);
  const idRef = useRef<any>({});
  const [files, setFiles] = useState<any>(null);
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );

  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((customer: any) => ({
            name: customer.customer_name,
            value: customer.customer_name,
          }));
          setFilterCustomer([...formattedData]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get('/api/defectRoutes/defectList/defectForAutoComplete')
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((defect: any) => ({
            name: defect.defect_name,
            value: defect.defect_name,
          }));
          setFilterDefectsReported([...formattedData]);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/customerComplaintRoutes/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}&customer=${customer}&defects=${defect}&all=${all}`
      )
      .catch((err) => {
        console.log(err);
      });

    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteCustomerConcern = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/customerComplaintRoutes/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };
  const uploadFile = async (e: any, item: any) => {
    if (!window.confirm('Are you sure you want upload this Files')) return;
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    for (const key of Object.keys(files)) {
      formData.append('files', files[key]);
    }
    setIsLoading(true);
    axios
      .patch(`/api/customerComplaintRoutes/handleUpload/${item._id}`, formData)
      .then((res) => {
        if (res.data) {
          alert('document upload successly');
          setIsLoading(false);
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useDebounce(fetchData, 500, [
    page,
    search,
    render,
    isModal,
    filter,
    customer,
    defect,
    all,
  ]);
  return (
    <>
      <ChildHeader text="Customer Complaint Register & Action Verification Sheet">
        <AddButton
          onClick={() => {
            setAll((value) => !value);
          }}
          label={all ? 'Hide' : 'Save'}
        />
        <AddButton
          onClick={() => setIsModal(true)}
          label="NEW CUSTOMER Complaint"
        />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <FilterBy
          name="customer"
          label="Filter By Customer"
          options={filterCustomer}
        />
        <FilterBy
          name="defects"
          label="Filter By Defects Reprted"
          options={filterDefectsReported}
        />
      </SearchFilterContainer>

      <TableScroll>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data &&
              data.map((item: IData, index: number) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center">
                      {perPage * (+page - 1) + index + 1}
                    </TableCell>
                    <TableCell align="center">
                      {item.issue_reported_date &&
                        formatDate(item.issue_reported_date)}
                    </TableCell>
                    <TableCell align="center">
                      {item.part_id?.part_name}
                    </TableCell>
                    <TableCell align="center">
                      {item.customer_id?.customer_name}
                    </TableCell>
                    <TableCell align="center">
                      {item.defect_id?.defect_name}
                    </TableCell>
                    <TableCell align="center">
                      {item.type_of_complaint}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: '200px' }}>
                      <TextAreaInput value={item.root_cause} />
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: '200px' }}>
                      <TextAreaInput value={item.action_taken} />
                    </TableCell>
                    <TableCell align="center">
                      {item.target_date && formatDate(item.target_date)}
                    </TableCell>
                    <TableCell align="center">
                      {item.actual_closure_date &&
                        formatDate(item.actual_closure_date)}
                    </TableCell>
                    <TableCell align="center">{item.resp}</TableCell>
                    {['jan', 'feb', 'mar', 'apr', 'may', 'jun'].map(
                      (month: any) => {
                        return (
                          <TableCell
                            style={{
                              ...(item[month] &&
                                item[month].toLowerCase() === 'repeated' && {
                                  background: '#e03131',
                                }),
                              ...(item[month] &&
                                item[month].toLowerCase() ===
                                  'not repeated' && {
                                  background: '#2f9e44',
                                }),
                              minWidth: '50px',
                            }}
                            align="center"
                          ></TableCell>
                        );
                      }
                    )}
                    <TableCell align="center">
                      {item.horizontal_deployment}
                    </TableCell>
                    <TableCell align="center">{item.quality_alert}</TableCell>
                    <TableCell align="center">{item.pfd}</TableCell>
                    <TableCell align="center">{item.pfmea}</TableCell>
                    <TableCell align="center">{item.control_plan}</TableCell>
                    <TableCell align="center">
                      {item.inspection_standard}
                    </TableCell>
                    <TableCell align="center">{item.wi}</TableCell>
                    <TableCell align="center">{item.others}</TableCell>
                    <TableCell align="center">{item.remarks}</TableCell>
                    <TableCell align="center">
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <CustomUploadButton
                          lable={'Defect photo'}
                          id={`${index}_iamges`}
                          multiple
                          accept="image/png,image/jpeg,image/jpg"
                          onChange={(e) => {
                            uploadFile(e, item);
                          }}
                        />
                        {item.images && item.images.length > 0 && (
                          <ViewIconButton
                            tooltipTitle="view"
                            onClick={() => {
                              setId(item._id || null);
                              setIsFileOpenPopup(true);
                              setContentType('image');
                            }}
                          />
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell align="center">
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <CustomUploadButton
                          lable={'Attach Doucments'}
                          id={`${index}_files`}
                          multiple
                          accept="application/pdf,application/doc,application/excel/,application/ppt"
                          onChange={(e) => {
                            uploadFile(e, item);
                          }}
                        />
                        {item.files && item.files.length > 0 && (
                          <ViewIconButton
                            tooltipTitle="view"
                            onClick={() => {
                              setId(item._id || null);
                              setIsFileOpenPopup(true);
                              setContentType('file');
                            }}
                          />
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            setComplaintId(item?._id || '');
                            setIsModal(true);
                          }}
                        />

                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            deleteCustomerConcern(item?._id || '');
                          }}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
      </TableScroll>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setComplaintId(null);
          setIsModal(false);
        }}
        title="CUSTOMER Complaint REGISTER"
      >
        <Create id={complaintId} setIsModal={setIsModal} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile id={id} contentType={contentType} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
