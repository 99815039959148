import moment from 'moment';
import { useState } from 'react';

import { TableCompHead } from '../../../../../../components/ui';
import formatDate from '../../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';

import { useQuery } from '../../../../../../hooks/UseQuery';
import { TableInput } from '../../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const heading = [
  'S. No.',
  'Parameter',
  'Specifications / Requirement',
  'Method of Checking',
  'Observation',
  'Status',
  'Remarks',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
      <TableCompHead
        colSpan={7}
        rowSpan={3}
        labels={['Doc. No.: ', 'Rev. No.:', 'Eff. Date:']}
        values={['FRM-MNT-B-013', '00', '01-04-2023']}
        heading={moduleData?.checkSheetType}
      />
      <tr>
        <th colSpan={2} align="left">
          PM Date :{' '}
          {isView ? (
            formatDate(moduleData.pm_date)
          ) : (
            <DatePickerMui
              label=""
              value={(moduleData.pm_date && moment(moduleData.pm_date)) || null}
              onChange={(date) => {
                moduleData.pm_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={2} align="left">
          Part Name:{' '}
          <TableInput
            isView={isView}
            name="part_name"
            type="text"
            value={moduleData?.part_name}
            onChange={(e) => {
              moduleData.part_name = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={3} align="left">
          Tool No: {moduleData?.tool_id?.tool_no}
        </th>
      </tr>
      <tr>
        {heading.map((header) => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
