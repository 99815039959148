import React, { useState } from 'react';
import { TableRowComponent } from './TableRowComponent';

interface IProps {
  moduleData: any;
  handleBulkDeleteFeature: any;
  selectionArray: any;
  updateModuleData: any;
  settings: any;
  machineData: any;
}
const TBody = ({
  moduleData,
  selectionArray,
  updateModuleData,
  handleBulkDeleteFeature,
  machineData,
  settings,
}: IProps) => {
  return (
    <tbody>
      {moduleData.map((item: any, index: number) => (
        <TableRowComponent
          key={item?._id || item?._tempId}
          item={item}
          // index={index}
          updateModuleData={updateModuleData}
          handleBulkDeleteFeature={handleBulkDeleteFeature}
          selectionArray={selectionArray}
          machineData={machineData}
          settings={settings}
          {...{ index }}
        />
      ))}
    </tbody>
  );
};

export default React.memo(TBody);
