import { Clause5 } from '../../pages/clause5page';
import List from '../../pages/employee/List';

export const clause5 = [
  {
    path: 'clause5',
    element: <Clause5 />,
  },
  {
    path: 'employee_list',
    element: <List />,
  },
];
