import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import tiles from './Tiles';
export type tilesType = {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number;
  p: string;
  span: string;
  icon?: any;
  style?: {};
}[];
const Clause = () => {
  return (
    <>
      <ChildHeader text="IMPLEMENTATION OF STANDARDS" />
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0fr"
      />
    </>
  );
};

export default Clause;
