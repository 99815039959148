import React, { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import formatDate from '../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData &&
        moduleData?.c?.map((process: any, index: number) => (
          <tr>
            <td>{process?.s_no}</td>
            <td style={{ minWidth: '300px', textAlign: 'center' }}>
              {process?.check_point}
            </td>
            <td style={{ minWidth: '200px', textAlign: 'center' }}>
              {process?.judgement_criteria}
            </td>
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              {process?.method}
            </td>
            <td style={{ minWidth: '300px', textAlign: 'center' }}>
              {process?.picture}
            </td>
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="cycle_1_obs_before"
                type="text"
                value={process.cycle_1_obs_before}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="cycle_1_obs_after"
                type="text"
                value={process.cycle_1_obs_after}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="cycle_1_action_taken_repair"
                type="text"
                value={process.cycle_1_action_taken_repair}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="cycle_1_action_taken_replace"
                type="text"
                value={process.cycle_1_action_taken_replace}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="cycle_1_action_taken_remark"
                type="text"
                value={process.cycle_1_action_taken_remark}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="cycle_2_obs_before"
                type="text"
                value={process.cycle_2_obs_before}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="cycle_2_obs_after"
                type="text"
                value={process.cycle_2_obs_after}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="cycle_2_action_taken_repair"
                type="text"
                value={process.cycle_2_action_taken_repair}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="cycle_2_action_taken_replace"
                type="text"
                value={process.cycle_2_action_taken_replace}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="cycle_2_action_taken_remark"
                type="text"
                value={process.cycle_2_action_taken_remark}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
      <tr>
        <th colSpan={5}></th>
        <th colSpan={3} style={{ textAlign: 'left' }}>
          Done By :
          <TableInput
            isView={isView}
            name="s3"
            type="text"
            value={moduleData.s3}
            onChange={(e) => {
              moduleData.s3 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2} style={{ textAlign: 'left' }}>
          PM Status:
          <TableInput
            isView={isView}
            name="s4"
            type="text"
            value={moduleData.s4}
            onChange={(e) => {
              moduleData.s4 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={3} style={{ textAlign: 'left' }}>
          Done By :
          <TableInput
            isView={isView}
            name="s5"
            type="text"
            value={moduleData.s5}
            onChange={(e) => {
              moduleData.s5 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2} style={{ textAlign: 'left' }}>
          PM Status:
          <TableInput
            isView={isView}
            name="s6"
            type="text"
            value={moduleData.s6}
            onChange={(e) => {
              moduleData.s6 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={5}></th>
        <th colSpan={3} style={{ textAlign: 'left' }}>
          Verified By :
          <TableInput
            isView={isView}
            name="s7"
            type="text"
            value={moduleData.s7}
            onChange={(e) => {
              moduleData.s7 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2} style={{ textAlign: 'left' }}>
          Next Due Date :
          {isView ? (
            formatDate(moduleData.d3)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d3)}
              onChange={(date) => {
                moduleData.d3 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={3} style={{ textAlign: 'left' }}>
          Verified By :
          <TableInput
            isView={isView}
            name="s8"
            type="text"
            value={moduleData.s8}
            onChange={(e) => {
              moduleData.s8 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2} style={{ textAlign: 'left' }}>
          Next Due Date :
          {isView ? (
            formatDate(moduleData.d4)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d4)}
              onChange={(date) => {
                moduleData.d4 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
    </tbody>
  );
};

export default TBody;
