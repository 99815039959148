import { SetStateAction, useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import { Grid, MenuItem, Tooltip } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { TimePicker } from '@mui/x-date-pickers';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import { convToTime } from '../../../../../utils/helpers';
import CustomMobileTimePickerMui from '../../../../../components/common/CustomMobileTimePickerMui';
import Operator from '../../../components/Operator';
import OperationSelect from '../OperationSelect';
import convToTime24 from '../../../../../utils/helpers/convToTime';
import ModalCustom from '../../../../../components/common/Modal';
import OperatorFor4mLogs from './OperatorFor4mLogs';
interface IProps {
  moduleData: any;
  isView: boolean;
  handleSumbit: any;
  machineData: any;
  autoCompleteMachineValue: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const TBody = ({
  moduleData,
  isView,
  handleSumbit,
  machineData,
  autoCompleteMachineValue,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [isOpen4mModel, setIsOpen4mModel] = useState(false);
  const [modalIdx, setModalIdx] = useState<any>(null);

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              {/* <td style={{ minWidth: '150px' }}>
                {isView ? (
                  formatDate(process.date)
                ) : (
                  <DatePickerMui
                    label=""
                    value={moment(process.date)}
                    onChange={(date) => {
                      process.date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td> */}
              <td width={'100px'} style={{ fontWeight: 'bold' }}>
                {index + 1}
              </td>
              <td style={{ minWidth: '130px' }}>
                {isView ? (
                  process.shift
                ) : (
                  <CustomSelect
                    name="shift"
                    value={process.shift || ''}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="MORNING">MORNING</MenuItem>
                    <MenuItem value="DAY">DAY</MenuItem>
                    <MenuItem value="NIGHT">NIGHT</MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td style={{ minWidth: '200px' }}>
                {isView
                  ? process?.mc_no?.machine_no
                  : process.date &&
                    process.part_id && (
                      <AutocompleteMuiCustom
                        id="Machine"
                        label=""
                        option_name="machine_no"
                        arrayofObj={machineData}
                        value={process?.mc_no || ''}
                        onChange={(e, value) => {
                          if (value === null) value = null;
                          process.mc_no = value;
                          setRender((prev) => !prev);
                        }}
                      />
                    )}
              </td>
              <td style={{ width: '250px', minWidth: '250px' }}>
                {isView ? (
                  process?.part_id && process?.part_id?.part_name
                ) : (
                  <Grid container xs={12} direction="row" alignItems={'center'}>
                    <Grid item xs={10}>
                      <p
                        style={{
                          fontSize: '1.6rem',
                        }}
                      >
                        {typeof process?.part_id === 'object' &&
                        process?.part_id !== null
                          ? process?.part_id?.part_name
                          : process?.partName || ''}
                        {!process?.part_id && 'Pick Part Name'}
                      </p>
                    </Grid>

                    {process.part_id ? (
                      <Grid item xs={2}>
                        <RemoveIconButton
                          tooltipTitle="remove part"
                          onClick={() => {
                            process.part_id = null;
                            process.partNumber = null;
                            process.partName = null;
                            setRender((prev) => !prev);
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <AddIconButton
                          tooltipTitle="add part"
                          onClick={() => {
                            setIsOpenPopup(true);
                            setPartIdx(index);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </td>
              <td style={{ minWidth: '200px' }}>
                <OperationSelect
                  {...{ process, isView, setRender }}
                  partId={
                    typeof process.part_id === 'string'
                      ? process?.part_id
                      : process?.part_id?._id
                  }
                />
              </td>
              <td style={{ minWidth: '200px' }}>
                {process.mc_no && process.date && (
                  <Operator
                    onSubmit={handleSumbit}
                    field="operator"
                    machineId={process.mc_no?._id}
                    dataObj={{
                      date: moduleData.dailyProduction_date,
                      part_id: process.part_id,
                      operation_name: process.operation_name,
                    }}
                    {...{ isView, process }}
                  />
                )}
              </td>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  convToTime(process.time_start)
                ) : (
                  <TimePicker
                    // ampm={false}
                    value={moment(process.time_start)}
                    onChange={(date: any) => {
                      process.time_start = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  convToTime(process.time_stop)
                ) : (
                  <TimePicker
                    // ampm={false}
                    value={moment(process.time_stop)}
                    onChange={(date: any) => {
                      process.time_stop = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="plan_qty"
                  type="number"
                  value={process.plan_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="actual_qty"
                  type="number"
                  value={process.actual_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '250px' }}>
                <TableInput
                  isView={isView}
                  name="lot_no"
                  type="text"
                  value={process.lot_no}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="rej_qty"
                  type="number"
                  value={process.rej_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '200px' }} colSpan={isView ? 2 : 1}>
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <AddIconButton
                    tooltipTitle={'Add To 4M'}
                    disabled={true}
                    onClick={() => {
                      setModalIdx(index);
                      setIsOpen4mModel(true);
                    }}
                  />
                </td>
              )}
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
      <ModalCustom
        title="SUMMARY DATA"
        openModal={isOpen4mModel}
        closeModal={() => {
          setIsOpen4mModel(false);
        }}
      >
        <OperatorFor4mLogs
          onSubmit={handleSumbit}
          field="operator"
          machineId={moduleData?.processes?.[modalIdx]?.mc_no?._id}
          dataObj={{
            date: moduleData?.dailyProduction_date,
            part_id: moduleData?.processes?.[modalIdx]?.part_id,
            operation_name: moduleData?.processes?.[modalIdx]?.operation_name,
          }}
          process={moduleData?.processes?.[modalIdx]}
          {...{ isView, isOpen4mModel, setIsOpen4mModel }}
        />
      </ModalCustom>
    </>
  );
};

export default TBody;
