import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';
import { Grid, MenuItem, SelectChangeEvent, Stack } from '@mui/material';
import GridSelect from '../../../components/common/inputs/GridSelect';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../components/common/Loader';
import moment from 'moment';
import formatDate from '../../../components/common/formatDate';
import DatePickerMui from '../../../components/common/DatePickerMui';
import NotesTextfield from '../../../components/common/NotesTextfield';
import { StyledNotesField } from '../../../components/common/FormControlMui';
import { Description } from '@mui/icons-material';
import DescriptionTextfield from '../../../components/common/DescriptionNotes';
import { useAuth } from '../../../services/auth/AuthProvider';
import MushinRemarksTextField from '../../../components/common/MushinRemarksTextFieldNotes';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
const Create = ({
  id,
  setIsModal,
  disable,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  disable: boolean;
}) => {
  const { state } = useAuth();
  const [data, setData] = useState<any>({});
  const [render, setRender] = useState(false);
  const [files, setFiles] = useState(null);
  const [otherFiles, setOtherFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [department, setDepartment] = useState('');
  const [employee, setEmployee] = useState('');
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Vendor' : 'Create Ventor'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  useEffect(() => {
    axios
      .get(`/api/department`)
      .then((res) => setDepartments(res.data.results))
      .catch((err) => console.log(err));
    axios
      .get(`/api/employee/employeeList/customerForAutoComplete`)
      .then((res) => {
        setEmployees(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/Management5S/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      data.user_id = state?.user?.username;
      data.complaint_date = new Date();
      data.status = 'OPEN';
    }
  }, [id]);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    setIsLoading(true);
    const formData = new FormData();
    console.log('data', data);
    if (typeof data.department_id === 'object') {
      data.department_id = data.department_id._id;
    }
    if (typeof data.employee_id === 'object') {
      data.employee_id = data.employee_id._id;
    }
    for (const key in data) {
      if (data[key]) {
        if (key !== 'files' && key !== 'otherFiles')
          formData.append(key, data[key]);
      }
    }
    if (files) {
      for (const key of Object.keys(files)) {
        formData.append('files', files[key]);
      }
    }
    if (otherFiles) {
      for (const key of Object.keys(otherFiles)) {
        formData.append('otherFiles', otherFiles[key]);
      }
    }
    if (id) {
      await axios
        .put(`/api/Management5S/${id}`, formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/Management5S', formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const filterEmployees = (departmentId: any) => {
    const newEmployees = employees.filter(
      (item: any) => item?.department_id?._id === departmentId
    );
    return newEmployees;
  };

  return (
    <>
      <Loader loader={isLoading} />
      <AutocompleteGridmui
        label={'Responsible Department'}
        id="department_name"
        option_name={'department_name'}
        value={department || data?.department_id || ''}
        arrayofObj={departments}
        onChange={(e: any, value: any) => {
          if (!disable) {
            data.department_id = value?._id;
            setDepartment(value);
          }
        }}
      />
      <AutocompleteGridmui
        id="employee_name"
        label="Responsible Employee"
        option_name="employee_name"
        value={employee || data?.employee_id || ''}
        arrayofObj={filterEmployees(data?.department_id)}
        onChange={(e: any, value: any) => {
          if (!disable) {
            data.employee_id = value?._id;
            setEmployee(value);
          }
        }}
      />
      <GridDatePicker
        label_name="Date Of Complaint: "
        html_for={'date_of_complaint'}
        views={['day', 'month', 'year']}
        value={data?.complaint_date}
        onChange={(date: any) => {
          data.complaint_date = date;
          setRender((prev) => !prev);
        }}
        disabled={disable}
      />
      <StyledNotesField>
        <DescriptionTextfield
          onChange={handleCustomerData}
          value={data.description || ''}
        />
      </StyledNotesField>
      <StyledNotesField>
        <MushinRemarksTextField
          onChange={handleCustomerData}
          value={data?.mushin_remarks || ''}
        />
      </StyledNotesField>
      <GridSelect
        name="status"
        label_name="STATUS (OPEN/WORK IN PROGRESS/CLOSED):"
        html_for={'status'}
        value={data.status || ''}
        onChange={(e: any) => {
          data.status = e.target.value;
          if (data.status === 'CLOSED') {
            data.closure_date = new Date();
            data.closed_by = state?.user?.username;
          } else {
            data.closure_date = '';
            data.closed_by = '';
          }
          setRender((prev) => !prev);
        }}
        disabled={disable}
      >
        <MenuItem disabled={disable} value="OPEN">
          OPEN
        </MenuItem>
        <MenuItem disabled={disable} value="WORK IN PROGRESS">
          WORK IN PROGRESS
        </MenuItem>
        <MenuItem disabled={disable} value="UNDER REVIEW">
          UNDER REVIEW
        </MenuItem>
        <MenuItem disabled={disable} value="CLOSED">
          CLOSED
        </MenuItem>
      </GridSelect>
      <GridDatePicker
        label_name="Closure Date: "
        html_for={'closure_date'}
        views={['day', 'month', 'year']}
        value={data?.closure_date || null}
        onChange={(date: any) => {
          data.closure_date = date;
          setRender((prev) => !prev);
        }}
        disabled={disable}
      />
      <GridInputs
        id={'user_id'}
        name={'user_id'}
        html_for={'user_id'}
        label_name={'Complaint Issued By :'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.user_id || ''}
        onChange={handleCustomerData}
        disabled={true}
      />
      <GridInputs
        id={'closed_by'}
        name={'closed_by'}
        html_for={'closed_by'}
        label_name={'Complaint Closed By :'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.closed_by || ''}
        onChange={handleCustomerData}
        disabled={true}
      />
      <Stack direction="row" flex="1" gap={9}>
        {!disable && (
          <Grid item xs={3}>
            <CustomUploadButton
              lable={'UPLOAD Problem Proof DOCUMENT'}
              id="problem_proof"
              multiple
              accept="image/png,image/jpeg,image/jpg,application/pdf"
              onChange={(e: any) => {
                setFiles(e.target.files);
              }}
            />{' '}
          </Grid>
        )}
        {!disable && (
          <Grid item xs={3}>
            <CustomUploadButton
              lable={'UPLOAD Closure Proof DOCUMENT'}
              id="closure_proof"
              multiple
              accept="image/png,image/jpeg,image/jpg,application/pdf"
              onChange={(e: any) => {
                setOtherFiles(e.target.files);
              }}
            />{' '}
          </Grid>
        )}
      </Stack>
      {!disable && (
        <div
          className="btn_save"
          style={{
            position: 'absolute',
            right: '140px',
            top: '-0.6px',
          }}
        >
          <SubmitButton
            label={`${id ? 'update' : 'submit'}`}
            type="button"
            onClick={handleSumbit}
          />
        </div>
      )}
      <DialogSubmit />
    </>
  );
};

export default Create;
