export const initialState = {
  QR_target_ppm: new Array(12).fill(null),
  QR_quantity_received: new Array(12).fill(null),
  QR_quantity_rejected: new Array(12).fill(null),
  QR_quantity_accepted: new Array(12).fill(null),
  QR_acheived_ppm: new Array(12).fill(null),
  QR_30_percent: new Array(12).fill(null),
  //end

  DR_schedule_quantity: new Array(12).fill(null),
  DR_quantity_received: new Array(12).fill(null),
  DR_delivery_percent_acheived: new Array(12).fill(null),
  // end

  DR_20_percent: new Array(12).fill(null),
  SC_complaint_target: new Array(12).fill(null),
  SC_no_of_complaint: new Array(12).fill(null),
  SC_10_percent: new Array(12).fill(null),
  // end

  CD4M_first_week_100_percent: new Array(12).fill(null),
  CD4M_10_percent: new Array(12).fill(null),
  // end

  IEF_target_percent: new Array(12).fill(null),
  IEF_acheived_percent: new Array(12).fill(null),
  IEF_5_percent: new Array(12).fill(null),
  // end

  CDLS_target_no: new Array(12).fill(null),
  CDLS_no: new Array(12).fill(null),
  CDLS_10_percent: new Array(12).fill(null),
  // end

  CN_target_no: new Array(12).fill(null),
  CN_no: new Array(12).fill(null),
  CN_10_percent: new Array(12).fill(null),
  // end

  AAPS_within_15_days: new Array(12).fill(null),
  AAPS_5_percent: new Array(12).fill(null),
  //end

  over_all_rating: new Array(12).fill(null), // for result
  card_type: new Array(12).fill(null),
};
