import { GrUser } from 'react-icons/gr';
import { HiDocumentReport } from 'react-icons/hi';
import { TbFaceIdError, TbReportAnalytics } from 'react-icons/tb';
// import { GiRing } from 'react-icons/gi';
// import { SiMaterialdesignicons } from 'react-icons/si';
// import { GiUnbalanced } from 'react-icons/gi';
// import { GiTeePipe } from 'react-icons/gi';
import { TbReport } from 'react-icons/tb';
// import { GrServices } from 'react-icons/gr';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { MdOutlineCalendarMonth, MdOutlineCompress } from 'react-icons/md';
import { BsReverseListColumnsReverse } from 'react-icons/bs';
import { MdOutlinePersonPin } from 'react-icons/md';
import { BsFillDeviceSsdFill } from 'react-icons/bs';
import { GiMoebiusStar } from 'react-icons/gi';

const tiles = [
    {
        sNo: 3,
        link: '/common/masterListOfVendor',
        isLink: true,
        icon: <MdOutlinePersonPin size="5rem" color="#064a29" />,
        p: 'MASTER LIST OF',
        span: 'SUPPLIER',
        isIcon: true,
    },
    {
        sNo: 1,
        link: '/common/riPart',
        isLink: true,
        icon: <HiDocumentReport size="5rem" color="#003566" />,
        p: 'RI PART',
        span: 'LIST',
        isIcon: true,
    },
    {
        sNo: 4,
        link: `/common/inspectionStandardList?filter=createdAt`,
        isLink: true,
        icon: <TbReport size="5rem" color="#a12222" />,
        p: 'INSPECTION QUALITY',
        span: 'STANDARD',
        isIcon: true,
    },
    {
        sNo: 5,
        link: `/common/inspectionReportList`,
        isLink: true,
        icon: <TbReportAnalytics size="5rem" color="#a12222" />,
        p: 'INCOMING INSPECTION',
        span: 'Report',
        isIcon: true,
    },
    {
        sNo: 6,
        link: `/common/samplingStandard`,
        isLink: true,
        icon: <BsFillDeviceSsdFill size="5rem" color="#a12222" />,
        p: 'SAMPLING',
        span: 'STANDARD',
        isIcon: true,
    },
    {
        sNo: 1,
        link: `/admin/SupplierComplaintRegister`,
        isLink: true,
        clauseNo: 8,
        icon: <GrUser size="5rem" color="#003566" />,
        p: 'SUPPLIER COMPLAINT',
        span: 'REGISTER',
        isIcon: true,
    },
    {
        sNo: 3,
        link: '/common/supplierplanlist',
        isLink: true,
        icon: <MdOutlineCalendarMonth size="5rem" color="#343a40" />,
        p: 'SUPPLIER',
        span: 'PLAN',
        isIcon: true,
    },
    {
        sNo: 2,
        link: '/common/supplierchecklist',
        isLink: true,
        icon: <BsReverseListColumnsReverse size="5rem" color="#087f5b" />,
        p: 'SUPPLIER',
        span: 'STATUS',
        isIcon: true,
    },
    {
        sNo: 4,
        link: `/common/approvedSupplierList`,
        isLink: true,
        icon: <GiMoebiusStar size="5rem" color="#003566" />,
        p: 'SUPPLIER',
        span: 'RATING',
        isIcon: true,
    },
    {
        sNo: 1,
        link: '/common/incomingPartMonitoringSheetList',
        isLink: true,
        icon: <MdOutlineCompress size="5rem" color="#1864ab" />,
        p: 'Incoming Part Monitoring',
        span: 'Sheet',
        isIcon: true,
    },
    {
        sNo: 2,
        link: '/common/rejectionReasonList?filter=createdAt',
        isLink: true,
        clauseNo: 8,
        icon: <TbFaceIdError size="5rem" color="#a12222" />,
        p: 'List Of Rejection',
        span: 'Reason',
        isIcon: true,
    },
    //   {
    //     sNo: 6,
    //     link: `/common/supplierComplaintRegister`,
    //     isLink: true,
    //     icon: <GrUser size="5rem" color="#003566" />,
    //     p: 'SUPPLIER COMPLAINT',
    //     span: 'REGISTER',
    //     isIcon: true,
    //   },
    //   {
    //     sNo: 7,
    //     link: `/common/riDocumentUpload/?documentType=iaiSSTPlan`,
    //     isLink: true,
    //     icon: <HiDocumentReport size="5rem" color="#003566" />,
    //     p: 'IAI SST',
    //     span: 'PLAN',
    //     isIcon: true,
    //   },
    //   {
    //     sNo: 8,
    //     link: `/common/riDocumentUpload/?documentType=iaiMtcPlanList`,
    //     isLink: true,
    //     icon: <HiDocumentReport size="5rem" color="#003566" />,
    //     p: 'IAI MTC',
    //     span: 'PLAN',
    //     isIcon: true,
    //   },
    //   {
    //     sNo: 9,
    //     link: `/common/riDocumentUpload/?documentType=riDocuments`,
    //     isLink: true,
    //     icon: <HiDocumentReport size="5rem" color="#003566" />,
    //     p: 'RI',
    //     span: 'DOCUMENTS',
    //     isIcon: true,
    //   },
    //   {
    //     sNo: 10,
    //     link: `/common/RawMaterialPlanTiles`,
    //     isLink: true,
    //     icon: <SiMaterialdesignicons size="4rem" color="#343a40" />,
    //     p: 'RAW MATERIAL',
    //     span: 'TEST PLAN (Child Parts)',
    //     isIcon: true,
    //   },
    //   {
    //     sNo: 10,
    //     link: `/common/RubberoringPartsTiles`,
    //     isLink: true,
    //     icon: <GiRing size="5rem" color="#343a40" />,
    //     p: 'RAW MATERIAL',
    //     span: "TEST PLAN (RUBBER & O'RING  PARTS)",
    //     isIcon: true,
    //   },
    //   {
    //     sNo: 10,
    //     link: `/common/SSTPlanTiles`,
    //     isLink: true,
    //     icon: <GrServices size="5rem" color="#343a40" />,
    //     p: 'SST',
    //     span: 'PLAN',
    //     isIcon: true,
    //   },
    //   {
    //     sNo: 10,
    //     link: `/common/TubesTiles`,
    //     isLink: true,
    //     icon: <GiTeePipe size="5rem" color="#343a40" />,
    //     p: 'Tube',
    //     span: 'PLAN',
    //     isIcon: true,
    //   },
    //   {
    //     sNo: 10,
    //     link: `/common/BOPPlanTiles`,
    //     isLink: true,
    //     icon: <GiUnbalanced size="5rem" color="#343a40" />,
    //     p: 'BOP',
    //     span: 'PLAN',
    //     isIcon: true,
    //   },
];

const RIDeparment = () => {
    return (
        <>
            <ChildHeader text="RI DEPARTMENT" />
            <RenderTiles height="100%" tilesObj={tiles} />
        </>
    );
};

export default RIDeparment;
