import { useState } from 'react';
import InteractiveIcon from '../../../../../components/common/InteractiveIcon';

interface IProps {
  moduleData: any;
  rowIdx: number;
  month: string;
  planType: string;
  monthIdx: number;
  phase: any;
}
const MonthDoubleClick = ({
  moduleData,
  rowIdx,
  month,
  planType,
  monthIdx,
  phase,
}: IProps) => {
  const [render, setRender] = useState(false);
  const handleDoubleClick = (event: any) => {
    if (event.detail === 2) {
      if (!moduleData[phase][rowIdx][month]) {
        moduleData[phase][rowIdx][month] = [
          {
            p: false,
            a: false,
          },
        ];
      }
      if (!moduleData[phase][rowIdx][month][monthIdx]) {
        moduleData[phase][rowIdx][month][monthIdx] = {
          p: false,
          a: false,
        };
      }
      const bool = moduleData[phase][rowIdx][month][monthIdx][planType];
      moduleData[phase][rowIdx][month][monthIdx][planType] = !bool
        ? true
        : false;
      setRender((prev) => !prev);
    }
  };
  return (
    <td
      style={{
        padding: '0px',
        margin: '0px',
      }}
      onClick={(e) => handleDoubleClick(e)}
    >
      <InteractiveIcon
        isYellow={moduleData[phase][rowIdx]?.[month]?.[monthIdx]?.[planType]}
      >
        {moduleData[phase][rowIdx]?.[month]?.[monthIdx]?.[planType] && planType}
      </InteractiveIcon>
    </td>
  );
};

export default MonthDoubleClick;
