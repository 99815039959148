import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
  date?: any;
  setRender?: any;
  calculateOverAllRating?: any;
}

const TBodyChange4m = ({
  moduleData,
  isView,
  setRender,
  calculateOverAllRating,
}: IProps) => {
  return (
    <tbody>
      <tr style={{ background: '#228be6' }}>
        <th colSpan={1} align="left">
          4M CHANGE DECLARATION (D)
        </th>
        <th colSpan={2}>(1st week 100% after 1st week 0%)</th>
        <th colSpan={11}>Weightage : 10% </th>
      </tr>
      <tr>
        <th align="left" style={{ minWidth: '180px' }}>
          First week-100%
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td key={'drsq' + 2001 + index} style={{ minWidth: '160px' }}>
            <TableInput
              isView={isView}
              name="dr"
              type="number"
              value={moduleData?.CD4M_first_week_100_percent?.[index]}
              onChange={(e) => {
                moduleData.CD4M_first_week_100_percent[index] = +e.target.value;
                setRender((prev: any) => !prev);
              }}
            />
          </td>
        ))}
      </tr>
      <tr>
        <th align="left" style={{ minWidth: '200px' }}>
          % 10 (D)
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td key={'drqc' + 2001 + index}>
            <TableInput
              isView={isView}
              name="cycle_time"
              type="number"
              value={moduleData?.CD4M_10_percent?.[index]}
              onChange={(e) => {
                moduleData.CD4M_10_percent[index] = +e.target.value;
                calculateOverAllRating(index);
                setRender((prev: any) => !prev);
              }}
            />
          </td>
        ))}
      </tr>
    </tbody>
  );
};

export default TBodyChange4m;
