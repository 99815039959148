import { useState } from 'react';
import styled, { keyframes } from 'styled-components';

interface MySelectProps {
  item: {
    isSubmitted?: string;
    isAdminRequestNo?: string;
  };
  index: number;
  handleChangeRequestStatus: (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => void;
  statusOptions: {
    value: string;
    label: string;
    bgColor: string;
    textColor?: string;
  }[];
}

interface StyledSelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  item: {
    isSubmitted?: string;
    isAdminRequestNo?: string;
  };
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledSelect = styled.select<StyledSelectProps>`
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 500;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 5px;
  margin-left: 0.5rem;
  width: 14rem;
  text-align: center;
  text-transform: capitalize;
  background-color: ${({ item }) =>
    item.isSubmitted === 'draft' ? '#FDD835' : 'transparent'};
  color: ${({ item }) => (item.isSubmitted === 'publish' ? '#fff' : 'inherit')};
  animation: ${fadeIn} 0.5s ease;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: ${({ item }) =>
      item.isSubmitted === 'draft' ? '#FBBF24' : 'rgba(0, 0, 0, 0.05)'};
    color: ${({ item }) =>
      item.isSubmitted === 'publish' ? '#fff' : 'inherit'};
  }
`;

export const SelectStatus = ({
  item,
  index,
  handleChangeRequestStatus,
  statusOptions,
}: MySelectProps) => {
  const [selectedValue, setSelectedValue] = useState(
    statusOptions.find((option) => option.value === item.isSubmitted)
  );

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = statusOptions.find(
      (option) => option.value === event.target.value
    );
    setSelectedValue(selectedOption);
    handleChangeRequestStatus(event, index);
  };

  return (
    <StyledSelect
      name="isSubmitted"
      item={item}
      value={selectedValue?.value}
      onChange={handleSelectChange}
    >
      {statusOptions.map((option) => (
        <option
          key={option.value}
          value={option.value}
          style={{
            backgroundColor: option.bgColor,
            color: option.textColor,
          }}
        >
          {option.label}
        </option>
      ))}
    </StyledSelect>
  );
};
