import React, { useState } from 'react';
import { Checkbox, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import Add from '@mui/icons-material/Add';
import StateCustomTable from '../../../../components/common/StateCustomTable';
import {
  CustomPagination,
  // StatePagination,
  TableCustom,
} from '../../../../components/common';
// import formatDate from '../../../components/common/formatDate';
// import { CustomIconButton } from '../../../components/common/button/CustomIconButton';
import useDebounce from '../../../../hooks/useDebounce';
import {
  CustomIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import { Add } from '@mui/icons-material';
// import { useQuery } from '../../../hooks/UseQuery';

interface Vendor {
  vender_name: string;
}
interface IPart {
  _id?: string;
  spare_part_name: string | null;
  spare_part_desc: string | null;
  // used_for_machine: string | null;
  // opening_stock_qty: number | null;
  opening_stock_UOM: string | null;
  min_stock_qty: number | null;
  max_stock_qty: number | null;
  reorder_level: number | null;
  // closing_stock_qty: number | null;
  // location: string | null;
  remarks: string | null;
}
const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'Name', rowspan: 1, colspan: 1 },
    { text: 'Description', rowspan: 1, colspan: 1 },
    { text: 'UOM', rowspan: 1, colspan: 1 },
    { text: 'Minimum Qty', rowspan: 1, colspan: 1 },
    { text: 'Maximum Qty', rowspan: 1, colspan: 1 },
    { text: 'Reorder Level', rowspan: 1, colspan: 1 },
    { text: 'Remarks', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'createdAt', name: 'Most Recent' },
  { value: 'spare_part_desc', name: 'A-Z' },
];

const SparePartList = (props: any) => {
  const { moduleData, partIdx } = props;
  const [page, setPage] = useState(1);
  const [partData, setPartData] = useState<IPart[]>([]);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<string>('createdAt');
  const navigate = useNavigate();
  const [render, setRender] = useState(false);

  const fetchPartData = () => {
    axios
      .get(
        `/api/criticalSparePartsRoute/?page=${page}&sortBy=${filter}&search=${search}&perPage=${10}`
      )
      .then((res) => {
        setPerPage(res.data?.perPage);
        setPartData(res.data?.results);
        setTotalPages(res.data?.pages);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchPartData, 300, [page, filter, search]);
  // console.log('spare', sparePartIds);
  return (
    <>
      <StateCustomTable
        filterOptions={filterOptions}
        {...{ search, setSearch, filter, setFilter }}
        sx={{ width: '100%', maxWidth: '100%' }}
        label="Search By Name And Spare Description "
      >
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {partData &&
              partData.map((item: IPart, index: number) => (
                <React.Fragment key={item?._id}>
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{item.spare_part_name}</TableCell>
                    <TableCell align="center">{item.spare_part_desc}</TableCell>
                    <TableCell align="center">
                      {item.opening_stock_UOM}
                    </TableCell>
                    <TableCell align="center">{item.min_stock_qty}</TableCell>
                    <TableCell align="center">{item.max_stock_qty}</TableCell>
                    <TableCell align="center">{item.reorder_level}</TableCell>
                    <TableCell align="center">{item.remarks}</TableCell>
                    <TableCell align="center">
                      {moduleData.processes[partIdx].spare_part_id ===
                      item._id ? (
                        <CustomIconButton
                          sx={{ p: 0 }}
                          tooltipTitle={'remove'}
                          onClick={() => {
                            moduleData.processes[partIdx].spare_part_id = null;
                            moduleData.processes[partIdx].partName = null;
                            // moduleData.processes[partIdx].partNumber = null;
                            setRender((prev) => !prev);
                          }}
                        >
                          <RemoveIconButton tooltipTitle="remove parts" />
                        </CustomIconButton>
                      ) : (
                        <CustomIconButton
                          sx={{ p: 0 }}
                          tooltipTitle={'add'}
                          onClick={() => {
                            moduleData.processes[partIdx].spare_part_id =
                              item?._id;
                            moduleData.processes[partIdx].partName =
                              item?.spare_part_name;
                            // moduleData.processes[partIdx].partNumber =
                            //   item?.part_number;
                            setRender((prev) => !prev);
                          }}
                        >
                          <Add style={{ color: '#343a40' }} />
                        </CustomIconButton>
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
        <CustomPagination totalPage={totalPages} />
      </StateCustomTable>
    </>
  );
};

export default SparePartList;
