import React from 'react';
import styled from 'styled-components';
import { FaTools } from 'react-icons/fa';
import { BsArrowLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f6f8fa;
`;

const UnderConstructionIcon = styled(FaTools)`
  font-size: 8rem;
  color: #ff6b6b;
`;

const Message = styled.h1`
  font-size: 2.5rem;
  color: #333333;
  margin-top: 1rem;
`;

const SubMessage = styled.p`
  font-size: 1.5rem;
  color: #666666;
  margin-top: 1rem;
  text-align: center;
`;

const BackLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #1da1f2;
  border-radius: 2rem;
  font-size: 1.2rem;
  padding: 0.8rem 2rem;
  margin-top: 2rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #0c88d1;
  }

  svg {
    margin-right: 0.5rem;
  }
`;
const UnderConstructionPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <UnderConstructionIcon />
      <Message>Under Construction</Message>
      <SubMessage>Sorry, this page is currently under construction.</SubMessage>
      <BackLink onClick={() => navigate(-1)}>
        <BsArrowLeft />
        Go back
      </BackLink>
    </Container>
  );
};

export default UnderConstructionPage;
