import React, { useEffect, useState } from 'react';
import TController from './TController';
import { Box, SelectChangeEvent } from '@mui/material';
import { PersitedDatePicker } from '../../../../../../components/common';
import { useQuery } from '../../../../../../hooks/UseQuery';
import axios from 'axios';
import ParetoChart from './ParetoChart';
import GridInputs from '../../../../../../components/common/inputs/GridInputs';
import { GraphHead } from '../../../../../../components/common/GraphHeading';
//import DefectWise from '../../../../../clause8Page/customerConcernDashboards/components/dashboard/defectwise/DefectWise';
interface IProps {
  apiData: any;
}
const PartWiseQty = ({ apiData /*year, month, data*/ }: IProps) => {
  return (
    <div>
      <>
        <TController apiData={apiData} />
        <GraphHead style={{ width: '80vw', margin: '14rem' }}>
          <p>Part wise pareto analysis</p>
          <ParetoChart apiData={apiData} />
        </GraphHead>
      </>
    </div>
  );
};

export default PartWiseQty;
