export const processAuditCheckSheet = [
  {
    heading: '1.0 Effectiveness Previous Audit NC(s)',
    s_no: 1,
    check_point: `Are they Corrective actions are effectively followed for the
    Previous Audit NCs ?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {
    s_no: 2,
    check_point: `Are all the NCs are closed properly with evidences ?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {
    s_no: 3,
    check_point: `Are the Corrective actions identified for the Observations
     and are all closed ?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {
    heading: '2.0 Man',
    s_no: 4,
    check_point: `Is the operator aware of the process. (As per Operation
       Standard)`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {
    s_no: 5,
    check_point: `Is the operator aware of the 5 S & Safety issues ?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {
    s_no: 6,
    check_point: `Does the skill matrix established for that process ?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {
    s_no: 7,
    check_point: `Is the operator working under safety conditions (wearing air
      plugs, goggles, hand gloves, shoes etc.)`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {
    s_no: 8,
    check_point: `Is the operator aware about abnormal condition ?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {

    heading: '3.0 Material',
    s_no: 9,
    check_point: `Are the materials / components etc. are properly stored ?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {
    s_no: 10,
    check_point: `Are the identification tags / FIFO system followed properly ?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {
    s_no: 11,
    check_point: `Are the NG components / Material kept in rejection area ?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {

    heading: '4.0 Method',
    s_no: 12,
    check_point: `Are the Drawings, PFD, PFMEA, Control Plan & Operation Std. available & match to each other?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {
    s_no: 13,
    check_point: `Are the latest revision level of above documents used ?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {

    heading: '5.0 Machine',
    s_no: 14,
    check_point: `Is there a daily maintenance system followed ?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {
    s_no: 15,
    check_point: `Is there a planned maintenance (PM) system including a
    maintenance schedule & defined maintenance criteria ?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {
    s_no: 16,
    check_point: `Are applied Poka / Yoke working & verified ?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {
    s_no: 17,
    check_point: `Is there a Tool / die maintenance system defined and followed`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {

    heading: '6.0 Monitoring & Measurment',
    s_no: 18,
    check_point: `Are the necessary inspection equipments/instruments/gauges
     made available & Calibrated ?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {
    s_no: 19,
    check_point: `Is the set-up approval & In-process system followed ?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {
    s_no: 20,
    check_point: `Is the packing standard available ?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {

    heading: '7.0 Milieu (Enviornment)',
    s_no: 21,
    check_point: `Are the Bins, containers, trays and trolleys kept clean ?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  },
  {
    s_no: 22,
    check_point: `Is the lighting comfortable for the operator ?`,
    c: false,
    o: false,
    nc: false,
    actual_observation: '',
  }
];
