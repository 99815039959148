import { Box, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  CustomPagination,
  PersitedDatePicker,
  TableCustom,
} from '../../../components/common';
import { AddButton } from '../../../components/common/button/CustomButtons';
import {
  DeleteIconButton,
  EditIconButton,
} from '../../../components/common/button/CustomIconButton';
import formatDate from '../../../components/common/formatDate';
import ModalCustom from '../../../components/common/Modal';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import GenerateFmea from './components/GenerateFmea';
const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'Report Date', rowspan: 1, colspan: 1 },
    { text: 'From/To', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const TableList = () => {
  const partNumber = useQuery('part_number');
  const partName = useQuery('part_name');
  const { partId } = useParams();
  const date = useQuery('date');
  const [data, setData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // fetch data here
    axios
      .get(`/api/fmeaManagementRoute/generateFmea/monthly/${partId}/${date}`)
      .then((res) => {
        if (res.data) {
          setData(res?.data);
        }
      })
      .catch((err) => console.log(err));
  }, [render, date, isOpenPopup]);
  const deleteDocument = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this document?',
    );
    if (isDeleted) {
      axios
        .delete(`/api/fmeaManagementRoute/generateFmea/${id}`)
        .then(() => {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  return (
    <Box>
      <ChildHeader text="GENERATED FMEA LIST">
        <AddButton
          label="GENERATE FMEA"
          onClick={() => {
            setIsOpenPopup(true);
          }}
        />
      </ChildHeader>
      <Box
        sx={{
          margin: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
      </Box>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">
                  {formatDate(item.createdAt)}
                </TableCell>
                <TableCell align="center">
                  {formatDate(item.from)} / {formatDate(item.to)}
                </TableCell>
                <TableCell>
                  <Stack direction="row" sx={{ justifyContent: 'center' }}>
                    <EditIconButton
                      tooltipTitle="EDIT"
                      onClick={() => {
                        navigate(
                          `/common/generateFmeaCreate/${item._id}?partNumber=${partNumber}&partName=${partName}`,
                        );
                      }}
                    />

                    <DeleteIconButton
                      tooltipTitle="DELETE"
                      onClick={() => {
                        deleteDocument(item?._id || '');
                      }}
                    />
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </TableCustom>
      <ModalCustom
        title="GENERATE FMEA"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <GenerateFmea
          {...{
            setIsOpenPopup,
          }}
        />
      </ModalCustom>
    </Box>
  );
};

export default TableList;
