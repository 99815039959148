import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';
interface IProps {
  moduleData: IData;
  isView: boolean;
}

const TController = ({ moduleData, isView }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead />
        <TBody
          {...{
            moduleData,
            isView,
          }}
        />
        <TFoot {...{ moduleData }} />
      </RenderTable>
    </Box>
  );
};

export default TController;
