import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import tiles from './tilesObj';
export type tilesType = {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number;
  p: string;
  span: string;
  icon?: any;
  style?: {};
}[];
const Clause = () => {
  return (
    <>
      <ChildHeader text="QUALITY AUDIT AND PROCESS VERIFICATION" />
      <RenderTiles tilesObj={tiles} height="100vh" />
    </>
  );
};

export default Clause;
