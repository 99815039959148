import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AiFillEye } from 'react-icons/ai';
import {
  CustomButton,
  SubmitButton,
} from '../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import TController from './components/create/TController';
import CustomUploadButton from '../../../../components/common/button/CustomUploadButton';
import useConfirm from '../../../../components/common/useConfirm';
import { Loader } from '../../../../components/common/Loader';

const Create = () => {
  const { toolId } = useParams();
  const [render, setRender] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);

  const [moduleData, setModuleData] = useState<any>({
    moduleDate: new Date(),
    tool_id: toolId || null,
    processes: [],
  });
  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?',
  );
  const isView = useQuery('isView') === 'true' ? true : false;
  useEffect(() => {
    if (!toolId) return;
    axios
      .get(`/api/toolChecklistFormatRoute/${toolId}`)
      .then((res) => {
        setModuleData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const onSubmit = async () => {
    if (moduleData?._id) {
      axios
        .put(`/api/toolChecklistFormatRoute/${moduleData?._id}`, moduleData)
        .then((res) => {
          if (res.data) {
            alert('updated successfull');
          }
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/toolChecklistFormatRoute', moduleData)
        .then((res) => {
          if (res.data) {
            alert('created successfull');
            setModuleData(res?.data?.result);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const addRows = () => {
    const values = { ...moduleData };
    values.processes.push({});
    setModuleData(values);
  };

  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.processes = data.processes.filter(
      (item: any, index: number) => index !== rowIdx,
    );
    setModuleData(data);
  };

  const upload = (e: any) => {
    setLoader(true);

    const formData = new FormData();
    formData.append('file', e.target.files[0], 'toolCheckListFormat.xlsx');

    axios
      .post('/api/excelUploadRoute/toolCheckListFormat', formData)
      .then((res) => {
        const data = res?.data;
        if (!data?.isExcel) {
          window.alert(
            'Something thing went wrong. Please check if you are uplaoding the correct excel sheet (.xlsx) or the excel sheet might be currupted.',
          );
        } else
          moduleData.processes = [...data?.processes, ...moduleData.processes];
        setLoader(false);

        setRender((prev) => !prev);
      })
      .catch((err) => {
        setLoader(false);

        if (err.response.data.err) {
          window.alert(err.response.data.err);
        } else {
          window.alert(
            'Something thing went wrong. Please check if you are uplaoding the correct excel sheet or the excel sheet might be currupted.',
          );
        }
      });
  };

  return (
    <Box>
      <Box>
        <Loader loader={loader} />
        <ChildHeader text="Quality Standard">
          {!isView && (
            <>
              <CustomUploadButton lable={'UPLOAD'} onChange={upload} />

              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={addRows}
              >
                ADD ROWS
              </CustomButton>

              <SubmitButton
                label={`${moduleData?._id ? 'Update ' : 'Submit'}`}
                onClick={() => onSubmit()}
              />
            </>
          )}
        </ChildHeader>
        <TController {...{ moduleData, isView, handelDeleteRows }} />
      </Box>
      <DialogDeleteRow />
    </Box>
  );
};

export default Create;
