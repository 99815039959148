// TableRowComponent.jsx
import React from 'react';
import { TableInput } from '../../../../../components/common';
import { useQuery } from '../../../../../hooks/UseQuery';

export const TableRowComponent = React.memo(
  ({ key, item, index, allmonth, updateModuleData, handleDelete }: any) => {
    const isView = useQuery('isView') === 'true' ? true : false;
    const rowId = item?._id || item?._tempId;

    const handleInputChange = (
      fieldName: string,
      value: string | number | null,
    ) => {
      updateModuleData('row', index, fieldName, { [fieldName]: value });
    };

    return (
      <React.Fragment key={key}>
        <tr>
          <td>{index + 1}</td>
          <td>
            <TableInput
              isView={isView}
              style={{}}
              name="checkpoint"
              type="text"
              value={item.checkpoint}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{}}
              name="method"
              type="text"
              value={item.method}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{}}
              name="standard"
              type="text"
              value={item.standard}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{}}
              name="observation"
              type="text"
              value={item.observation}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{}}
              name="judgement"
              type="text"
              value={item.judgement}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{}}
              name="remark"
              type="text"
              value={item.remark}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
        </tr>
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => {
    // Check if the relevant data for this row has changed
    return (
      prevProps.item === nextProps.item && prevProps.index === nextProps.index
    );
  },
);
