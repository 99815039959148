interface IProps {
  moduleData: any;
}

const TBody = ({ moduleData }: IProps) => {
  return (
    <tbody>
      {moduleData &&
        moduleData?.topics.map((item: any, index: number) => (
          <>
            <tr>
              <td align="center">{item.s_no}</td>
              <td align="center" height="50px">
                {item.topic_type}
              </td>
              <td align="center" height="50px">
                {item.name}
              </td>
            </tr>
          </>
        ))}
    </tbody>
  );
};

export default TBody;
