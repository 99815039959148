import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import ObservationInput from './ObservationInput';
// import CustomSelect from '../../../../../components/common/SelectField';
// import { IconButton, MenuItem, Tooltip } from '@mui/material';
// import { handleChangeColorOnRemarks } from '../../helpers/handleChangeColorOnRemarks';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
// import { convToTime } from '../../../../../utils/helpers';
// import { TimePicker } from '@mui/x-date-pickers';
// import moment from 'moment';
// import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
  machineData?: any;
}
const TBody = ({ moduleData, tabValue, isView, machineData }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData.pirs[tabValue] &&
        moduleData.pirs[tabValue].pir_list &&
        moduleData.pirs[tabValue].pir_list.map((spec: any, index: number) => (
          <tr key={300 + index + tabValue + 'spec_body'}>
            {index === 0 && (
              <th
                rowSpan={moduleData?.pirs[tabValue]?.pir_list?.length}
                style={{ width: '200px' }}
              >
                {moduleData?.invoice_no}
              </th>
            )}
            {index === 0 && (
              <th
                rowSpan={moduleData?.pirs[tabValue]?.pir_list?.length}
                style={{ width: '200px' }}
              >
                {formatDate(moduleData?.moduleData_date)}
              </th>
            )}
            <th
              style={{
                minWidth: '100px',
              }}
            >
              {String.fromCharCode(index + 64 + 1)}
            </th>
            {Array.from({ length: 5 }, (_, obsIndex) => (
              <td
                key={obsIndex + 'obs'}
                style={{ textTransform: 'uppercase', minWidth: '100px' }}
              >
                <ObservationInput
                  {...{ spec, setRender, isView, obsIndex }}
                  obsNumber={0}
                  checkType={spec.appearance}
                />
              </td>
            ))}
            <td style={{ textTransform: 'uppercase', minWidth: '200px' }}>
              {isView ? (
                spec.result
              ) : (
                <CustomSelect
                  name="dropdown"
                  value={spec.result || ''}
                  onChange={(e) => {
                    spec.result = e.target.value;
                    setRender((prev) => !prev);
                  }}
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  <MenuItem value="">NONE</MenuItem>
                  <MenuItem value="ok">OK</MenuItem>
                  <MenuItem value="not ok">NOT OK</MenuItem>
                  <MenuItem value="accepted">ACCEPTED</MenuItem>
                  <MenuItem value="under deviation">UNDER DEVIATION</MenuItem>
                </CustomSelect>
              )}
            </td>
            <td style={{ width: '150px' }}>
              <TableInput
                name="qty"
                type="number"
                value={spec.qty}
                onChange={(e) => {
                  spec.qty = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </td>
            <td style={{ width: '150px' }}>
              <TableInput
                name="sign"
                type="text"
                value={spec.sign}
                onChange={(e) => {
                  spec.sign = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </td>

            {/* <td style={{ textTransform: 'uppercase' }}>
              {spec.appearance === 'check3' ? (
                isView ? (
                  spec.result
                ) : (
                  <CustomSelect
                    name="dropdown"
                    value={spec.result || ''}
                    onChange={(e) => {
                      spec.result = e.target.value;
                      setRender((prev) => !prev);
                    }}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <MenuItem value="">NONE</MenuItem>
                    <MenuItem value="ok">OK</MenuItem>
                    <MenuItem value="ng">NG</MenuItem>
                  </CustomSelect>
                )
              ) : (
                spec.result
              )}
            </td> */}
          </tr>
        ))}
      <tr>
        <th colSpan={11} align="left">
          Lot status- Ok/ Not Ok / Accepted / Under Deviation
        </th>
      </tr>
    </tbody>
  );
};

export default TBody;
