import { useState } from 'react';
import { Grid } from '@mui/material';
// import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';

import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';

const TBody = ({ moduleData, isView, handelDeleteRows }: any) => {
  const [render, setRender] = useState(false);

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData?.processes?.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <th>{index + 1}</th>
              <td>
                <TableInput
                  isView={isView}
                  name="training_need_description"
                  type="text"
                  value={process.training_need_description}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="effected_area"
                  type="text"
                  value={process.effected_area}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="training_description"
                  type="text"
                  value={process.training_description}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="trainer"
                  type="text"
                  value={process.trainer}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="shop"
                  type="text"
                  value={process.shop}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="name"
                  type="text"
                  value={process.name}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="training_hrs"
                  type="text"
                  value={process.training_hrs}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                {isView ? (
                  formatDate(process.date_of_training)
                ) : (
                  <DatePickerMui
                    label=""
                    value={
                      process.date_of_training
                        ? moment(process.date_of_training)
                        : null
                    }
                    onChange={(date) => {
                      process.date_of_training = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="signature_of_trainee"
                  type="text"
                  value={process.signature_of_trainee}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="effectiveness"
                  type="text"
                  value={process.effectiveness}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '120px' }} colSpan={isView ? 2 : 1}>
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
        <tr>
          <th colSpan={8} rowSpan={2} align="left">
            DATE:{' '}
            {isView ? (
              formatDate(moduleData.moduleData_Date)
            ) : (
              <DatePickerMui
                label=""
                value={moment(moduleData.moduleData_Date)}
                onChange={(date) => {
                  moduleData.moduleData_Date = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </th>
          <th colSpan={5} align="left">
            Name Of HOD :
            <TableInput
              isView={isView}
              name="name_of_hod"
              type="text"
              value={moduleData.name_of_hod}
              onChange={(e) => {
                moduleData.name_of_hod = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
        </tr>
        <tr>
          <th colSpan={5} align="left">
            Signature :
            <TableInput
              isView={isView}
              name="sign"
              type="text"
              value={moduleData.sign}
              onChange={(e) => {
                moduleData.sign = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
        </tr>
      </tbody>
    </>
  );
};

export default TBody;
