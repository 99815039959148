import { Box } from '@mui/system';
import SelectNpdPart from '../components/SelectNpdPart';

const List = () => {
  return (
    <Box>
      <SelectNpdPart link={'/common/createNpdMatrix'} />
    </Box>
  );
};

export default List;
