import axios from 'axios';
import { useState, useEffect } from 'react';

const useGetData = (partId: string | undefined, pirId: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({
    mig_date: new Date(),
    list: Array.from({ length: 25 }, () => ({})),
    image: [],
  });

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (pirId) {
      axios
        .get(`/api/migRoute/${pirId}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, []);

  return {
    moduleData,
    isLoading,
    error,
  };
};

export default useGetData;
