import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { handleChangeModuleData } from '../../processAuditChecksheet/checklist/helpers/handleChangeModuleData';
import { createConstant } from '../../constant';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import moment from 'moment';

interface Ifoot {
  moduleData: any;
  isView: any;
}
export interface IRev {
  rev_no?: any;
  rev_date?: any;
  rev_desc?: string;
  prepared_by?: string;
  approved_by?: string;
}
const TFoot = ({ moduleData, isView }: Ifoot) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
        <td align="left" colSpan={3}>
          Prepared By :
          <TableInput
            isView={isView}
            name="report_prepared_by"
            type="text"
            value={moduleData.report_prepared_by}
            onChange={(e: any) => {
              handleChangeModuleData(e, moduleData);
              setRender((prev: any) => !prev);
            }}
          />
        </td>
        <td align="left" colSpan={8}>
          Approved By :
          <TableInput
            isView={isView}
            name="report_approved_by"
            type="text"
            value={moduleData.report_approved_by}
            onChange={(e: any) => {
              handleChangeModuleData(e, moduleData);
              setRender((prev: any) => !prev);
            }}
          />
        </td>
      </tr>
      <tr>
        <th colSpan={11} align="left">
          Revision History
        </th>
      </tr>
      {/* {moduleData?.revision_history?.map((revData: IRev, index: number) => (
        <tr>
          <td style={{ width: '120px' }}>
            <TableInput
              name="rev_no"
              type="number"
              value={revData.rev_no}
              onChange={(e) => {
                revData.rev_no = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td style={{ width: '250px' }}>
            {isView ? (
              formatDate(revData.rev_date || null)
            ) : (
              <DatePickerMui
                value={moment(revData.rev_date)}
                onChange={(date) => {
                  revData.rev_date = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </td>
          <td colSpan={5}>
            <TableInput
              name="rev_desc"
              type="text"
              value={revData.rev_desc}
              onChange={(e) => {
                revData.rev_desc = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td colSpan={2}>
            <TableInput
              name="prepared_by"
              type="text"
              value={revData.prepared_by}
              onChange={(e) => {
                revData.prepared_by = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td colSpan={2}>
            <TableInput
              name="approved_by"
              type="text"
              value={revData.approved_by}
              onChange={(e) => {
                revData.approved_by = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
        </tr>
      ))}
      <tr>
        {[
          'Rev.No',
          'Revision Date',
          'Revision Description',
          'Prepared By:',
          'Approved By:',
        ].map((heading, index) => (
          <th key={heading} colSpan={index === 2 ? 5 : index >= 3 ? 2 : 1}>
            {heading}
          </th>
        ))}
      </tr> */}
    </tfoot>
  );
};

export default TFoot;
