import TBodyRow from './TBodyRow';

interface IProps {
  processes: any;
  isView: any;
  handleChangeTbody: any;
  topThreeRpn: number[];
}
const TBody = ({
  topThreeRpn,
  processes,
  handleChangeTbody,
  isView,
}: IProps) => {
  return (
    <tbody>
      {processes.map((process: any, processIndex: number) => {
        return process.fmeas.map((fmea: any, fmeaIndex: number) => (
          <TBodyRow
            rowSpan={process.fmeas.length}
            {...{
              topThreeRpn,
              process,
              fmea,
              handleChangeTbody,
              isView,
              processIndex,
              fmeaIndex,
            }}
          />
        ));
      })}
    </tbody>
  );
};

export default TBody;
