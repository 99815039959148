import moment from 'moment';

export const processes = [
  {
    hour: null,
    product_desc: null,
    target_shots: null,
    counter_start: null,
    counter_end: null,
    total_shots: null,
    off_shots: null,
    n_f: null,
    catch: null,
    a_b: null,
    b_h: null,
    solder: null,
    bend: null,
    crack: null,
    dent: null,
    rejection_detail_others: null,
    total_rej_shots: null,
    ok_shots: null,
    die_maint: null,
    die_dh_over: null,
    degassing: null,
    sft_ch_over: null,
    no_operator: null,
    mc_maint: null,
    power_cut: null,
    no_plan: null,
    reason_less_prod_others: null,
  },
];

export const initialState = {
  shift: null,
  mc_id: null,
  mc_no: null,
  operator: null,
  operator_2: null,
  dailyProduction_date: moment(),
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  processes: Array.from({ length: 5 }, () => ({ ...processes[0] })),
};
