import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  SaveButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import formatDate from '../../../components/common/formatDate';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import TController from './components/create/TController';
import useGetData from './helpers/useGetData';
interface MyData {
  moduleData: any; // replace PirType with the type of your `pir` data
  isLoading: boolean;
  error?: string | null;
  machineData?: any;
  autoCompleteMachineValue?: any;
  setAutoCompleteMachineValue?: any;
}
const Create = () => {
  const { partId, pirId } = useParams();
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const partName = useQuery('partName');
  const partNumber = useQuery('partNumber');

  const { moduleData, isLoading, error }: MyData = useGetData(partId, pirId);

  const onSubmit = async (isSubmitted: boolean) => {
    moduleData.isSubmitted = isSubmitted;
    moduleData.is_admin_request = 'pending';
    moduleData.report_prepared_by = state.user?.name;
    moduleData.part_id = partId;
    let response: any;
    if (moduleData._id) {
      response = await axios
        .put(`/api/migRoute/${moduleData._id}`, moduleData)
        .catch((err) => console.log(err));
    } else {
      response = await axios
        .post(`/api/migRoute`, moduleData)
        .catch((err) => console.log(err));
    }

    if (response.data && state.user?.userType === 3 && isSubmitted) {
      const messageData = {
        category: 'Mig Welding  Record',
        title: 'Mig Welding Record CREATED',
        user: 'admin',
        body: `Mig Welding Report of "${partName}" ${
          moduleData._id ? 'updated' : 'submitted'
        } by "${state.user?.name}" on ${formatDate(new Date())}`,
        link: `/common/migcreate/${partId}/${response.data._id}?partNumber=${partNumber}&partName=${partName}&isView=true`,
      };
      axios
        .post(`/api/notification`, messageData)
        .then(() => {
          if (moduleData._id) {
            alert('updated successfully ');
            return;
          }
          alert('created successfully');
          navigate(-1);
        })
        .catch((err) => console.log(err));
      return;
    }
    if (moduleData._id && !isSubmitted) {
      alert('updated successfully ');
      return;
    }
    alert('created successfully');
    navigate(-1);
  };
  return (
    <Box>
      <Box>
        <ChildHeader text="CREATE Mig Welding  REPORT">
          {!isView && (
            <>
              {state.user?.userType === 3 && (
                <SaveButton
                  label="Save & Continue"
                  onClick={() => onSubmit(false)}
                />
              )}
              <SubmitButton label="Submit" onClick={() => onSubmit(true)} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
        }}
      />
    </Box>
  );
};

export default Create;
