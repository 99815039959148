import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { GrCodepen } from 'react-icons/gr';
import { useParams } from 'react-router-dom';
import { CustomButton } from '../../../../components/common/button/CustomButtons';
import GridDatePicker from '../../../../components/common/inputs/GridDatePicker';

const GenerateFmea = ({ setIsOpenPopup }: { setIsOpenPopup: any }) => {
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const { partId } = useParams();
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    if (fromDate && toDate) {
      let isValidDate =
        new Date(toDate).getTime() - new Date(fromDate).getTime();
      if (isValidDate < 0) {
        setFromDate(null);
        setToDate(null);
        alert('Please choose a valid date!');
        setData(null);
      } else {
        axios
          .get(
            `/api/fmeaManagementRoute/generateFmea/getOccFromProcessRejection/${partId}/${fromDate}/${toDate}`,
          )
          .then((res) => {
            if (!res.data) {
              setData(null);
              return;
            }
            setData(res.data);
          })
          .catch((err) => {
            console.log(err);
            setData(null);
          });
      }
    }
  }, [fromDate, toDate]);
  const submitReport = async () => {
    if (data) {
      data.from = fromDate;
      data.to = toDate;
      delete data._id;
      axios
        .post(`/api/fmeaManagementRoute/generateFmea`, data)
        .then((res) => {
          if (res.data) {
            alert('report generated successfully');
            setIsOpenPopup(false);
          }
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <GridDatePicker
        label_name="FROM"
        html_for={'fromDate'}
        value={moment(fromDate || null)}
        onChange={(date) => {
          setFromDate(date);
        }}
      />
      <GridDatePicker
        label_name="TO"
        html_for={'toDate'}
        value={moment(toDate || null)}
        onChange={(date) => {
          setToDate(date);
        }}
      />
      <CustomButton
        disabled={!data}
        color="primary"
        sx={{ margin: '10px' }}
        icon={<GrCodepen />}
        size="large"
        onClick={() => submitReport()}
      >
        GENERATE
      </CustomButton>
    </>
  );
};

export default GenerateFmea;
