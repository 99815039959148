import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  SaveButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import formatDate from '../../../components/common/formatDate';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import TController from './components/create/TController';
import useGetData from './helpers/useGetData';
interface MyData {
  moduleData: any;
  isLoading: boolean;
  error?: string | null;
  machineData?: any;
}
const Create = () => {
  const { partId, pirId } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const partName = useQuery('partName');
  const partNumber = useQuery('partNumber');
  const { moduleData, isLoading, error, machineData }: MyData = useGetData(
    partId,
    pirId
  );

  const onSubmit = async (isSubmitted: boolean) => {
    moduleData.isSubmitted = isSubmitted;
    moduleData.is_admin_request = 'pending';
    moduleData.report_prepared_by = state.user?.name;
    let response: any;
    if (moduleData._id) {
      response = await axios
        .put(`/api/pirPressShopRoute/${moduleData._id}`, moduleData)
        .catch((err) => console.log(err));
    } else {
      response = await axios
        .post(`/api/pirPressShopRoute`, moduleData)
        .catch((err) => console.log(err));
    }
    if (response.data && state.user?.userType === 3 && isSubmitted) {
      const messageData = {
        category: 'PIR',
        title: 'PIR CREATED',
        user: 'admin',
        body: `PIR Report of "${partName}" ${
          moduleData._id ? 'updated' : 'submitted'
        } by "${state.user?.name}" on ${formatDate(new Date())}`,
        link: `/common/pressShop/createPir/${partId}/${response.data._id}?partNumber=${partNumber}&partName=${partName}&isView=true`,
      };
      axios
        .post(`/api/notification`, messageData)
        .then(() => {
          if (moduleData._id) {
            alert('updated successfully ');
            return;
          }
          alert('created successfully');
          navigate(-1);
        })
        .catch((err) => console.log(err));
      return;
    }
    if (moduleData._id && !isSubmitted) {
      alert('updated successfully ');
      return;
    }
    alert('created successfully');
    navigate(-1);
  };
  // console.log(machineData);
  return (
    <Box>
      <Box>
        <ChildHeader text="In-Process Inspection Report (Press Shop)">
          {!isView && (
            <>
              {state.user?.userType === 3 && (
                <SaveButton
                  label="Save & Continue"
                  onClick={() => onSubmit(false)}
                />
              )}
              <SubmitButton label="Submit" onClick={() => onSubmit(true)} />
            </>
          )}
        </ChildHeader>
      </Box>
      <Paper elevation={2}>
        <Tabs
          value={tabValue}
          onChange={(_, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          textColor="secondary"
        >
          {moduleData &&
            moduleData.pirs.map((pir: any) => <Tab label={pir.process_name} />)}
        </Tabs>
      </Paper>
      <TController {...{ moduleData, tabValue, isView, machineData }} />
    </Box>
  );
};

export default Create;
