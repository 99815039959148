import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'Sr. No.',
  'Shift',
  'M/C No',
  'Part Name',
  'Operation',
  'Operator',
  'M/c Start Time',
  'M/c End Time',
  'Plan Qty.',
  'Actual Qty.',
  'Lot. No.',
  'Rejection',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={15}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['FRM-PRD-01-1', '00', '01-04-2023']}
        heading="Daily Production Report"
      />
      <tr>
        <th colSpan={2}>Daily Production Date : </th>
        <th colSpan={13} align="left">
          {isView ? (
            formatDate(moduleData.dailyProduction_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.dailyProduction_date)}
              onChange={(date) => {
                moduleData.dailyProduction_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading + index} colSpan={1}>
            {heading}
          </th>
        ))}
        <th colSpan={3}>Remarks</th>
      </tr>
    </thead>
  );
};

export default THead;
