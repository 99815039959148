import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import moment from 'moment';
import AlertMui from '../../../../components/common/AlertMui';

import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import InputMuiCustom from '../../../../components/common/inputs/InputMuiCustom';
import FormControlMui, {
  StyledField,
} from '../../../../components/common/FormControlMui';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import useConfirm from '../../../../components/common/useConfirm';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
interface Iprop {
  setIsNewPartModal: Dispatch<SetStateAction<boolean>>;
  id?: string | object | null;
  setPartId: React.Dispatch<React.SetStateAction<string | null>>;
}

export interface IPart {
  _id?: string;
  part_number: string | null;
  part_name: string | null;
  customer_id?: any;
  customer_id_temp?: any;
  date_orig: moment.Moment;
  createdAt?: Date;
}

const AddEditPart = ({ setIsNewPartModal, id, setPartId }: Iprop) => {
  const [partData, setPartData] = useState<IPart>({
    part_number: null,
    part_name: null,
    date_orig: moment(),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [render, setRender] = useState(false);
  const [alert, setAlert] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'DRAFT PART',
    `Are you sure you want to ${id ? 'update' : 'save'} this part?`,
  );

  const handlePartData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPartData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    axios
      .get('/api/customerRoutes/')
      .then((res) => {
        setCustomers(res.data.results);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/npdPartRoute/${id}`)
        .then((res) => {
          setPartData(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      setPartData((prev) => prev);
    }
  }, [id]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) return;
    setIsLoading(true);
    if (id) {
      await axios
        .put(`/api/npdPartRoute/${id}`, partData)
        .then((res) => {
          setIsLoading(false);
          setIsNewPartModal(false);
          setPartId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      await axios
        .post('/api/npdPartRoute', partData)
        .then((res) => {
          setIsLoading(false);
          setIsNewPartModal(false);
          setPartId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Box>
        <FormControlMui handleSubmit={onSubmit}>
          <div
            className="btn_save"
            style={{
              position: 'absolute',
              right: '140px',
              top: '-0.6px',
            }}
          >
            <SubmitButton
              label={`${id ? 'update' : 'submit'}`}
              type="submit"
              disabled={isLoading}
            />
          </div>
          <StyledField>
            <InputMuiCustom
              type="text"
              name="part_number"
              placeholder="Part Number"
              required={true}
              onChange={handlePartData}
              value={partData?.part_number || ''}
            />
            <InputMuiCustom
              type="text"
              name="part_name"
              placeholder="Customer Part Name"
              onChange={handlePartData}
              value={partData?.part_name || ''}
              required={true}
            />
            <AutocompleteMuiCustom
              id="customer_name"
              label="Customer"
              option_name="customer_name"
              arrayofObj={customers}
              value={partData.customer_id_temp || partData.customer_id || ''}
              onChange={(e, value) => {
                partData.customer_id = value?._id;
                partData.customer_id_temp = value;
                setRender((prev) => !prev);
              }}
            />
            <DatePickerMui
              label="DATE (Original)"
              onChange={(date) => {
                partData.date_orig = date;
                setRender((prev) => !prev);
              }}
              value={moment(partData.date_orig) || null}
            />
          </StyledField>
        </FormControlMui>
      </Box>
      <DialogSubmit />
      <AlertMui
        alert={alert}
        message={
          'Please select a customer from the list. The customer field cannot be left empty'
        }
        setAlert={setAlert}
        severity={'warning'}
      />
    </>
  );
};

export default AddEditPart;
