import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import TController from './components/create/TController';
const Create = () => {
  const [moduleData, setModuleData] = useState<any>({
    employees: [],
  });
  const { departmentId } = useParams();
  const [machineData, setMachineData] = useState([]);
  const [render, setRender] = useState(false);
  const isView = useQuery('isView') === 'true' ? true : false;
  useEffect(() => {
    axios
      .get(`/api/machineRoutes/machineByDepartmentId/${departmentId}`)
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(`/api/manMachineMatrix/${departmentId}`)
      .then((res) => {
        setModuleData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const onSubmit = async () => {
    await axios
      .put(`/api/manMachineMatrix/${moduleData._id}`, moduleData)
      .then((res) => {
        if (res.data) {
          alert('created successfully');
          setRender((prev) => !prev);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box>
      <Box>
        <ChildHeader text="MAN MACHINE MATRIX">
          {!isView && (
            <>
              <SubmitButton label="UPDATE" onClick={() => onSubmit()} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          machineData,
        }}
      />
    </Box>
  );
};

export default Create;
