import { Box } from '@mui/material';
import PartPickerForSingleDoc from '../../../components/common/PartPickerForSingleDoc';

const TableList = () => {
  return (
    <Box>
      <PartPickerForSingleDoc link={'/common/mispStandardsCreate'} />
    </Box>
  );
};

export default TableList;
