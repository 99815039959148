import React from "react";
import styled from "styled-components";
import { AiOutlineFrown } from "react-icons/ai";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Navbar } from "./components/ui";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f6f8fa;
`;

const ErrorIcon = styled(AiOutlineFrown)`
  font-size: 8rem;
  color: #ff6b6b;
`;

const ErrorMessage = styled.h1`
  font-size: 2.5rem;
  color: #333333;
  margin-top: 1rem;
`;

const ErrorText = styled.p`
  font-size: 1.5rem;
  color: #666666;
  margin-top: 1rem;
  text-align: center;
`;

const BackLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #1da1f2;
  border-radius: 2rem;
  font-size: 1.2rem;
  padding: 0.8rem 2rem;
  margin-top: 2rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #0c88d1;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <Container>
        <ErrorIcon />
        <ErrorMessage>Oops! 404</ErrorMessage>
        <ErrorText>
          Sorry, the page you are looking for could not be found.
        </ErrorText>
        <BackLink onClick={() => navigate(-1)}>
          <BsArrowLeft />
          Go back
        </BackLink>
      </Container>
    </>
  );
};

export default ErrorPage;
