export const apiConstant = {
  // list api url
  gettableListDataurl: 'gaugeMaintenancePlanRoute/monthlylist',
  deleteListurl: 'gaugeMaintenancePlanRoute',
  getListPlanByid: 'gaugeMaintenancePlanRoute',
  createListPlanByid: 'gaugeMaintenancePlanRoute',
  // -----plan--
  getAllPlanById: 'gaugeMaintenancePlanRoute/allplansbyid',
  upadateCreateAllPlan: 'gaugeMaintenancePlanRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'gaugeMaintenancePlanRoute/maintenancePlanDeleteBulkById',
  sync: 'gaugeMaintenancePlanRoute/handleSync',
  // ----status---
  getAllStatus: 'gaugeMaintenancePlanRoute/getAllSatusByYear',
  upadateListStatus: 'gaugeMaintenancePlanRoute/handleStatusUpdate',

  //
  updatePlanByid: 'gaugeMaintenancePlanRoute',
  checklistUpload: 'gaugeMaintenancePlanRoute/handleUpload',
  checklistDeleteImage: 'gaugeMaintenancePlanRoute/handleDeleteImage',
  checklistData: 'gaugeMaintenancePlanRoute/checklistRoute',
};

export const tilesConstant = {
  childHeaderText: 'Gauge Maintenance',
};
