import { AiOutlineCloudUpload } from 'react-icons/ai';
import { HiDocumentReport } from 'react-icons/hi';
import {
  MdDriveFolderUpload,
  MdFeaturedPlayList,
  MdSummarize,
  MdSupervisorAccount,
} from 'react-icons/md';
const tiles = [
  // {
  //   sNo: 1,
  //   link: '/common/clauseUploadList?documentType=masterProcedureClause3&heading=MASTER PROCEDURE',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'MASTER',
  //   span: 'PROCEDURE',
  //   isIcon: true,
  // },
  // {
  //   sNo: 2,
  //   link: '/common/4mPdc',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: '4M',
  //   span: 'PDC',
  //   isIcon: true,
  // },
  // {
  //   sNo: 3,
  //   link: '/common/4mPressShop',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#a12222" />,
  //   p: '4M PRESS',
  //   span: 'SHOP',
  //   isIcon: true,
  // },
  // {
  //   sNo: 4,
  //   link: '/common/4mRotor',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: '4M',
  //   span: 'ROTOR',
  //   isIcon: true,
  // },
  {
    sNo: 1,
    link: '/common/company_roles_responsibilities_listOfProcedures?documentType=clause3ListOfProcedure',
    isLink: true,
    icon: <MdDriveFolderUpload size="5rem" color="#003566" />,
    p: 'LIST OF',
    span: 'PROCEDURE',
    isIcon: true,
  },
  {
    sNo: 19,
    link: '/common/4mSummaryList',
    isLink: true,
    icon: <MdSummarize size="5rem" color="#003566" />,
    p: '4M',
    span: 'SUMMARY',
    isIcon: true,
  },
  // {
  //   sNo: 6,
  //   link: 'https://uc.secure.aqua.mushinlabs.com',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <MdFeaturedPlayList size="5rem" color="#135089" />,
  //   p: '4M MONITERNING',
  //   span: 'REPORTS',
  //   isIcon: true,
  // },

  {
    sNo: 19,
    link: `/common/masterProcedureNewUpload/?documentType=masterProcedureNewUpload&heading=MASTER PROCEDURE`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="orange" />,
    p: 'Master',
    span: 'Procedure',
    isIcon: true,
  },
  {
    sNo: 19,
    link: `/common/workInstructionsUpload/?documentType=workInstructionsUpload&heading=WORK INSTRUCTIONS`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="green" />,
    p: 'WORK',
    span: 'INSTRUCTIONS',
    isIcon: true,
  },
];

export default tiles;
