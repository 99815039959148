import { SetStateAction, useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import { Grid, MenuItem, Tooltip } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { TimePicker } from '@mui/x-date-pickers';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import { convToTime } from '../../../../../utils/helpers';

interface IProps {
  moduleData: any;
  isView: boolean;
  handleSumbit: any;
  // machineData: any;
  // supplierData: any;
  // autoCompleteMachineValue: any;
  // autoCompleteSupplierValue_1: any;
  // autoCompleteSupplierValue_2: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const TBody = ({
  moduleData,
  isView,
  handleSumbit,
  // machineData,
  // supplierData,
  // autoCompleteMachineValue,
  // autoCompleteSupplierValue_1,
  // autoCompleteSupplierValue_2,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <th>{index + 1}</th>
              <td style={{ minWidth: '180px' }}>
                <TableInput
                  isView={isView}
                  name="rejection_rec_from"
                  type="text"
                  value={process.rejection_rec_from}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ width: '280px', minWidth: '280px' }}>
                {isView ? (
                  process?.part_id && process?.part_id?.part_name
                ) : (
                  <Grid container xs={12} direction="row" alignItems={'center'}>
                    <Grid item xs={10}>
                      <p
                        style={{
                          fontSize: '1.2rem',
                        }}
                      >
                        {typeof process?.part_id === 'object' &&
                        process?.part_id !== null
                          ? process?.part_id?.part_name
                          : process?.partName || ''}
                        {!process?.part_id && 'Pick Part Name.'}
                      </p>
                    </Grid>

                    {process.part_id ? (
                      <Grid item xs={2}>
                        <RemoveIconButton
                          tooltipTitle="remove part"
                          onClick={() => {
                            process.part_id = null;
                            // process.partNumber = null;
                            process.partName = null;
                            setRender((prev) => !prev);
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <AddIconButton
                          tooltipTitle="add part"
                          onClick={() => {
                            setIsOpenPopup(true);
                            setPartIdx(index);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="defects"
                  type="text"
                  value={process.defects}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="check_qty"
                  type="number"
                  value={process.check_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="rejection_qty"
                  type="number"
                  value={process.rejection_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="rejection_percentage"
                  type="number"
                  value={process.rejection_percentage}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="cause"
                  type="text"
                  value={process.cause}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="rework"
                  type="text"
                  value={process.rework}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="scrap"
                  type="text"
                  value={process.scrap}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '250px' }}>
                <TableInput
                  isView={isView}
                  name="corrective_and_preventive_action"
                  type="text"
                  value={process.corrective_and_preventive_action}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="responsibility"
                  type="text"
                  value={process.responsibility}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  formatDate(process.target_date)
                ) : (
                  <DatePickerMui
                    label=""
                    value={moment(process.target_date)}
                    onChange={(date) => {
                      process.target_date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="status"
                  type="text"
                  value={process.status}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="redbin_approval"
                  type="text"
                  value={process.redbin_approval}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '80px' }}>
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                {isView ? (
                  ''
                ) : (
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
