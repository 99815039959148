import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  SaveButton,
  SubmitButton,
} from '../../components/common/button/CustomButtons';
import formatDate from '../../components/common/formatDate';
import ChildHeader from '../../components/ui/ChildHeader';
import { useQuery } from '../../hooks/UseQuery';
import { useAuth } from '../../services/auth/AuthProvider';
import TController from './create/TController';

interface MyData {
  moduleData: any; // replace PdirType with the type of your `pdir` data
  isLoading: boolean;
  machineData: any;
  error?: string | null;
}
const Create = () => {
  const { state } = useAuth();
  const { partId, id } = useParams();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const partName = useQuery('partName');
  const partNumber = useQuery('partNumber');
  const [moduleData, setModuleData] = useState<any>({});
  const [process, setProcess] = useState<any>([]);

  useEffect(() => {
    axios
      .get(`/api/processControlRoute/getcontrolplan/${partId}`)
      .then((res) => {
        setProcess(res.data.processes);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!id) return;
    axios
      .get(`/api/processControlRoute/${id}`)
      .then((res) => {
        setModuleData(res.data);
      })
      .catch((err) => console.log(err));
  }, [id]);

  const onSubmit = async (isSubmitted: boolean) => {
    moduleData.part_id = partId;
    if (id) {
      axios
        .put(`/api/processControlRoute/${id}`, moduleData)
        .then((res) => {
          alert('successfully update');
          navigate(-1);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post(`/api/processControlRoute`, moduleData)
        .then((res) => {
          alert('successfully created');
          navigate(-1);
        })
        .catch((err) => console.log(err));
    }

    //
  };

  return (
    <Box>
      <Box>
        <ChildHeader text="PROCESS CONTROL STANDARD">
          {!isView && (
            <>
              {state.user?.userType === 3 && (
                <SaveButton
                  label="Save & Continue"
                  onClick={() => onSubmit(false)}
                />
              )}
              <SubmitButton label="Submit" onClick={() => onSubmit(true)} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController {...{ moduleData, isView, process, setModuleData }} />
    </Box>
  );
};

export default Create;
