export const listConstant = {
  childHeader: 'SPC Plan list',
  addButtonLable: 'New SPC',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'SPC Plan list',
  theadHeader: 'SPC  Plan',
  extracolSpan: 6,
};

export const checkListList = {
  childHeader: 'SPC PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'spcPlanRoute',
  gettableDataurl: 'spcPlanRoute/monthly',
  getPlanByid: 'spcPlanRoute',
  createPlanByid: 'spcPlanRoute',
  updatePlanByid: 'spcPlanRoute',
  sync: 'spcPlanRoute/handleSync',
  getAllCheckList: 'spcPlanRoute/getAllCheckList',
  checkListStatus: 'spcPlanRoute/handleStatus',
  checklistUpload: 'spcPlanRoute/handleUpload',
  checklistDeleteImage: 'spcPlanRoute/handleDeleteImage',
};

export const tilesConstant = {
  childHeaderText: 'SPC PLAN',
};

export const navigationConstant = {
  createPlanSchedule: '/common/spcPlanPlanCreate',
};
