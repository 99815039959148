import { RenderTiles } from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';
import { AiOutlineCloudUpload } from 'react-icons/ai';
const tiles = [
  {
    sNo: 1,
    link: '/common/clauseUploadList?documentType=manMachineMatrixClause3Rotor&heading=MAN MACHINE MATRIX',
    isLink: true,
    clauseNo: 3,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'MAN MACHINE',
    span: 'MATRIX',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/clauseUploadList?documentType=wiClause3Rotor&heading=WI',
    isLink: true,
    clauseNo: 3,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'WI',
    span: '',
    isIcon: true,
  },
];

export type tilesType = {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number;
  p: string;
  span: string;
  icon?: any;
  style?: {};
}[];
const RotorHome = () => {
  return (
    <>
      <ChildHeader text="4M PRESS SHOP" />
      <RenderTiles tilesObj={tiles} height="100vh" />
    </>
  );
};

export default RotorHome;
