import { useState } from 'react';
import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: { [key: string]: any };
  isView: boolean;
  phase: any;
}
const TFoot = ({ phase, moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
        <td>SN</td>
        <td colSpan={3}>ACTIVITY</td>
        <td colSpan={13}>DETAIL OF ACTIVITY FOR DELAY/RESCHEDULING</td>
        <td colSpan={12}>ACTION PLAN</td>
        <td colSpan={5}>SIGN.</td>
      </tr>
      {moduleData?.[`${phase}_remarks`]?.length > 0 &&
        moduleData[`${phase}_remarks`].map((item: any, index: any) => (
          <>
            <tr style={{ textAlign: 'center' }}>
              <th>{item.s_no}</th>
              <th colSpan={3}>{item.activity}</th>
              <th colSpan={13}></th>
              <th colSpan={12}>
                <TableInput
                  isView={isView}
                  style={{ height: '80px' }}
                  name="detail_of_activity"
                  type="text"
                  value={item.detail_of_activity}
                  onChange={(e) => {
                    moduleData[`${phase}_remarks`][index].detail_of_activity =
                      e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th colSpan={5}></th>
            </tr>
          </>
        ))}
    </tfoot>
  );
};

export default TFoot;
