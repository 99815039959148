import moment from 'moment';
import { useState } from 'react';

import { TableCompHead } from '../../../../../../components/ui';
import formatDate from '../../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import { TableInput } from '../../../../../../components/common';
import { useQuery } from '../../../../../../hooks/UseQuery';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';

interface IProps {
  moduleData: any;
  isView: boolean;
  customer: any;
}

const heading = [
  'S. No.',
  'Check Point',
  'Dimensions',
  'Method',
  'Observation',
  'C / NC',
];

const THead = ({ moduleData, isView, customer }: IProps) => {
  const [render, setRender] = useState(false);
  const part_no = useQuery('part_no');
  const part_name = useQuery('part_name');
  const customer_name = useQuery('customer_name');
  const machin_no = useQuery('machin_no');

  return (
    <thead>
      <TableCompHead
        colSpan={11}
        rowSpan={3}
        labels={['Doc. No.: ', 'Rev. No.:', 'Eff. Date:']}
        values={['RM-QAS-A-008', '00', '01-04-2023']}
        heading="Product Audit Checksheet"
      />
      <tr>
        <th colSpan={4}>Customer Name: {customer_name}</th>
        <th align="left" colSpan={7}>
          Auditor:
          <TableInput
            isView={isView}
            name="s3"
            type="text"
            value={moduleData.s3}
            onChange={(e: any) => {
              handleChangeModuleData(e, moduleData);
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={4}>Part Name : {part_name}</th>
        <th align="left" colSpan={7}>
          Auditee:
          <TableInput
            isView={isView}
            name="s4"
            type="text"
            value={moduleData.s4}
            onChange={(e: any) => {
              handleChangeModuleData(e, moduleData);
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={4}>Part No. : {part_no}</th>
        <th colSpan={7} align="left">
          Audit Date :{' '}
          {isView ? (
            formatDate(moduleData.d2)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d2)}
              onChange={(date) => {
                moduleData.d2 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {heading.map((header) => (
          <th
            key={header}
            colSpan={
              (header === 'Observation' && 6) ||
              (header === 'Action Taken' && 3) ||
              1
            }
            rowSpan={
              header === 'Observation' || header === 'Action Taken' ? 1 : 2
            }
          >
            {header}
          </th>
        ))}
      </tr>
      <tr>
        {['1', '2', '3', '4', '5', '6'].map((num) => (
          <td>{num}</td>
        ))}
      </tr>
      <tr style={{ background: '#f1f3f5' }}>
        <th colSpan={3} align="left">
          For: Appearance check
        </th>
        <th>5</th>
        <th colSpan={2} align="left">
          For: Dimensional check
        </th>
        <th>3</th>
        <th colSpan={3} align="left">
          For: Material testing and other tests.
        </th>
        <th>1</th>
      </tr>
    </thead>
  );
};

export default THead;
