import { HiDocumentReport } from 'react-icons/hi';
import { RenderTiles } from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';

export type tilesType = {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number;
  p: string;
  span: string;
  icon?: any;
  style?: {};
}[];
const tiles = [
  {
    sNo: 1,
    isLink: false,
    link: '/common/masterlistofTniCategory',
    clauseNo: 5,
    icon: <HiDocumentReport size="5rem" color="#135089" />,
    p: 'ADD TNI',
    span: 'CATEGORY',
    isIcon: true,
  },
  {
    sNo: 2,
    isLink: false,
    link: '/common/topicPaper/list',
    clauseNo: 5,
    icon: <HiDocumentReport size="5rem" color="#135089" />,
    p: 'CREATE',
    span: 'TNI TOPIC',
    isIcon: true,
  },
  {
    sNo: 3,
    isLink: false,
    link: '/common/tniPlanList',
    clauseNo: 5,
    icon: <HiDocumentReport size="5rem" color="#135089" />,
    p: 'TNI',
    span: 'PLAN',
    isIcon: true,
  },
];

const TNITiles = () => {
  return (
    <>
      <ChildHeader text="Identify Training Needs & Training Topics" />
      <RenderTiles tilesObj={tiles} height={'100vh'} justify="start" />
    </>
  );
};

export default TNITiles;
