import React, { useState } from 'react';
import ObservationInput from './ObservationInput';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';
interface IProps {
  spec: any;
  isView: boolean;
}

const TBodyRow = ({ spec, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      {Array.from({ length: 5 }, () => null).map((_, obsIndex) => (
        <ObservationInput
          {...{ spec, isView, obsIndex }}
          setRender={setRender}
          obsNumber={0}
          checkType={spec.appearance}
        />
      ))}
      <td style={{ textTransform: 'uppercase' }}>
        {spec.appearance === 'check3' ? (
          isView ? (
            (spec?.check_0_status === 'ng' && 'NOT OK') ||
            (spec?.check_0_status === 'ok' && 'OK')
          ) : (
            <CustomSelect
              name="dropdown"
              value={spec.check_0_status || ''}
              onChange={(e) => {
                spec.check_0_status = e.target.value;
                setRender((prev) => !prev);
              }}
              sx={{
                textAlign: 'center',
              }}
            >
              <MenuItem value="">NONE</MenuItem>
              <MenuItem value="ok">OK</MenuItem>
              <MenuItem value="ng">NG</MenuItem>
            </CustomSelect>
          )
        ) : (
          (spec?.check_0_status === 'ng' && 'NOT OK') ||
          (spec?.check_0_status === 'ok' && 'OK')
        )}
      </td>
    </>
  );
};

export default TBodyRow;
