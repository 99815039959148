export const apiConstant = {
  // // list api url
  // gettableListDataurl: 'prevantiveMaintenancePlan_NEW_Route/monthlylist',
  // deleteListurl: 'prevantiveMaintenancePlan_NEW_Route',
  getListPlanByid: 'prevantiveMaintenancePlan_NEW_Route',
  createListPlanByid: 'prevantiveMaintenancePlan_NEW_Route',
  // -----plan--
  // getAllPlanById: 'prevantiveMaintenancePlan_NEW_Route/allplansbyid',
  // upadateCreateAllPlan:
  //   'prevantiveMaintenancePlan_NEW_Route/allplansCreateUpdate',
  // maintenancePlanDeleteBulkById:
  //   'prevantiveMaintenancePlan_NEW_Route/maintenancePlanDeleteBulkById',
  // sync: 'prevantiveMaintenancePlan_NEW_Route/handleSync',
  // // ----status---
  // getAllStatus: 'prevantiveMaintenancePlan_NEW_Route/getAllSatusByYear',
  // upadateListStatus: 'prevantiveMaintenancePlan_NEW_Route/handleStatusUpdate',

  // //
  // updatePlanByid: 'prevantiveMaintenancePlan_NEW_Route',
  // checklistUpload: 'prevantiveMaintenancePlan_NEW_Route/handleUpload',
  // checklistDeleteImage: 'prevantiveMaintenancePlan_NEW_Route/handleDeleteImage',
  // checklistData: 'prevantiveMaintenancePlan_NEW_Route/checklistRoute',
};

export const tilesConstant = {
  childHeaderText: 'Prevantive Maintenance',
};
