import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={5}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Rev Date']}
        values={[' F-QA-09A', '02', '09.10.2019']}
        heading="INCOMING INSPECTION STANDARD"
      />
      <tr>
        <th colSpan={3} align="left">
          Item Name:
          {moduleData.part_name
            ? moduleData.part_name
            : moduleData.part_id?.part_name}
        </th>
        <th colSpan={2} align="left">
          Responsibility:
          <TableInput
            name="resp"
            type="text"
            value={moduleData.resp}
            onChange={(e) => {
              moduleData.resp = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Inspection Qty:
          <TableInput
            name="qty"
            type="text"
            value={moduleData.qty}
            onChange={(e) => {
              moduleData.qty = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={2} align="left">
          Date:
          {isView ? (
            formatDate(moduleData.incoming_date)
          ) : (
            <DatePickerMui
              label="Incoming Inspection Date"
              value={moment(moduleData.incoming_date)}
              onChange={(date) => {
                moduleData.incoming_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Item Number:
          {moduleData.part_number
            ? moduleData.part_number
            : moduleData.part_id?.part_number}
        </th>
        <th colSpan={2} align="left"></th>
      </tr>
      <tr>
        {[
          'S.NO',
          'PARAMETER',
          'SPECIFICATION',
          'Checking method',
          'remarks',
        ].map((head) => (
          <th style={{ textTransform: 'uppercase' }}>{head}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
