import { MenuItem } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import ModalCustom from '../../../../../components/common/Modal';
import CustomSelect from '../../../../../components/common/SelectField';
import ForMSummaryForm from '../../../components/ForMSummaryForm';

const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

const OperatorFor4mLogs = ({
  machineId,
  process,
  field,
  onSubmit,
  dataObj,
  isView,
  isOpen4mModel,
  setIsOpen4mModel,
}: {
  machineId: string;
  process: any;
  field: string;
  isOpen4mModel?: any;
  onSubmit?: any;
  dataObj?: any;
  isView?: any;
  setIsOpen4mModel?: any;
}) => {
  const [operators, setOpeartors] = useState([]);
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [data, setData] = useState<any>({});
  console.log(process);

  useEffect(() => {
    if (isOpen4mModel) {
      setIsOpenPopup(true);
    }
    // return () => {
    //   setIsOpen4mModel(false);
    // };
  }, [isOpen4mModel]);

  useEffect(() => {
    const getMachineMaxtirx = async () => {
      if (machineId) {
        await axios
          .get(`/api/manMachineMatrix/operatorById/${machineId}`)
          .then((res) => {
            if (res.data) {
              setOpeartors(res.data);
            }
          })
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });
      }
    };
    getMachineMaxtirx();
    return () => {
      setData({});
      setOpeartors([]);
    };
  }, [machineId]);

  const addTo4mSummary = () => {
    const month = new Date(dataObj.date).getMonth();
    const year = new Date(dataObj.date).getFullYear();
    const annualMonth = `${months[month]}-${year}`;
    if (!data.reason) {
      return alert('Please choose reason!');
    }
    const body = {
      date: dataObj.date,
      part_id: dataObj.part_id,
      operation_name: dataObj.operation_name,
      machine_id: machineId,
      ...data,
    };
    axios
      .put(
        `/api/forMSummaryRoute/insertFromProductionToSummary/${annualMonth}`,
        body
      )
      .then((res) => {
        if (res.data) {
          onSubmit(true, 'forSummary');
          setIsOpenPopup(false);
        }
      });
  };

  return (
    <>
      <ForMSummaryForm {...{ addTo4mSummary, process, field, setData, data }} />
    </>
  );
};
export default OperatorFor4mLogs;
