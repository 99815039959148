import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { convToTime } from '../../../../../utils/helpers';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';
import useConfirm from '../../../../../components/common/useConfirm';
import CustomMobileTimePickerMui from '../../../../../components/common/CustomMobileTimePickerMui';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const partName = useQuery('partName');
  const partNumber = useQuery('partNumber');
  const [DialogCavity, confirmCavity] = useConfirm(
    'Alert',
    'Are you sure you want to change the cavity?'
  );
  console.log(moduleData);

  return (
    <thead>
      <TableCompHead
        colSpan={9}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Eff. Date:']}
        values={['FRM-QAS-C-03', '00', '01-04-2023']}
        heading="Mig Welding  Record"
      />

      <tr>
        <th colSpan={2} align="left">
          Part Name
        </th>
        <th colSpan={2} align="left">
          {partName}
        </th>
        <th
          data-img={moduleData?.image}
          colSpan={3}
          rowSpan={5}
          style={{
            height: '400px',
          }}
        >
          <img src={moduleData?.image} height={400} width={600} alt="image" />
        </th>
        <th colSpan={2}> Parameter Specification </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Part No.-
        </th>
        <th colSpan={2} align="left">
          {partNumber}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Sheet Thickness-
        </th>
        <th colSpan={2} align="left">
          <TableInput
            name="sheet_thickness"
            type="text"
            value={moduleData?.sheet_thickness}
            onChange={(e) => {
              moduleData.sheet_thickness = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={1} align="left">
          Current
        </th>
        <th colSpan={1} align="left">
          <TableInput
            name="current"
            type="text"
            value={moduleData?.current}
            onChange={(e) => {
              moduleData.current = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Collar Size-
        </th>
        <th colSpan={2} align="left">
          <TableInput
            name="collar_size"
            type="text"
            value={moduleData?.collar_size}
            onChange={(e) => {
              moduleData.collar_size = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={1} align="left">
          Voltage
        </th>
        <th colSpan={1} align="left">
          <TableInput
            name="voltage"
            type="text"
            value={moduleData?.voltage}
            onChange={(e) => {
              moduleData.voltage = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Child Part-
        </th>
        <th colSpan={2} align="left">
          <TableInput
            name="child_part"
            type="text"
            value={moduleData?.child_part}
            onChange={(e) => {
              moduleData.child_part = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={1} align="left">
          Weld Length
        </th>
        <th colSpan={1} align="left">
          <TableInput
            name="weld_length"
            type="text"
            value={moduleData?.weld_length}
            onChange={(e) => {
              moduleData.weld_length = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Date:{' '}
        </th>
        <th colSpan={3}>
          {isView ? (
            formatDate(moduleData.mig_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.mig_date)}
              onChange={(date) => {
                moduleData.mig_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th rowSpan={2}>S.No.</th>
        <th rowSpan={2}>Date</th>
        <th rowSpan={2}>Operator Name</th>
        <th colSpan={4}>Observation</th>
        <th>Appearance</th>
        <th>No Mig Weld Spatter , No Blow Hole</th>
      </tr>
      <tr>
        <th>Current ( AMP)</th>
        <th>Voltage (V)</th>
        <th>Mig Welding Length</th>
        <th>Appearance</th>
        <th>Production Qty.</th>
        <th>Supervisor Sign.</th>
      </tr>
      <DialogCavity isSubmitContent={false} />
    </thead>
  );
};

export default THead;
