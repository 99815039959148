export const listConstant = {
  childHeader: 'Prevantive Maintenance list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Prevantive Maintenance list',
  theadHeader: ' Prevantive Maintenance',
  extracolSpan: 3,
};

export const checkListList = {
  childHeader: 'Maintenance STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'prevantiveMaintenancePlanRoute',
  gettableDataurl: 'prevantiveMaintenancePlanRoute/monthly',
  getPlanByid: 'prevantiveMaintenancePlanRoute',
  createPlanByid: 'prevantiveMaintenancePlanRoute',
  updatePlanByid: 'prevantiveMaintenancePlanRoute',
  sync: 'prevantiveMaintenancePlanRoute/handleSync',
  getAllCheckList: 'prevantiveMaintenancePlanRoute/getAllCheckList',
  checkListStatus: 'prevantiveMaintenancePlanRoute/handleStatus',
  checklistUpload: 'prevantiveMaintenancePlanRoute/handleUpload',
  checklistDeleteImage: 'prevantiveMaintenancePlanRoute/handleDeleteImage',
  checklistData: 'prevantiveMaintenancePlanRoute/checklistRoute',
};

export const tilesConstant = {
  childHeaderText: 'Prevantive Maintenance',
};

export const navigationConstant = {
  createPlanSchedule: '/common/prevantiveMaintenacePlanCreate',
  createChecklist: '/common/prevantiveMaintenaceChecklistCreate',
};
