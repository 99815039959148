import moment from 'moment';
import lodash from 'lodash';

export const processes = [
  {
    abnormal_Situation:
      'मशीन / टूल में किसी भी प्रकार की असामान्य ध्वनि या खराबी (Breakdown) होना | ',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    abnormal_Situation:
      ' मशीन / टूल  के किसी भी पोका-योके अथवा सेन्सर का काम न करना',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    abnormal_Situation:
      'ऑपरेटर द्वारा काम को करने में गलत तरीके का उपयोग किया जाना ',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    abnormal_Situation:
      'मटिरियल का नॉन-स्टैंडर्ड बिन / बॉक्स में पैक किया हुआ  मिलना',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    abnormal_Situation: 'मशीन के पैरामेटर ठीक न मिलना',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    abnormal_Situation: 'रौशनी (Lux Level) सही नहीं होना ',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    abnormal_Situation:
      'लगातार 10 खराब पार्ट्स की डाइमैन्शन / विज्वल (Visual) ख़राब (NG) मिलना ',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    abnormal_Situation: 'पार्ट का फ्लोर पर गिर जाना ',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    abnormal_Situation:
      'पार्ट्स से भरे हुए बिन अथवा बॉक्स  का फ्लोर पर गिर जाना । ',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    abnormal_Situation:
      'एक बिन में एक से अधिक तरह के मटेरियल का मिक्स होना या पाया जाना ',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    abnormal_Situation:
      'टूल / गेज/ इन्स्ट्रूमेंट्स का खराब होना या निचे गिर जाना ',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    abnormal_Situation:
      'बिना कैलिब्रेशन वाले इन्स्ट्रुमेंट / उपकरणों का प्रयोग करना',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    abnormal_Situation: 'बिजली कट होना, जब मशीन रनिंग कंडिशन में होती है ',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    abnormal_Situation: 'प्लांट / मशीन  में आग लगना',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    abnormal_Situation: 'विसुअल इंस्पेक्टर का पाइजन टेस्ट में फेल हो जाना ',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    abnormal_Situation: 'लिमिट सेंपल का नही होना	',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    abnormal_Situation: 'शॉप फ्लोर पर दुर्घटना हो जाना ',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  abnormalLogBook_date: moment(),
  processes: lodash.cloneDeep(processes),
};
