import formatDate from '../../../../../components/common/formatDate';
import TableCompHead from '../../../../../components/ui/TableCompHead';

interface IProps {
  moduleData: any;
}

const THead = ({ moduleData }: IProps) => {
  return (
    <thead>
      <TableCompHead
        colSpan={12}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading=""
      />
      <tr>
        <th
          colSpan={1}
          rowSpan={2}
          // align="left"
        >
          PART NAME
        </th>
        <th
          colSpan={2}
          rowSpan={2}
          // align="left"
        >
          {moduleData?.part_id?.part_name}
        </th>
        <th colSpan={5} rowSpan={4} style={{ fontSize: '2rem' }}>
          Process Control Standard
        </th>
        <th
          colSpan={1}
          rowSpan={2}
          // align="left"
        >
          VENDOR V. CODE
        </th>
        <th colSpan={2} rowSpan={2}>
          {moduleData?.part_id?.supplier_id?.supplier_code}
        </th>
        <th>Rev. No.</th>
      </tr>
      <tr>
        <th>01</th>
      </tr>
      <tr>
        <th
          // align="left"
          rowSpan={2}
        >
          PART NO.
        </th>
        <th
          colSpan={2}
          // align="left"
          rowSpan={2}
        >
          {moduleData?.part_id?.part_number}
        </th>
        <th
          colSpan={1}
          // align="left"
          rowSpan={2}
        >
          MODEL
        </th>
        <th
          colSpan={2}
          // align="left"
          rowSpan={2}
        >
          {moduleData?.model}
        </th>
        <th>Page No.</th>
      </tr>
      <tr>
        <th>1-1</th>
      </tr>
      {/* <tr>
        {moduleData.part_type === 'prototype' ? (
          <td
            colSpan={2}
            style={{
              border: '1px solid black',
            }}
            align="left"
          >
            <span
              style={{
                fontSize: '2.5rem',
                marginRight: '0.6rem',
              }}
            >
              ☑
            </span>
            <span>Proto Type</span>
          </td>
        ) : (
          <td colSpan={2} align="left" style={{ border: '1px solid black' }}>
            <span
              style={{
                fontSize: '2.5rem',
                marginRight: '0.6rem',
              }}
            >
              □
            </span>
            <span>Proto Type</span>
          </td>
        )}

        {moduleData.part_type === 'pre-Launch' ? (
          <td
            colSpan={2}
            style={{
              border: '1px solid black',
            }}
            align="left"
          >
            <span
              style={{
                fontSize: '2.5rem',
                marginRight: '0.6rem',
              }}
            >
              ☑
            </span>
            <span>Pre-Launch</span>
          </td>
        ) : (
          <td colSpan={2} align="left" style={{ border: '1px solid black' }}>
            <span
              style={{
                fontSize: '2.5rem',
                marginRight: '0.6rem',
              }}
            >
              □
            </span>
            <span>Pre-Launch</span>
          </td>
        )}

        {moduleData.part_type === 'production' ? (
          <td
            colSpan={2}
            align="left"
            style={{
              border: '1px solid black',
            }}
          >
            <span
              style={{
                fontSize: '2.5rem',
                marginRight: '0.6rem',
              }}
            >
              ☑
            </span>
            <span>Production</span>
          </td>
        ) : (
          <td colSpan={2} align="left" style={{ border: '1px solid black' }}>
            <span
              style={{
                fontSize: '2.5rem',
                marginRight: '0.6rem',
              }}
            >
              □
            </span>
            <span>Production</span>
          </td>
        )}
        <th colSpan={1} align="left">
          Key Contact/Phone
        </th>
        <th colSpan={1} align="left">
          {moduleData?.part_id?.key_contact}
        </th>
        <th colSpan={1} align="left">
          {moduleData?.part_id?.phone}
        </th>
        <th colSpan={2} align="left">
          DATE (Org) :
        </th>
        <th colSpan={1} align="left">
          {formatDate(moduleData?.part_id?.date_orig)}
        </th>
      </tr>
      <tr>
        <th colSpan={1} align="left">
          Ideal Part Number / Latest Change Level :
        </th>
        <th colSpan={2} align="left">
          {moduleData?.part_id?.part_number_ideal}
        </th>
        <th colSpan={1} align="left">
          {moduleData?.latest_change_level_ideal}
        </th>
        <th colSpan={2} align="left">
          Core Team :
        </th>
        <th colSpan={2} align="left">
          {moduleData?.part_id?.core_team}
        </th>
        <th colSpan={4} align="left">
          Page
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Customer Part Number / Latest Change Level
        </th>
        <th colSpan={2} align="left">
          {moduleData?.part_id?.part_number}
        </th>
        <th colSpan={1} align="left">
          {moduleData?.latest_change_level}
        </th>
        <th colSpan={2} align="left">
          Supplier/Plant Approval Date
        </th>
        <th colSpan={2} align="left">
          {formatDate(moduleData?.supplier_approval_date)}
        </th>
        <th colSpan={1} align="left">
          Customer Engg. Approval Date(If Reqd)
        </th>
        <th colSpan={2} align="left">
          {formatDate(moduleData?.customer_engg_approval_date)}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Part Name/Description :
        </th>
        <th colSpan={2} align="left">
          {moduleData?.part_id?.part_name}
        </th>
        <th colSpan={2} align="left">
          Other approvalDate(If Reqd){' '}
        </th>
        <th colSpan={2} align="left">
          {formatDate(moduleData?.supplier_other_approval_date)}
        </th>
        <th colSpan={2} align="left">
          Customer Quality Approval date(If Reqd)
        </th>
        <th colSpan={2} align="left">
          {formatDate(moduleData?.customer_quality_approval_date)}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Supplier/Plant :{' '}
        </th>
        <th colSpan={2} align="left">
          {moduleData?.part_id?.supplier_id?.supplier_name}
        </th>
        <th colSpan={2} align="left">
          Supplier Code :{' '}
        </th>
        <th colSpan={2} align="left">
          {moduleData?.part_id?.supplier_id?.supplier_code}
        </th>

        <th colSpan={2} align="left">
          Other Approval Date(If Reqd){' '}
        </th>
        <th colSpan={2} align="left">
          {formatDate(moduleData?.customer_other_approval_date)}
        </th>
      </tr> */}
      <tr>
        {[
          'SL. NO.',
          'PROCESS DESCRIPTION',
          'PROCESS MACHINE',
          'CONTROL PARAMETER',
          'STANDARD SPEC.',
          'CONTROL SPEC',
          'INSPECTION METHOD',
          'CONTROL INCHARGE',
          'CONTROL METHOD',
          'SAMPLING SCHEME FREQUENCY',
          'RECORDING YES/NO',
          'REMARKS',
        ].map((head, index) => (
          <th colSpan={1} rowSpan={1} key={index + head}>
            {head}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
