import moment from 'moment';
import { useState } from 'react';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';

interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
}

const THead = ({ tabValue, moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={11}
        rowSpan={3}
        labels={['Doc No', 'Rev No.', 'Eff. Date:']}
        values={['FRM-QAS-C-02', '00', '01-04-2023']}
        heading="WELD SHOP INSPECTION  REPORT"
      />

      <tr>
        <th colSpan={11} align="left">
          Part Name:
          {moduleData.part_name
            ? moduleData.part_name
            : moduleData.part_id?.part_name}
        </th>
      </tr>
      <tr>
        <th colSpan={11} align="left">
          Date:{' '}
          {isView ? (
            formatDate(moduleData.pir_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.pir_date)}
              onChange={(date) => {
                moduleData.pir_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {[
          'S.NO',
          'M/C NO.',
          'OPERATION',
          'TIME',
          'PARAMETER',
          'STD. SPECIFICATION',
          'METHOD',
          'SETUP APPROVAL',
          'MID.',
          'END',
          'REMARKS',
        ].map((head) => (
          <th rowSpan={1} style={{ textTransform: 'uppercase' }}>
            {head}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
