import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import {subTiles3} from './SubTiles';

const pressShop = () => {
  return (
    <>
      <ChildHeader text="Press Shop" />
      <RenderTiles tilesObj={subTiles3} height="100vh" justify="start" />
    </>
  );
};

export default pressShop;
