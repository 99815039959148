import React, { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import moment from 'moment';
import { TheatersRounded } from '@mui/icons-material';

interface IProps {
  moduleData: any;
  isView: any;
}

const TFoot = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tfoot>
      <tr>
        <th align="left" colSpan={4}>
          Auditor Name & Sign. :
          <TableInput
            isView={isView}
            name="s4"
            type="text"
            value={moduleData.s4}
            onChange={(e: any) => {
              handleChangeModuleData(e, moduleData);
              setRender((prev: any) => !prev);
            }}
          />
        </th>
        <th align="left" colSpan={11}>
          Auditee Name & Sign. :
          <TableInput
            isView={isView}
            name="s5"
            type="text"
            value={moduleData.s5}
            onChange={(e: any) => {
              handleChangeModuleData(e, moduleData);
              setRender((prev: any) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={4}>
          Date :{' '}
          {isView ? (
            formatDate(moduleData.d3)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d3)}
              onChange={(date) => {
                moduleData.d3 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th align="left" colSpan={7}>
          Date :{' '}
          {isView ? (
            formatDate(moduleData.d4)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d4)}
              onChange={(date) => {
                moduleData.d4 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
