import PDCSection from '../../pages/ProductionPage/PDCSection';
import PressShop from '../../pages/ProductionPage/PressShop';
import RotorSection from '../../pages/ProductionPage/RotorSection';
import { Profile } from '../../pages/profile';

interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}

export const other: routes[] = [
  {
    path: 'profile',
    element: <Profile />,
  },
];
