import { Box } from '@mui/system';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import TController from './components/Tcontroller';
import useGetData from './helpers/useGetData';
interface MyData {
  moduleData: any;
  isLoading: boolean;
  error?: string | null;
  handleChange: any;
}
const MispChecklistCreate = () => {
  const { partId, id } = useParams();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const { moduleData, isLoading, error, handleChange }: MyData = useGetData(
    id,
    partId,
  );
  const checklist_id = useQuery('checklist_id');
  const monthYear = useQuery('monthYear');
  const onSubmit = async () => {
    if (id) {
      axios
        .put(`/api/mispAuditReportRoute/${moduleData._id}`, moduleData)
        .then((res) => {
          window.alert('successfully updated');
          navigate(-1);
        })
        .catch((err) => console.log(err));
    } else {
      moduleData.part_id = partId;
      if (checklist_id) moduleData.checklist_id = checklist_id;
      moduleData.monthYear = monthYear;
      delete moduleData._id;
      axios
        .post(`/api/mispAuditReportRoute`, moduleData)
        .then((res) => {
          if (res.data?.status === 'failed') return alert(res.data.msg);
          window.alert('successfully created');
          navigate(-1);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Box>
      <Box>
        <ChildHeader text="MIS-P AUDIT REPORT">
          {!isView && (
            <SubmitButton label="Submit" onClick={() => onSubmit()} />
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handleChange,
        }}
      />
    </Box>
  );
};

export default MispChecklistCreate;
