import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import THead from './THead';
import TBody from './TBody';
interface IProps {
  moduleData: any;
  isView: boolean;
  handleChange: any;
}

const TController = ({ handleChange, moduleData, isView }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
          }}
        />
        {moduleData.list.map((item: any, index: number) => (
          <TBody
            item={item}
            {...{
              index,
              isView,
              handleChange,
            }}
          />
        ))}
      </RenderTable>
    </Box>
  );
};

export default TController;
