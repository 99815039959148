import lodash from 'lodash';
export const immediate_action_correction = {
  action: '',
  resp: '',
  target_date: null,
  implemented_on_date: null,
  status: '',
};

export const why_desc = {
  desc: '',
};

export const systematic_action_plan = {
  action: '',
  resp: '',
  target_date: null,
  status: '',
};

export const checklist_for_doc_updation = {
  doc_desc: '',
  done: '',
  ref_doc_no: '',
  resp: '',
  target_date: null,
  status: '',
};

export const spare_part_used = {
  spare_part_desc: '',
  qty: null,
  size_grade: '',
  remarks: '',
};
export const horizontol_deployement = {
  action: '',
  mc_tool_code: '',
  target_date: null,
  status: '',
};

export const effectiveness_verification = {
  first_month_status: '',
  second_month_status: '',
  third_month_status: '',
};

export const analysis = {
  immediate_action_correction: [lodash.cloneDeep(immediate_action_correction)],
  why_desc: [lodash.cloneDeep(why_desc)],
  systematic_action_plan: [lodash.cloneDeep(systematic_action_plan)],
  checklist_for_doc_updation: [
    {
      doc_desc: 'PM Checksheet',
      done: '',
      ref_doc_no: '',
      resp: '',
      target_date: null,
      status: '',
    },
    {
      doc_desc: 'Daily Checksheet',
      done: '',
      ref_doc_no: '',
      resp: '',
      target_date: null,
      status: '',
    },
    {
      doc_desc: 'Work Instruction',
      done: '',
      ref_doc_no: '',
      resp: '',
      target_date: null,
      status: '',
    },
    {
      doc_desc: 'One Point Lesson',
      done: '',
      ref_doc_no: '',
      resp: '',
      target_date: null,
      status: '',
    },
    {
      doc_desc: 'Maint. Frequency',
      done: '',
      ref_doc_no: '',
      resp: '',
      target_date: null,
      status: '',
    },
    {
      doc_desc: 'Spare Parts List',
      done: '',
      ref_doc_no: '',
      resp: '',
      target_date: null,
      status: '',
    },
    {
      doc_desc: 'Any Other',
      done: '',
      ref_doc_no: '',
      resp: '',
      target_date: null,
      status: '',
    },
  ],
  spare_part_used: [lodash.cloneDeep(spare_part_used)],
  horizontol_deployement: [lodash.cloneDeep(horizontol_deployement)],
  effectiveness_verification: [lodash.cloneDeep(effectiveness_verification)],
};
