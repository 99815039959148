import { Box, Stack } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
import { AddProcessButton } from '../../../../components/common/button/CustomButtons';
import { ISpec } from '../../helpers/Interface';
import { spec } from '../../helpers/initialState';
import { useState } from 'react';
import SpeedDialCustom from '../common/ui/SpeedDialCustom';
import AlertMui from '../../../../components/common/AlertMui';
import { copyTextToClipboard } from '../../../../utils/helpers/clipboardUtils';

interface IProps {
  moduleData: any;
  setModuleData: any;
  tabValue: number;
  isView: boolean;
  setRenderParent?: any;
  controlPlanId?: any;
  uploadImageFile: any;
  handleProcessChange: any;
  openSpecToleranceModal: any;
}

const TController = ({
  moduleData,
  tabValue,
  setRenderParent,
  setModuleData,
  controlPlanId,
  isView,
  handleProcessChange,
  uploadImageFile,
  openSpecToleranceModal,
}: IProps) => {
  const [Alert, setAlert] = useState(false);

  const handleCopy = (text: string) => {
    copyTextToClipboard(
      text,
      () => setAlert(true),
      (err: any) => console.error('Copy failed:', err)
    );
  };

  const handleAction = (actionName: string) => {
    const addCheck: any = {
      '±': () => handleCopy(' ± '),
      '~': () => handleCopy(' ~ '),
      Min: () => handleCopy('Min '),
      Max: () => handleCopy('Max '),
    };

    if (!actionName) return;
    addCheck[actionName]();
  };
  return (
    <Box>
      <Stack
        direction="row-reverse"
        display="flex"
        gap={2}
        style={{
          border: '1px solid #ced4da',
          borderRadius: '5px',
          padding: '5px',
          margin: '10px 5px 0px 5px',
          position: 'relative',
        }}
      >
        <AddProcessButton
          label="add specs"
          sx={{ backgroundColor: '#343a40', color: '#f8f9fa', margin: 0 }}
          onClick={() => {
            if (!moduleData?.processes?.[tabValue]) {
              alert('First Add A New Process!!!');
              return;
            }
            const newSpec: ISpec = {
              ...spec,
              s_no: moduleData?.processes?.[tabValue]?.specs?.length + 1,
              key: Math.random(),
            };
            moduleData.processes[tabValue].specs.push(newSpec);
            setRenderParent((prev: any) => !prev);
          }}
        />
        <SpeedDialCustom handleAction={handleAction} />
      </Stack>
      <RenderTable>
        <THead
          {...{ moduleData, tabValue, isView, setRenderParent, controlPlanId }}
        />
        <TBody
          {...{
            moduleData,
            tabValue,
            isView,
            setRenderParent,
            uploadImageFile,
            handleProcessChange,
            openSpecToleranceModal,
          }}
        />
      </RenderTable>
      <AlertMui
        alert={Alert}
        message={'Copied to clipboard !!'}
        setAlert={setAlert}
        severity={'success'}
      />
    </Box>
  );
};

export default TController;
