import axios from 'axios';
import { useState, useEffect } from 'react';

const containsHonda = (str: string) => {
  const pattern = /honda/i;
  return pattern.test(str);
};

const useGetData = (partId: string | undefined, pirId: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({
    pir_date: new Date(),
    pirs: [],
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (pirId) {
      axios
        .get(`/api/pirWeldShopRoute/${pirId}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      axios
        .get(`/api/pirWeldShopRoute/getProcessFromControlPlan/${partId}`)
        .then((res) => {
          const response = res.data;
          setModuleData((item: any) => {
            item.part_id = partId;
            item.part_name = response.part_id?.part_name;
            item.part_number = response.part_id?.part_number;
            item.customer_id = response.part_id?.customer_id;
            item.customer_name = response.part_id?.customer_id?.customer_name;
            item.supplier_name = response.part_id?.supplier_id?.supplier_name;
            // for temp basic
            item.revision_no = response.revision_no;
            item.revision_date = response.revision_date;
            item.pirs = response.processes.map((process: any) => {
              return {
                process_name: process.process_name,
                pir_process_date: new Date(),
                pir_list: process.specs.map((spec: any) => ({
                  parameter: spec.char_product || spec.char_process,
                  check4_symbol: spec.check4_symbol,
                  checkbox4_num: spec.checkbox4_num,
                  specification: containsHonda(
                    response.part_id?.customer_id?.customer_name
                  )
                    ? spec.ppc_control_value
                    : spec.control_product,
                  methods: spec.methods_measure,
                  machine: spec.machine,
                  appearance: spec.appearance,
                  mean: spec.mean,
                  utolerance: spec.utolerance,
                  ltolerance: spec.ltolerance,
                  utl: spec.utl,
                  ltl: spec.ltl,
                  check_0_colors: Array.from(Array(5)),
                  check_0_values: Array.from(Array(5)),
                  check_0_results: Array.from(Array(5)),
                  check_0_status: '',
                  remarks: '',
                })),
              };
            });
            return { ...item };
          });
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    }
  }, []);
  return {
    moduleData,
    isLoading,
    error,
  };
};

export default useGetData;
