import React from 'react';
// icon
import { TbReportAnalytics } from 'react-icons/tb';
import { FcInspection } from 'react-icons/fc';
// module
import ChildHeader from '../../../components/ui/ChildHeader';
import { RenderTiles } from '../../../components/common';
import { useAuth } from '../../../services/auth/AuthProvider';

const PdirTiles = () => {
  const { state } = useAuth();
  const { user } = state;
  const tiles = [
    {
      sNo: 1,
      link: `/common/breakDownLogBook`,
      isLink: true,
      icon: <FcInspection size="5rem" color="#a12222" />,
      p: 'Breakdown',
      span: 'Log Book',
      isIcon: true,
    },
    {
      sNo: 2,
      link: `/common/mtbf`,
      isLink: true,
      icon: <TbReportAnalytics size="5rem" color="#2b8a3e" />,
      p: 'MTBF',
      span: 'Annual Log',
      isIcon: true,
    },
    {
      sNo: 3,
      link: `/common/mttr`,
      isLink: true,
      icon: <TbReportAnalytics size="5rem" color="#2b8a3e" />,
      p: 'MTTR',
      span: 'Annual Log',
      isIcon: true,
    },
  ];
  return (
    <>
      <ChildHeader text="Maintenance Log" />
      <RenderTiles
        height="100vh"
        tilesObj={tiles}
        justify="start"
        columns="0fr"
      />
      ;
    </>
  );
};

export default PdirTiles;
