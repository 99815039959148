import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitButton } from '../../../../../components/common/button/CustomButtons';
import { Button, Grid, MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';
import { useAuth } from '../../../../../services/auth/AuthProvider';

const SelectChemicalCompositionType = ({
  materialId,
  ccId,
}: {
  materialId: string;
  ccId: string;
}) => {
  const [cct, setCCT] = useState('none');
  const navigate = useNavigate();
  const { state } = useAuth();
  const { user } = state;

  const cctList = [
    'none',
    'adc_12',
    'adc_10',
    'lm_6',
    'zinck',
    'ec_grade',
    'chrono_470',
    'chrono_600',
    'chrono_1000',
    'crca',
  ];

  return (
    <Grid container xs={12} columnGap={3} width={600} alignItems="center">
      <Grid item xs={8}>
        <CustomSelect
          label="select type"
          name="cct"
          value={cct}
          onChange={(e: any) => {
            setCCT(e.target.value);
          }}
        >
          {cctList.map((item, index) => {
            return (
              <MenuItem
                key={index}
                value={item}
                style={{ textTransform: 'uppercase' }}
              >
                {item.replaceAll('_', ' ')}
              </MenuItem>
            );
          })}
        </CustomSelect>
      </Grid>
      <Grid item xs={2}>
        <Button
          type="button"
          size="large"
          variant="contained"
          color="success"
          onClick={() => {
            navigate(
              `/${
                user?.userType === 2 ? 'admin' : 'supervisor'
              }/chemicalCompositionIncomingMaterial/${materialId}/${ccId}?cc_type=${cct}`
            );
          }}
        >
          OK
        </Button>
      </Grid>
    </Grid>
  );
};

export default SelectChemicalCompositionType;
