import { Checkbox } from '@mui/material';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
  machineData: any;
}

const TBody = ({ moduleData, isView, machineData }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData.employees.map((employee: any, index: any) => (
        <tr>
          <td>{index + 1}</td>
          <td>{employee.employee_id?.employee_name}</td>
          {machineData.map((machine: any) => (
            <td>
              {isView ? (
                employee[machine._id] && '✓'
              ) : (
                <Checkbox
                  name="machine_no"
                  color="primary"
                  checked={employee[machine._id]}
                  onChange={(e) => {
                    employee[machine._id] = e.target.checked;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
          ))}
          <td>
            <TableInput
              name="remarks"
              type="text"
              value={employee.remarks}
              onChange={(e) => {
                employee.remarks = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TBody;
