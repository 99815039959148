import { Box } from '@mui/system';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import { IoMdAdd } from 'react-icons/io';

import {
  CustomButton,
  SubmitButton,
} from '../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import { GoSync } from 'react-icons/go';
import useConfirm from '../../../../components/common/useConfirm';
import { Loader } from '../../../../components/common/Loader';
import { CustomIconButton } from '../../../../components/common/button/CustomIconButton';
import { MdDeleteForever } from 'react-icons/md';
import { Checkbox, Stack, Tooltip, Typography } from '@mui/material';
import { useMemo } from 'react';
import TController from './components/TController';
import useGetData from './helpers/useGetData';
import { apiConstant } from './helpers/constant';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';

interface MyData {
  moduleData: any;
  isLoading: boolean;
  error?: string | null;
  updateModuleData: any;
  masterCheckListData: any;
  handleDelete: any;
  setTriggerRender: any;
  checkSheetType: any;
  setCheckSheetType: any;
}

const Create = () => {
  const { id: rawId } = useParams<{ id: string }>();
  const id = rawId === 'null' ? undefined : rawId;
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [DialogSave, confirmSave] = useConfirm(
    'SAVE',
    'Are you sure you want to Update this?',
  );
  const month = useQuery('month');
  const documentId = useQuery('documentId');
  const date = useQuery('date');
  const planId = useQuery('planId');

  const {
    moduleData,
    isLoading,
    error,
    updateModuleData,
    handleDelete,
    masterCheckListData,
    setTriggerRender,
    checkSheetType,
    setCheckSheetType,
  }: MyData = useGetData(id);

  const onSubmit = async (isSubmitted: boolean) => {
    const ans = await confirmSave();
    if (!ans) return;
    if (id) {
      axios
        .put(`/api/${apiConstant.update}/${id}`, moduleData)
        .then((res) => {
          alert('updated successfully ');
          // setTriggerRender((prev: any) => !prev);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post(
          `/api/${
            apiConstant.post
          }?documentId=${documentId}&month=${month}&year=${
            date && new Date(date).getFullYear()
          }`,
          { ...moduleData, plan_id: planId },
        )
        .then((res) => {
          navigate(-1);
          alert('create successfully');
          // setTriggerRender((prev: any) => !prev);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Box>
      <Box>
        <Loader loader={isLoading} />
        <ChildHeader text="">
          {isView ? (
            <CustomIconButton
              sx={{ p: 0 }}
              tooltipTitle={'Delete'}
              onClick={handleDelete}
            >
              <MdDeleteForever color="#f03e3e" size={'3.5rem'} />
            </CustomIconButton>
          ) : (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{}}
            >
              <Box
                sx={{
                  backgroundColor: '#f8f9fa',
                  borderRadius: '5px',
                  mr: 2,
                  width: '16.6vw',
                }}
              >
                <AutocompleteMuiCustom
                  label={''}
                  id={'checkList_name'}
                  option_name={'checkList_name'}
                  value={checkSheetType}
                  arrayofObj={masterCheckListData}
                  onChange={async (e, value) => {
                    setCheckSheetType(value);
                  }}
                />
              </Box>
              <SubmitButton
                label={id ? 'Update' : 'submit'}
                onClick={() => onSubmit(true)}
              />
            </Stack>
          )}
        </ChildHeader>
      </Box>
      <TController {...{ moduleData, updateModuleData }} />
      <DialogSave isSubmitContent={false} />
    </Box>
  );
};

export default Create;
