import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { convToTime } from '../../../../../utils/helpers';
interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
}
const TBody = ({ moduleData, tabValue, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData.pirs[tabValue] &&
        moduleData.pirs[tabValue].pir_list &&
        moduleData.pirs[tabValue].pir_list.map((spec: any, index: number) => (
          <tr>
            <td>{index + 1}</td>
            <td>{spec.machine}</td>
            <td>{moduleData.pirs[tabValue].process_name}</td>
            <td style={{ minWidth: '100px' }}>
              {isView ? (
                convToTime(spec.time)
              ) : (
                <TimePicker
                  sx={{ width: 140 }}
                  value={moment(spec.time || null)}
                  onChange={(date) => {
                    spec.time = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td>{spec.parameter}</td>
            <td>{spec.specification}</td>
            <td>{spec.methods}</td>
            <td>
              <TableInput
                isView={isView}
                name="setup_approval"
                type="text"
                value={spec.setup_approval}
                onChange={(e) => {
                  spec.setup_approval = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <TableInput
                isView={isView}
                name="mid"
                type="text"
                value={spec.mid}
                onChange={(e) => {
                  spec.mid = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <TableInput
                isView={isView}
                name="end"
                type="text"
                value={spec.end}
                onChange={(e) => {
                  spec.end = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={spec.remarks}
                onChange={(e) => {
                  spec.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
