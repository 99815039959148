import React, { useEffect, useState } from 'react';
import TController from './components/view/TController';
import { IData } from './helpers/interface';
import { initialState } from './helpers/initialState';
import lodash from 'lodash';
import axios from 'axios';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  SubmitButton,
  UpdateButton,
} from '../../../components/common/button/CustomButtons';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';

const View = () => {
  const [moduleData, setModuleData] = useState<IData>(
    lodash.cloneDeep(initialState)
  );
  const [apiRender, setApiRender] = useState(false);
  const isView = useQuery('isView') === 'true' ? true : false;
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${moduleData?._id ? 'update' : 'submit'}`,
    'Are you sure you want to save this document?'
  );

  useEffect(() => {
    axios
      .get(`/api/samplingStdRoute`)
      .then((res) => {
        setModuleData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [apiRender]);

  const handleSumbit = async (isSubmit: boolean) => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (isSubmit) {
      let res = await axios
        .post('/api/samplingStdRoute', moduleData)
        .catch((err) => console.log(err));
      if (res?.data?.status === 'success') {
        alert('submitted successfully');
        setApiRender((prev) => !prev);
      }
    }
    if (!isSubmit) {
      let res = await axios
        .put(`/api/samplingStdRoute/${moduleData?._id}`, moduleData)
        .catch((err) => console.log(err));
      if (res?.data?.status === 'success') {
        alert('updated successfully');
        setApiRender((prev) => !prev);
      }
    }
  };

  return (
    <>
      <Box>
        <ChildHeader text="Sampling Standard">
          {moduleData?._id ? (
            <UpdateButton
              label="Update"
              onClick={() => {
                handleSumbit(false);
              }}
            />
          ) : (
            <SubmitButton
              label="Submit"
              onClick={() => {
                handleSumbit(true);
              }}
            />
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
        }}
      />

      <DialogSubmit />
    </>
  );
};

export default View;
