import { Box } from '@mui/material';
import { RenderTable } from '../../../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  moduleData: any;
  isView: boolean;
  customer: any;
}

const TController = ({ moduleData, isView, customer }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, isView, customer }} />
        <TBody {...{ moduleData, isView }} />
        <TFoot {...{ moduleData, isView }} />
      </RenderTable>
    </Box>
  );
};

export default TController;
