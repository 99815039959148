export const listConstant = {
  childHeader: 'Supplier plan list',
  addButtonLable: 'New Supplier',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Supplier plan list',
  theadHeader: 'Supplier Audit Plan',
  extracolSpan: 5,
};

export const checkListList = {
  childHeader: 'Supplier STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'supplierPlan',
  gettableDataurl: 'supplierPlan/monthly',
  getPlanByid: 'supplierPlan',
  createPlanByid: 'supplierPlan',
  updatePlanByid: 'supplierPlan',
  sync: 'supplierPlan/handleSync',
  getAllCheckList: 'supplierPlan/getAllCheckList',
  checkListStatus: 'supplierPlan/handleStatus',
  checklistUpload: 'supplierPlan/handleUpload',
  checklistDeleteImage: 'supplierPlan/handleDeleteImage',
};

export const navigationConstant = {
  createPlanSchedule: '/common/supplierplancreate',
};
