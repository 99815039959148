import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
// import TFoot from './TFoot';
import THead from './THead';
import { useState } from 'react';
interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
  machineData?: any;
}

const TController = ({ moduleData, tabValue, isView, machineData }: IProps) => {
  const [dateInvoiceRender, setDateInvoiceRender] = useState(false);
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, tabValue, isView, setDateInvoiceRender }} />
        <TBody {...{ moduleData, tabValue, isView, machineData }} />

        {/* <TFoot
          preparedBy={moduleData.report_prepared_by}
          approvedBy={moduleData.report_approved_by}
        /> */}
      </RenderTable>
    </Box>
  );
};

export default TController;
