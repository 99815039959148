import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { convToTime } from '../../../../utils/helpers';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  moduleData: any;
  isView: boolean;
  tabValue: number;
  machineData?: any;
}

const THead = ({ tabValue, moduleData, isView, machineData }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={13}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-QA-10A', '02', '22.03.2023']}
        heading="first part approval"
      />
      <tr>
        <th colSpan={13} align="left">
          Customer:
          {moduleData.customer_name
            ? moduleData.customer_name
            : moduleData.customer_id?.customer_name}
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Part Name:
          {moduleData.part_name
            ? moduleData.part_name
            : moduleData.part_id?.part_name}
        </th>
        <th colSpan={4} align="left">
          Part Number:
          {moduleData.part_number
            ? moduleData.part_number
            : moduleData.part_id?.part_number}
        </th>
        <th colSpan={6}></th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Date:
          {isView ? (
            formatDate(moduleData.setup_date)
          ) : (
            <DatePickerMui
              label="FPA Date"
              value={moment(moduleData.setup_date)}
              onChange={(date) => {
                moduleData.setup_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={4} align="left">
          Shift:
          <TableInput
            name="shift"
            type="text"
            value={moduleData.shift}
            onChange={(e) => {
              moduleData.shift = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={3} align="left">
          M/C No:
          {isView ? (
            moduleData?.setups[tabValue]?.machine_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label="Machine"
              option_name="machine_no"
              key={tabValue + 'machine'}
              arrayofObj={machineData}
              value={
                moduleData?.setups[tabValue]?.machine_id_autocomplete ||
                moduleData?.setups[tabValue]?.machine_id ||
                ''
              }
              onChange={(e, value) => {
                moduleData.setups[tabValue].machine_id = value?._id;
                moduleData.setups[tabValue].machine_id_autocomplete = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={3} align="left">
          Heat No:
          <TableInput
            name="heat_no"
            type="text"
            value={moduleData.heat_no}
            onChange={(e) => {
              moduleData.heat_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        {['S.NO', 'PARAMETER', 'SPECIFICATION', 'MODE OF INSPECTION'].map(
          (head) => (
            <th rowSpan={2} style={{ textTransform: 'uppercase' }}>
              {head}
            </th>
          )
        )}
        <th colSpan={8}>OBSERVATIONS</th>
        <th rowSpan={2}>REMARKS</th>
      </tr>
      <tr>
        {moduleData.setups.length > 0 &&
          Array.from({ length: 4 }, () => 0).map((_, index) => (
            <>
              <th style={{ width: '120px' }}>
                {isView ? (
                  convToTime(moduleData.setups[tabValue].times[index])
                ) : (
                  <TimePicker
                    value={moment(moduleData.setups[tabValue].times[index])}
                    onChange={(date) => {
                      moduleData.setups[tabValue].times[index] = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </th>
              <th>JUDGEMENT</th>
            </>
          ))}
      </tr>
    </thead>
  );
};

export default THead;
