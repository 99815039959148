import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../components/ui';
import { TimePicker } from '@mui/x-date-pickers';
import { CenteredLogoImg } from '../../../../../../components/ui/TableCompHead';
import { images, textInputs } from '../../../../../../utils/constants/constant';

interface IProps {
  moduleData: any;
  isView: boolean;
  cc_type: string;
}
const THead = ({ moduleData, isView, cc_type }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <tr>
        <th
          colSpan={3}
          align="left"
          style={{
            fontSize: '2rem',
            textAlign: 'center',
            height: '100px',
            position: 'relative',
          }}
        >
          <CenteredLogoImg>
            <img
              src={images.moduleCompanyImgHeader}
              alt={textInputs.companyName}
            />
            <span>{textInputs.companyName}</span>
          </CenteredLogoImg>
        </th>
      </tr>
      <tr>
        <th
          colSpan={3}
          style={{
            fontSize: '2rem',
            textAlign: 'center',
            fontWeight: 600,
            backgroundColor: '#212529',
            color: 'white',
            border: '1px solid black',
            textTransform: 'uppercase',
          }}
        >
          Chemical Composition of CRCA Sheet
        </th>
      </tr>

      <tr>
        {['S.No.', 'Contents in (%)', 'As per IS 648'].map((head, index) => (
          <th
            rowSpan={index === 2 ? 1 : 2}
            style={{ textTransform: 'uppercase' }}
            key={index + 'head'}
          >
            {head}
          </th>
        ))}
      </tr>
      <tr>
        <th>CRNGO (50C470)</th>
      </tr>
    </thead>
  );
};

export default THead;
