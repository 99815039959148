import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
import TBody2 from './TBody2';
import TBody3 from './TBody3';
import TBody4 from './TBody4';
import TBody5 from './TBody5';
import TBody6 from './TBody6';
import TBody7 from './TBody7';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  handleAddRow: any;
}

const TController = ({
  moduleData,
  isView,
  handelDeleteRows,
  handleAddRow,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handelDeleteRows,
            handleAddRow,
          }}
        />
        <TBody2
          {...{
            moduleData,
            isView,
            handelDeleteRows,
            handleAddRow,
          }}
        />
        <TBody3
          {...{
            moduleData,
            isView,
            handelDeleteRows,
            handleAddRow,
          }}
        />
        <TBody4
          {...{
            moduleData,
            isView,
            handelDeleteRows,
            handleAddRow,
          }}
        />
        <TBody5
          {...{
            moduleData,
            isView,
            handelDeleteRows,
            handleAddRow,
          }}
        />
        <TBody6
          {...{
            moduleData,
            isView,
            handelDeleteRows,
            handleAddRow,
          }}
        />
        <TBody7
          {...{
            moduleData,
            isView,
            handelDeleteRows,
            handleAddRow,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
