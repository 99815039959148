import axios from 'axios';
import { useState, useEffect } from 'react';
// import { apiConstant } from '../../constant';
import lodash from 'lodash';
import { processAuditCheckSheet } from './checklist';

const useGetData = (id: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const checkSheetdata: any = {
    c1: lodash.cloneDeep(processAuditCheckSheet),
  };

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      axios
        .get(`/api/${'productAuditPlanRoute/checklistRoute'}/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      setModuleData({
        c1: lodash.cloneDeep(checkSheetdata.c1),
      });
    }
  }, []);

  return { moduleData, isLoading, error };
};

export default useGetData;
