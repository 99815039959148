// import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { TableCompHead } from '../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={11}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="Tool History Card"
      />
      <tr>
        <th colSpan={5} align="left">
          Tool No. :-
          <TableInput
            name="tool_no"
            type="text"
            value={moduleData.tool_no}
            onChange={(e) => {
              moduleData.tool_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left" colSpan={2}>
          Starting Cumulative Frequency :-
          <TableInput
            name="initial_cumulative"
            type="number"
            disabled={moduleData?.initial_cumulative_prev >= 0}
            value={moduleData.initial_cumulative}
            onChange={(e) => {
              moduleData.initial_cumulative = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={4} align="left">
          Month:
          <TableInput
            name="month"
            type="text"
            value={moduleData.month}
            onChange={(e) => {
              moduleData.month = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Part Name:- {moduleData?.part_id?.part_name}
        </th>
        <th colSpan={2} align="left">
          Tool Life Freq:-
          <TableInput
            name="tool_life_freq"
            type="number"
            value={moduleData.tool_life_freq}
            onChange={(e) => {
              moduleData.tool_life_freq = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={4} align="left">
          Standard Tool Life :-
          <TableInput
            name="std_tool_life"
            type="text"
            value={moduleData.std_tool_life}
            onChange={(e) => {
              moduleData.std_tool_life = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Operation/Tool Name :-{' '}
          <TableInput
            name="tool_name"
            type="text"
            value={moduleData.tool_name}
            isView={true}
          />
        </th>
        <th colSpan={2} align="left">
          Tool Life Cumulative Frequency :-
          <TableInput
            name="tool_life_cumulative_total"
            type="number"
            disabled={true}
            value={moduleData.tool_life_cumulative_total}
            isView={isView}
          />
        </th>
        <th colSpan={4} align="left">
          Tool PM Frequency. :-
          <TableInput
            name="changed_frequncy"
            type="number"
            disabled={moduleData.changed_frequncy_prev >= 0}
            value={moduleData.changed_frequncy}
            onChange={(e) => {
              moduleData.changed_frequncy = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'Tool Loading Date',
          'Tool Un-Loading Date',
          'M/c No.',
          'Prod. Qty',
          'Cumulative. Qty.',
          'Last Part/ Tool Condition',
          'Create CheckList',
          'Reason (If not ok)',
          'Action Taken',
          'Updated By',
          'Remarks',
        ].map((head) => (
          <th
            key={head}
            style={{
              textTransform: 'uppercase',
              minWidth: `${head === 'Reason (If not ok)' && '300px'}`,
            }}
          >
            {head}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
