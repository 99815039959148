import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<any>({});
  const [customerData, setCustomerData] = useState([]);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Supplier' : 'Create Supplier'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    axios
      .get('/api/customerRoutes/')
      .then((res) => {
        setCustomerData(res.data.results);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/gaugesRoute/${id}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleDepartment(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/gaugesRoute/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/gaugesRoute', moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'name'}
        name={'name'}
        html_for={'name'}
        label_name={'Gauge Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.name}
        onChange={handleDepartment}
      />
      <GridInputs
        id={'number'}
        name={'number'}
        html_for={'number'}
        label_name={'Gauge No.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.number}
        onChange={handleDepartment}
      />
      <GridInputs
        id={'freq'}
        name={'freq'}
        html_for={'freq'}
        label_name={'Frequency:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.freq}
        onChange={handleDepartment}
      />
      <GridInputs
        id={'location'}
        name={'location'}
        html_for={'location'}
        label_name={'Location:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.location}
        onChange={handleDepartment}
      />
      <GridInputs
        id={'responsibility'}
        name={'responsibility'}
        html_for={'responsibility'}
        label_name={'Responsibility:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.responsibility}
        onChange={handleDepartment}
      />
      <AutocompleteGridmui
        label={'Customer:'}
        id={'customer_id'}
        option_name={'customer_name'}
        value={moduleData?.customer_id || ''}
        arrayofObj={customerData}
        onChange={(e, value) => {
          moduleData.customer_id = value;
          setRender((prev) => !prev);
        }}
      />

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
