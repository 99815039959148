import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TimePicker } from '@mui/x-date-pickers';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import TextAreaInput from '../../../../../components/common/TextAreaInput';
import { CustomButton } from '../../../../../components/common/button/CustomButtons';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  handleAddRow: any;
}
const TBody5 = ({
  moduleData,
  isView,
  handelDeleteRows,
  handleAddRow,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      <tr
        style={{
          fontSize: '2rem',
          background: '#dee2e6',
        }}
      >
        <th colSpan={8}>Spare Parts Used :</th>
        <th>
          <CustomButton
            sx={{
              backgroundColor: '#1c7ed6',
            }}
            onClick={() => {
              handleAddRow('spare_part_used');
            }}
          >
            ADD ROWS
          </CustomButton>
        </th>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'S. No.',
          'Spare Part Description',
          'Qty.',
          'Size / Grade',
          'Remarks',
          'Action',
        ].map((head, index) => (
          <th
            key={head}
            style={{ textTransform: 'uppercase' }}
            colSpan={index === 1 ? 4 : 1}
          >
            {head}
          </th>
        ))}
      </tr>
      {moduleData?.spare_part_used &&
        moduleData?.spare_part_used?.map((item: any, index: number) => (
          <tr>
            <td>{index + 1}</td>
            <td style={{ minWidth: '300px' }} colSpan={4}>
              <TextAreaInput
                isView={isView}
                name="spare_part_desc"
                value={item.spare_part_desc}
                onChange={(e) => {
                  item.spare_part_desc = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '200px' }}>
              <TableInput
                isView={isView}
                type="number"
                name="qty"
                value={item.qty}
                onChange={(e) => {
                  item.qty = +e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <TableInput
                isView={isView}
                type="text"
                name="size_grade"
                value={item.size_grade}
                onChange={(e) => {
                  item.size_grade = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <TextAreaInput
                isView={isView}
                name="remarks"
                value={item.remarks}
                onChange={(e) => {
                  item.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>

            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handelDeleteRows(index, 'spare_part_used')}
              />
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody5;
