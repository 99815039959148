import React, { useEffect, useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { TableInput } from '../../../../../components/common';
import { Table } from '../../../../../components/ui/renderTable/styled';
import axios from 'axios';
import { potentialMode } from '../../helpers/data';

const RejectionTable = (props: any) => {
  const { process, isView, partId } = props;
  const [render, setRender] = useState(false);
  const [requirements, setRequirements] = useState([]);
  useEffect(() => {
    if (partId) {
      axios
        .get(
          `/api/processRejection/getControlPlanRequirement/${partId}?processName=${encodeURIComponent(process.operation_name)}`,
        )
        .then((res) => {
          if (res.data) {
            setRequirements(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [partId]);
  return (
    <Table style={{ width: '80vh' }}>
      <thead>
        <tr>
          <th>S.No.</th>
          <th>Requirements</th>
          <th>Reasons</th>
          <th>Rejection Qty</th>
        </tr>
      </thead>
      <tbody>
        {process?.reasons_for_rej?.map((item: any, index: number) => (
          <tr>
            <th>{index + 1}</th>
            <td style={{ minWidth: '250px', textAlign: 'center' }}>
              {isView ? (
                item.requirement
              ) : (
                <CustomSelect
                  name="requirement"
                  value={item.requirement}
                  onChange={(e) => {
                    item.requirement = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {requirements.map((requirement: string) => (
                    <MenuItem value={requirement}>{requirement}</MenuItem>
                  ))}
                </CustomSelect>
              )}
            </td>
            <td style={{ minWidth: '300px', textAlign: 'center' }}>
              {isView ? (
                item.reason
              ) : (
                <CustomSelect
                  name="reason"
                  value={item.reason}
                  onChange={(e) => {
                    item.reason = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {potentialMode.map((item: any) => (
                    <MenuItem value={item.value}>{item.name}</MenuItem>
                  ))}
                </CustomSelect>
              )}
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="rej_qty"
                key="rej_qty"
                type="number"
                value={item.rej_qty}
                onChange={(e) => {
                  item.rej_qty = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={2}>Total Rejection</th>
          <th>{process.prod_qty - process.ok_qty}</th>
        </tr>
      </tfoot>
    </Table>
  );
};

export default RejectionTable;
