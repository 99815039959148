interface IProps {
  moduleData: any;
}

const TBodyResult = ({ moduleData }: IProps) => {
  return (
    <tbody>
      <tr>
        <th align="left" style={{ minWidth: '200px', background: '#fcc419' }}>
          Overall Rating
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td key={'qrqa' + index} style={{ background: '#862e9c' }}>
            {moduleData?.over_all_rating?.[index]}
          </td>
        ))}
      </tr>
      <tr>
        <th align="left" style={{ minWidth: '200px' }}>
          Card Type
        </th>
        {Array.from({ length: 12 }, (_, index) => (
          <td
            key={'qrapm' + index}
            style={{ background: `${moduleData?.card_type?.[index]}` }}
          ></td>
        ))}
      </tr>
      <tr>
        <th colSpan={13} align="left">
          These above are your company delivery & quality rating. It will help
          you improve your quality and delivery performance.
        </th>
      </tr>
      <tr>
        <th colSpan={5} style={{ background: '#2b8a3e' }}>
          Green Card
        </th>
        <th colSpan={4} style={{ background: '#ffd43b' }}>
          Yellow Card
        </th>
        <th colSpan={4} style={{ background: '#f03e3e' }}>
          Red Card
        </th>
      </tr>
      <tr>
        <th colSpan={5} style={{ background: '#2b8a3e' }}>
          Meeting target overall rating ˃80%
        </th>
        <th colSpan={4} style={{ background: '#ffd43b' }}>
          {
            'Meeting tergets overall rating  ˃60% & less than <80%. At some area improvement required'
          }
        </th>
        <th colSpan={4} style={{ background: '#f03e3e' }}>
          {'Not meeting tergets overall rating <60%. Wide improvement required'}
        </th>
      </tr>
    </tbody>
  );
};

export default TBodyResult;
