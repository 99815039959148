import { Box } from '@mui/system';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import TController from './components/create/TController';
const heading: any = {
  setup: 'SETTING APPROVAL RECORDS',
  retro: 'RETROACTIVE CHECK INSPECTION RECORDS',
  suspected: 'SUSPECTED REPORT',
  change4m: '4M CHANGE REPORT',
};
const Create = () => {
  const navigate = useNavigate();
  const { partId, rowId, setupId } = useParams();
  const annual_month = useQuery('annual_date') || '';
  const documentType = useQuery('documentType') || '';
  const operation_name = useQuery('operation_name') || '';
  const summary_date = useQuery('summary_date') || '';
  const [operation, setOperation] = useState([]);
  const [moduleData, setModuleData] = useState<any>({
    specs: [],
    rejectionDetails: [],
  });

  useEffect(() => {
    axios
      .get(`/api/controlPlan/controlPlan/processes/${partId}`)
      .then((res) => {
        if (res.data) {
          setOperation(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  const isView = useQuery('isView') === 'true' ? true : false;
  const getProcess = (operationName: string) => {
    axios
      .get(
        `/api/ForMSetupRetroSuspectedRoute/getProcessByOperation/${partId}?operation_name=${encodeURIComponent(operationName)}`,
      )
      .then((res) => {
        if (res.data) {
          const response = res.data;
          const setupObj: any = {
            //header
            category_of_changes: '',
            suspected_lot_insp: false,
            retroactive_check: false,
            first_setup_approval: false,
            planned_change: false,
            unplanned_change: false,
            abnormal_situations: false,
            training: false,
            heat_no: '',
            batch_no: '',
            change_4m_final_status: '',
            change_4m_time: '',
            date: summary_date || new Date(),
            part_id: partId,
            operation_name: operationName,
            part_name: response.part_id?.part_name,
            part_number: response.part_id?.part_number,
            customer_id: response.part_id?.customer_id,
            customer_name: response.part_id?.customer_id?.customer_name,
            specs: [],
            rejectionDetails: Array.from({ length: 2 }, () => ({
              rejectionValues: [],
            })),
          };
          response.processes.map((process: any) => {
            process.specs.map((spec: any) => {
              setupObj.specs.push({
                parameter: spec.char_product || spec.char_process,
                check4_symbol: spec.check4_symbol,
                checkbox4_num: spec.checkbox4_num,
                specification: spec.control_product,
                methods: spec.methods_measure,
                appearance: spec.appearance,
                mean: spec.mean,
                utolerance: spec.utolerance,
                ltolerance: spec.ltolerance,
                utl: spec.utl,
                ltl: spec.ltl,
                check_0_colors: Array.from(Array(5)),
                check_0_values: Array.from(Array(5)),
              });
            });
          });
          setupObj._id = setupId;
          setModuleData(setupObj);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (setupId) {
      axios
        .get(`/api/ForMSetupRetroSuspectedRoute/${setupId}`, moduleData)
        .then((res) => {
          if (res.data) {
            setModuleData(res.data);
            if (res.data.part_id?._id !== partId) {
              getProcess(operation_name);
            }
          }
        })
        .catch((err) => console.log(err));
    } else {
      getProcess(operation_name);
    }
  }, []);
  const onSubmit = async () => {
    moduleData.part_id = partId;
    if (!moduleData.operation_name) {
      moduleData.operation_name = operation_name;
    }
    if (moduleData._id) {
      await axios
        .put(`/api/ForMSetupRetroSuspectedRoute/${moduleData._id}`, moduleData)
        .then((res) => {
          if (res.data) {
            alert('updated successfully ');
          }
        })
        .catch((err) => console.log(err));
    } else {
      moduleData.annual_month = annual_month;
      moduleData.summary_row_id = rowId;
      moduleData.document_type = documentType;
      await axios
        .post(
          `/api/ForMSetupRetroSuspectedRoute/${rowId}/${annual_month}?documentType=${documentType}`,
          moduleData,
        )
        .then((res) => {
          alert('created successfully');
          navigate(-1);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Box>
      <Box>
        <ChildHeader text={heading[documentType]}>
          {!isView && (
            <>
              <SubmitButton
                label={moduleData._id ? `UPDATE` : `SUBMIT`}
                onClick={() => onSubmit()}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          getProcess,
          operation,
          isView,
        }}
      />
    </Box>
  );
};

export default Create;
