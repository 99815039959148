import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomButton,
  SaveButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import formatDate from '../../../components/common/formatDate';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import TController from './components/create/TController';
import useConfirm from '../../../components/common/useConfirm';
import { Loader } from '../../../components/common/Loader';
import { moduleConstant } from './helpers/constant';

const Create = () => {
  const { id } = useParams();
  const date = useQuery('date');
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [DialogSave, confirmSave] = useConfirm(
    'SAVE',
    'Are you sure you want to Update this?'
  );

  const [moduleData, setModuleData] = useState<any[]>([{}]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    if (id) {
      axios
        .get(`/api/${moduleConstant.api}/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, []);

  const onSubmit = async () => {
    const ans = await confirmSave();
    if (!ans) return;

    axios
      .put(`/api/${moduleConstant.api}/${id}`, moduleData)
      .then((res) => alert('updated successfully '))
      .catch((err) => console.log(err));
  };

  return (
    <Box>
      <Box>
        <Loader loader={isLoading} />
        <ChildHeader text={`${moduleConstant.listHeader}`}>
          <SubmitButton label="Submit" onClick={() => onSubmit()} />
        </ChildHeader>
      </Box>
      <TController {...{ moduleData, isView }} />
      <DialogSave isSubmitContent={false} />
    </Box>
  );
};

export default Create;
