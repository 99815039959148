import { useState } from 'react';
import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const TFoot = ({ isView, moduleData }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
        <th align="left" colSpan={18}>
          Status:
          <TableInput
            isView={isView}
            name="status"
            type="text"
            value={moduleData?.status}
            onChange={(e) => {
              moduleData.status = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={8} align="left">
          Inspected By:
          <TableInput
            isView={isView}
            name="batch_no"
            type="text"
            value={moduleData?.inspected_by}
            onChange={(e) => {
              moduleData.inspected_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={10} align="left">
          Approved By:
          <TableInput
            isView={isView}
            name="approved_by"
            type="text"
            value={moduleData?.approved_by}
            onChange={(e) => {
              moduleData.approved_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
