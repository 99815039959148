// import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { TableCompHead } from '../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={14}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="Maintenance History Card"
      />
      <tr>
        <th colSpan={6} align="left">
          Machine Name:-
          {moduleData?.machine_name}
        </th>
        <th colSpan={4} rowSpan={4}>
          Machine Maintenance Records
        </th>
        <th colSpan={4} align="left">
          Year:
          <TableInput
            name="maintenance_year"
            type="text"
            value={moduleData.maintenance_year}
            onChange={(e) => {
              moduleData.maintenance_year = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={6} align="left">
          Machine No.:- {moduleData?.machine_no}
        </th>
        <th align="left" colSpan={4}>
          PM Frequency :-
          <TableInput
            name="maintenance_changed_frequncy"
            type="number"
            disabled={
              moduleData.maintenance_changed_frequncy_prev >= 0 &&
              moduleData.maintenance_changed_frequncy_prev != null
            }
            value={moduleData.maintenance_changed_frequncy}
            onChange={(e) => {
              moduleData.maintenance_changed_frequncy = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={6} align="left">
          Capacity: {moduleData?.capacity}
        </th>
        <th colSpan={4} align="left">
          Cumulative Strock Qty.:
          {moduleData?.life_cumulative_total}
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={6}>
          Starting Cumulative Frequency :-
          <TableInput
            name="maintenance_initial_cumulative"
            type="number"
            disabled={
              moduleData?.maintenance_initial_cumulative_prev >= 0 &&
              moduleData?.maintenance_initial_cumulative_prev != null
            }
            value={moduleData.maintenance_initial_cumulative}
            onChange={(e) => {
              moduleData.maintenance_initial_cumulative = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={4}></th>
      </tr>

      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'Date',
          'Stroke Qty.',
          'Cumulative Qty.',
          'Condition',
          'PM Start Time',
          'PM End Time',
          'PM Check Sheet',
          'BD',
          'PM',
          'Reason For Maintenance',
          'Action Taken',
          'Idle Time(Hrs.)',
          'Sign.',
          'Remarks',
        ].map((head) => (
          <th
            key={head}
            style={{
              textTransform: 'uppercase',
              minWidth: `${head === 'Reason For Maintenance' && '300px'}`,
            }}
          >
            {head}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
