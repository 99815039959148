import React from 'react';
import { ImStatsBars } from 'react-icons/im';
import { ImStatsDots } from 'react-icons/im';
import { MdQueryStats } from 'react-icons/md';
import { RenderTiles } from '../../components/common';
import { useQuery } from '../../hooks/UseQuery';
import { useParams } from 'react-router-dom';
import ChildHeader from '../../components/ui/ChildHeader';
import PartDetailsHeader from '../../components/ui/PartDeatailsHeader';

const TilesPpap = () => {
  const { partId } = useParams();
  const partName = useQuery('part_name');
  const partNumber = useQuery('part_number');
  const ppapTilesObj = [
    {
      sNo: 1,
      link: `/admin/newSpc/${partId}?part_name=${partName}&part_number=${partNumber}&filter=createdAt`,
      isLink: true,
      clauseNo: 4,
      p: 'NEW',
      span: 'SPC',
      isIcon: true,
      icon: <ImStatsBars size="5rem" />,
    },
    {
      sNo: 2,
      link: `/admin/spcFromInspection/${partId}?part_name=${partName}&part_number=${partNumber}`,
      isLink: true,
      clauseNo: 4,
      p: 'AUTO',
      span: 'GENERATE INSPECTION',
      isIcon: true,
      icon: <MdQueryStats size="5rem" color="#1864ab" />,
    },
  ];
  return (
    <>
      <ChildHeader text="DOCUMENT LIST" />
      <PartDetailsHeader
        partName={partName || ''}
        partNumber={partNumber || ''}
      />
      <RenderTiles tilesObj={ppapTilesObj} />;
    </>
  );
};

export default TilesPpap;
