import { AiOutlinePartition } from 'react-icons/ai';
import { GrUser } from 'react-icons/gr';
import { HiDocumentReport } from 'react-icons/hi';
import { MdDriveFolderUpload } from 'react-icons/md';
const tiles = [
  {
    sNo: 1,
    link: '/common/company_roles_responsibilities_listOfProcedures?documentType=clause12ListOfProcedure',
    isLink: true,
    icon: <MdDriveFolderUpload size="5rem" color="#003566" />,
    p: 'LIST OF',
    span: 'PROCEDURE',
    isIcon: true,
  },
];

export default tiles;
