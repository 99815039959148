import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { convToTime } from '../../../../../utils/helpers';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';
import useConfirm from '../../../../../components/common/useConfirm';
import CustomMobileTimePickerMui from '../../../../../components/common/CustomMobileTimePickerMui';

interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
  machineData?: any;
  // autoCompleteMachineValue?: any;
  // setAutoCompleteMachineValue?: any;
}

const THead = ({ tabValue, moduleData, isView, machineData }: IProps) => {
  const [render, setRender] = useState(false);
  const [DialogCavity, confirmCavity] = useConfirm(
    'Alert',
    'Are you sure you want to change the cavity?'
  );
  console.log(moduleData);

  return (
    <thead>
      <TableCompHead
        colSpan={11}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Eff. Date:']}
        values={['FRM-QAS-B-005', '00', '01-04-2023']}
        heading="BOP Inspection Standard Cum Report"
      />

      <tr>
        <th colSpan={5} align="left">
          Part Name:
          {moduleData.part_name
            ? moduleData.part_name
            : moduleData.part_id?.part_name}
        </th>
        <th colSpan={5} align="left">
          Supplier Name:{' '}
          {moduleData.supplier_name
            ? moduleData.supplier_name
            : moduleData.part_id?.supplier_id?.supplier_name}
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Date:{' '}
        </th>
        <th colSpan={3}>
          {isView ? (
            formatDate(moduleData.pir_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.pir_date)}
              onChange={(date) => {
                moduleData.pir_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th align="left">Invoice No :</th>
        <th colSpan={5}>
          <TableInput
            name="shift"
            key={tabValue + 'shift'}
            type="text"
            value={moduleData?.pirs[tabValue]?.invoice_no}
            onChange={(e) => {
              moduleData.pirs[tabValue].invoice_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Received Qty.:
        </th>
        <th colSpan={4}>
          <TableInput
            name="receive_qty"
            key={tabValue + 'receive_qty'}
            type="text"
            value={moduleData?.pirs[tabValue]?.receive_qty}
            onChange={(e) => {
              moduleData.pirs[tabValue].receive_qty = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={11}>Sketch</th>
      </tr>
      <tr>
        <th
          data-img={moduleData?.image}
          colSpan={11}
          style={{
            height: '400px',
          }}
        >
          <img src={moduleData?.image} height={400} width={600} alt="image" />
        </th>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        <th colSpan={4}>Standard Parameter</th>
        <th colSpan={7}>OBSERVATIONS</th>
      </tr>
      <tr>
        {['S.NO', 'PARAMETER', 'SPECIFICATION', 'Method'].map((head) => (
          <th rowSpan={1} style={{ textTransform: 'uppercase' }}>
            {head}
          </th>
        ))}
        {moduleData.pirs.length > 0 &&
          Array.from({ length: 5 }, () => 0).map((_, index) => (
            <>
              <th>{index + 1}</th>
            </>
          ))}
        <th>Status</th>
        <th>Remark</th>
      </tr>
      <DialogCavity isSubmitContent={false} />
    </thead>
  );
};

export default THead;
