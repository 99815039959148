import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'Sr. No.',
  'Date',
  'Shift',
  'M/C No',
  'Part Name / No.',
  'Operation',
  // 'Operator',
  'Production Qty.',
  'Ok Qty.',
  'NG Qty.',
  'Reason for NG',
  'Rej PPM on M/c.',
  // 'M/c Start Time',
  // 'M/c End Time',
  // 'Plan Qty.',
  // 'Actual Qty.',
  // 'Lot. No.',
  // 'Rejection',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const date = useQuery('annual_date');
  return (
    <thead>
      <TableCompHead
        colSpan={13}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['FRM-QAS-C-07', '00', '01-04-2023']}
        heading="IN-HOUSE REJECTION SHEET"
      />
      <tr>
        <th colSpan={12} align="left">
          Month : {date}
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading + index} colSpan={index === 9 ? 3 : 1}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
