import React, { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { IProcess } from '../../../helpers/Interface';
import { spclChar } from '../../../helpers/SpecialCharacters';
import formatDate from '../../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
}
type revisionObj = {
  rev_no: number;
  summary: string;
  [key: string]: string | number;
};

const colSpanRevHeader = (head: string) => {
  if (head === 'Action Taken') return 2;
  if (head === 'Detail After Change') return 2;
  if (head === 'Detail Before Change') return 2;
  return 1;
};

const TBody = ({ moduleData }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData.processes &&
        moduleData.processes.map((process: IProcess, index: number) => (
          <React.Fragment key={index}>
            {process.specs.map((spec, indx) => (
              <React.Fragment key={indx}>
                <tr>
                  {indx === 0 && (
                    <>
                      {/* MFG Order for honda */}
                      <td rowSpan={process.specs.length} align="center">
                        {process.process_no}
                      </td>
                      {/* Process Name Equipment Supplier Name */}
                      <td rowSpan={process.specs.length} align="center">
                        {process.process_name}
                      </td>
                    </>
                  )}
                  <td></td>
                  <td></td>
                  <td>{indx + 1}</td>
                  {/* product or process */}

                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                    }}
                    // rowSpan={(spec?.char_product_rowSpan as number)}
                  >
                    {spec?.char_product} {<br />}
                    {spec?.char_process}
                  </td>
                  {/* PRODUCT SPECIFICATION  & Process Specification*/}
                  <td align="center" style={{ border: '1px solid black' }}>
                    {spec?.control_product}
                  </td>
                  <td align="center" style={{ border: '1px solid black' }}>
                    {spec?.responsibility}
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    align="center"
                  >
                    {spec.methods_measure}
                  </td>
                  {/* Sample Size and sample freq(methods_by)	 */}
                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                    }}
                  >
                    {spec?.methods_size}
                    {spec?.methods_by}
                  </td>
                  {/* method_process is data record no. in honda and control record in other */}
                  <td>{spec?.methods_process}</td>
                  {/* recording */}
                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                    }}
                    // rowSpan={(spec?.record_rowSpan as number) || 1}
                  >
                    {spec?.recording}
                  </td>
                  {/* new data for honda */}
                  <td>{spec?.submission_freq_data}</td>
                  {/* ..........new field only for honda..............  */}
                  <td></td>

                  {/* prodiction condition control */}
                  <td>{spec?.ppc_control_point}</td>
                  <td>{spec?.ppc_control_value}</td>
                  <td>{spec?.ppc_person_incharge}</td>
                  <td>{spec?.ppc_check_method}</td>
                  <td>{spec?.ppc_checking_freq}</td>
                  <td>{spec?.ppc_data_record_form_no}</td>
                  <td>{spec?.ppc_recording}</td>
                  <td>{spec?.illustration}</td>
                </tr>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      {/* <tr style={{ background: 'yellow' }}>
        {[
          'S.No.',
          'Drg.Rev.No.',
          'Drg.Rev.Date',
          'Detail Before Change',
          'Detail After Change',
          'Action Taken',
          'Change Sugg.To',
          'Change Sugg.By',
          'Status',
        ].map((head, index) => (
          <th
            colSpan={colSpanRevHeader(head)}
            // rowSpan={index === 3 || index === 4 || index === 5 ? 1 : 2}
            key={head + index}
          >
            {head}
          </th>
        ))}
      </tr> */}
      {/* <tr style={{ background: 'yellow' }}>
        <th colSpan={10} style={{ textTransform: 'uppercase' }}>
          Ammendment Details
        </th>
      </tr> */}
      {/* revision map  */}
      {moduleData &&
        moduleData.revisionData.map((revision: revisionObj, index: number) => (
          <tr style={{ background: 'yellow' }}>
            <td>{index}</td>
            <td colSpan={3}>{revision.rev_no}</td>
            <td colSpan={4}>{formatDate(revision.rev_date)}</td>
            <td colSpan={9}>
              {revision.summary?.split('𝗧𝗢')[0].split('𝗙𝗥𝗢𝗠')[1]}
              {revision.summary?.split('𝗧𝗢')[1]}
            </td>
            {/* <td colSpan={3}>{revision.summary?.split('𝗧𝗢')[1]}</td>
            <td colSpan={4}>{revision.action_taken}</td>
            <td colSpan={2}>{revision.sugg_to}</td>
            <td colSpan={1}>{revision.sugg_by}</td> */}
            <th colSpan={2}></th>
            <th colSpan={2}></th>
            <th colSpan={2}></th>
            {/* <td colSpan={1}>{revision.status}</td> */}
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
