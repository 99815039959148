import axios from 'axios';
import { useState, useEffect } from 'react';
import { useQuery } from '../../../../hooks/UseQuery';
import { moduleConstant } from '../constant';

const useGetData = () => {
  const date = useQuery('date');
  const [moduleData, setModuleData] = useState<any>([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [render, setParentRender] = useState(false);
  const [renderforUpdate, setRenderforUpdate] = useState(false);
  const [toolData, setToolData] = useState([]);

  useEffect(() => {
    if (!date) return;
    const handleApi = async () => {
      setIsLoading(true);
      axios
        .get(`${moduleConstant.apiConstant}/getByMonth/${date}`)
        .then((response) => {
          setModuleData(response?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setModuleData([]);
          setError(err);
        });
    };

    handleApi();
    setIsLoading(false);
  }, [date, renderforUpdate]);

  return {
    error,
    moduleData,
    setModuleData,
    isLoading,
    setIsLoading,
    setParentRender,
    setRenderforUpdate,
    toolData,
  };
};

export default useGetData;
