import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import ProductAuditChecklistCreate from './ProudctAuditChecklistCreate';
import MispChecklistCreate from './mispChecklist/MispChecklistCreate';
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const Create = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Box color="secondary">
        <Tabs
          value={value}
          centered
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="AUDIT CHECKSHEET" {...a11yProps(0)} />
          <Tab label="MIS-P CHECKSHEET" {...a11yProps(1)} />
        </Tabs>
      </Box>
      {/* Product Audit Checklist*/}
      {value === 0 && <ProductAuditChecklistCreate />}
      {/* Product Audit Checklist*/}
      {value === 1 && <MispChecklistCreate />}
    </Box>
  );
};

export default Create;
