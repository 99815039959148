import axios from 'axios';
import { useState, useEffect } from 'react';
import { useQuery } from '../../../../../hooks/UseQuery';

const useGetData = (id: string | undefined, partId: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({
    list: [],
  });
  const checklist_id = useQuery('checklist_id');
  const monthYear = useQuery('monthYear');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    (async () => {
      if (id) {
        axios
          .get(`/api/mispAuditReportRoute/getProductAuditReportById/${id}`)
          .then((res) => {
            if (res.data) {
              setModuleData(res.data);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            setError(err);
            setIsLoading(false);
            console.log(err);
          });
      } else {
        try {
          let res = await axios.get(`/api/mispStandardRoute/${partId}`);
          if (res.data) {
            setModuleData(res.data);
            setIsLoading(false);
          }
        } catch (err: any) {
          setError(err);
          setIsLoading(false);
          console.log(err);
        }
      }
      if (checklist_id) {
        axios
          .get(
            `/api/mispAuditReportRoute/getProductAuditReportById?part_id=${partId}&checklist_id=${checklist_id}&monthYear=${monthYear}`,
          )
          .then((res) => {
            setModuleData(res.data);
            setIsLoading(false);
            return;
          })
          .catch((err) => {
            setError(err);
            setIsLoading(false);
            console.log(err);
          });
      }
    })();
    // eslint-disable-next-line
  }, [id, partId, checklist_id]);

  const handleChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const values: any = { ...moduleData };
    values.list[index][name] = value;
    setModuleData(values);
  };
  return {
    moduleData,
    isLoading,
    error,
    handleChange,
  };
};

export default useGetData;
