import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import CustomSelect from '../../../../components/common/SelectField';
import { Checkbox, IconButton, MenuItem, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TBodyProcess from './TBodyProcess';
import TBodySpecs from './TBodySpecs';

interface IProps {
  moduleData: any;
  setRenderParent: any;
  handleProcessChange: any;
  uploadImageFile: any;
  tabValue: number;
  openSpecToleranceModal: any;
  isView: boolean;
}
const TBody = ({
  moduleData,
  tabValue,
  isView,
  setRenderParent,
  handleProcessChange,
  uploadImageFile,
  openSpecToleranceModal,
}: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      <TBodyProcess
        {...{
          tabValue,
          isView,
          moduleData,
          setRenderParent,
          uploadImageFile,
          openSpecToleranceModal,
        }}
        process={moduleData?.processes?.[tabValue]}
        onProcessChange={(updatedProcess: any) =>
          handleProcessChange(tabValue, updatedProcess)
        }
      />
    </tbody>
  );
};

export default TBody;
