const copyTextToClipboard = async (
  textToCopy: string,
  onSuccess: any,
  onError: any
) => {
  if ('clipboard' in navigator) {
    try {
      await navigator.clipboard.writeText(textToCopy);
      onSuccess();
    } catch (err) {
      console.error('Failed to copy: ', err);
      onError(err);
    }
  } else {
    // Fallback for older browsers
    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    onSuccess();
  }
};

export { copyTextToClipboard };
