import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'SR. NO.',
  'PRODUCTION DATE',
  'M/C NO.',
  'PART DESCRIPTION',
  'TOTAL PRODUCTION QTY.',
  'REJECTION QTY.',
  'INSPECTION DATE',
  'REJ. %',
  'DEFECT NAME',
  'QTY.',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={12}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-QA-50', '00', '26.7.2022']}
        heading="DAILY REJECTION NOTE - ROTOR"
      />
      <tr>
        <th colSpan={2}>Daily Production Date : </th>
        <th colSpan={10} align="left">
          {isView ? (
            formatDate(moduleData.dailyProduction_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.dailyProduction_date)}
              onChange={(date) => {
                moduleData.dailyProduction_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading + index}>{heading}</th>
        ))}
        <th colSpan={2} rowSpan={1}>
          Remarks
        </th>
      </tr>
    </thead>
  );
};

export default THead;
