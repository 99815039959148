import { MdOutlineCompress, MdRoomPreferences } from 'react-icons/md';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';

const tiles = [
  {
    sNo: 1,
    link: '/common/processRejection/dailyProductionList', // ?documentType=pressShop1and2&heading=Press Shop 1 and 2
    isLink: true,
    icon: <MdOutlineCompress size="5rem" color="#1864ab" />,
    p: 'IN-HOUSE',
    span: 'REJECTION SHEET',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/processRejection/dashboardList',
    isLink: true,
    icon: <MdRoomPreferences size="5rem" color="#003566" />,
    p: 'PROCESS REJECTION',
    span: 'DASHBOARD',
    isIcon: true,
  },
];

const InhouseTiles = () => {
  return (
    <>
      <ChildHeader text="IN-HOUSE REJECTION" />
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0fr"
      />
    </>
  );
};

export default InhouseTiles;
