import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';

export const CustomTypography: any = styled(Typography)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.7)',
  background: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '4px',
  padding: '4px 8px',
  display: 'inline-flex',
  letterSpacing: '0.0003333em',
  alignItems: 'center',
  fontWeight: 500,
  '&::first-letter': {
    textTransform: 'capitalize',
  },
}));
