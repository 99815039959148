import React, { useEffect, useState } from 'react';
import TController from './TController';
import { Box } from '@mui/material';
import { PersitedDatePicker } from '../../../../../../components/common';
import { useQuery } from '../../../../../../hooks/UseQuery';
import axios from 'axios';
import ParetoChart from './ParetoChart';
import { GraphHead } from '../../../../../../components/common/GraphHeading';
interface IProps {
  apiData: any;
}
const DefectWiseQty = ({ apiData }: IProps) => {
  return (
    <div>
      <TController apiData={apiData} />
      {
        <GraphHead style={{ width: '80vw', margin: '14rem' }}>
          <p>Defect wise pareto analysis</p>
          <ParetoChart apiData={apiData} />
        </GraphHead>
      }
    </div>
  );
};

export default DefectWiseQty;
