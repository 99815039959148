import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';
import { useEffect, useState } from 'react';
import axios from 'axios';

interface IProps {
  moduleData: IData;
  isView: boolean;
  handleSumbit: any;
  autoCompleteMachineValue: any;
  setAutoCompleteMachineValue: any;
  // autoCompleteEmployeeValue: any;
  autoCompleteSupplierValue: any;
  handelDeleteRows: any;
  // isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const TController = ({
  moduleData,
  isView,
  handleSumbit,
  autoCompleteMachineValue,
  setAutoCompleteMachineValue,
  // autoCompleteEmployeeValue,
  autoCompleteSupplierValue,
  handelDeleteRows,
  // isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [machineData, setMachine] = useState({});
  const [supplierData, setSupplier] = useState({});
  // const [employeeData, setEmployeeData] = useState({});

  useEffect(() => {
    axios
      .get('/api/machineRoutes/')
      .then((res) => {
        setMachine(res.data.results);
      })
      .catch((err) => console.log(err));

    axios
      .get('/api/supplierRoutes/')
      .then((res) => {
        setSupplier(res.data.results);
      })
      .catch((err) => console.log(err));
    // axios
    //   .get('/api/employee/')
    //   .then((res) => {
    //     setEmployeeData(res.data.results);
    //   })
    //   .catch((err) => console.log(err));
  }, []);

  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            machineData,
            autoCompleteMachineValue,
            setAutoCompleteMachineValue,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handleSumbit,
            supplierData,
            // employeeData,
            // autoCompleteEmployeeValue,
            autoCompleteSupplierValue,
            handelDeleteRows,
            // isOpenPopup,
            setIsOpenPopup,
            setPartIdx,
          }}
        />
        <TFoot {...{ moduleData, isView }} />
      </RenderTable>
    </Box>
  );
};

export default TController;
