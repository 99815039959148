import lodash from 'lodash';
export const lot_size = [
  '2 to 8',
  '9 to 15',
  '16 to 25',
  '26 to 50',
  '51 to 90',
  '91 to 150',
  '151 to 280',
  '281 to 500',
  '501 to 1200',
  '1201 to 3200',
  '3201 to 10000',
  '10001 to 35000',
  '35001 to 150000',
  '150001 to 500000',
];

export const initialState = {
  processes: Array.from({ length: 14 }, (_, index) =>
    lodash.cloneDeep({
      lot_size: lot_size[index],
      sample_size: null,
      ac: null,
      re: null,
    })
  ),
};
