import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { useQuery } from '../../../../../hooks/UseQuery';
interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const partNumber = useQuery('partNumber');
  const partName = useQuery('partName');
  return (
    <thead>
      <TableCompHead
        colSpan={10}
        rowSpan={3}
        labels={['Doc No.', 'Rev No.', 'Eff. Date']}
        values={['FRM-QAS-A-008', '00', '01-04-2023']}
        heading="PRODUCT AUDIT CHECKSHEET (MIS-P)"
      />
      <tr>
        <th align="left" colSpan={2}>
          Customer:
        </th>
        <th align="left" colSpan={3}>
          {moduleData?.part_id?.customer_id?.customer_name}
        </th>
        <th align="left" colSpan={2}>
          Auditor:
        </th>
        <th align="left" colSpan={3}>
          <TableInput
            isView={isView}
            name="auditor"
            type="text"
            value={moduleData.auditor}
            onChange={(e) => {
              moduleData.auditor = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={2}>
          Part Name:
        </th>
        <th align="left" colSpan={3}>
          {partName}
        </th>
        <th align="left" colSpan={2}>
          Auditee:
        </th>
        <th align="left" colSpan={3}>
          <TableInput
            isView={isView}
            name="auditee"
            type="text"
            value={moduleData.auditee}
            onChange={(e) => {
              moduleData.auditee = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={2}>
          Part No.:
        </th>
        <th align="left" colSpan={3}>
          {partNumber}
        </th>
        <th align="left" colSpan={2}>
          Audit Date:
        </th>
        <th align="left" colSpan={3}>
          {isView ? (
            formatDate(moduleData.report_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.report_date)}
              onChange={(date) => {
                moduleData.report_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th rowSpan={2}>S.No.</th>
        <th rowSpan={2}>Check Point</th>
        <th rowSpan={2}>Dimensions</th>
        <th rowSpan={2}>Method</th>
        <th colSpan={5}>Observation</th>
        <th rowSpan={2} style={{ minWidth: '80px' }}>
          C/ NC
        </th>
      </tr>
      <tr>
        <th style={{ minWidth: '80px' }}>1</th>
        <th style={{ minWidth: '80px' }}>2</th>
        <th style={{ minWidth: '80px' }}>3</th>
        <th style={{ minWidth: '80px' }}>4</th>
        <th style={{ minWidth: '80px' }}>5</th>
      </tr>
    </thead>
  );
};

export default THead;
