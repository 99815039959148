import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ViewIconButton } from '../../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import InteractiveIcon from '../../../../../components/common/InteractiveIcon';
import MonthDoubleClick from './MonthDoubleClick';

interface IProps {
  moduleData: { [key: string]: any };
  isView: boolean;
  title?: string;
  phase: any;
  onSubmit: any;
  handleRemarks: any;
}
const TBody1 = ({
  handleRemarks,
  title,
  phase,
  moduleData,
  isView,
  onSubmit,
}: IProps) => {
  const [render, setRender] = useState(false);
  const { partId } = useParams();
  const navigate = useNavigate();
  const handleDoubleClick = async (
    event: any,
    rowIdx: number,
    bool: boolean,
  ) => {
    if (event.detail === 2) {
      moduleData[phase][rowIdx].isRequired = bool;
      setRender((prev) => !prev);
    }
  };
  return (
    <tbody>
      {moduleData[phase].map((item: any, index: number) => (
        <React.Fragment key={index}>
          <tr>
            <td rowSpan={2}>{item.s_no}</td>
            {index === 0 && (
              <td rowSpan={moduleData[phase].length * 2}>{title}</td>
            )}
            <td rowSpan={2} style={{ minWidth: '500px' }}>
              {item.activity}
            </td>
            <td
              style={{
                padding: '0px',
                margin: '0px',
              }}
              rowSpan={2}
              onClick={(e) => handleDoubleClick(e, index, true)}
            >
              <InteractiveIcon isYellow={item.isRequired === true}>
                {item.isRequired === true && 'P'}{' '}
              </InteractiveIcon>
            </td>
            <td
              style={{
                padding: '0px',
                margin: '0px',
              }}
              rowSpan={2}
              onClick={(e) => handleDoubleClick(e, index, false)}
            >
              <InteractiveIcon isYellow={item.isRequired === false}>
                {item.isRequired === false && 'A'}{' '}
              </InteractiveIcon>
            </td>
            <td rowSpan={2} style={{ minWidth: '200px' }}>
              {item.resp}
            </td>
            {Array.from({ length: 5 }).map((_, monthIdx) => (
              <MonthDoubleClick
                planType="p"
                month="month_1"
                {...{ moduleData, monthIdx, phase }}
                rowIdx={index}
              />
            ))}
            {Array.from({ length: 5 }).map((_, monthIdx) => (
              <MonthDoubleClick
                planType="p"
                month="month_2"
                {...{ moduleData, monthIdx, phase }}
                rowIdx={index}
              />
            ))}
            {Array.from({ length: 5 }).map((_, monthIdx) => (
              <MonthDoubleClick
                planType="p"
                month="month_3"
                {...{ moduleData, monthIdx, phase }}
                rowIdx={index}
              />
            ))}
            {Array.from({ length: 5 }).map((_, monthIdx) => (
              <MonthDoubleClick
                planType="p"
                month="month_4"
                {...{ moduleData, monthIdx, phase }}
                rowIdx={index}
              />
            ))}
            <td
              style={{
                backgroundColor: 'yellow',
                textAlign: 'center',
                minWidth: '50px',
              }}
            >
              P
            </td>
            <td style={{ minWidth: '100px' }}>
              {isView ? (
                formatDate(moduleData.customer_drawing_date)
              ) : (
                <DatePickerMui
                  sx={{ width: '150px' }}
                  label=""
                  value={moment(item.start_date?.p || null)}
                  onChange={(date) => {
                    item.start_date.p = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td style={{ minWidth: '100px' }}>
              {isView ? (
                formatDate(item.complete_date?.p)
              ) : (
                <DatePickerMui
                  label=""
                  value={moment(item.complete_date?.p || null)}
                  sx={{ width: '150px' }}
                  onChange={(date) => {
                    item.complete_date.p = date;
                    handleRemarks(
                      item?.complete_date?.p,
                      item?.complete_date?.a,
                      item.activity,
                      index,
                    );
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td
              rowSpan={2}
              style={{
                background:
                  item.remarks === 'DELAYED'
                    ? 'red'
                    : item.remarks === 'ON TRACK'
                    ? 'green'
                    : 'white',
                color: 'white',
              }}
            >
              {item.remarks}
            </td>
            <td rowSpan={2}>
              {moduleData._id && (
                <ViewIconButton
                  tooltipTitle="VIEW"
                  onClick={async () => {
                    await onSubmit();
                    navigate(`/viewFile/${partId}/${item._id}`);
                  }}
                />
              )}
            </td>
          </tr>

          <tr>
            {Array.from({ length: 5 }).map((_, monthIdx) => (
              <MonthDoubleClick
                planType="a"
                month="month_1"
                {...{ moduleData, monthIdx, phase }}
                rowIdx={index}
              />
            ))}
            {Array.from({ length: 5 }).map((_, monthIdx) => (
              <MonthDoubleClick
                planType="a"
                month="month_2"
                {...{ moduleData, monthIdx, phase }}
                rowIdx={index}
              />
            ))}
            {Array.from({ length: 5 }).map((_, monthIdx) => (
              <MonthDoubleClick
                planType="a"
                month="month_3"
                {...{ moduleData, monthIdx, phase }}
                rowIdx={index}
              />
            ))}
            {Array.from({ length: 5 }).map((_, monthIdx) => (
              <MonthDoubleClick
                planType="a"
                month="month_4"
                {...{ moduleData, monthIdx, phase }}
                rowIdx={index}
              />
            ))}
            <td
              style={{
                backgroundColor: '#2f9e44',
                textAlign: 'center',
                minWidth: '50px',
              }}
            >
              A
            </td>

            <td style={{ minWidth: '100px' }}>
              {isView ? (
                formatDate(item.start_date?.a)
              ) : (
                <DatePickerMui
                  label=""
                  sx={{ width: '150px' }}
                  value={moment(item.start_date?.a || null)}
                  onChange={(date) => {
                    item.start_date.a = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td style={{ minWidth: '100px' }}>
              {isView ? (
                formatDate(item.complete_date?.p)
              ) : (
                <DatePickerMui
                  label=""
                  value={moment(item.complete_date?.a || null)}
                  sx={{ width: '150px' }}
                  onChange={(date) => {
                    item.complete_date.a = date;
                    handleRemarks(
                      item?.complete_date?.p,
                      item?.complete_date?.a,
                      item.activity,
                      index,
                    );
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
          </tr>
        </React.Fragment>
      ))}
    </tbody>
  );
};

export default TBody1;
