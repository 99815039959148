export const createFormFields = (
  moduleData: any,
  isView: any,
  setRender: any
) => [
  {
    type: 'label',
    content: 'Revision Number',
  },
  {
    type: 'InputMuiCustom',
    name: 'revision_no',
    label: 'Revision Number',
    placeholder: '',
    inputType: 'number',
    required: true,
    value: moduleData.revision_no || 0,
    variant: 'outlined',
    onChange: (e: any) => {
      moduleData.revision_no = e.target.value;
      setRender((prev: any) => !prev);
    },
  },
  {
    type: 'label',
    content: 'Proto Type',
  },
  {
    type: 'CheckBoxCustomMui',
    name: 'prototype',
    gridStyle: { justifyContent: 'center' },
    label: '',
    checked: moduleData.part_type === 'prototype',
    onClick: (e: any) => {
      moduleData.part_type = e.target.name;
      setRender((prev: any) => !prev);
    },
  },
  {
    type: 'label',
    content: 'Pre-Launch',
  },
  {
    type: 'CheckBoxCustomMui',
    name: 'pre-Launch',
    gridStyle: { justifyContent: 'center' },
    label: '',
    checked: moduleData.part_type === 'pre-Launch',
    onClick: (e: any) => {
      moduleData.part_type = e.target.name;
      setRender((prev: any) => !prev);
    },
  },
  {
    type: 'label',
    content: 'Production',
  },
  {
    type: 'CheckBoxCustomMui',
    name: 'production',
    gridStyle: { justifyContent: 'center' },
    label: '',
    checked: moduleData.part_type === 'production',
    onClick: (e: any) => {
      moduleData.part_type = e.target.name;
      setRender((prev: any) => !prev);
    },
  },
  {
    type: 'label',
    content: 'Revision Date',
  },
  {
    type: 'DatePickerMui',
    label: '',
    name: 'production',
    value: moduleData.revision_date,
    onChange: (date: any) => {
      moduleData.revision_date = date;
      setRender((prev: any) => !prev);
    },
  },

  {
    type: 'label',
    content: 'COMPANY CODE - MODEL',
  },
  {
    type: 'InputMuiCustom',
    name: 'compnay_code',
    label: '',
    placeholder: '',
    inputType: 'text',
    value: moduleData.compnay_code || null,
    variant: 'outlined',
    onChange: (e: any) => {
      moduleData.compnay_code = e.target.value;
      setRender((prev: any) => !prev);
    },
  },
  {
    type: 'label',
    content: 'CUSTOMER QUALITY APPROVAL & DATE',
  },
  {
    type: 'DatePickerMui',
    label: '',
    name: 'customer_quality_approval_date',
    value: moduleData.customer_quality_approval_date,
    onChange: (date: any) => {
      moduleData.customer_quality_approval_date = date;
      setRender((prev: any) => !prev);
    },
  },
  {
    type: 'label',
    content: 'OTHER APPROVAL & DATE',
  },
  {
    type: 'DatePickerMui',
    label: '',
    name: 'supplier_other_approval_date',
    value: moduleData.supplier_other_approval_date,
    onChange: (date: any) => {
      moduleData.supplier_other_approval_date = date;
      setRender((prev: any) => !prev);
    },
  },
  {
    type: 'label',
    content: 'CONTROL PLAN FOR',
  },
  {
    type: 'InputMuiCustom',
    name: 'control_plan_for',
    label: '',
    placeholder: '',
    inputType: 'text',
    value: moduleData.control_plan_for || 0,
    variant: 'outlined',
    onChange: (e: any) => {
      moduleData.control_plan_for = e.target.value;
      setRender((prev: any) => !prev);
    },
  },
  {
    type: 'label',
    content: 'CUSTOMER PRODUCT NAME',
  },
  {
    type: 'InputMuiCustom',
    name: 'customer_product_name',
    label: '',
    placeholder: '',
    inputType: 'text',
    value: moduleData.customer_product_name || 0,
    variant: 'outlined',
    onChange: (e: any) => {
      moduleData.customer_product_name = e.target.value;
      setRender((prev: any) => !prev);
    },
  },
  {
    type: 'label',
    content: 'CONTROL PLAN NO.',
  },
  {
    type: 'InputMuiCustom',
    name: 'control_plan_no',
    label: '',
    placeholder: '',
    inputType: 'text',
    value: moduleData.control_plan_no || null,
    variant: 'outlined',
    onChange: (e: any) => {
      moduleData.control_plan_no = e.target.value;
      setRender((prev: any) => !prev);
    },
  },
  {
    type: 'label',
    content: 'Drg Rev date',
  },
  {
    type: 'DatePickerMui',
    label: '',
    name: 'production',
    value: moduleData.drg_date,
    onChange: (date: any) => {
      moduleData.drg_date = date;
      setRender((prev: any) => !prev);
    },
  },
  {
    type: 'label',
    content: 'Drg Rev. no',
  },
  {
    type: 'InputMuiCustom',
    name: 'drg_std_status',
    label: '',
    placeholder: '',
    inputType: 'text',
    value: moduleData.drg_std_status || 0,
    variant: 'outlined',
    onChange: (e: any) => {
      moduleData.drg_std_status = e.target.value;
      setRender((prev: any) => !prev);
    },
  },

  {
    type: 'label',
    content: 'KEY CONTACT / PHONE',
  },
  {
    type: 'InputMuiCustom',
    name: 'key_contact',
    label: '',
    placeholder: '',
    inputType: 'text',
    value: moduleData?.part_id?.key_contact || null,
    variant: 'outlined',
  },
  {
    type: 'label',
    content: 'CUSTOMER NAME',
  },
  {
    type: 'InputMuiCustom',
    name: 'customer_name',
    gridStyle: { width: '200px' },
    label: '',
    placeholder: '',
    inputType: 'text',
    value: moduleData?.part_id?.customer_id?.customer_name || '',
    variant: 'outlined',
    onChange: (e: any) => {
      moduleData.part_id.customer_name = e.target.value;
      setRender((prev: any) => !prev);
    },
  },
  {
    type: 'label',
    content: 'CORE TEAM',
  },
  {
    type: 'InputMuiCustom',
    name: 'core_team',
    label: '',
    placeholder: '',
    inputType: 'text',
    value: moduleData?.part_id?.core_team || '',
    variant: 'outlined',
    onChange: (e: any) => {
      moduleData.part_id.core_team = e.target.value;
      setRender((prev: any) => !prev);
    },
  },
  {
    type: 'label',
    content: 'CUSTOMER PRODUCT NUMBER',
  },
  {
    type: 'InputMuiCustom',
    name: 'customer_product_no',
    label: '',
    placeholder: '',
    inputType: 'text',
    value: moduleData?.customer_product_number || '',
    variant: 'outlined',
    onChange: (e: any) => {
      moduleData.customer_product_number = e.target.value;
      setRender((prev: any) => !prev);
    },
  },
  {
    type: 'label',
    content: 'PART NAME',
  },
  {
    type: 'InputMuiCustom',
    name: 'part_name',
    label: '',
    placeholder: '',
    inputType: 'text',
    value: moduleData?.part_id?.part_name || '',
    variant: 'outlined',
  },
  {
    type: 'label',
    content: 'PART NO.',
  },
  {
    type: 'InputMuiCustom',
    name: 'part_no',
    label: '',
    placeholder: '',
    inputType: 'text',
    value: moduleData?.part_id?.part_number || '',
    variant: 'outlined',
  },
  {
    type: 'label',
    content: 'CP REV. NO.',
  },
  {
    type: 'InputMuiCustom',
    name: 'cp_rev_no',
    label: '',
    placeholder: '',
    inputType: 'number',
    value: moduleData?.revision_no || null,
    variant: 'outlined',
    onChange: (e: any) => {
      moduleData.revision_no = e.target.value;
      setRender((prev: any) => !prev);
    },
  },
  {
    type: 'label',
    content: 'ORG. PREPARATION DATE',
  },
  {
    type: 'DatePickerMui',
    label: '',
    name: 'org_preparation_date',
    value: moduleData?.part_id?.date_orig,
    onChange: (date: any) => {
      moduleData.part_id.date_orig = date;
      setRender((prev: any) => !prev);
    },
  },
  {
    type: 'label',
    content: 'CP REV. DATE',
  },
  {
    type: 'DatePickerMui',
    label: '',
    name: 'cp_rev_date',
    value: moduleData?.revision_date,
    onChange: (date: any) => {
      moduleData.revision_date = date;
      setRender((prev: any) => !prev);
    },
  },
];
