import { Box } from '@mui/system';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import { IoMdAdd } from 'react-icons/io';

import {
  CustomButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import useConfirm from '../../../components/common/useConfirm';
import { Loader } from '../../../components/common/Loader';
import { Stack } from '@mui/material';
import TController from './components/TController';
import useGetData from './helpers/useGetData';
import { apiConstant, navigationConstant } from './helpers/constant';
import {
  DeleteIconButton,
  UploadImageIconButton,
} from '../../../components/common/button/CustomIconButton';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';

interface MyData {
  moduleData: any;
  Sync: any;
  isLoading: boolean;
  error?: string | null;
  updateModuleData: any;
  addRow: any;
  handleDelete: any;
  setIsLoading: any;
}

const Create = () => {
  const { id } = useParams();
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [DialogSave, confirmSave] = useConfirm(
    'SAVE',
    'Are you sure you want to Update this?',
  );
  const {
    moduleData,
    isLoading,
    error,
    Sync,
    updateModuleData,
    handleDelete,
    addRow,
    setIsLoading,
  }: MyData = useGetData(id);

  const onSubmit = async () => {
    return new Promise(async (resolve, reject) => {
      const ans = await confirmSave();
      if (!ans) return;
      if (id) {
        axios
          .put(`/api/${apiConstant.update}/${id}`, moduleData)
          .then((res) => {
            alert('updated successfully ');
            resolve(1);
            // setTriggerRender((prev: any) => !prev);
          })
          .catch((err) => console.log(err));
      } else {
        axios
          .post(`/api/${apiConstant.post}`, moduleData)
          .then((res) => {
            alert('create successfully');
            if (res.data)
              navigate(
                `${navigationConstant.createEditChecklist}/${res.data.id}`,
              );
            resolve(1);
            // setTriggerRender((prev: any) => !prev);
          })
          .catch((err) => {
            console.log(err);
            resolve(0);
          });
      }
    });
  };
  const uploadImageFile = async (e: any) => {
    await onSubmit();
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    formData.append('file', files[0]);
    e.target.value = '';
    setIsLoading(true);
    axios
      .patch(`/api/${apiConstant.upload}/${moduleData._id}`, formData)
      .then((res) => {
        if (res.data) {
          alert('document upload successly');
          if (res.data.files)
            updateModuleData('key-pair', null, 'files', {
              files: [res.data.files],
            });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <Box>
      <Box>
        <Loader loader={isLoading} />
        <ChildHeader text="">
          {!isView && (
            <>
              {id && (
                <Stack
                  direction="row"
                  sx={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <CustomUploadButton
                    lable={'UPLOAD'}
                    accept={'image/png,image/jpeg,image/jpg'}
                    onChange={(e) => {
                      uploadImageFile(e);
                    }}
                  />
                  {moduleData.files?.length > 0 && (
                    <>
                      <DeleteIconButton
                        tooltipTitle={'delete'}
                        onClick={() => {
                          updateModuleData('key-pair', null, 'files', {
                            files: [],
                          });
                        }}
                      />
                    </>
                  )}
                </Stack>
              )}
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{}}
              >
                <CustomButton
                  sx={{
                    backgroundColor: '#228be6',
                    color: '#e7f5ff',
                  }}
                  icon={<IoMdAdd />}
                  size="large"
                  onClick={addRow}
                >
                  ADD ROW
                </CustomButton>
                <SubmitButton
                  label={id ? 'Update' : 'submit'}
                  onClick={() => onSubmit()}
                />
              </Stack>
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          handleDelete,
          moduleData,
          updateModuleData,
        }}
      />
      <Sync isSubmitContent={false} />
      <DialogSave isSubmitContent={false} />
    </Box>
  );
};

export default Create;
