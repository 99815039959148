import moment from 'moment';
import { useState } from 'react';
// import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
  tabValue: number;
  setDateInvoiceRender: any;
}
const THead = ({
  moduleData,
  isView,
  tabValue,
  setDateInvoiceRender,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={11}
        rowSpan={3}
        labels={['Doc No', 'Rev No.', 'Eff.Dated']}
        values={['FRM-QAS-B-004', '00', '01-04-2023']}
        heading="Plating Inspection Standard Cum Report"
      />
      <tr>
        <th colSpan={5} align="left">
          Date:{' '}
          {isView ? (
            formatDate(moduleData.moduleData_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.moduleData_date)}
              onChange={(date) => {
                moduleData.moduleData_date = date;
                setDateInvoiceRender((prev: any) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={6} align="left">
          Invoice Number :
          <TableInput
            name="invoice_no"
            type="text"
            value={moduleData.invoice_no}
            onChange={(e) => {
              moduleData.invoice_no = e.target.value;
              setDateInvoiceRender((prev: any) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Part Name / No.:
        </th>
        <th colSpan={2} align="left">
          {moduleData.part_name
            ? moduleData.part_name
            : moduleData.part_id?.part_name}
        </th>
        <th colSpan={2} align="left">
          {moduleData.part_number
            ? moduleData.part_number
            : moduleData.part_id?.part_number}
        </th>
        <th colSpan={2} align="left">
          Supplier Name:
        </th>
        <th colSpan={3} align="left">
          {moduleData.supplier_name
            ? moduleData.supplier_name
            : moduleData.part_id?.supplier_id?.supplier_name}
        </th>
      </tr>
      <tr>
        {[
          'S.No',
          'Parameter',
          'Specification',
          'Method',
          'Sample lot',
          'Remarks',
        ].map((head) => (
          <th
            key={head + 0.33}
            colSpan={head === 'S.No' ? 1 : 2}
            style={{ textTransform: 'uppercase' }}
          >
            {head}
          </th>
        ))}
      </tr>
      {moduleData.pirs[tabValue] &&
        moduleData.pirs[tabValue].pir_list &&
        moduleData.pirs[tabValue].pir_list.map((spec: any, index: number) => (
          <tr key={+index + tabValue + 333.333 + '5_specs'}>
            <th>{String.fromCharCode(index + 64 + 1)}</th>
            <td colSpan={2}>{spec.parameter}</td>
            <td colSpan={2}>{spec.specification}</td>
            <td colSpan={2}>{spec.methods}</td>
            <td colSpan={2}>
              <TableInput
                name="sample_lot"
                type="number"
                value={spec.sample_lot}
                onChange={(e) => {
                  spec.sample_lot = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </td>

            <td colSpan={2}>
              <TableInput
                name="remarks"
                type="text"
                value={spec.remarks}
                onChange={(e) => {
                  spec.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </td>
          </tr>
        ))}
      <tr>
        <th colSpan={11}>Observation</th>
      </tr>
      <tr>
        {[
          'Invoice No.',
          'Date',
          'S.No.',
          1,
          2,
          3,
          4,
          5,
          'Status',
          'Qty.',
          'Sign',
        ].map((head) => (
          <th style={{ textTransform: 'uppercase' }} key={head + '111.11'}>
            {head}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
