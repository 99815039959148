import React from 'react';
import {
  AddIconButton,
  CheckIconButton,
  EditIconButton,
} from '../../../../components/common/button/CustomIconButton';
import { actionTakenTickMarks } from '../helpers/data';

const ReportsButton = ({
  process,
  reportKey,
  redirect,
}: {
  process: any;
  reportKey: string;
  redirect: any;
}) => {
  // console.log(_id);
  if (process.reason && process.action_taken) {
    if (
      actionTakenTickMarks[`${process?.reason}${process?.action_taken}`]?.[
        reportKey
      ]
    ) {
      return process[`${reportKey}_id`] ? (
        <CheckIconButton
          tooltipTitle="edit"
          onClick={() => {
            redirect(process, process[`${reportKey}_id`], reportKey);
          }}
        />
      ) : (
        <EditIconButton
          tooltipTitle="edit"
          onClick={() => {
            redirect(process, process[`${reportKey}_id`], reportKey);
          }}
        />
      );
    }
  }
  if (process.reason) {
    if (actionTakenTickMarks[`${process?.reason}`]?.[reportKey]) {
      return process[`${reportKey}_id`] ? (
        <CheckIconButton
          tooltipTitle="edit"
          onClick={() => {
            redirect(process, process[`${reportKey}_id`], reportKey);
          }}
        />
      ) : (
        <EditIconButton
          tooltipTitle="edit"
          onClick={() => {
            redirect(process, process[`${reportKey}_id`], reportKey);
          }}
        />
      );
    }
  }
  return <React.Fragment></React.Fragment>;
};
export default ReportsButton;
