import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { Table } from '../../../../components/ui/renderTable/styled';

interface IProps {
  moduleData: any;
  isView?: boolean;
}
const style1 = {
  fontSize: '1.8rem',
};
const PersonHeadTable = ({ moduleData, isView = false }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <Table style={{ width: '84vh' }}>
      <thead>
        <tr>
          <th colSpan={5} style={{ ...style1 }} align="left">
            Other contact detail:
          </th>
        </tr>
        <tr>
          <th></th>
          <th>Quality Head</th>
          <th>Supply Chain Head</th>
          <th>Plant Head</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th align="left">Name</th>
          <td>
            <TableInput
              isView={isView}
              name="quality_head_name"
              key="quality_head_name"
              type="text"
              value={moduleData.quality_head_name}
              onChange={(e) => {
                moduleData.quality_head_name = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              name="supply_chain_head_name"
              key="supply_chain_head_name"
              type="text"
              value={moduleData.supply_chain_head_name}
              onChange={(e) => {
                moduleData.supply_chain_head_name = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              name="plant_head_name"
              key="plant_head_name"
              type="text"
              value={moduleData.plant_head_name}
              onChange={(e) => {
                moduleData.plant_head_name = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
        </tr>
        <tr>
          <th align="left">Telephone</th>
          <td>
            <TableInput
              isView={isView}
              name="quality_head_telephone"
              key="quality_head_telephone"
              type="text"
              value={moduleData.quality_head_telephone}
              onChange={(e) => {
                moduleData.quality_head_telephone = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              name="supply_chain_head_telephone"
              key="supply_chain_head_telephone"
              type="text"
              value={moduleData.supply_chain_head_telephone}
              onChange={(e) => {
                moduleData.supply_chain_head_telephone = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              name="plant_head_telephone"
              key="plant_head_telephone"
              type="text"
              value={moduleData.plant_head_telephone}
              onChange={(e) => {
                moduleData.plant_head_telephone = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
        </tr>
        <tr>
          <th align="left">E-Mail</th>
          <td>
            <TableInput
              isView={isView}
              name="quality_head_email"
              key="quality_head_email"
              type="text"
              value={moduleData.quality_head_email}
              onChange={(e) => {
                moduleData.quality_head_email = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              name="supply_chain_head_email"
              key="supply_chain_head_email"
              type="text"
              value={moduleData.supply_chain_head_email}
              onChange={(e) => {
                moduleData.supply_chain_head_email = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              name="plant_head_email"
              key="plant_head_email"
              type="text"
              value={moduleData.plant_head_email}
              onChange={(e) => {
                moduleData.plant_head_email = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default PersonHeadTable;
