import { TableInput } from '../../../../components/common';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import moment from 'moment';
import DatePickerMui from '../../../../components/common/DatePickerMui';

interface IProps {
  moduleData: any;
  machineData?: any;
  updateModuleData?: any;
}

const THead = ({ machineData, moduleData, updateModuleData }: IProps) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const gaugeName = useQuery('gaugeName');
  const gaugeNumber = useQuery('gaugeNumber');

  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <thead>
      <TableCompHead
        colSpan={7}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No.:', 'Eff. Date:']}
        values={['FRM-MNT-C-013', '00', '01-04-2023']}
        heading={`${headerConstant.tableCompHeadTitle}`}
      />
      <tr>
        <th colSpan={1}>CHECKSHEET NAME</th>
        <th colSpan={2}>{moduleData.checkList_name}</th>
        <th colSpan={1}>Customer:</th>
        <th colSpan={4}>
          <TableInput
            isView={isView}
            style={{ height: '80px' }}
            name="customer"
            type="text"
            value={moduleData.customer}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th>Gauge Name</th>
        <th colSpan={2}>{gaugeName}</th>
        <th></th>
        <th colSpan={4}></th>
      </tr>
      <tr style={{ height: '50px' }}>
        <th>Gauge No.</th>
        <th colSpan={2}>{gaugeNumber}</th>
        <th colSpan={1} align="left">
          Date
        </th>
        <th colSpan={1}>
          <DatePickerMui
            label=""
            value={moduleData?.date ? moment(moduleData.date) : null}
            onChange={(date) => {
              handleInputChange('date', date);
            }}
          />
        </th>
        <th colSpan={1} align="left">
          Next Due Date
        </th>
        <th colSpan={1}>
          <DatePickerMui
            label=""
            value={
              moduleData?.next_due_date
                ? moment(moduleData.next_due_date)
                : null
            }
            onChange={(date) => {
              handleInputChange('next_due_date', date);
            }}
          />
        </th>
      </tr>

      <tr>
        <th colSpan={7} align="center">
          <img
            height={600}
            width={'auto'}
            src={moduleData.files && moduleData?.files[0]?.url}
            alt="image"
          />
        </th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
