import { MenuItem } from '@mui/material';
import { TableInput } from '../../../../../components/common';
import CustomSelect from '../../../../../components/common/SelectField';

const THead = ({
  isView,
  item,
  index,
  handleChange,
}: {
  isView: any;
  item: any;
  index: number;
  handleChange: any;
}) => {
  console.log(item);
  return (
    <thead>
      <tr>
        <td>{index + 1}</td>
        <td style={{ minWidth: '250px' }}>
          <TableInput
            isView={isView}
            name="check_point"
            type="text"
            value={item.check_point}
            onChange={(e) => {
              handleChange(e, index);
            }}
          />
        </td>
        <td style={{ minWidth: '250px' }}>
          <TableInput
            isView={isView}
            name="dimension"
            type="text"
            value={item.dimension}
            onChange={(e) => {
              handleChange(e, index);
            }}
          />
        </td>
        <td style={{ minWidth: '250px' }}>
          <TableInput
            isView={isView}
            name="method"
            type="text"
            value={item.method}
            onChange={(e) => {
              handleChange(e, index);
            }}
          />
        </td>
        <td style={{ minWidth: '120px' }}>
          <TableInput
            isView={isView}
            name="obs_1"
            type="text"
            value={item.obs_1}
            onChange={(e) => {
              handleChange(e, index);
            }}
          />
        </td>
        <td style={{ minWidth: '120px' }}>
          <TableInput
            isView={isView}
            name="obs_2"
            type="text"
            value={item.obs_2}
            onChange={(e) => {
              handleChange(e, index);
            }}
          />
        </td>
        <td style={{ minWidth: '120px' }}>
          <TableInput
            isView={isView}
            name="obs_3"
            type="text"
            value={item.obs_3}
            onChange={(e) => {
              handleChange(e, index);
            }}
          />
        </td>
        <td style={{ minWidth: '120px' }}>
          <TableInput
            isView={isView}
            name="obs_4"
            type="text"
            value={item.obs_4}
            onChange={(e) => {
              handleChange(e, index);
            }}
          />
        </td>
        <td style={{ minWidth: '120px' }}>
          <TableInput
            isView={isView}
            name="obs_5"
            type="text"
            value={item.obs_5}
            onChange={(e) => {
              handleChange(e, index);
            }}
          />
        </td>
        <td style={{ minWidth: '120px' }}>
          {isView ? (
            item.remarks
          ) : (
            <CustomSelect
              name="remarks"
              value={item.remarks || ''}
              onChange={(e) => {
                handleChange(e, index);
              }}
            >
              <MenuItem value="">NONE</MenuItem>
              <MenuItem value="C">C</MenuItem>
              <MenuItem value="NC">NC</MenuItem>
            </CustomSelect>
          )}
        </td>
      </tr>
    </thead>
  );
};

export default THead;
