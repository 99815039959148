const TFoot = () => {
  return (
    <tfoot>
      <tr>
        <th colSpan={2} rowSpan={5} align="left">
          {' '}
          LEGENDS FOR CONTROL IN CHARGE
        </th>
        <th colSpan={10} align="left">
          R - PATROL INSPECTION
        </th>
      </tr>
      <tr>
        <th colSpan={10} align="left">
          AM -AUTOMATIC MEASUREMENT
        </th>
      </tr>
      <tr>
        <th colSpan={10} align="left">
          W - OPERATOR
        </th>
      </tr>
      <tr>
        <th colSpan={10} align="left">
          P- PROCESS APPROVAL
        </th>
      </tr>
      <tr>
        <th colSpan={10} align="left">
          I - INSPECTOR
        </th>
      </tr>
      <tr>
        <th rowSpan={2} colSpan={2}>
          Doc. No.
        </th>
        <th rowSpan={2} colSpan={2}>
          FRM-ENG-06
        </th>
        <th colSpan={2}>Rev. No. - 01</th>
        <th colSpan={6} rowSpan={2}></th>
      </tr>
      <tr>
        <th colSpan={2}>Eff. Date- 01-11-2017</th>
      </tr>

      <tr>
        <th colSpan={6}> Prepared By:</th>
        <th colSpan={6}> Approved By:</th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
