import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridSelect from '../../../components/common/inputs/GridSelect';
import { SelectChangeEvent } from '@mui/material';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<any>({
    faculty_name: '',
    faculty_type: '',
    address: '',
  });

  const [render, setRender] = useState(false);
  const [autoCompleteDepartment, setAutocompleteDepartment] = useState('');
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Faculty' : 'Create Faculty'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterlistofFaculty/${id}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleFaculty(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/masterlistofFaculty/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/masterlistofFaculty', moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'faculty_name'}
        name={'faculty_name'}
        html_for={'faculty_name'}
        label_name={'Faculty Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.faculty_name}
        onChange={handleFaculty}
      />
      <GridSelect
        // last_child={1}
        // id={'faculty_type'}
        name="faculty_type"
        label_name="Type"
        html_for={'faculty_type'}
        value={moduleData?.faculty_type || ''}
        onChange={handleFaculty}
      >
        <MenuItem value="Internal">Internal</MenuItem>
        <MenuItem value="External">External</MenuItem>
      </GridSelect>
      {/* <GridInputs
        id={'faculty_type'}
        name={'faculty_type'}
        html_for={'faculty_type'}
        label_name={'Type:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.faculty_type}
        onChange={handleFaculty}
      /> */}
      <GridInputs
        id={'address'}
        name={'address'}
        html_for={'address'}
        label_name={'Address:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.address}
        onChange={handleFaculty}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
