import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';
import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import GridSelect from '../../../components/common/inputs/GridSelect';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../components/common/Loader';
import ModalCustom from '../../../components/common/Modal';

import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import { DivStyled } from '../../../assets/styles/components/DivStyledLableInputs';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../components/common/button/CustomIconButton';
import PartTable from './components/PartTable';
import { Checkbox } from '@mui/material';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({
    exclude_report: false,
  });
  const [files, setFiles] = useState<any>(null);
  const [images, setImages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Customer Complaint' : 'Create Customer Complaint'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [customerData, setCustomerData] = useState([]);
  const [defectData, setDefectData] = useState([]);
  const [isPartModal, setIsPartModal] = useState(false);
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/customerComplaintRoutes/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        setCustomerData(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get('/api/defectRoutes/defectList/defectForAutoComplete')
      .then((res) => {
        setDefectData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  const handleSumbit = async () => {
    if (!data.customer_id) {
      alert('Please enter customer name');
      return;
    }
    if (!data.defect_id) {
      alert('Please enter defect name');
      return;
    }
    const ans = await confirmSubmit();
    if (!ans) return;

    setIsLoading(true);

    if (typeof data.customer_id === 'object') {
      data.customer_id = data.customer_id._id;
    }
    if (typeof data.defect_id === 'object') {
      data.defect_id = data.defect_id._id;
    }
    if (typeof data.part_id === 'object') {
      data.part_id = data.part_id._id;
    }

    if (id) {
      await axios
        .put(`/api/customerComplaintRoutes/${id}`, data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/customerComplaintRoutes', data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <GridDatePicker
        label_name="Date of Occurance"
        html_for={'issue_reported_date'}
        value={data?.issue_reported_date || null}
        onChange={(date) => {
          data.issue_reported_date = date;
        }}
      />
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part Number'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof data?.part_id === 'object' && data?.part_id !== null
                  ? data?.part_id?.part_number
                  : data?.partNumber || ''}
              </p>
            </Grid>

            {data.part_id ? (
              <Grid item xs={2}>
                <RemoveIconButton
                  tooltipTitle="remove part"
                  onClick={() => {
                    data.part_id = null;
                    data.partNumber = null;
                    data.partName = null;

                    setRender((prev) => !prev);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={2}>
                <AddIconButton
                  tooltipTitle="add part"
                  onClick={() => {
                    setIsPartModal(true);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </DivStyled>
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part Name'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof data?.part_id === 'object' && data?.part_id !== null
                  ? data?.part_id?.part_name
                  : data?.partName || ''}
              </p>
            </Grid>
          </Grid>
        </div>
      </DivStyled>

      <AutocompleteGridmui
        id={'customer_name'}
        label={'Customer Name'}
        option_name={'customer_name'}
        arrayofObj={customerData}
        value={data.customer_name || data.customer_id || ''}
        onChange={(e, value) => {
          data.customer_id = value._id;
          data.customer_name = value;
          setRender((prev) => !prev);
        }}
      />
      <AutocompleteGridmui
        id={'defect_name'}
        label={'Defects'}
        option_name={'defect_name'}
        arrayofObj={defectData}
        value={data.defect_name || data.defect_id || ''}
        onChange={(e, value) => {
          data.defect_id = value._id;
          data.defect_name = value;
          setRender((prev) => !prev);
        }}
      />
      <GridSelect
        name="type_of_complaint"
        label_name="Complaint category"
        html_for={'type_of_complaint'}
        value={data?.type_of_complaint || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Critical">Critical</MenuItem>
        <MenuItem value="Major">Major</MenuItem>
      </GridSelect>

      <GridInputs
        id={'root_cause'}
        name={'root_cause'}
        html_for={'root_cause'}
        label_name={'Root Cause: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.root_cause || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'action_taken'}
        name={'action_taken'}
        html_for={'action_taken'}
        label_name={'Action Taken: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.action_taken || ''}
        onChange={handleCustomerData}
      />
      <GridDatePicker
        label_name="Target Date"
        html_for={'target_date'}
        value={data?.target_date || null}
        onChange={(date) => {
          data.target_date = date;
        }}
      />
      <GridDatePicker
        label_name="Actual Date"
        html_for={'actual_closure_date'}
        value={data?.actual_closure_date || null}
        onChange={(date) => {
          data.actual_closure_date = date;
        }}
      />
      <GridInputs
        id={'resp'}
        name={'resp'}
        html_for={'resp'}
        label_name={'Resp.: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.resp || ''}
        onChange={handleCustomerData}
      />
      <h1>Effectiveness (6 Month)</h1>
      <GridSelect
        name="jan"
        label_name="1"
        html_for={'jan'}
        value={data?.jan || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="feb"
        label_name="2"
        html_for={'feb'}
        value={data?.feb || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="mar"
        label_name="3"
        html_for={'mar'}
        value={data?.mar || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="apr"
        label_name="4"
        html_for={'apr'}
        value={data?.apr || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="may"
        label_name="5"
        html_for={'may'}
        value={data?.may || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="jun"
        label_name="6"
        html_for={'jun'}
        value={data?.jun || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridInputs
        id={'horizontal_deployment'}
        name={'horizontal_deployment'}
        html_for={'horizontal_deployment'}
        label_name={'Horizontal Deployment:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.horizontal_deployment || ''}
        onChange={handleCustomerData}
      />
      <h1>Institutionalise the Problem with Upadation of Followings</h1>
      <GridSelect
        name="quality_alert"
        label_name="Quality Alert"
        html_for={'quality_alert'}
        value={data?.quality_alert || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="pfd"
        label_name="DRG / PFD"
        html_for={'pfd'}
        value={data?.pfd || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="pfmea"
        label_name="FMEA/ QA Matrix"
        html_for={'pfmea'}
        value={data?.pfmea || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="control_plan"
        label_name="Control Plan"
        html_for={'control_plan'}
        value={data?.control_plan || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="inspection_standard"
        label_name="Inspection Std."
        html_for={'inspection_standard'}
        value={data?.inspection_standard || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="wi"
        label_name="Work Instructions"
        html_for={'wi'}
        value={data?.wi || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridInputs
        id={'others'}
        name={'others'}
        html_for={'others'}
        label_name={'Lessons Learnt /Others: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.others || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remark/Status: '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.remarks || ''}
        onChange={handleCustomerData}
      />
      <div>
        <Checkbox
          name="exclude_report"
          checked={data?.exclude_report}
          onChange={(e) => {
            setData((prev: any) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }));
          }}
        />

        <span
          style={{
            fontWeight: '500',
            fontSize: '20px',
          }}
        >
          Exclude from report
        </span>
      </div>

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
      <ModalCustom
        openModal={isPartModal}
        closeModal={() => {
          setIsPartModal(false);
        }}
        title="PART"
      >
        <PartTable moduleData={data} />
      </ModalCustom>
    </>
  );
};

export default Create;
