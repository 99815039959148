import React, { useRef, useState } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { TableCustom } from '../../../../components/common';
import ApprovalSelect from './ApprovalSelect';
import formatDate from '../../../../components/common/formatDate';
import {
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import { UpdateButton } from '../../../../components/common/button/CustomButtons';
import { useAuth } from '../../../../services/auth/AuthProvider';
import SelectChemicalCompositionType from '../attachChemicalCompositionModule/components/SelectChemicalCompositionType';
import ModalCustom from '../../../../components/common/Modal';

interface IProps {
  handleChangeStatus: (
    e: React.ChangeEvent<HTMLSelectElement>,
    documentIndex: number
  ) => void;
  deleteDocument: (id: string) => void;
  updateStatus: (id: string, documentIndex: number) => void;
  handleDeleteCC: any;
  statusObj: {
    pending: number;
    accepted: number;
    rejected: number;
  };
  tableData: {
    [key: string | number]: any;
  }[];
}

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ITEM NUMBER',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'MATERIAL INCOMING DATE',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'NAME',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'STATUS',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'CHEMICAL COMPOSITION ',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ACTION',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'UPDATE STATUS',
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const AdminTable = ({
  handleChangeStatus,
  deleteDocument,
  updateStatus,
  statusObj,
  tableData,
  handleDeleteCC,
}: IProps) => {
  const navigate = useNavigate();
  const { state } = useAuth();
  const { user, IS } = state;
  const [selectCCOpenModal, setSelectCCOpenModal] = useState(false);
  const materialId = useRef(null);
  const ccId = useRef(null);
  return (
    <React.Fragment>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {tableData.map(
            (document: { [key: string]: any }, documentIndex: number) => (
              <TableRow>
                <TableCell align="center">{documentIndex + 1}</TableCell>
                <TableCell align="center">
                  {document.part_id?.part_number}
                </TableCell>
                <TableCell align="center">
                  {document.report_prepared_by}
                </TableCell>

                <TableCell align="center">
                  {formatDate(document.materialIncoming_date)}
                </TableCell>
                <TableCell align="center">
                  <ApprovalSelect
                    {...{
                      handleChangeStatus,
                      document,
                      documentIndex,
                      statusObj,
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <EditIconButton
                    tooltipTitle="edit"
                    onClick={() => {
                      ccId.current = document?.chemical_composition_id?._id;
                      materialId.current = document?._id;
                      if (ccId.current) {
                        navigate(
                          `/${
                            user?.userType === IS.Admin ? 'admin' : 'supervisor'
                          }/chemicalCompositionIncomingMaterial/${
                            document?._id
                          }/${ccId.current}?cc_type=${
                            document?.chemical_composition_id?.cc_type
                          }`
                        );
                      } else {
                        setSelectCCOpenModal(true);
                      }
                    }}
                  />
                  {document?.chemical_composition_id?._id && (
                    <DeleteIconButton
                      tooltipTitle="delete"
                      onClick={() =>
                        handleDeleteCC(
                          document._id,
                          document.chemical_composition_id._id
                        )
                      }
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  <ViewIconButton
                    tooltipTitle="view"
                    onClick={() =>
                      navigate(
                        `/${
                          user?.userType === 2 ? 'admin' : 'supervisor'
                        }/materialIncoming/${document.part_id?._id}/${
                          document._id
                        }?isView=true&partName=${
                          document.part_id?.part_name
                        }&partNumber=${document.part_id?.part_number}`
                      )
                    }
                  />
                  <EditIconButton
                    tooltipTitle="edit"
                    onClick={() =>
                      navigate(
                        `/${
                          user?.userType === 2 ? 'admin' : 'supervisor'
                        }/materialIncoming/${document.part_id?._id}/${
                          document._id
                        }?isView=false&partName=${
                          document.part_id?.part_name
                        }&partNumber=${document.part_id?.part_number}`
                      )
                    }
                  />
                  <DeleteIconButton
                    tooltipTitle="delete"
                    onClick={() => deleteDocument(document._id)}
                  />
                </TableCell>
                <TableCell align="center">
                  <UpdateButton
                    label="update"
                    size="small"
                    onClick={() => updateStatus(document._id, documentIndex)}
                  />
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </TableCustom>
      <ModalCustom
        title={'SELECT CHEMICAL COMPOSITION'}
        openModal={selectCCOpenModal}
        closeModal={() => {
          setSelectCCOpenModal(false);
        }}
      >
        <SelectChemicalCompositionType
          materialId={materialId.current || ''}
          ccId={ccId.current || ''}
        />
      </ModalCustom>
    </React.Fragment>
  );
};

export default AdminTable;
