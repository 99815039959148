import React, { useState } from 'react';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: '1',
  backgroundColor: '#ffec99',
  // color: '#fff',
} as const;
const TBodyLeftside = ({ partData, item }: any) => {
  const [render, setRender] = useState(false);
  return (
    <>
      {['a1', 'a2', 'a3', 'a4', 'a5', 'a6', 'a7', 'a8', 'a9', 'a10', 'a11'].map(
        (key, index) => (
          <td
            key={index + key}
            style={
              key === 'a1'
                ? {
                    ...stickStylesColumn,
                    left: 50,
                    width: '350px',
                    minWidth: '350px',
                  }
                : {}
            }
            rowSpan={2}
          >
            <AutocompleteMuiCustom
              id="part_number"
              label=""
              option_name="part_number"
              option2="part_name"
              arrayofObj={partData}
              value={item?.[key]}
              onChange={(e, value) => {
                item[key] = value;
                setRender((prev) => !prev);
              }}
            />
          </td>
        ),
      )}
    </>
  );
};

export default TBodyLeftside;
