import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'Sr. No.',
  'Date',
  'Supplier Name',
  'Part Name',
  'Insp. Lot Qty.',
  'Ok Qty.',
  'NG Qty.',
  'Reason (If Not Ok) ',
  'Status',
  'Rework Qty.',
  'Insp. Signature',
  'Remark',
  'Action',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const date = useQuery('annual_date');
  return (
    <thead>
      <TableCompHead
        colSpan={13}
        rowSpan={3}
        labels={['Doc No', 'Rev No.', 'Eff. Date']}
        values={['FRM-QAS-D-004', '01', '01-04-2018']}
        heading={`Final Inspection Rejection Records (Plating): Month - (${date})`}
      />
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading + index} colSpan={1}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
