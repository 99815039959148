import { HiDocumentReport } from 'react-icons/hi';
import { TbReportAnalytics } from 'react-icons/tb';
import { TbReport } from 'react-icons/tb';
import { RiDeleteBin4Fill } from 'react-icons/ri';
import { TbFileReport } from 'react-icons/tb';

const subTiles = [
  {
    sNo: 1,
    link: '/common/pdc/rotorHourlyProductionList',
    isLink: true,
    icon: <TbFileReport size="5rem" color="#a12222" />,
    p: 'Hourly Production Report',
    span: 'PDC',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/pdc/dailyProductionRedBinList',
    isLink: true,
    icon: <RiDeleteBin4Fill size="5rem" color="#a12222" />,
    p: 'RED BIN REPORT',
    span: 'PDC',
    isIcon: true,
  },
];
const subTiles2 = [
  {
    sNo: 1,
    link: '/common/rotorSection/dailyProductionList',
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'DAILY PRODUCTION',
    span: 'ROTOR',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/rotorSection/dailyProductionRotorDailyRejectionList',
    isLink: true,
    icon: <TbReport size="5rem" color="#a12222" />,
    p: 'DAILY REJECTION NOTE',
    span: 'ROTOR',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/rotorSection/redBinRotorSection',
    isLink: true,
    icon: <RiDeleteBin4Fill size="5rem" color="#a12222" />,
    p: 'RED BIN REPORT',
    span: 'ROTOR',
    isIcon: true,
  },
];

const subTiles3 = [
  {
    sNo: 1,
    link: '/common/pressShop/dailyProductionStrokesList',
    isLink: true,
    icon: <TbReportAnalytics size="5rem" color="#064a29" />,
    p: 'DAILY PRODUCTION REPORT',
    span: 'PRESS SHOP',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/pressShop/redbinPressShop',
    isLink: true,
    icon: <RiDeleteBin4Fill size="5rem" color="#a12222" />,
    p: 'RED BIN REPORT',
    span: 'PRESS SHOP',
    isIcon: true,
  },
];

const procCncTiles = [
  {
    sNo: 1,
    link: '/common/cnc/dailyHourlyProdCNCList',
    isLink: true,
    icon: <RiDeleteBin4Fill size="5rem" color="#a12222" />,
    p: 'DAILY HOURLY PRODUCTION REPORT',
    span: 'CNC',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/cnc/redbinCNC',
    isLink: true,
    icon: <RiDeleteBin4Fill size="5rem" color="#a12222" />,
    p: 'RED BIN REPORT',
    span: 'CNC',
    isIcon: true,
  },
];

export { subTiles, subTiles2, subTiles3, procCncTiles };
