import { TableInput } from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { useQuery } from '../../../../../hooks/UseQuery';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import { getTotalColsSpan } from '../../../../../utils/helpers/utils';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
  moduleData: any;
  updateModuleData?: any;
}

const THead = ({ moduleData, updateModuleData }: IProps) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const processName = useQuery('processName');

  const handleInputChange = (
    fieldName: string,
    value: string | number | null,
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <thead>
      <TableCompHead
        colSpan={getTotalColsSpan(HeaderStandard)}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No.:', 'Date']}
        values={['F-MR-08A', '07', '02.09.2021']}
        heading={`${headerConstant.tableCompHeadTitle} ${
          moduleData?.checkList_name || ''
        }`}
      />
      <tr>
        <th colSpan={1} align="left">
          CHECKSHEET NAME
        </th>
        <th colSpan={1} align="left">
          {moduleData.checkList_name}
        </th>
        <th colSpan={6}></th>
      </tr>
      <tr style={{ height: '50px' }}>
        <th colSpan={3} align="left">
          Date/Shift:
          {isView ? (
            formatDate(moduleData.pm_date_1)
          ) : (
            <DatePickerMui
              label=""
              value={
                moduleData?.pm_date_1 ? moment(moduleData.pm_date_1) : null
              }
              onChange={(date) => {
                handleInputChange('pm_date_1', date);
              }}
            />
          )}
        </th>
        <th colSpan={2}></th>
        <th colSpan={2} align="left">
          Auditor Name:
          <TableInput
            isView={isView}
            name="auditor_name"
            type="text"
            value={moduleData.auditor_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr style={{ height: '50px' }}>
        <th colSpan={3} align="left">
          Process Name:{processName}
        </th>
        <th colSpan={2}></th>
        <th colSpan={2} align="left">
          Auditee Name:
          <TableInput
            isView={isView}
            name="auditee_name"
            type="text"
            value={moduleData.auditee_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
