export const StyledCircle = ({ item }: { item: any }) => {
  let fontSize = '2rem';

  if (item.topic_type === '◔') {
    fontSize = '3rem';
  }
  if (item.topic_type === '◑') {
    fontSize = '2rem';
  }

  if (item.topic_type === '◕') {
    fontSize = '3rem';
  }
  if (item.topic_type === '●') {
    fontSize = '4rem';
  }
  return (
    <td
      align="center"
      height="50px"
      style={{
        fontSize,
      }}
    >
      {item.topic_type}
    </td>
  );
};
