import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TimePicker } from '@mui/x-date-pickers';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import TextAreaInput from '../../../../../components/common/TextAreaInput';
import { CustomButton } from '../../../../../components/common/button/CustomButtons';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  handleAddRow: any;
}
const TBody7 = ({
  moduleData,
  isView,
  handelDeleteRows,
  handleAddRow,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      <tr
        style={{
          fontSize: '2rem',
          background: '#dee2e6',
        }}
      >
        <th colSpan={8}>Effectiveness Verification</th>
        <th>
          <CustomButton
            sx={{
              backgroundColor: '#1c7ed6',
            }}
            onClick={() => {
              handleAddRow('effectiveness_verification');
            }}
          >
            ADD ROWS
          </CustomButton>
        </th>
      </tr>
      <tr>
        <th colSpan={8}>For next 3 Months</th>
        <th></th>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        <th>Month</th>
        <th colSpan={3}>First Month</th>
        {['Second Monh', 'Third Month ', 'Action'].map((head, index) => (
          <th
            key={head}
            style={{ textTransform: 'uppercase' }}
            colSpan={index === 2 ? 1 : 2}
          >
            {head}
          </th>
        ))}
      </tr>
      {moduleData?.effectiveness_verification &&
        moduleData?.effectiveness_verification?.map(
          (item: any, index: number) => (
            <tr>
              <th colSpan={1}>{'Status'}</th>

              <td colSpan={3}>
                {isView ? (
                  item.first_month_status
                ) : (
                  <CustomSelect
                    label=""
                    name="first_month_status"
                    value={item.first_month_status}
                    onChange={(e: any) => {
                      item.first_month_status = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="">NONE</MenuItem>
                    <MenuItem value="YES">OK</MenuItem>
                    <MenuItem value="NO">NOT OK</MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td colSpan={2}>
                {isView ? (
                  item.second_month_status
                ) : (
                  <CustomSelect
                    label=""
                    name="second_month_status"
                    value={item.second_month_status}
                    onChange={(e: any) => {
                      item.second_month_status = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="">NONE</MenuItem>
                    <MenuItem value="YES">YES</MenuItem>
                    <MenuItem value="NO">NO</MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td colSpan={2}>
                {isView ? (
                  item.third_month_status
                ) : (
                  <CustomSelect
                    label=""
                    name="third_month_status"
                    value={item.third_month_status}
                    onChange={(e: any) => {
                      item.third_month_status = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="">NONE</MenuItem>
                    <MenuItem value="YES">YES</MenuItem>
                    <MenuItem value="NO">NO</MenuItem>
                  </CustomSelect>
                )}
              </td>
              {!isView && (
                <td style={{ minWidth: '100px', textAlign: 'center' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() =>
                      handelDeleteRows(index, 'effectiveness_verification')
                    }
                  />
                </td>
              )}
            </tr>
          )
        )}
    </tbody>
  );
};

export default TBody7;
