import { PhotoCamera } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CustomButton,
  SaveButton,
} from '../../components/common/button/CustomButtons';
import CustomUploadButton from '../../components/common/button/CustomUploadButton';
import InputMuiCustom from '../../components/common/inputs/InputMuiCustom';
import { useAuth } from '../../services/auth/AuthProvider';
// import handleChangeSpec from '../../pir/helpers/handleChangeSpec';

const Profile = () => {
  const { state, login } = useAuth();
  const [preview, setPreview] = useState<string | null>(null);
  const [profile, setProfile] = useState<null | File | Blob | MediaSource>(
    null
  );
  const [userData, setUserData] = useState({
    name: state.user?.name,
    username: state.user?.username,
    profile: state.user?.profile,
    confirmPassword: '',
    password: '',
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (!profile) {
      setPreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(profile);
    setPreview(objectUrl);
    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [profile]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  function onSubmit() {
    let formisvalid = true;
    if (!userData) {
      return;
    }
    if (userData.confirmPassword !== userData.password) {
      alert('password and confirm password is not match');
      return;
    }
    if (userData.password.length) {
      let regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[a-z]).{8,}$/;
      if (!regex.test(userData.password)) {
        formisvalid = false;
        alert('password must includes capital letter, number and symbol');
      }
    }
    if (formisvalid) {
      const formdata = new FormData();
      formdata.append('name', userData.name as string);
      formdata.append('username', userData.username as string);
      formdata.append('password', userData.password);
      if (profile) {
        formdata.append('profile', profile as File);
      }
      axios
        .patch(`/api/userRoutes/${state.user?._id}`, formdata)
        .then(() => {
          login();
          navigate('/');
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }
  return (
    <Stack sx={{ marginBottom: '1rem' }} alignItems="center">
      <Stack alignItems="center" gap={1} sx={{ marginTop: '1rem' }}>
        <Avatar
          alt="profile"
          className="admin_img"
          sx={{ width: 250, height: 250 }}
          src={preview ? preview : (state.user?.profile as string)}
        />
        <CustomUploadButton
          lable="Change Picture"
          accept="image/png,image/jpeg,image/jpg"
          onChange={(e) => {
            if (e.target?.files) setProfile(e.target.files[0]);
          }}
        />
      </Stack>
      <Stack gap={2} sx={{ width: 300, marginTop: 3 }}>
        <InputMuiCustom
          name="name"
          type="text"
          value={userData.name}
          placeholder="Name"
          onChange={handleChange}
        />
        <InputMuiCustom
          name="username"
          type="text"
          value={userData.username}
          placeholder="Username"
          onChange={handleChange}
        />
        <InputMuiCustom
          name="password"
          type="password"
          value={userData.password}
          placeholder="Password"
          onChange={handleChange}
        />
        <InputMuiCustom
          name="confirmPassword"
          type="confirmPassword"
          value={userData.confirmPassword}
          placeholder="Confirm Password"
          onChange={handleChange}
        />
        <SaveButton label="Save" onClick={() => onSubmit()} />
      </Stack>
    </Stack>
  );
};

export default Profile;
