// for handling color change of obs based on remarks

export const handleChangeColorOnRemarks = (spec: any, remarks: string) => {
  if (remarks === 'under deviation' || remarks === 'tbc') {
    spec?.check_0_colors?.forEach((color: string, index: number) => {
      if (color === '#f7aeae') {
        spec.check_0_colors[index] = '#fbfbac';
      }
    });
  }
  if (
    remarks === 'ok' ||
    remarks === 'none' ||
    remarks === 'other' ||
    remarks === 'operation not done'
  ) {
    spec?.check_0_colors?.forEach((color: string, index: number) => {
      if (color === '#fbfbac') {
        spec.check_0_colors[index] = '#f7aeae';
      }
    });
  }
};
