import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import { TableInput } from '../../../../../components/common';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { convToTime } from '../../../../../utils/helpers';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'Sr. No',
  'Rej. Rec From',
  'Part Name',
  'Defects',
  'Check Qty',
  'Rej. Qty',
  'Rej%',
  'Cause',
  'Rework',
  'Scrap',
  'Corrective & Preventive Action',
  'Resp.',
  'Target Date',
  'Status',
  'Red Bin Approval',
  'Remarks',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={17}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-QA-29  ', '01', '01.07.2019']}
        heading="Red Bin Report"
      />
      <tr>
        <th colSpan={2}>Date : </th>
        <th colSpan={15} align="left">
          {isView ? (
            formatDate(moduleData.dailyProduction_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.dailyProduction_date)}
              onChange={(date) => {
                moduleData.dailyProduction_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={2}>Team Members</th>
        <th colSpan={6}>
          <TableInput
            isView={isView}
            name="team_members"
            type="text"
            value={moduleData.team_members}
            onChange={(e) => {
              moduleData.team_members = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2}>Shop Floor Members</th>
        <th colSpan={7}>
          <TableInput
            isView={isView}
            name="shop_floor_members"
            type="text"
            value={moduleData.shop_floor_members}
            onChange={(e) => {
              moduleData.shop_floor_members = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2}>Sign-</th>
        <th colSpan={6}>
          <TableInput
            isView={isView}
            name="team_members_sign"
            type="text"
            value={moduleData.team_members_sign}
            onChange={(e) => {
              moduleData.team_members_sign = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2}>Sign-</th>
        <th colSpan={7}>
          <TableInput
            isView={isView}
            name="shop_floor_members_sign"
            type="text"
            value={moduleData.shop_floor_members_sign}
            onChange={(e) => {
              moduleData.shop_floor_members_sign = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th
            key={heading + index}
            // rowSpan={heading === 'Remarks' ? 1 : 2}
            colSpan={heading === 'Remarks' ? 2 : 1}
          >
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
