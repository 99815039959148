import React, { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../../components/common';
import CustomSelect from '../../../../../../components/common/SelectField';
import { Checkbox, MenuItem } from '@mui/material';
import formatDate from '../../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData &&
        moduleData?.c2?.map((process: any, index: number) => (
          <>
            {process.heading && (
              <tr style={{ background: '#f1f3f5' }}>
                <th colSpan={11} align="left">
                  {process?.heading}
                </th>
              </tr>
            )}
            <tr>
              <td align="left">{process?.s_no}</td>
              <th align="left">{process?.check_point}</th>
              <td align="left">
                <TableInput
                  isView={isView}
                  name="dimension"
                  type="text"
                  value={process.dimension}
                  onChange={(e: any) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td align="left">
                <TableInput
                  isView={isView}
                  name="method"
                  type="text"
                  value={process.method}
                  onChange={(e: any) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {[1, 2, 3, 4, 5, 6].map((num) => (
                <td align="left">
                  <TableInput
                    isView={isView}
                    name={`observation_${num}`}
                    type="text"
                    value={process.observation[num - 1] || ''}
                    onChange={(e: any) => {
                      process.observation[num - 1] = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              ))}
              <td align="left">
                {isView ? (
                  process.status
                ) : (
                  <CustomSelect
                    name="status"
                    value={process.status}
                    onChange={(e: any) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="">NONE</MenuItem>
                    <MenuItem value="c">C</MenuItem>
                    <MenuItem value="nc">NC</MenuItem>
                  </CustomSelect>
                )}
              </td>
            </tr>
          </>
        ))}
      <tr>
        <td colSpan={4}>
          Conforming
          <Checkbox
            name="conforming"
            checked={moduleData?.conforming || false}
            onChange={(e: any) => {
              moduleData.conforming = e.target.checked;
              if (moduleData.conforming === true) {
                moduleData.non_conforming = false;
              }
              setRender((prev) => !prev);
            }}
          />
          Non Conforming
          <Checkbox
            name="non_conforming"
            checked={moduleData?.non_conforming || false}
            onChange={(e: any) => {
              moduleData.non_conforming = e.target.checked;
              if (moduleData.non_conforming === true) {
                moduleData.conforming = false;
              }
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td colSpan={11}>NCR Reference</td>
      </tr>
    </tbody>
  );
};

export default TBody;
