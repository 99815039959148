import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const machine_name = useQuery('machine_name');
  const machine_no = useQuery('machine_no');

  return (
    <thead>
      <TableCompHead
        colSpan={9}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No : ', 'Eff. Date:']}
        values={['FRM-QAS-A-011', '00', '01-04-2023']}
        heading="Breakdown Analysis Report"
      />
      <tr>
        <th colSpan={5} align="left">
          Analysis Date:
          <DatePickerMui
            label=""
            onChange={(date) => {
              moduleData.analysis_date = moment(date || null);
              setRender((prev: any) => !prev);
            }}
            value={
              (moduleData?.analysis_date &&
                moment(moduleData?.analysis_date)) ||
              null
            }
          />
        </th>
        <th colSpan={4} align="left">
          Team Members:
          <TableInput
            name="team_member"
            type="text"
            value={moduleData.team_member}
            onChange={(e) => {
              moduleData.team_member = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2}>M/c Or Tool Name</th>
        <th colSpan={3}>{machine_name}</th>
        <th colSpan={1}>M/c Or Tool No</th>
        <th colSpan={3}>{machine_no}</th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Problem Date/Period:{' '}
          <DatePickerMui
            label=""
            onChange={(date) => {
              moduleData.problem_period_date = moment(date || null);
              setRender((prev: any) => !prev);
            }}
            value={
              (moduleData?.problem_period_date &&
                moment(moduleData?.problem_period_date)) ||
              null
            }
          />
        </th>
        <th colSpan={4} align="left">
          B/D Hours:{' '}
          <TableInput
            name="bd_hours"
            type="text"
            value={moduleData.bd_hours}
            onChange={(e) => {
              moduleData.bd_hours = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2}>Problem Statement :</th>
        <th colSpan={7}>{moduleData?.reason} </th>
      </tr>
    </thead>
  );
};

export default THead;
